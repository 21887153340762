import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";

interface UserNamesState {
    userNameMap: {[key: string]: string};
}

const initialState = {userNameMap: {}} as UserNamesState;

const UserNamesSlice = createSlice({
    name: "userNames",
    initialState,
    reducers: {
        updateUserNames(state, action: PayloadAction<{[key: string]: string}>) {
            state.userNameMap = action.payload;
        },
    },
});

export const {updateUserNames} = UserNamesSlice.actions;
export default UserNamesSlice.reducer;
