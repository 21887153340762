import counterReducer from "./ClickCounter";
import loginReducer from "./LoginData";
import measurementListReducer from "./MeasurementList";
import experimentsTableReducer from "./ExperimentsTable";
import measurementsTableReducer from "./MeasurementsTable";
import studiesTableReducer from "./StudiesTable";
import {CombinedState, Reducer, ReducersMapObject} from "redux";
import userNames from "./UserNames";

function combineReducers<S>(reducers: ReducersMapObject<S, any>): Reducer<CombinedState<S>> {
    const reducerKeys = Object.keys(reducers);
    type reducerKeyType = keyof typeof reducers;

    return (state, action): any => {
        let newState: CombinedState<S> = {} as CombinedState<S>;

        let pieces: string[] = action.type.split("/", 2);

        if (pieces.length == 2 && reducerKeys.includes(pieces[0])) {
            reducerKeys.forEach((key) => {
                if (key == pieces[0]) {
                    let fn = reducers[key as reducerKeyType];
                    newState[key as reducerKeyType] = fn(
                        state === undefined ? undefined : state[key as reducerKeyType],
                        action
                    );
                } else {
                    if (state !== undefined) {
                        newState[key as reducerKeyType] = state[key as reducerKeyType];
                    }
                }
            });
        } else {
            reducerKeys.forEach((key) => {
                let fn = reducers[key as reducerKeyType];
                newState[key as reducerKeyType] = fn(
                    state === undefined ? undefined : state[key as reducerKeyType],
                    action
                );
            });
        }
        return newState;
    };
}

const rootReducer = combineReducers({
    loginState: loginReducer,
    counter: counterReducer,
    measurements: measurementListReducer,
    experimentMeasurements: measurementListReducer,
    experimentsTable: experimentsTableReducer,
    measurementsTableReducer: measurementsTableReducer,
    userNames: userNames,
    studiesTable: studiesTableReducer,
});

export default rootReducer;
