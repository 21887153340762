import {styled} from "@mui/material/styles";
import {getConfig, useConfig} from "../../RuntimeConfig";
import {enqueueSnackbar} from "notistack";
import {
    snackbarError,
    snackbarInfo,
    snackbarSuccess,
    snackbarWarning,
    snackbarWithClose,
    snackbarWithLink,
} from "../../helpers/error";
import {dispatchEvent} from "../../helpers/EventDispatcher";
import {useEffect} from "react";

interface EventProviderProps {
    className?: string;
    children: React.ReactNode;
}

class RetriableError extends Error {
    httpStatus: number;

    constructor(status: number) {
        super();

        this.httpStatus = status;
    }
}
class FatalError extends Error {}

function sendToSnackbar(message: string, variant: string, linkTitle?: string, link?: string) {
    let v = undefined;
    if (variant == "error") {
        v = snackbarError;
    } else if (variant === "success-persistent") {
        v = snackbarWithClose(true, snackbarSuccess);
    } else if (variant === "success") {
        v = snackbarSuccess;
    } else if (variant === "info") {
        v = snackbarInfo;
    } else if (variant === "info-persistent") {
        v = snackbarWithClose(true, snackbarInfo);
    } else if (variant === "warning") {
        v = snackbarWarning;
    }

    if (linkTitle !== undefined && link !== undefined) {
        v = snackbarWithLink(linkTitle, link, v);
    }

    enqueueSnackbar(message, v);
}

// (async () => {
//     await fetchEventSource(getConfig("backendUrl") + "/event/", {
//         credentials: "include",
//         async onopen(response) {
//             // @ts-ignore
//             if (response.ok && response.headers.get("content-type").split(";")[0] === EventStreamContentType) {
//                 return; // everything's good
//             } else if (
//                 response.status >= 400 &&
//                 response.status < 500 &&
//                 response.status !== 429 &&
//                 response.status !== 403 &&
//                 response.status !== 503 // Special case, lost backend database connection, we would want to retry
//             ) {
//                 // client-side errors are usually non-retriable:
//                 throw new FatalError();
//             } else {
//                 throw new RetriableError(response.status);
//             }
//         },
//         onmessage(msg) {
//             // if the server emits an error message, throw an exception
//             // so it gets handled by the onerror callback below:
//             if (msg.event === "FatalError") {
//                 throw new FatalError(msg.data);
//             }
//
//             // Ignore ping keepalives
//             if (msg.event === "ping") {
//                 return;
//             }
//
//             console.log(msg);
//
//             if (msg.event === "message") {
//                 let ev = JSON.parse(msg.data);
//                 if (ev.type === "snack") {
//                     sendToSnackbar(ev.message, ev.variant, ev.linkTitle, ev.link);
//                 }
//                 if (ev.type === "event") {
//                     dispatchEvent(ev, msg);
//                 }
//             }
//         },
//         onclose() {
//             // if the server closes the connection unexpectedly, retry:
//             throw new RetriableError(0);
//         },
//         onerror(err) {
//             // if (err instanceof FatalError) {
//             //     throw err; // rethrow to stop the operation
//             // } else {
//             //     // do nothing to automatically retry. You can also
//             //     // return a specific retry interval here.
//             // }
//         },
//     });
// })();

var reconnectTime = 1;
var eventSource: EventSource;
var lastEventId: string;

function triggerReconnect() {
    setTimeout(() => {
        newEventStream();
        reconnectTime *= 2;
        if (reconnectTime > 64) {
            reconnectTime = 64;
        }
    }, reconnectTime * 1000);
}

function newEventStream() {
    const url = getConfig("backendUrl") + "/event/";

    eventSource = new EventSource(url, {
        withCredentials: true,
    });
    eventSource.onopen = (e) => {
        reconnectTime = 1;
        console.log("Event source open");
    };
    eventSource.onmessage = (msg) => {
        // if the server emits an error message, throw an exception
        // so it gets handled by the onerror callback below:

        // Ignore ping keepalives
        if (msg.type === "ping") {
            return;
        }

        console.log(msg);
        if (lastEventId === msg.lastEventId) {
            return;
        }

        lastEventId = msg.lastEventId;

        if (msg.type === "message") {
            let ev = JSON.parse(msg.data);

            if (ev.type === "snack") {
                sendToSnackbar(ev.message, ev.variant, ev.link_title, ev.link);
            } else {
                dispatchEvent(ev, msg.data);
            }
        }
    };
    eventSource.onerror = (e) => {
        eventSource.close();
        console.log("Error on event source " + e);
        triggerReconnect();
    };
}

function EventProvider(props: EventProviderProps): JSX.Element {
    const {getConfig} = useConfig();
    const url = getConfig("backendUrl") + "/event";

    useEffect(() => {
        newEventStream();

        return () => {
            eventSource.close();
        };
    }, []);

    return <>{props.children}</>;
}

export default styled(EventProvider)``;
