/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Statistics } from './Statistics';
import {
    StatisticsFromJSON,
    StatisticsFromJSONTyped,
    StatisticsToJSON,
} from './Statistics';

/**
 * Arviz summary statistics generated to deserialised json emitted by data analysis az.summary.
 * This is the data model used for the analysis output summary
 * @export
 * @interface MaapDataStats
 */
export interface MaapDataStats {
    /**
     * 
     * @type {Statistics}
     * @memberof MaapDataStats
     */
    mean: Statistics;
    /**
     * 
     * @type {Statistics}
     * @memberof MaapDataStats
     */
    sd: Statistics;
    /**
     * 
     * @type {Statistics}
     * @memberof MaapDataStats
     */
    hdi25: Statistics;
    /**
     * 
     * @type {Statistics}
     * @memberof MaapDataStats
     */
    hdi975: Statistics;
    /**
     * 
     * @type {Statistics}
     * @memberof MaapDataStats
     */
    median: Statistics;
    /**
     * 
     * @type {Statistics}
     * @memberof MaapDataStats
     */
    mode: Statistics;
}

/**
 * Check if a given object implements the MaapDataStats interface.
 */
export function instanceOfMaapDataStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mean" in value;
    isInstance = isInstance && "sd" in value;
    isInstance = isInstance && "hdi25" in value;
    isInstance = isInstance && "hdi975" in value;
    isInstance = isInstance && "median" in value;
    isInstance = isInstance && "mode" in value;

    return isInstance;
}

export function MaapDataStatsFromJSON(json: any): MaapDataStats {
    return MaapDataStatsFromJSONTyped(json, false);
}

export function MaapDataStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaapDataStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mean': StatisticsFromJSON(json['mean']),
        'sd': StatisticsFromJSON(json['sd']),
        'hdi25': StatisticsFromJSON(json['hdi_2.5%']),
        'hdi975': StatisticsFromJSON(json['hdi_97.5%']),
        'median': StatisticsFromJSON(json['median']),
        'mode': StatisticsFromJSON(json['mode']),
    };
}

export function MaapDataStatsToJSON(value?: MaapDataStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mean': StatisticsToJSON(value.mean),
        'sd': StatisticsToJSON(value.sd),
        'hdi_2.5%': StatisticsToJSON(value.hdi25),
        'hdi_97.5%': StatisticsToJSON(value.hdi975),
        'median': StatisticsToJSON(value.median),
        'mode': StatisticsToJSON(value.mode),
    };
}

