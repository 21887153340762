import {styled, Typography} from "@mui/material";
import GroupsTable from "../../pages/Study/GroupsTable";
import {StudySummarySchema, StudyType} from "../../api";
import StudyDetailsMultiDensity from "./StudyDetailsMultiDensity";
import StudyDetailsMultiAssay from "./StudyDetailsMultiAssay";

interface StudyDetailsMaffconProps {
    studyData: StudySummarySchema;
}

export default styled((props: StudyDetailsMaffconProps) => {
    return (
        <>
            {
                // @ts-ignore
                props.studyData.extra?.studyType == StudyType.MaffconMultiDensity ? (
                    <StudyDetailsMultiDensity studyData={props.studyData} />
                ) : (
                    <StudyDetailsMultiAssay studyData={props.studyData} />
                )
            }
        </>
    );
})((theme) => {
    return {};
});
