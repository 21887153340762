import {useTheme} from "@mui/material/styles";
import {useMemo} from "react";
import {Link, useNavigate} from "react-router-dom";

interface AnalysisStatusProps {
    analysisStatus: string | null | undefined;
    analysisId: string | null | undefined;
    created: Date;
    disableFailedLink?: boolean;
}

const AnalysisStatus = (props: AnalysisStatusProps) => {
    const theme = useTheme();

    const analysisStatusElement = useMemo((): JSX.Element => {
        /* Change analysis status according to following conditions:
            1. If the analysisStatus is null, change the status to "Not run".
            2. If the analysisStatus is undefined change the status to "...".
            3. If the analysisStatus is string, capitalize it.
         */
        let analysisStatusText: string;
        if (props.analysisStatus === null) analysisStatusText = "Not run";
        else if (props.analysisStatus === undefined) analysisStatusText = "...";
        else
            analysisStatusText =
                props.analysisStatus.charAt(0).toLocaleUpperCase() + props.analysisStatus.slice(1).toLocaleLowerCase();

        return <span>{analysisStatusText}</span>;
    }, [props.analysisStatus]);

    const shouldAttachErrorLink = (): boolean => {
        if (props.analysisId && props.analysisStatus == "FAILED" && !props.disableFailedLink) {
            const validErrorLinkDate: Date = new Date();
            validErrorLinkDate.setMilliseconds(props.created.getMilliseconds() + 604800000); // 1 week after creation

            return validErrorLinkDate > props.created;
        }
        return false;
    };

    return (
        <span>
            Analysis status:
            {shouldAttachErrorLink() ? (
                <Link to={`/errors?errorRef=${props.analysisId}`}>{analysisStatusElement}</Link>
            ) : (
                analysisStatusElement
            )}
        </span>
    );
};

export default AnalysisStatus;
