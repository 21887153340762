/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MaapOutput } from './MaapOutput';
import {
    MaapOutputFromJSON,
    MaapOutputFromJSONTyped,
    MaapOutputToJSON,
} from './MaapOutput';
import type { NllsOutput } from './NllsOutput';
import {
    NllsOutputFromJSON,
    NllsOutputFromJSONTyped,
    NllsOutputToJSON,
} from './NllsOutput';
import type { SingleExperimentResponse } from './SingleExperimentResponse';
import {
    SingleExperimentResponseFromJSON,
    SingleExperimentResponseFromJSONTyped,
    SingleExperimentResponseToJSON,
} from './SingleExperimentResponse';

/**
 * Analysis summary data structure for MAAP and NLLS
 * @export
 * @interface AnalysisSummaryResponse
 */
export interface AnalysisSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof AnalysisSummaryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSummaryResponse
     */
    jobId?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSummaryResponse
     */
    status: string;
    /**
     * 
     * @type {Date}
     * @memberof AnalysisSummaryResponse
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof AnalysisSummaryResponse
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSummaryResponse
     */
    experimentId: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSummaryResponse
     */
    userId: string;
    /**
     * 
     * @type {SingleExperimentResponse}
     * @memberof AnalysisSummaryResponse
     */
    experiment: SingleExperimentResponse;
    /**
     * 
     * @type {MaapOutput}
     * @memberof AnalysisSummaryResponse
     */
    maap?: MaapOutput;
    /**
     * 
     * @type {NllsOutput}
     * @memberof AnalysisSummaryResponse
     */
    nlls?: NllsOutput;
}

/**
 * Check if a given object implements the AnalysisSummaryResponse interface.
 */
export function instanceOfAnalysisSummaryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "experimentId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "experiment" in value;

    return isInstance;
}

export function AnalysisSummaryResponseFromJSON(json: any): AnalysisSummaryResponse {
    return AnalysisSummaryResponseFromJSONTyped(json, false);
}

export function AnalysisSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalysisSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'jobId': !exists(json, 'job_id') ? undefined : json['job_id'],
        'status': json['status'],
        'created': (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'experimentId': json['experiment_id'],
        'userId': json['user_id'],
        'experiment': SingleExperimentResponseFromJSON(json['experiment']),
        'maap': !exists(json, 'maap') ? undefined : MaapOutputFromJSON(json['maap']),
        'nlls': !exists(json, 'nlls') ? undefined : NllsOutputFromJSON(json['nlls']),
    };
}

export function AnalysisSummaryResponseToJSON(value?: AnalysisSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'job_id': value.jobId,
        'status': value.status,
        'created': (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'experiment_id': value.experimentId,
        'user_id': value.userId,
        'experiment': SingleExperimentResponseToJSON(value.experiment),
        'maap': MaapOutputToJSON(value.maap),
        'nlls': NllsOutputToJSON(value.nlls),
    };
}

