import React, {Children, createRef, useContext} from "react";
import {styled, Switch} from "@mui/material";
import Button from "@mui/material/Button";
import {CustomThemeContext} from "../../themes/CustomThemeProvider";

interface ThemeSwitcherProps {
    className?: string;
    children?: React.ReactNode;
}

function ThemeSwitcher(props: ThemeSwitcherProps): JSX.Element {
    const {currentTheme, setTheme} = useContext(CustomThemeContext);

    function handleClick(e: any) {
        let theme = currentTheme;
        if (setTheme) {
            if (theme === "light") {
                setTheme("dark");
            } else {
                setTheme("light");
            }
        }
    }

    return (
        // <Button sx={{color: "white"}} className={"button"} onClick={handleClick}>
        //     {props.children}
        // </Button>
        <>
            {React.Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, {
                    onClick: handleClick,
                    currenttheme: currentTheme,
                });
            })}
        </>
    );
}

export default styled(ThemeSwitcher)``;
