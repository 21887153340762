// export const increment = () => {
//     return {
//         type: 'counter/increment'
//     }
// }
//
// const initialState = { counter: 0 }
//
// export function counterReducer(state = initialState, action: any) {
//     // Check to see if the reducer cares about this action
//     if (action.type === 'counter/increment') {
//         // If so, make a copy of `state`
//         return {
//             ...state,
//             // and update the copy with the new value
//             counter: state.counter + 1
//         }
//     }
//     // otherwise return the existing state unchanged
//     return state
// }
import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

interface CounterState {
    counter: number;
}

const initialState = {counter: 0} as CounterState;

const counterSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
        increment(state) {
            state.counter++;
        },
        decrement(state) {
            state.counter--;
        },
        incrementByAmount(state, action: PayloadAction<number>) {
            state.counter += action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.counter = 0;
        });
    },
});

export const {increment, decrement, incrementByAmount} = counterSlice.actions;
export default counterSlice.reducer;
