import React, {useEffect, useRef, useState} from "react";
import {
    MeasurementGroup,
    MeasurementHeader,
    MeasurementsApi,
    MeasurementSchema,
    ReadHeadersResponse,
    ReadSectionResponse,
    ResponseError,
} from "../../api";
import {apiConfig} from "../../ApiConfig";
import fluidityOneM from "./Fluidity-One-M_128x128px.png";
import fluidityOneW from "./Fluidity-One-W-instrument_128x128px.png";
import pixel from "./Pixel.png";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Checkbox from "@mui/material/Checkbox";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {Collapse, ListItem, styled, Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";
import EditMeasurementModal from "./EditMeasurementModal";
import EditSampleModal from "./EditSampleModal";
import {handleNetworkError} from "../../helpers/error";

import {useStateCallback} from "../../helpers/StateCallback";
import {useAppDispatch, useAppSelector} from "../../ReduxStore";
import {ItemState, ListState, SectionState, setListData as reducerSetListData} from "../../reducers/MeasurementList";
import {FilterType} from "../MeasurementFilter/types";
import Typography from "@mui/material/Typography";
import TypographyWithTooltip from "../../helpers/TextWithTooltip";
import TextWithTooltip from "../../helpers/TextWithTooltip";
import MeasurementEntry from "../MeasurementEntry";
import {isConstructorDeclaration} from "typescript";
import {StandardDateTimeDisplay} from "../../helpers/HelperComponents";
import YinYang from "../Svgs/YinYang";
import {colorMap} from "../../helpers";

const cloneDeep = require("lodash.clonedeep");

const measurementsApi = new MeasurementsApi(apiConfig);

export const instrumentIcons: {[key: string]: any} = {
    F1M: fluidityOneM,
    F1W: fluidityOneW,
};

class MeasurementListProps {
    className?: string;
    filesUploaded?: File[] = [];
    reducer?: string;
    itemRenderFunction?: (
        item: MeasurementSchema,
        header: MeasurementHeader,
        callback: (e: any, id: string) => void,
        checked: boolean,
        setLastModifiedHeaders?: React.Dispatch<React.SetStateAction<MeasurementHeader[]>>
    ) => JSX.Element;
    headerRenderFunction?: (
        header: MeasurementHeader,
        handleHeaderCheckbox: (e: any, id: string) => void,
        checkboxState: boolean | undefined,
        headerClick: (header: MeasurementHeader) => void,
        expanded: boolean,
        selectedCount: number | undefined
    ) => JSX.Element;

    subheaderRenderFunction?: (header: MeasurementHeader) => JSX.Element;
    listheaderRenderFunction?: () => JSX.Element;
    listfiltersRenderFunction?: (filters?: any) => JSX.Element;
    listpaginationRenderFunction?: (numberOfPages: number, rowsCount: number) => JSX.Element;
    filtersApplied?: FilterType;
    initialSelectedMeasurements?: string[];
    showSelectedOnly?: boolean;
}

interface ItemData {
    item: MeasurementSchema;
}

interface SectionData {
    header: MeasurementHeader;
    items: string[];
}

interface ListData {
    sections: {[key: string]: SectionData};
    items: {[key: string]: ItemData};
}

function MeasurementList(props: MeasurementListProps): JSX.Element {
    const refLastHeader = useRef<FilterType>();

    const [isLoaded, setIsLoadedCallback] = useStateCallback<boolean>(false);
    const [headersCount, setHeadersCountCallback] = useStateCallback<number>(0);
    const [lastModifiedHeaders, setLastModifiedHeaders] = React.useState<MeasurementHeader[]>([]);
    const [listData, setListData] = useState<ListData>({
        items: {},
        sections: {},
    });
    const [localListState, setLocalListState] = useState<ListState>({items: {}, sections: {}});
    const [tableNeedsRefresh, setTableNeedsRefresh] = useState<boolean>(false);
    const [headersAreRequesting, setHeadersAreRequesting] = useState<boolean>(false);

    // @ts-ignore
    const persistedState = useAppSelector((state) => state[props.reducer]);
    const dispatch = useAppDispatch();

    // Because of persisted data from older versions, we have to do this because
    // if the old data gets rehydrated into the new struct, these fields would be undefined
    // To avoid checking it all over the place, we check for it here, once.
    var listState: ListState = cloneDeep(persistedState);
    if (persistedState === undefined) {
        listState = cloneDeep(localListState);
    }

    if (listState.items === undefined) {
        listState.items = {};
    }
    if (listState.sections === undefined) {
        listState.sections = {};
    }

    function setListState() {
        setListData({...listData});

        if (!props.reducer) {
            setLocalListState(listState);
            return;
        }

        dispatch(reducerSetListData(props.reducer, cloneDeep(listState)));
    }

    function reloadSection(header: MeasurementHeader) {
        let key = keyFromHeader(header);

        if (listState.sections.hasOwnProperty(key)) {
            //listData.sections[key].unrolled = false;
            listData.sections[key].items.map((item) => {
                delete listState.items[item];
            });
            listData.sections[key].items = [];
        }

        loadItems(header);
    }

    // Default filters are still independently defined here in order to obviate the need to pass
    // filter props (should we want to call the component from more places in the FE).
    const defaultFilters: FilterType = {
        startDate: new Date(2000, 1, 1, 0, 0, 0, 0),
        endDate: new Date(),
        columnFilters: [],
        pagination: {
            headersPerPage: 5,
            currentPage: 1,
        },
    };
    const [filtersUsed, setFiltersUsed] = useStateCallback<FilterType>(
        props.filtersApplied ? props.filtersApplied : defaultFilters
    );

    React.useEffect(() => {
        if (props.filtersApplied !== undefined) {
            setFiltersUsed(props.filtersApplied);
        }
    }, [props.filtersApplied]);

    const navigate = useNavigate();

    function loadHeaders() {
        measurementsApi
            .getMeasurementHeaders(filtersUsed.startDate, filtersUsed.endDate)
            .then((resp: ReadHeadersResponse) => {
                processHeaders(resp);
            })
            .catch((e: any) => {
                if (e instanceof RangeError) {
                    return;
                }
                let response: ResponseError = e as ResponseError;
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            })
            .finally(() => {
                setTableNeedsRefresh(true);
                setHeadersAreRequesting(false);
            });
    }

    // This covers the situation when files get uploaded
    useEffect(() => {
        if (JSON.stringify(filtersUsed) === JSON.stringify(refLastHeader.current) || headersAreRequesting) {
            return;
        }
        refLastHeader.current = filtersUsed;
        setTableNeedsRefresh(false);
        setHeadersAreRequesting(true);
        loadHeaders();
        setTableNeedsRefresh(true);
        setHeadersAreRequesting(false);
    }, [props.filesUploaded, filtersUsed]);

    useEffect(() => {
        setIsLoadedCallback(false, () => {
            refLastHeader.current = filtersUsed;
            setTableNeedsRefresh(false);
            setHeadersAreRequesting(true);
            loadHeaders();
        });
    }, [lastModifiedHeaders]);

    // This covers a measurement metadata modification
    useEffect(() => {
        if (lastModifiedHeaders.length > 0) {
            setTableNeedsRefresh(false);
            setHeadersAreRequesting(true);
            measurementsApi
                .getMeasurementHeaders(filtersUsed.startDate, new Date())
                .then((resp: ReadHeadersResponse) => processHeaders(resp))
                .catch((response: ResponseError) => {
                    handleNetworkError(response).then((target) => {
                        if (target) {
                            navigate(target);
                        }
                    });
                })
                .finally(() => {
                    setTableNeedsRefresh(true);
                    setHeadersAreRequesting(false);
                });

            // should the last-modified headers change, we will generate header keys from them and
            // update the corresponding sections
            lastModifiedHeaders.map((header) => {
                reloadSection(header);
            });
            // nulls the array of last modified headers so nothing happens next time (e.g. when
            // measurements are uploaded)
            setLastModifiedHeaders([]);
            setListState();
        }
    }, [lastModifiedHeaders]);

    function processHeaders(resp: ReadHeadersResponse) {
        setHeadersCountCallback(resp.headers.length);
        if (props.initialSelectedMeasurements && props.initialSelectedMeasurements.length > 0 && !isLoaded) {
            measurementsApi.getMeasurementGroupsByIds(props.initialSelectedMeasurements).then((groups) => {
                let initialLoadHeaders = groups.map((g) => keyFromGroup(g));

                props.initialSelectedMeasurements?.map((m) => {
                    listState.items[m] = {selected: true};
                });
                setListState();
                processHeadersFromResponse(resp, initialLoadHeaders);
            });
        }

        processHeadersFromResponse(resp);
    }

    function processHeadersFromResponse(resp: ReadHeadersResponse, initialLoadHeaders: string[] = []) {
        let itemMap: {[key: string]: string[]} = {};
        Object.keys(listData.sections).map((h) => {
            itemMap[h] = listData.sections[h].items;
        });
        listData.sections = {};

        resp.headers.map((header) => {
            let key = keyFromHeader(header);

            if (listState.sections[key] === undefined) {
                listState.sections[key] = {unrolled: false, selected: false};
            }

            if (itemMap[key] !== undefined) {
                listData.sections[key] = {items: itemMap[key], header: header};
            } else {
                listData.sections[key] = {items: [], header: header};
            }

            if (initialLoadHeaders.includes(key) || listState.sections[key]?.unrolled) {
                loadItems(header);
            }
        });

        setIsLoadedCallback(true, () => {
            setListState();
        });

        return <></>;
    }

    function calcSectionCheckbox(sectionKey: string): boolean | undefined {
        if (listState.sections[sectionKey] === undefined) {
            return false;
        }
        let items = listData.sections[sectionKey].items;
        let masterState = listState.items[listData.sections[sectionKey].items[0]]?.selected || false;
        for (let i of items) {
            if (listState.items[i].selected != masterState) {
                return undefined;
            }
        }
        return masterState;
    }

    function itemParent(measurement: MeasurementSchema): string | undefined {
        if (measurement === undefined) {
            return undefined;
        }
        return keyFromMeasurement(measurement);
    }

    function setItemCheckboxes(parent: string, value: boolean) {
        listData.sections[parent]?.items.map((id) => {
            listState.items[id].selected = value;
        });
        setListState();
    }

    function renderDefaultHeaderView(
        header: MeasurementHeader,
        handleHeaderCheckbox: (e: any, id: string) => void,
        checkboxState: boolean | undefined,
        headerClick: (header: MeasurementHeader) => void,
        expanded: boolean
    ): JSX.Element {
        const colors = header.colorInfo?.toLowerCase().split(",") || ["red"];
        let color1: string, color2: string;
        if ((header.colorCount || 0) < 2) {
            color1 = colorMap[colors[0]];
            color2 = colorMap[colors[0]];
        } else {
            color1 = colorMap[colors[0]];
            color2 = colorMap[colors[1]];
        }

        return (
            <>
                <div
                    className={"header-first"}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "stretch",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <img
                                style={{paddingRight: "5px", width: "48px", verticalAlign: "middle"}}
                                src={instrumentIcons[header.instrumentType] || pixel}
                                alt={header.instrumentType}
                            />
                        </div>
                        <Tooltip title={"Color(s): " + colors.join(", ")}>
                            <div style={{paddingTop: "6px", marginRight: "10px"}}>
                                <YinYang color1={color1} color2={color2} />
                            </div>
                        </Tooltip>
                    </div>
                    <div style={{paddingTop: "4px"}}>
                        <StandardDateTimeDisplay value={header.runTimestamp} />
                    </div>
                </div>
                <div className={"header-field"}>
                    <Typography noWrap={true}>{header.labeledName}</Typography>
                </div>
                <div className={"header-field"}>
                    <TextWithTooltip noWrap={true}>
                        {(() => {
                            if (header.unlabeledName && header.complexName) {
                                return (
                                    <>
                                        {header.unlabeledName} / {header.complexName}
                                    </>
                                );
                            } else if (header.unlabeledName) {
                                return <>{header.unlabeledName}</>;
                            } else if (header.complexName) {
                                return <>{header.complexName}</>;
                            } else {
                                return <>Not set</>;
                            }
                        })()}
                    </TextWithTooltip>
                </div>
                <div className={"header-field"}>{header.instrumentId}</div>
                <div className={"header-last"} style={{marginRight: "1rem"}}>
                    ({header.count})
                    <Checkbox
                        className={"checkbox"}
                        indeterminate={checkboxState === undefined}
                        checked={checkboxState || false}
                        onClick={(e) => {
                            handleHeaderCheckbox(e, keyFromHeader(header));
                        }}
                    />
                </div>

                <EditSampleModal
                    key={`${keyFromHeader(header)}_edit_modal`}
                    header={header}
                    setLastModifiedHeaders={setLastModifiedHeaders}
                />

                <ListItemButton
                    className={"expand"}
                    onClick={() => {
                        headerClick(header);
                    }}
                >
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </>
        );
    }

    function getHeaderContent(
        header: MeasurementHeader,
        headerRenderFunction?: (
            header: MeasurementHeader,
            handleHeaderCheckbox: (e: any, id: string) => void,
            checkboxState: boolean | undefined,
            headerClick: (header: MeasurementHeader) => void,
            expanded: boolean,
            selectedCount: number | undefined
        ) => JSX.Element
    ) {
        function handleHeaderCheckbox(e: any, id: string) {
            if (listState.sections[id] === undefined) {
                listState.sections[id] = {unrolled: false, selected: false};
            }
            if (listData.sections[id] === undefined) {
                listData.sections[id] = {header: {...header}, items: []};
            }
            if (listData.sections[id].items.length == 0) {
                loadItems(header, true);
                return <></>;
            }

            let currentSelection = listState.sections[id].selected;

            switch (currentSelection) {
                case undefined:
                    setItemCheckboxes(id, false);
                    break;
                case false:
                    setItemCheckboxes(id, true);
                    break;
                case true:
                    setItemCheckboxes(id, false);
                    break;
            }

            listState.sections[id].selected = calcSectionCheckbox(id);

            setListState();
        }

        function getSelectedCount(header: MeasurementHeader) {
            let id = keyFromHeader(header);

            if (props.showSelectedOnly === undefined) {
                return undefined;
            }

            if (listData.sections[id] === undefined || listData.sections[id].items.length == 0) {
                return 0;
            }

            if (listState.items === undefined) {
                return 0;
            }

            return listData.sections[id].items.reduce((acc: number, cur: string) => {
                if (listState.items[cur].selected) {
                    return acc + 1;
                }
                return acc;
            }, 0);
        }

        if (headerRenderFunction !== undefined) {
            return headerRenderFunction(
                header,
                handleHeaderCheckbox,
                listState.sections[keyFromHeader(header)].selected,
                headerClick,
                listState.sections[keyFromHeader(header)]?.unrolled,
                getSelectedCount(header)
            );
        }

        if (listState.sections[keyFromHeader(header)] === undefined) {
            return <></>;
        }

        return renderDefaultHeaderView(
            header,
            handleHeaderCheckbox,
            listState.sections[keyFromHeader(header)].selected,
            headerClick,
            listState.sections[keyFromHeader(header)]?.unrolled
        );
    }

    function processSection(resp: ReadSectionResponse, select: boolean = false) {
        let key = keyFromHeader(resp.header);

        if (listData.sections[key] === undefined) {
            listData.sections[key] = {header: resp.header, items: []};
        } else {
            listData.sections[key].items = [];
        }

        resp.items.map((item) => {
            if (listState.items[item.id] === undefined) {
                listState.items[item.id] = {selected: select};
            } else {
                if (select) {
                    listState.items[item.id].selected = true;
                }
            }

            listData.items[item.id] = {item: item};
            listData.sections[key].items.push(item.id);
        });

        listState.sections[key] = listState.sections[key] || {unrolled: false, selected: false};
        listState.sections[key].selected = calcSectionCheckbox(key);

        setListState();
    }

    function loadItems(header: MeasurementHeader, select: boolean = false) {
        measurementsApi
            .getMeasurementsDetails(
                header.runTimestamp,
                header.labeledName,
                header.instrumentType,
                header.instrumentId,
                header.unlabeledName,
                header.complexName
            )
            .then((resp: ReadSectionResponse) => processSection(resp, select))
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    }

    function headerClick(header: MeasurementHeader) {
        let key = keyFromHeader(header);

        listState.sections[key].unrolled = !listState.sections[key].unrolled;

        if (listState.sections[key].unrolled) {
            if (listData.sections[key].items.length == 0) {
                loadItems(header);
            } else {
                setListState();
            }
        } else {
            setListState();
        }
    }

    function renderDefaultItemView(
        item: MeasurementSchema,
        header: MeasurementHeader,
        checkboxCallback: (e: any, itemId: string) => void,
        checked: boolean,
        setLastModifiedHeaders?: React.Dispatch<React.SetStateAction<MeasurementHeader[]>>
    ): JSX.Element {
        return (
            <MeasurementEntry measurement={item} useCircuit={true}>
                <div className="edit-wrap">
                    <EditMeasurementModal
                        key={`${item.id}_edit_modal`}
                        header={header}
                        selectedMeasurementId={item.id}
                        setLastModifiedHeaders={setLastModifiedHeaders}
                    />
                </div>
                <div className="checkbox-wrap">
                    {/* Do not remove this || false. The component will not update if it is removed! */}
                    <Checkbox
                        className={"checkbox"}
                        id={"check" + item.id}
                        onChange={(e) => {
                            checkboxCallback(e, item.id);
                        }}
                        checked={listState.items[item.id]?.selected || false}
                    ></Checkbox>
                </div>
            </MeasurementEntry>
        );
    }

    function getItemContent(
        header: MeasurementHeader,
        renderItemFunction?: (
            item: MeasurementSchema,
            header: MeasurementHeader,
            handleCheckbox: (e: any, id: string) => void,
            checked: boolean,
            setLastModifiedHeaders?: React.Dispatch<React.SetStateAction<MeasurementHeader[]>>
        ) => JSX.Element,
        subheaderRenderFunction?: (header: MeasurementHeader) => JSX.Element
    ): JSX.Element {
        function handleItemCheckbox(e: any, id: string) {
            listState.items[id].selected = e.target.checked;

            let key = keyFromMeasurement(listData.items[id]?.item);
            listState.sections[key] = listState.sections[key] || {unrolled: false, selected: false};
            listState.sections[key].selected = calcSectionCheckbox(key);
            setListState();
        }

        return (
            <div className={"item-wrapper"} id={keyFromHeader(header) + "items"}>
                {subheaderRenderFunction && subheaderRenderFunction(header)}
                {listData.sections[keyFromHeader(header)].items.map((id) => (
                    <div className={"item"} id={listData.items[id].item.id} key={listData.items[id].item.id}>
                        {renderItemFunction === undefined
                            ? renderDefaultItemView(
                                  listData.items[id].item,
                                  header,
                                  handleItemCheckbox,
                                  listState.items[id]?.selected || false,
                                  setLastModifiedHeaders
                              )
                            : renderItemFunction(
                                  listData.items[id].item,
                                  header,
                                  handleItemCheckbox,
                                  listState.items[id]?.selected || false,
                                  setLastModifiedHeaders
                              )}
                    </div>
                ))}
            </div>
        );
    }

    const inputFiltersAvailable = [
        {
            title: "Sample",
            boundColumn: "labeledName",
            value: 0,
            values: Object.values(listData.sections)
                .map((x) => x.header.labeledName)
                .filter((v, i, a) => a.indexOf(v) === i)
                .sort(),
        },
        {
            title: "Binding partner",
            boundColumn: "unlabeledName",
            value: 1,
            values: Object.values(listData.sections)
                .map((x) => x.header.unlabeledName)
                .concat(Object.values(listData.sections).map((x) => x.header.complexName))
                .filter((v, i, a) => a.indexOf(v) === i)
                .sort(),
        },
        {
            title: "Instrument",
            boundColumn: "instrumentId",
            value: 2,
            values: Object.values(listData.sections)
                .map((x) => x.header.instrumentId)
                .filter((v, i, a) => a.indexOf(v) === i),
        },
    ];

    if (listState.sections === undefined || Object.keys(listState.sections).length == 0) {
        return <></>;
    }

    // This is currently frontend-filtering already-loaded data. That is because
    // getMeasurementHeaders does not have accept filtering arguments (it might be preferrable to
    // add this capacity at a later point once the data-lists get larger.)
    let filteredData = Object.values(listData.sections);
    if (props.showSelectedOnly) {
        filteredData = filteredData.filter(
            (h) => h.items.map((id) => listState.items[id]).filter((i) => i?.selected || false).length > 0
        );
    }
    if (filteredData.length == 0) {
        let x = 0;
    }
    filtersUsed.columnFilters.forEach((columnFilter) => {
        if (columnFilter.boundColumn != "unlabeledName") {
            filteredData = filteredData.filter(
                (row: SectionData) => row.header[columnFilter.boundColumn] === columnFilter.value
            );
        } else {
            filteredData = filteredData.filter(
                (row: SectionData) =>
                    row.header.unlabeledName === columnFilter.value || row.header.complexName === columnFilter.value
            );
        }
    });

    let numberOfPages: number = 1;

    if (filtersUsed.pagination.headersPerPage !== undefined) {
        numberOfPages = Math.ceil(filteredData.length / filtersUsed.pagination.headersPerPage);

        if (props.filtersApplied) {
            if (props.filtersApplied.pagination.currentPage > numberOfPages) {
                filtersUsed.pagination.currentPage = numberOfPages;
            } else if (numberOfPages > 0 && props.filtersApplied.pagination.currentPage == 0) {
                filtersUsed.pagination.currentPage = 1;
            }
        }

        // this is for applying pagination
        if (filteredData.length > filtersUsed.pagination.headersPerPage) {
            let startingIndex = (filtersUsed.pagination.currentPage - 1) * filtersUsed.pagination.headersPerPage;
            let endingIndex = filtersUsed.pagination.currentPage * filtersUsed.pagination.headersPerPage;
            filteredData = filteredData.slice(startingIndex, endingIndex);
        }
    }

    return (
        <Box className={props.className}>
            <List className="list">
                {props.listfiltersRenderFunction && props.listfiltersRenderFunction(inputFiltersAvailable)}
                {props.listheaderRenderFunction && props.listheaderRenderFunction()}
                {tableNeedsRefresh &&
                    filteredData.map((info) => {
                        if (info.header.count === 0) {
                            return;
                        }
                        return (
                            <div key={keyFromHeader(info.header)}>
                                <ListItem className={"header-item"}>
                                    {getHeaderContent(info.header, props.headerRenderFunction)}
                                </ListItem>
                                <Collapse in={listState.sections[keyFromHeader(info.header)]?.unrolled || false}>
                                    {getItemContent(
                                        info.header,
                                        props.itemRenderFunction,
                                        props.subheaderRenderFunction
                                    )}
                                </Collapse>
                            </div>
                        );
                    })}
                {props.listpaginationRenderFunction && props.listpaginationRenderFunction(numberOfPages, headersCount)}
            </List>
        </Box>
    );
}

export default styled(MeasurementList)((theme) => {
    return {
        width: "1100px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2rem",
        "ul li": {
            backgroundColor: theme.theme.palette.background.paper,
            marginTop: "2px",
            textAlign: "left",
        },
        ".header-item": {
            "& .header-field": {
                width: "19%",
            },
            "& .header-first": {
                width: "25%",
            },
            "& .header-last": {
                flexGrow: "100",
                textAlign: "right",
            },
        },
        ".measurement-header": {
            "& .listItemOuter": {
                margin: "0px",
                padding: "0px",
                // width: "100%",
                "&>.header-field": {
                    display: "inline-block",
                    width: "19%",
                    border: "1px solid blue",
                },
                "&>div:first-of-type": {
                    width: "25%",
                },
                "&>div:last-of-type": {
                    float: "right",
                    textAlign: "right",
                    lineHeight: "48px",
                    width: "unset",
                },
            },
        },
    };
});

export function keyFromHeader(header: MeasurementHeader): string {
    return (
        header.runTimestamp.toString() +
        "-" +
        header.labeledName +
        "-" +
        (header.unlabeledName == undefined ? "" : header.unlabeledName) +
        "-" +
        (header.complexName == undefined ? "" : header.complexName) +
        "-" +
        header.instrumentType +
        "-" +
        header.instrumentId
    );
}

export function keyFromGroup(group: MeasurementGroup): string {
    return (
        group.timestamp.toString() +
        "-" +
        group.labeledName +
        "-" +
        (group.unlabeledName == undefined ? "" : group.unlabeledName) +
        "-" +
        (group.complexName == undefined ? "" : group.complexName) +
        "-" +
        group.instrumentType +
        "-" +
        group.instrumentId
    );
}

export function keyFromMeasurement(measurement: MeasurementSchema) {
    return (
        measurement.runTimestamp +
        "-" +
        measurement.labeledName +
        "-" +
        (measurement.unlabeledName == undefined ? "" : measurement.unlabeledName) +
        "-" +
        (measurement.complexName == undefined ? "" : measurement.complexName) +
        "-" +
        measurement.instrumentType +
        "-" +
        measurement.instrumentId
    );
}
