import * as React from "react";
import {Theme, useTheme} from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, {SelectChangeEvent} from "@mui/material/Select";

export const ALL = "All";
export const MAXIMUM_PAGE_SIZE = 100; // Datagrid doesn't support page size greater than 100.

interface SelectItemsPerPageProps {
    itemsPerPage: number | undefined;
    itemsPerPageOptions: Array<number>;
    handleChangeItemsPerPage: (newItemsNumber: number) => void;
}

export default function SelectItemsPerPage(props: SelectItemsPerPageProps) {
    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = React.useState<number>(
        props.itemsPerPage || props.itemsPerPageOptions[0]
    );

    const handleChange = (event: SelectChangeEvent<string>) => {
        const optionSelected: string = event.target.value;
        const optionValue: number = parseInt(optionSelected, 10);

        setNumberOfItemsPerPage(optionValue);
    };

    const selectedOption = React.useMemo(() => {
        if (numberOfItemsPerPage === undefined) {
            return props.itemsPerPageOptions[0];
        }
        return numberOfItemsPerPage.toString();
    }, [numberOfItemsPerPage]);

    // This syncs the number with the global one.
    React.useEffect(() => {
        props.handleChangeItemsPerPage(numberOfItemsPerPage);
    }, [numberOfItemsPerPage]);

    return (
        <div>
            <FormControl sx={{m: 1, width: "150px"}}>
                <InputLabel id="multiple-name-label">Items per page</InputLabel>
                <Select
                    id="multiple-name"
                    labelId="multiple-name-label"
                    variant={"standard"}
                    sx={{">div": {padding: "4px"}}}
                    value={selectedOption as string}
                    onChange={handleChange}
                    input={<OutlinedInput label="Number of items" />}
                >
                    {props.itemsPerPageOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
