import * as React from "react";

import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import {PageDefinition} from "./types";
import {SvgFluidicDopletWhite, SvgFluidicLogoWhite} from "../Svgs";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {apiConfig} from "../../ApiConfig";
import {AuthApi, ResponseError, VersionInfoResponse} from "../../api";
import {ServicesApi} from "../../api";
import {useConfig} from "../../RuntimeConfig";

import {handleNetworkError, snackbarError, snackbarSuccess, snackbarWithLink} from "../../helpers/error";
import ThemeSwitcher from "../ThemeSwitcher";
import {increment} from "../../reducers/ClickCounter";
import {purge} from "../../ReduxStore";
import {useAppDispatch, useAppSelector} from "../../ReduxStore";
import {setLoggedIn, setLoggedOut} from "../../reducers/LoginData";
import {useContext, useState} from "react";
import {useSnackbar} from "notistack";
import {dispatchEvent} from "../../helpers/EventDispatcher";
import {Breadcrumbs, Switch} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {CustomThemeContext} from "../../themes/CustomThemeProvider";
import TopBarRight from "../Svgs/TopBarRight";
import BreadcrumbStartBackground from "../Svgs/BreadcrumbStartBackground";
import BreadcrumbStart from "../Breadcrumbs/BreadcrumbStart";
import BreadcrumbMiddle from "../Breadcrumbs/BreadcrumbMiddle";
import breadcrumbsBackground from "../Svgs/experiment_breadcrumb.svg";
import {useGlobalState} from "../../GlobalState";

const authApi = new AuthApi(apiConfig);
const servicesApi = new ServicesApi(apiConfig);

// Although we do not strictly need to support mobile (or reduced-size) screens, responsive menus
// come at very little extra cost in Material UI, thus we are also including a smaller simplified
// navigation menu (pages_xs).

const pages: PageDefinition[] = [
    {title: "Uploads", path: "/upload", dropdown: false},
    {title: "Measurements", path: "/measurements", dropdown: false},
    {title: "Experiments", path: "/experiments", dropdown: false},
    {title: "Studies", path: "/studies", dropdown: false},
    {title: "?", external: "https://fluidic.com/user-guide-for-fluidity-insight_ifu-003/", dropdown: false},
];

const pages_xs: PageDefinition[] = [
    {title: "Uploads", path: "/upload", dropdown: false},
    {title: "Measurements", path: "/measurements", dropdown: false},
    {title: "Experiments", path: "/experiments", dropdown: false},
    {title: "Studies", path: "/studies", dropdown: false},
    {title: "?", external: "https://fluidic.com/user-guide-for-fluidity-insight_ifu-003/", dropdown: false},
];

const TopNav = (): JSX.Element => {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [version, setVersion] = React.useState<VersionInfoResponse>({} as VersionInfoResponse);
    const {getConfig} = useConfig();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.counter);
    const loginData = useAppSelector((state) => state.loginState);
    const [lock, setLock] = useState<boolean>(false);
    const theme = useTheme();
    const snackbar = useSnackbar();
    const [globalState, updateGlobalState] = useGlobalState();

    React.useEffect(() => {
        if (version.apiVersion === undefined) {
            servicesApi.getVersion().then((versionInfo) => {
                setVersion(versionInfo);
            });
        }
    });

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (): void => {
        setAnchorElNav(null);
    };

    if (!lock) {
        authApi
            .getLoginStatus()
            .then((resp) => {
                if (resp.loggedIn) {
                    dispatch(setLoggedIn());
                } else {
                    purge();
                }
            })
            .catch((response: ResponseError) => {
                if (response && response.response && response.response.status == 403) {
                    setLock(true);
                    if (location.pathname == "/login" || location.pathname == "/loginfail") {
                        return;
                    }
                }

                purge();

                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    }

    return (
        <>
            <AppBar position="static" sx={{height: "92px"}}>
                <div style={{display: "flex"}}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 3,
                            padding: "0px",
                            margin: "0px",
                            display: {xs: "none", md: "flex"},
                            height: "92px",
                            width: "127px",
                            backgroundColor: "#2A007D",
                        }}
                    >
                        <SvgFluidicDopletWhite
                            className="top-nav__logo"
                            sx={{height: "54px", width: "auto", margin: "auto"}}
                        />
                    </Typography>
                    <div style={{width: "100%"}}>
                        <TopBarRight sx={{position: "absolute", right: "0px"}} pixelWidth={400} pixelHeight={92} />
                        <Container maxWidth={false} sx={{backgroundColor: theme.palette.primary.light}}>
                            <Toolbar disableGutters sx={{height: "92px"}}>
                                <Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: {xs: "block", md: "none"},
                                        }}
                                    >
                                        {pages_xs.map((page) => {
                                            if (page.path) {
                                                return (
                                                    <Link to={page.path} key={page.path}>
                                                        <MenuItem
                                                            key={page.title.replace(/[^\x20-\x7E]/g, "")}
                                                            onClick={handleCloseNavMenu}
                                                        >
                                                            <Typography
                                                                textAlign="center"
                                                                style={{color: "red", border: "none"}}
                                                            >
                                                                {page.title}
                                                            </Typography>
                                                        </MenuItem>
                                                    </Link>
                                                );
                                            } else if (page.external) {
                                          return (
                                            <Link to={{}}>
                                              <MenuItem
                                            key={page.title.replace(/[^\x20-\x7E]/g, "")}
                                            onClick={() => window.open(page.external)}
                                            >
                                              <Typography
                                            textAlign="center"
                                            style={{color: "red", border: "none"}}
                                                    >
                                              {page.title}
                                            </Typography>
                                                    </MenuItem>
                                            </Link>
                                          );
                                            } else if (page.elem) {
                                                return (
                                                    <page.elem>
                                                        <MenuItem
                                                            key={page.title.replace(/[^\x20-\x7E]/g, "")}
                                                            onClick={handleCloseNavMenu}
                                                        >
                                                            <Typography textAlign="center">
                                                                <span>{page.title}</span>
                                                            </Typography>
                                                        </MenuItem>
                                                    </page.elem>
                                                );
                                            } else {
                                                return <></>;
                                            }
                                        })}
                                    </Menu>
                                </Box>
                                <Box sx={{flexGrow: 1, display: {xs: "none", md: "flex"}}}>
                                    {pages.map((page) => {
                                        if (page.path) {
                                            return (
                                                <Link to={page.path} key={page.path}>
                                                    <Button
                                                        key={page.title.replace(/[^\x20-\x7E]/g, "")}
                                                        onClick={handleCloseNavMenu}
                                                        sx={{my: 2, color: "white", display: "block"}}
                                                    >
                                                        {page.title}
                                                    </Button>
                                                </Link>
                                            );
                                          
                                        } else if (page.external) {
                                          return (
                                            <Link to={{}}>
                                              <Button
                                                        key={page.title.replace(/[^\x20-\x7E]/g, "")}
                                                        onClick={() => window.open(page.external)}
                                                        sx={{my: 2, color: "white", display: "block"}}
                                                    >
                                                        {page.title}
                                                    </Button>
                                            </Link>
                                          );
                                    } else if (page.elem) {
                                            return (
                                              <page.elem>
                                                    <Button
                                                        key={page.title.replace(/[^\x20-\x7E]/g, "")}
                                                        onClick={handleCloseNavMenu}
                                                        sx={{my: 2, color: "white", display: "block"}}
                                                    >
                                                        <Stack direction="row" gap={0.5}>
                                                            <span>{page.title}</span>
                                                            {page.dropdown ? (
                                                                <ArrowDropDownCircleIcon fontSize="small" />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Stack>
                                                    </Button>
                                                </page.elem>
                                            );
                                        } else {
                                            return <></>;
                                        }
                                    })}
                                </Box>

                                <Box sx={{flexGrow: 1, textAlign: "right"}}>
                                    <Box
                                        sx={{
                                            height: "24px",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            columnGap: "20px",
                                            color: "primary.contrastText",
                                            paddingRight: "20px",
                                            ">div": {textAlign: "right"},
                                            ">div:first-of-type": {flexGrow: "400"},
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                color: "#0072ce",
                                                display: getConfig("showVersion") ? "block" : "none",
                                            }}
                                        >
                                            {"Backend Version " +
                                                (version.applicationVersion ?? version.gitHash) +
                                                " (" +
                                                version.gitHash +
                                                ") API Version " +
                                                version.apiVersion}
                                        </Box>
                                        <Box sx={{display: loginData.login ? "block" : "none"}}>
                                            <Typography>
                                                Logged in as {loginData.user ? loginData.user.fullname : "unknown"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            columnGap: "20px",
                                            ">div": {textAlign: "right"},
                                            ">div:first-of-type": {flexGrow: "400"},
                                        }}
                                    >
                                        {/*<Box>*/}
                                        {/*    <Button key="purge_button" sx={{color: "primary.contrastText"}} onClick={() => {*/}
                                        {/*        purge();*/}
                                        {/*    }}>*/}
                                        {/*        Purge!*/}
                                        {/*    </Button>*/}
                                        {/*    <Button key="redux_button" sx={{color: "primary.contrastText"}} onClick={() => {*/}
                                        {/*        dispatch(increment());*/}
                                        {/*    }}>*/}
                                        {/*        <>Redux! ({state.counter})</>*/}
                                        {/*    </Button>*/}
                                        {/*</Box>*/}
                                        <Box>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignContent: "stretch",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <span style={{textTransform: "capitalize"}}>
                                                        <Typography>Theme: {theme.palette.mode}</Typography>
                                                    </span>
                                                </div>
                                                <ThemeSwitcher>
                                                    <Switch
                                                        sx={{
                                                            "& .MuiSwitch-thumb": {
                                                                color: theme.palette.primary.contrastText,
                                                            },
                                                            "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                                                                backgroundColor: "white",
                                                            },
                                                        }}
                                                        checked={theme.palette.mode == "dark"}
                                                    />
                                                </ThemeSwitcher>
                                            </div>
                                        </Box>
                                        <Box>
                                            {loginData.login === true ? (
                                                <Button
                                                    key="logout_button"
                                                    onClick={() => {
                                                        authApi.logout().then(() => {});
                                                        window.location.href = getConfig("logoutUrl");
                                                        purge();
                                                    }}
                                                    sx={{
                                                        color: "primary.contrastText",
                                                        display: "inline-block",
                                                        paddingRight: "20px",
                                                    }}
                                                >
                                                    Log Out
                                                </Button>
                                            ) : (
                                                <Button
                                                    key="login_button"
                                                    onClick={() => {
                                                        window.location.href = getConfig("authUrl");
                                                    }}
                                                    sx={{
                                                        color: "primary.contrastText",
                                                        display: "inline-block",
                                                        paddingRight: "20px",
                                                    }}
                                                >
                                                    Log In
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Toolbar>
                        </Container>
                    </div>
                </div>
            </AppBar>
            <Breadcrumbs
                separator={false}
                maxItems={10}
                sx={{
                    ".MuiBreadcrumbs-separator": {
                        margin: "0px",
                    },
                    backgroundSize: "100% 32px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "0px 10px",
                    backgroundImage: `url(${breadcrumbsBackground})`,
                    maxWidth: "1200px",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            ></Breadcrumbs>
        </>
    );
};
export default TopNav;
