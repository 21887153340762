/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPValidationError,
  LoginRequest,
  LoginResponse,
  LoginWithCredentialsRequest,
  StatusResponse,
  WhoAmIResponse,
} from '../models/index';
import {
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    LoginRequestFromJSON,
    LoginRequestToJSON,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    LoginWithCredentialsRequestFromJSON,
    LoginWithCredentialsRequestToJSON,
    StatusResponseFromJSON,
    StatusResponseToJSON,
    WhoAmIResponseFromJSON,
    WhoAmIResponseToJSON,
} from '../models/index';

export interface GetUserDetailsRequest {
    userId: string;
}

export interface LoginOperationRequest {
    loginRequest: LoginRequest;
}

export interface LoginWithCredentialsOperationRequest {
    loginWithCredentialsRequest: LoginWithCredentialsRequest;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Loginstatus
     */
    async getLoginStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusResponseFromJSON(jsonValue));
    }

    /**
     * Loginstatus
     */
    async getLoginStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatusResponse> {
        const response = await this.getLoginStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get User Details
     */
    async getUserDetailsRaw(requestParameters: GetUserDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WhoAmIResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/user_details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WhoAmIResponseFromJSON(jsonValue));
    }

    /**
     * Get User Details
     */
    async getUserDetails(userId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WhoAmIResponse> {
        const response = await this.getUserDetailsRaw({ userId: userId }, initOverrides);
        return await response.value();
    }

    /**
     * Login
     */
    async loginRaw(requestParameters: LoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        if (requestParameters.loginRequest === null || requestParameters.loginRequest === undefined) {
            throw new runtime.RequiredError('loginRequest','Required parameter requestParameters.loginRequest was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Login
     */
    async login(loginRequest: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.loginRaw({ loginRequest: loginRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Login With Credentials
     */
    async loginWithCredentialsRaw(requestParameters: LoginWithCredentialsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        if (requestParameters.loginWithCredentialsRequest === null || requestParameters.loginWithCredentialsRequest === undefined) {
            throw new runtime.RequiredError('loginWithCredentialsRequest','Required parameter requestParameters.loginWithCredentialsRequest was null or undefined when calling loginWithCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login_with_credentials`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginWithCredentialsRequestToJSON(requestParameters.loginWithCredentialsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Login With Credentials
     */
    async loginWithCredentials(loginWithCredentialsRequest: LoginWithCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.loginWithCredentialsRaw({ loginWithCredentialsRequest: loginWithCredentialsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Logout
     */
    async logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Logout
     */
    async logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.logoutRaw(initOverrides);
        return await response.value();
    }

    /**
     * Test endpoint for creating a fake user session. This is only enabled in the development server
     * Test Login
     */
    async testLoginRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Test endpoint for creating a fake user session. This is only enabled in the development server
     * Test Login
     */
    async testLogin(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.testLoginRaw(initOverrides);
        return await response.value();
    }

    /**
     * Whoami
     */
    async whoamiRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WhoAmIResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/whoami`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WhoAmIResponseFromJSON(jsonValue));
    }

    /**
     * Whoami
     */
    async whoami(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WhoAmIResponse> {
        const response = await this.whoamiRaw(initOverrides);
        return await response.value();
    }

}
