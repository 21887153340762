import React, {FC} from "react";

import {AppBar, Container} from "@mui/material";

import VersionInfo from "./VersionInfo";
import {Link, useLocation, useNavigate} from "react-router-dom";

// Note: the footer has the z-index of -1 in order to avoid dialogues (like the link measurement
// dialogue) covering it

const Footer: FC = () => {
    return (
        <AppBar
            position="static"
            color="primary"
            sx={{
                backgroundColor: "#FFFFFF",
                zIndex: -1,
                marginTop: "6rem",
                marginBottom: 0,
                paddingBottom: 0,
            }}
        >
            <Container
                style={{
                    textAlign: "center",
                    color: "#ffffff",
                    paddingTop: "1.5rem",
                    paddingBottom: "1.5rem",
                    fontSize: "90%",
                }}
      >
        <Link to="/licenses">Licenses</Link>
        &emsp
      <a href="mailto:techsupport@fluidic.com?subject=Fluidic%20Insights%20Support%20Query">support@fluidic.com</a>
            </Container>
        </AppBar>
    );
};

export default Footer;
