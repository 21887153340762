import {Props} from "./types";

import React, {FC} from "react";
import {styled} from "@mui/material";

const SvgFluidicDopletWhite: FC<Props> = ({className, ...props}) => {
 return (
   <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1em"
      height="1em"
      version="1.1"
      viewBox="0 0 100 121.083"
      data-name="marque shape"
      {...props}
    >
      <defs>
        <clipPath id="clipPath110-0" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 1080h1080V0H0z"
            transform="translate(-721.189 -135)"
          ></path>
        </clipPath>
        <clipPath id="clipPath114-9" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 1080h1080V0H0z"
            transform="translate(-744.541 -457.371)"
          ></path>
        </clipPath>
        <clipPath id="clipPath116-0" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 1080h1080V0H0z"
            transform="translate(-624.852 -524.486)"
          ></path>
        </clipPath>
        <clipPath id="clipPath118-9" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 1080h1080V0H0z"
            transform="translate(-457.064 -524.486)"
          ></path>
        </clipPath>
        <clipPath id="clipPath120-8" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 1080h1080V0H0z"
            transform="translate(-337.375 -457.371)"
          ></path>
        </clipPath>
        <clipPath id="clipPath124-5" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 1080h1080V0H0z"
            transform="translate(-744.541 -622.629)"
          ></path>
        </clipPath>
        <clipPath id="clipPath126" clipPathUnits="userSpaceOnUse">
          <path
            d="M0 1080h1080V0H0z"
            transform="translate(-624.852 -555.514)"
          ></path>
        </clipPath>
      </defs>
      <g stroke="none">
        <path
          fill="#ffffff"
          fillOpacity="1"
          fillRule="nonzero"
          d="M-362.379 809.999c-123.608 0-223.81-100.204-223.81-223.81V223.812c0-123.607 100.202-223.814 223.81-223.814H.001c123.608 0 223.81 100.207 223.81 223.814V586.19c0 123.606-100.202 223.81-223.81 223.81zm-22.553-129.68c27.799 0 50.334-22.534 50.334-50.334 0-14.635-6.249-27.81-16.22-37.007v-59.597c0-25.39 18.833-46.044 41.98-46.044h257.216c23.147 0 41.98 20.655 41.98 46.044v57.914c-11.081 9.234-18.142 23.138-18.142 38.69 0 27.8 22.539 50.334 50.339 50.334 27.8 0 50.338-22.534 50.338-50.334 0-14.235-5.921-27.08-15.42-36.237v-60.367c0-62.396-48.94-113.163-109.095-113.163h-257.217c-60.152 0-109.098 50.767-109.098 113.163v58.613c-10.613 9.228-17.333 22.82-17.333 37.99 0 27.8 22.537 50.335 50.338 50.335zm76.093-290.54h257.217c60.154 0 109.095-50.763 109.095-113.159v-60.367c9.499-9.156 15.42-22.002 15.42-36.237 0-27.8-22.538-50.334-50.338-50.334-27.801 0-50.339 22.534-50.339 50.334 0 15.552 7.06 29.456 18.142 38.69v57.914c0 25.388-18.834 46.044-41.98 46.044h-257.217c-23.146 0-41.979-20.656-41.979-46.044V217.02c9.971-9.197 16.22-22.368 16.22-37.003 0-27.8-22.534-50.334-50.334-50.334-27.8 0-50.338 22.534-50.338 50.334 0 15.17 6.723 28.763 17.337 37.99v58.614c0 62.396 48.94 113.159 109.094 113.159z"
          clipPath="url(#clipPath110-0)"
          transform="matrix(.12346 0 0 -.12346 72.369 110.541)"
        ></path>
        <path
          fill="#ffffff"
          fillOpacity="1"
          fillRule="nonzero"
          d="M0 0c-18.533 0-33.558-15.024-33.558-33.558 0-18.533 15.025-33.557 33.558-33.557s33.558 15.024 33.558 33.557C33.558-15.024 18.533 0 0 0"
          clipPath="url(#clipPath114-9)"
          transform="matrix(.12346 0 0 -.12346 75.252 70.742)"
        ></path>
        <path
          fill="#ffffff"
          fillOpacity="1"
          fillRule="nonzero"
          d="M0 0c-18.533 0-33.558-15.024-33.558-33.558 0-18.533 15.025-33.557 33.558-33.557s33.558 15.024 33.558 33.557C33.558-15.024 18.533 0 0 0"
          clipPath="url(#clipPath116-0)"
          transform="matrix(.12346 0 0 -.12346 60.476 62.457)"
        ></path>
        <path
          fill="#ffffff"
          fillOpacity="1"
          fillRule="nonzero"
          d="M0 0c-18.533 0-33.558-15.024-33.558-33.558 0-18.533 15.025-33.557 33.558-33.557s33.558 15.024 33.558 33.557C33.558-15.024 18.533 0 0 0"
          clipPath="url(#clipPath118-9)"
          transform="matrix(.12346 0 0 -.12346 39.761 62.457)"
        ></path>
        <path
          fill="#ffffff"
          fillOpacity="1"
          fillRule="nonzero"
          d="M0 0c-18.533 0-33.558-15.024-33.558-33.558 0-18.533 15.025-33.557 33.558-33.557s33.558 15.024 33.558 33.557C33.558-15.024 18.533 0 0 0"
          clipPath="url(#clipPath120-8)"
          transform="matrix(.12346 0 0 -.12346 24.985 70.742)"
        ></path>
        <path
          fill="#ffffff"
          fillOpacity="1"
          fillRule="nonzero"
          d="M0 0c-18.533 0-33.558 15.024-33.558 33.558 0 18.533 15.025 33.557 33.558 33.557s33.558-15.024 33.558-33.557C33.558 15.024 18.533 0 0 0"
          clipPath="url(#clipPath124-5)"
          transform="matrix(.12346 0 0 -.12346 75.252 50.34)"
        ></path>
        <path
          fill="#ffffff"
          fillOpacity="1"
          fillRule="nonzero"
          d="M0 0c-18.533 0-33.558 15.024-33.558 33.558 0 18.533 15.025 33.557 33.558 33.557s33.558-15.024 33.558-33.557C33.558 15.024 18.533 0 0 0"
          clipPath="url(#clipPath126)"
          transform="matrix(.12346 0 0 -.12346 60.476 58.626)"
        ></path>
        <path
          fill="none"
          strokeWidth="0.345"
          d="M0.345 0H100V121.083H0.345z"
        ></path>
      </g>
    </svg>
  );
}
export default styled(SvgFluidicDopletWhite)``;
