/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExperimentType } from './ExperimentType';
import {
    ExperimentTypeFromJSON,
    ExperimentTypeFromJSONTyped,
    ExperimentTypeToJSON,
} from './ExperimentType';
import type { ExtraArguments } from './ExtraArguments';
import {
    ExtraArgumentsFromJSON,
    ExtraArgumentsFromJSONTyped,
    ExtraArgumentsToJSON,
} from './ExtraArguments';

/**
 * 
 * @export
 * @interface CreateExperimentRequest
 */
export interface CreateExperimentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateExperimentRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExperimentRequest
     */
    description?: string;
    /**
     * 
     * @type {ExperimentType}
     * @memberof CreateExperimentRequest
     */
    type: ExperimentType;
    /**
     * 
     * @type {ExtraArguments}
     * @memberof CreateExperimentRequest
     */
    extraArguments?: ExtraArguments;
    /**
     * 
     * @type {boolean}
     * @memberof CreateExperimentRequest
     */
    qc: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateExperimentRequest
     */
    color?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExperimentRequest
     */
    measurementIds?: Array<string>;
}

/**
 * Check if a given object implements the CreateExperimentRequest interface.
 */
export function instanceOfCreateExperimentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "qc" in value;

    return isInstance;
}

export function CreateExperimentRequestFromJSON(json: any): CreateExperimentRequest {
    return CreateExperimentRequestFromJSONTyped(json, false);
}

export function CreateExperimentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExperimentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': ExperimentTypeFromJSON(json['type']),
        'extraArguments': !exists(json, 'extra_arguments') ? undefined : ExtraArgumentsFromJSON(json['extra_arguments']),
        'qc': json['qc'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'measurementIds': !exists(json, 'measurement_ids') ? undefined : json['measurement_ids'],
    };
}

export function CreateExperimentRequestToJSON(value?: CreateExperimentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'type': ExperimentTypeToJSON(value.type),
        'extra_arguments': ExtraArgumentsToJSON(value.extraArguments),
        'qc': value.qc,
        'color': value.color,
        'measurement_ids': value.measurementIds,
    };
}

