/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ZippedAnalysisResponse
 */
export interface ZippedAnalysisResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ZippedAnalysisResponse
     */
    processing: boolean;
    /**
     * 
     * @type {string}
     * @memberof ZippedAnalysisResponse
     */
    link?: string;
}

/**
 * Check if a given object implements the ZippedAnalysisResponse interface.
 */
export function instanceOfZippedAnalysisResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "processing" in value;

    return isInstance;
}

export function ZippedAnalysisResponseFromJSON(json: any): ZippedAnalysisResponse {
    return ZippedAnalysisResponseFromJSONTyped(json, false);
}

export function ZippedAnalysisResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZippedAnalysisResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processing': json['processing'],
        'link': !exists(json, 'link') ? undefined : json['link'],
    };
}

export function ZippedAnalysisResponseToJSON(value?: ZippedAnalysisResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processing': value.processing,
        'link': value.link,
    };
}

