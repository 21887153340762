import React, {useEffect} from "react";
import {Grid, styled, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import {useTheme} from "@mui/material/styles";
import {handleNetworkError, snackbarError, snackbarSuccess, snackbarWarning} from "../../helpers/error";
import {MeasurementFileInfo, MeasurementsApi, ResponseError} from "../../api";
import FileUpload, {FileUploadProps} from "../../components/FileUpload";
import {apiConfig} from "../../ApiConfig";
import {Link, useNavigate} from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
    PageContainer,
    PageContentBlock,
    PageHeader,
    StandardDateTimeDisplay,
    UserName,
} from "../../helpers/HelperComponents";
import TextWithTooltip from "../../helpers/TextWithTooltip";

const measurementsApi = new MeasurementsApi(apiConfig);

interface UploadStatus {
    imported: boolean;
    errors: boolean;
    valid: number;
    invalid: number;
    hasExtraInfo?: boolean;
}

interface UploadErrorIndicatorProps {
    className?: string;
    uploadStatus: UploadStatus | undefined;
    errorRef: string;
}
const UploadErrorIndicator: React.FC<UploadErrorIndicatorProps> = styled((props: UploadErrorIndicatorProps) => {
    const theme = useTheme();

    if (props.uploadStatus === undefined) {
        return <></>;
    }
    return (
        <div className={props.className}>
            {/*Don't remove that === true !!*/}
            {props.uploadStatus.errors ? (
                props.uploadStatus.valid == 0 && props.uploadStatus.invalid == 0 ? (
                    <>
                        <InfoIcon sx={{color: theme.palette.error.main, position: "absolute"}} />
                        <div style={{paddingLeft: "30px"}}>
                            {`The file was not parseable`}
                            {props.uploadStatus.hasExtraInfo && (
                                <>
                                    &nbsp;
                                    <Link
                                        style={{textDecoration: "underline"}}
                                        to={`/errors?errorRef=${props.errorRef}`}
                                    >
                                        info
                                    </Link>
                                </>
                            )}
                        </div>
                    </>
                ) : props.uploadStatus.valid == 0 ? (
                    <>
                        <InfoIcon sx={{color: theme.palette.error.main, position: "absolute"}} />
                        <div style={{paddingLeft: "30px"}}>
                            {`No measurements were valid`}
                            {props.uploadStatus.hasExtraInfo && (
                                <>
                                    &nbsp;
                                    <Link
                                        style={{textDecoration: "underline"}}
                                        to={`/errors?errorRef=${props.errorRef}`}
                                    >
                                        info
                                    </Link>
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <InfoIcon sx={{color: theme.palette.error.main, position: "absolute"}} />
                        <div style={{paddingLeft: "30px"}}>
                            {`${props.uploadStatus.invalid} of ${
                                props.uploadStatus.valid + props.uploadStatus.invalid
                            } measurements were invalid`}
                            {props.uploadStatus.hasExtraInfo && (
                                <>
                                    &nbsp;
                                    <Link
                                        style={{textDecoration: "underline"}}
                                        to={`/errors?errorRef=${props.errorRef}`}
                                    >
                                        info
                                    </Link>
                                </>
                            )}
                        </div>
                    </>
                )
            ) : (
                <>
                    <CheckCircleIcon sx={{color: theme.palette.success.main, position: "absolute"}} />
                    <div style={{paddingLeft: "30px"}}>{`${props.uploadStatus.valid} measurements imported`}</div>
                </>
            )}
        </div>
    );
})``;

export default styled(function (props: any): JSX.Element {
    const [filesUploaded, setFilesUploaded] = React.useState<File[]>();
    const [recentUploads, setRecentUploads] = React.useState<MeasurementFileInfo[] | undefined>(undefined);
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!filesUploaded || filesUploaded.length === 0) return;

        const body = new FormData();

        for (let i = 0; i < filesUploaded.length; i++) {
            body.append(`files`, filesUploaded[i]);
        }

        measurementsApi
            .uploadMeasurements(filesUploaded)
            .then((resp) => {
                setFilesUploaded([]);
                if (resp.totalValid == 0 && resp.totalErrors == 0) {
                    enqueueSnackbar("Files were uploaded but nothing could be parsed", snackbarError);
                } else if (resp.totalValid == 0) {
                    enqueueSnackbar("Files were uploaded but no measurements were valid", snackbarError);
                } else if (resp.totalErrors > 0) {
                    enqueueSnackbar("Files were uploaded but not all measurements were valid", snackbarWarning);
                } else {
                    enqueueSnackbar("All files successfully uploaded", snackbarSuccess);
                }
                measurementsApi.getRecentUploads().then((resp) => {
                    setRecentUploads(resp);
                });
            })
            .catch((response: ResponseError) => {
                enqueueSnackbar("There wasn an error uploading the file(s)", snackbarError);
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    }, [filesUploaded, enqueueSnackbar]);

    useEffect(() => {
        measurementsApi.getRecentUploads().then((resp) => {
            setRecentUploads(resp);
        });
    }, []);

    if (recentUploads === undefined) {
        return <></>;
    }

    const fileUploadProp: FileUploadProps = {
        backgroundColor: theme.palette.background.paper,
        accept: "application/json",
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files !== null && event.target?.files?.length > 0) {
                const uploadedFiles = [];
                for (let i = 0; i < event.target.files.length; i++) {
                    uploadedFiles.push(event.target.files[i]);
                }
                setFilesUploaded(uploadedFiles);
            }
        },
        onDrop: (event: React.DragEvent<HTMLElement>) => {
            if (event.dataTransfer.files !== null && event.dataTransfer?.files?.length > 0) {
                const uploadedFiles = [];
                for (let i = 0; i < event.dataTransfer.files.length; i++) {
                    uploadedFiles.push(event.dataTransfer.files[i]);
                }
                setFilesUploaded(uploadedFiles);
            }
        },
    };

    return (
        <div className={props.className}>
            <PageHeader text="Uploads" />
            <PageContainer>
                <PageContentBlock sx={{paddingBottom: "0px"}}>
                    <FileUpload height="6rem" {...fileUploadProp} />
                </PageContentBlock>
                <PageContentBlock sx={{backgroundColor: theme.palette.background.default}}>
                    <Grid container direction="column" sx={{textAlign: "left"}}>
                        <Typography
                            variant="h5"
                            sx={{textAlign: "center", backgroundColor: theme.palette.background.paper}}
                        >
                            Recent uploads
                        </Typography>
                        {recentUploads.map((upload) => {
                            var status = undefined;
                            if (upload.status != null) {
                                status = JSON.parse(upload.status) as UploadStatus;
                            }
                            return (
                                <Grid
                                    key={upload.id}
                                    item
                                    container
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        marginTop: "2px",
                                        paddingLeft: "30px",
                                        paddingRight: "30px",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <Grid item xs={2}>
                                        <StandardDateTimeDisplay value={upload.dateUploaded} />
                                    </Grid>
                                    <Grid item xs={4.5}>
                                        <TextWithTooltip>{upload.fileName}</TextWithTooltip>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography noWrap={true}>
                                            <UserName user={upload.userUuid} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <UploadErrorIndicator errorRef={upload.id} uploadStatus={status} />
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </PageContentBlock>
            </PageContainer>
        </div>
    );
})((theme) => {
    return {};
});
