import React, {useRef, useState} from "react";
import {styled} from "@mui/material";
import {useAutocomplete} from "@mui/base/AutocompleteUnstyled";
import {SelectChangeEvent} from "@mui/material/Select";
import {BoundColumnType, FilterType} from "./types";

const Listbox = styled("ul")(({theme}) => ({
    width: 200,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: "absolute",
    listStyle: "none",
    maxHeight: "400px",
    overflowX: "scroll",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid rgba(0,0,0,.5)",
    "& li.Mui-focused": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        cursor: "pointer",
    },
    "& li:active": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

const Input = styled("input")(({theme}) => ({
    width: "200px",
    backgroundColor: "red",
}));

interface AutocompleteTagInputProps {
    boundColumn: BoundColumnType;
    values?: string[];
    filtersSelected: FilterType;
    handleSelectColumnFilter: ({boundColumn, newValue}: {boundColumn: BoundColumnType; newValue: string}) => void;
    className?: string;
}

function AutocompleteTagInput(props: AutocompleteTagInputProps) {
    const [value, setValue] = React.useState("");
    const {boundColumn, handleSelectColumnFilter, values, ...other} = props;

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
        handleSelectColumnFilter({boundColumn: boundColumn, newValue: event.target.value as string});
    };

    React.useEffect(() => {
        const filterInitialValue: string =
            props.filtersSelected.columnFilters.filter((f) => f.boundColumn === boundColumn).length > 0
                ? props.filtersSelected.columnFilters.filter((f) => f.boundColumn === boundColumn)[0].value
                : "";
        setValue(filterInitialValue);
    }, []);

    // @ts-ignore
    const {getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions} = useAutocomplete({
        id: "select-filter",
        options: props.values || [],
        getOptionLabel: (option: any) => option,
        onChange: (event: React.ChangeEvent<{}>, value: any) => {
            setValue(value as string);
            handleSelectColumnFilter({boundColumn: boundColumn, newValue: value as string});
        },
    });

    return (
        <div className={props.className} {...getRootProps()}>
            <Input {...getInputProps()} placeholder={"Click to select"} />
            {groupedOptions.length > 0 ? (
                <Listbox {...getListboxProps()}>
                    {(groupedOptions as string[]).map((option: string, index: number) => {
                        return <li {...getOptionProps({option, index})}>{option}</li>;
                    })}
                </Listbox>
            ) : null}
        </div>
    );
}

export default styled(AutocompleteTagInput)({});
