import React, {useState, useEffect, ChangeEvent} from "react";

import TextField from "@mui/material/TextField";

import {MeasurementSchema} from "../../api";
import {InputAdornment, styled} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";

type MeasurementSchemaParam = [keyof MeasurementSchema];

interface Props {
    label: string;
    id?: string;
    value: string | number | Date | undefined;
    handleInputChange: (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        changeTarget: MeasurementSchemaParam
    ) => void;
    paramName: MeasurementSchemaParam;
    isValidated: boolean;
    placeholder?: string;
    validationText?: string;
    className?: string;
    resetValue?: () => void;
    showReset?: boolean;
}

function EditMeasurementTextField({
    label,
    id,
    value = "",
    handleInputChange,
    paramName,
    isValidated,
    validationText,
    className,
    placeholder,
    showReset,
    resetValue,
}: Props) {
    const [textInputValue, setTextInputValue] = useState(() => value);

    const handleTextInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setTextInputValue(event.target.value);
        // once the value updates, we send it to the parent component state too
        // it is not read directly from there due to performance reasons (state collocation)
        handleInputChange(event, paramName);
    };

    useEffect(() => {
        setTextInputValue(value);
    }, [value]);

    return (
        <div
            className={className}
            style={{
                margin: 0,
                paddingTop: "0.25rem",
                paddingBottom: 0,
            }}
        >
            <TextField
                key={id}
                error={!isValidated}
                helperText={validationText && validationText.length > 0 ? validationText : " "}
                InputProps={{
                    endAdornment: showReset ? (
                        <InputAdornment position="end" sx={{cursor: "pointer"}}>
                            <ReplayIcon onClick={resetValue} />
                        </InputAdornment>
                    ) : undefined,
                }}
                InputLabelProps={{
                    style: {
                        hyphens: "auto",
                        fontSize: "100%",
                    },
                    shrink: true,
                }}
                FormHelperTextProps={{
                    style: {
                        marginLeft: 0,
                        marginRight: 0,
                        marginBottom: "0.6rem",
                    },
                }}
                fullWidth
                id={id}
                label={label}
                placeholder={placeholder}
                variant="outlined"
                onChange={handleTextInputChange}
                value={textInputValue}
            />
        </div>
    );
}

export default styled(
    React.memo(EditMeasurementTextField, (prevProps, nextProps) => prevProps.value == nextProps.value)
)``;
