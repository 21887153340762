import React from "react";
import {styled} from "@mui/material";
import Button from "@mui/material/Button";
import InputDatePicker from "./InputDatePicker";
import InputFilter from "./InputFilter";
import {FilterType, BoundColumnType, InputFilterType} from "./types";

interface ListSelectionBarProps {
    className?: string;
    children?: React.ReactNode;
    filtersSelected: FilterType;
    handleSelectFilter: ({
        filterName,
        newValue,
    }: {
        filterName: keyof FilterType;
        newValue: string | Date | null;
    }) => void;
    handleSelectColumnFilter: ({boundColumn, newValue}: {boundColumn: BoundColumnType; newValue: string}) => void;
    handleDeleteColumnFilter: ({boundColumn}: {boundColumn: BoundColumnType}) => void;
    inputFiltersAvailable?: InputFilterType[];
}

function ListSelectionBar(props: ListSelectionBarProps): JSX.Element {
    return (
        <div className={props.className}>
            <InputDatePicker
                sx={{
                    input: {
                        padding: "6px",
                        paddingBottom: "5px",
                        width: "100px",
                    },
                    flexGrow: "1",
                }}
                valueSelected={props.filtersSelected.startDate}
                handleSelectFilter={props.handleSelectFilter}
                boundColumn="startDate"
                label="Start date"
            />
            <InputDatePicker
                sx={{
                    input: {
                        padding: "6px",
                        paddingBottom: "5px",
                        width: "100px",
                    },
                    flexGrow: "1",
                }}
                valueSelected={props.filtersSelected.endDate}
                handleSelectFilter={props.handleSelectFilter}
                boundColumn="endDate"
                label="End date"
            />
            <InputFilter
                sx={{flexGrow: "5000"}}
                handleSelectColumnFilter={props.handleSelectColumnFilter}
                handleDeleteColumnFilter={props.handleDeleteColumnFilter}
                filtersSelected={props.filtersSelected}
                filtersAvailable={props.inputFiltersAvailable as InputFilterType[]}
            />
        </div>
    );
}

export default styled(ListSelectionBar)((theme) => {
    return {
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        backgroundColor: theme.theme.palette.background.paper,
        //min-height: 70px;
        height: "auto",
        padding: "8px 10px",
        margin: "0px",
        gap: "10px",
    };
});
