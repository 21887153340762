import React, {createRef, useEffect, useRef, useState} from "react";
import {styled, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";

function TextWithTooltip(props: any) {
    const [overflowActive, setOverflowActive] = useState<boolean>(false);

    const childRef: any = useRef(null);

    useEffect(() => {
        if (childRef === null || childRef.current === null) {
            return;
        }
        if (childRef.current.offsetWidth < childRef.current.scrollWidth) {
            setOverflowActive(true);
        }
    }, []);

    return (
        <>
            {overflowActive ? (
                <Tooltip title={props.children}>
                    <div ref={childRef} style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                        {props.children}
                    </div>
                </Tooltip>
            ) : (
                <div ref={childRef} style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                    {props.children}
                </div>
            )}
        </>
    );
}

export default styled(TextWithTooltip)({});
