/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ZippedAnalysisRequest
 */
export interface ZippedAnalysisRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ZippedAnalysisRequest
     */
    generate: boolean;
}

/**
 * Check if a given object implements the ZippedAnalysisRequest interface.
 */
export function instanceOfZippedAnalysisRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "generate" in value;

    return isInstance;
}

export function ZippedAnalysisRequestFromJSON(json: any): ZippedAnalysisRequest {
    return ZippedAnalysisRequestFromJSONTyped(json, false);
}

export function ZippedAnalysisRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZippedAnalysisRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'generate': json['generate'],
    };
}

export function ZippedAnalysisRequestToJSON(value?: ZippedAnalysisRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'generate': value.generate,
    };
}

