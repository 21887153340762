/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MeasurementHeader
 */
export interface MeasurementHeader {
    /**
     * 
     * @type {Date}
     * @memberof MeasurementHeader
     */
    runTimestamp: Date;
    /**
     * 
     * @type {string}
     * @memberof MeasurementHeader
     */
    labeledName: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementHeader
     */
    unlabeledName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementHeader
     */
    complexName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementHeader
     */
    instrumentType: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementHeader
     */
    instrumentId: string;
    /**
     * 
     * @type {number}
     * @memberof MeasurementHeader
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementHeader
     */
    colorCount?: number;
    /**
     * 
     * @type {string}
     * @memberof MeasurementHeader
     */
    colorInfo?: string;
}

/**
 * Check if a given object implements the MeasurementHeader interface.
 */
export function instanceOfMeasurementHeader(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "runTimestamp" in value;
    isInstance = isInstance && "labeledName" in value;
    isInstance = isInstance && "instrumentType" in value;
    isInstance = isInstance && "instrumentId" in value;

    return isInstance;
}

export function MeasurementHeaderFromJSON(json: any): MeasurementHeader {
    return MeasurementHeaderFromJSONTyped(json, false);
}

export function MeasurementHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'runTimestamp': (new Date(json['run_timestamp'])),
        'labeledName': json['labeled_name'],
        'unlabeledName': !exists(json, 'unlabeled_name') ? undefined : json['unlabeled_name'],
        'complexName': !exists(json, 'complex_name') ? undefined : json['complex_name'],
        'instrumentType': json['instrument_type'],
        'instrumentId': json['instrument_id'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'colorCount': !exists(json, 'color_count') ? undefined : json['color_count'],
        'colorInfo': !exists(json, 'color_info') ? undefined : json['color_info'],
    };
}

export function MeasurementHeaderToJSON(value?: MeasurementHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'run_timestamp': (value.runTimestamp.toISOString()),
        'labeled_name': value.labeledName,
        'unlabeled_name': value.unlabeledName,
        'complex_name': value.complexName,
        'instrument_type': value.instrumentType,
        'instrument_id': value.instrumentId,
        'count': value.count,
        'color_count': value.colorCount,
        'color_info': value.colorInfo,
    };
}

