/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MeasurementFileSchema
 */
export interface MeasurementFileSchema {
    /**
     * 
     * @type {string}
     * @memberof MeasurementFileSchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementFileSchema
     */
    userUuid: string;
    /**
     * 
     * @type {Date}
     * @memberof MeasurementFileSchema
     */
    dateUploaded: Date;
    /**
     * 
     * @type {string}
     * @memberof MeasurementFileSchema
     */
    measurementFile: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementFileSchema
     */
    status?: string;
}

/**
 * Check if a given object implements the MeasurementFileSchema interface.
 */
export function instanceOfMeasurementFileSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userUuid" in value;
    isInstance = isInstance && "dateUploaded" in value;
    isInstance = isInstance && "measurementFile" in value;

    return isInstance;
}

export function MeasurementFileSchemaFromJSON(json: any): MeasurementFileSchema {
    return MeasurementFileSchemaFromJSONTyped(json, false);
}

export function MeasurementFileSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementFileSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userUuid': json['user_uuid'],
        'dateUploaded': (new Date(json['date_uploaded'])),
        'measurementFile': json['measurement_file'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function MeasurementFileSchemaToJSON(value?: MeasurementFileSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user_uuid': value.userUuid,
        'date_uploaded': (value.dateUploaded.toISOString()),
        'measurement_file': value.measurementFile,
        'status': value.status,
    };
}

