import React from "react";
import {Grid, styled} from "@mui/material";
import {MeasurementSchema, MeasurementOriginalValues} from "../../api";
import {useTheme} from "@mui/material/styles";
import TextWithTooltip from "../../helpers/TextWithTooltip";

interface MeasurementEntryProps {
    measurement: MeasurementSchema;
    className?: string;
    children?: React.ReactNode;
    useNames?: boolean;
    useCircuit?: boolean;
    strikeout?: boolean;
}

export function calculateCompensation(measurement: MeasurementSchema) {
    let diffusedCompensated = measurement.diffusedRawFilled - measurement.diffusedRawEmpty * 0.9;
    let undiffusedCompensated = measurement.undiffusedRawFilled - measurement.undiffusedRawEmpty * 0.9;

    let dilutionCompensation = (1 + measurement.ratioUp) / (2 * measurement.ratioUp);

    let diffusedDilutionCompensated = diffusedCompensated * dilutionCompensation;
    let undiffusedDilutionCompensated = undiffusedCompensated * dilutionCompensation;

    return ((diffusedDilutionCompensated + undiffusedDilutionCompensated) * 1000).toFixed(2);
}

function MeasurementEntry({measurement, className, children, useNames, useCircuit, strikeout}: MeasurementEntryProps) {
    const theme: any = useTheme();
    const calculatedCompensation = calculateCompensation(measurement);

    const getEntryColor = React.useCallback(
        (entryName: keyof MeasurementOriginalValues): string => {
            const isValueEditied =
                measurement.originalValues && measurement[entryName] !== measurement.originalValues[entryName];
            return isValueEditied ? theme.palette.warning.dark : "inherit";
        },
        [measurement]
    );

    if (measurement === undefined) {
        return <></>;
    }

    return (
        <div className={className} style={{display: "flex", alignContent: "stretch"}}>
            {useCircuit ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                    className={"circuit"}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                fontSize: "8pt",
                                position: "relative",
                                left: "-4px",
                            }}
                        >
                            {measurement.color && (measurement.color.toLowerCase() == "red" ? <>R</> : <>G</>)}
                        </div>
                        <div
                            style={{
                                flexGrow: "10",
                                width: "6px",
                                marginTop: "2px",
                                marginBottom: "8px",
                                marginRight: "10px",
                                backgroundColor: measurement.color || theme.palette.background.paper,
                            }}
                        ></div>
                    </div>
                    <div
                        style={{
                            verticalAlign: "middle",
                            paddingTop: "8px",
                        }}
                    >
                        {measurement.circuitId}
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className={"sample measurement-item"}>
                {useNames ? (
                    <div className={"sample-title"} style={{color: getEntryColor("labeledName")}}>
                        {measurement.labeledName}
                    </div>
                ) : (
                    <div>Labeled concentration</div>
                )}
                <div
                    style={{
                        color: getEntryColor("labeledConcentration"),
                        textDecoration: strikeout ? "line-through" : "inherit",
                    }}
                >
                    {(measurement.labeledConcentration * 1000).toFixed(2)} nM
                </div>
            </div>

            <div className={"bindingpartner measurement-item"}>
                <div>Binding partner</div>
                <div>
                    {useNames ? (
                        <div className={"binding-partner-name"}>
                            <div>
                                {measurement.unlabeledName !== undefined ? (
                                    <div style={{color: getEntryColor("unlabeledName")}}>
                                        <TextWithTooltip>{measurement.unlabeledName}</TextWithTooltip>
                                    </div>
                                ) : measurement.unlabeledConcentration !== undefined ? (
                                    <div
                                        style={{
                                            color: getEntryColor("unlabeledConcentration"),
                                            textDecoration: strikeout ? "line-through" : "inherit",
                                        }}
                                    >
                                        "Unnamed"
                                    </div>
                                ) : (
                                    <>&nbsp;</>
                                )}
                            </div>
                            <div>
                                {measurement.complexName !== undefined ? (
                                    <div style={{color: getEntryColor("complexName")}}>
                                        <TextWithTooltip>{measurement.complexName}</TextWithTooltip>
                                    </div>
                                ) : measurement.complexMixtureDilution !== undefined ? (
                                    <div
                                        style={{
                                            color: getEntryColor("complexMixtureDilution"),
                                            textDecoration: strikeout ? "line-through" : "inherit",
                                        }}
                                    >
                                        "Unnamed"
                                    </div>
                                ) : (
                                    <>&nbsp;</>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={"binding-partner-title"}>
                            <div>Unlabeled concentration</div>
                            <div>Complex mixture dilution</div>
                        </div>
                    )}
                </div>
                <div className={"binding-partner-value"}>
                    <div
                        className={"value"}
                        style={{
                            color: getEntryColor("unlabeledConcentration"),
                            textDecoration: strikeout ? "line-through" : "inherit",
                        }}
                    >
                        {measurement.unlabeledConcentration !== undefined ? (
                            (measurement.unlabeledConcentration * 1000).toFixed(2) + " nM"
                        ) : (
                            <>&nbsp;</>
                        )}
                    </div>
                    <div
                        className={"value"}
                        style={{
                            color: getEntryColor("complexMixtureDilution"),
                            textDecoration: strikeout ? "line-through" : "inherit",
                        }}
                    >
                        {measurement.complexMixtureDilution !== undefined ? (
                            measurement.complexMixtureDilution.toFixed(2) + " %"
                        ) : (
                            <>&nbsp;</>
                        )}
                    </div>
                </div>
            </div>

            <div className={"hydrodynamic-radius measurement-item"}>
                <div>Hydrodynamic radius</div>
                <div style={{textDecoration: strikeout ? "line-through" : "inherit"}}>
                    {measurement.size !== undefined ? measurement.size.toFixed(2) + " nm" : <>&nbsp;</>}
                </div>
            </div>

            <div className={"fluorescence-intensity measurement-item"}>
                <div>Fluorescence intensity</div>
                <div style={{textDecoration: strikeout ? "line-through" : "inherit"}}>
                    {calculatedCompensation !== undefined ? calculatedCompensation + " mAU" : <>&nbsp;</>}
                </div>
            </div>

            <div className={"child-container"}>{children}</div>
        </div>
    );
}

export default styled(MeasurementEntry)((theme) => {
    return {
        backgroundColor: theme.theme.palette.background.paper,
        marginTop: "5px",
        paddingRight: "20px",
        "& > *": {
            flex: "12",
        },
        "& .circuit": {
            fontSize: "20pt",
            paddingLeft: "0px",
            paddingRight: "20px",
            marginLeft: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: "0",
        },
        "& .measurement-item": {
            display: "flex",
            flexDirection: "column",
            marginBottom: "0px",
            "&:not(:last-of-type)": {
                paddingRight: "10px",
            },

            // flexGrow: "12",
            "& div": {
                // border: "1px solid red",
                textAlign: "center",
            },
            "& >div:not(:last-of-type)": {
                fontSize: "8pt",
                color: theme.theme.palette.text.secondary,
                flexGrow: "12",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
            },
            "& .binding-partner-title, .binding-partner-value, .binding-partner-name": {
                display: "flex",
                flexDirection: "row",
                "& >div": {
                    flexGrow: "12",
                },
            },
            "& .binding-partner-value >div:first-of-type": {
                paddingRight: "10px",
            },
            "& .binding-partner-name": {
                color: theme.theme.palette.text.primary,
                fontWeight: "bold",
            },
            "& >div.sample-title": {
                color: theme.theme.palette.text.primary,
            },
        },
        "& .bindingpartner": {
            flexGrow: "24",
        },
        "& .bindingpartner > div:first-of-type": {
            borderBottom: "1px solid " + theme.theme.palette.text.secondary,
        },
        "& .child-container": {
            flex: "0",
        },
    };
});
