import {Grid, styled} from "@mui/material";
import {PageContainer, PageContentBlock, PageHeader} from "../../helpers/HelperComponents";
import frontendVersion from "../../version";
import {getConfig} from "../../RuntimeConfig";
import {ServicesApi, VersionInfoResponse} from "../../api";
import {apiConfig} from "../../ApiConfig";
import {handleNetworkError} from "../../helpers/error";
import {useState} from "react";
import VersionInfo from "../../components/Footer/VersionInfo";

const servicesApi = new ServicesApi(apiConfig);

export default styled((props: any) => {
    const [version, setVersion] = useState<VersionInfoResponse>({} as VersionInfoResponse);

    servicesApi
        .getVersion()
        .then((version) => {
            setVersion(version);
        })
        .catch((resp) => {});

    return (
        <div className={props.className}>
            <PageContainer>
                <PageHeader text="Version info" />
                <PageContentBlock>
                    <Grid container className={"grid"}>
                        <Grid item xs={4}>
                            Frontend version
                        </Grid>
                        <Grid item xs={8}>
                            {frontendVersion.version}
                        </Grid>
                    </Grid>
                    <Grid container className={"grid"}>
                        <Grid item xs={4}>
                            Frontend git hash
                        </Grid>
                        <Grid item xs={8}>
                            {getConfig("gitHash")}
                        </Grid>
                    </Grid>
                </PageContentBlock>
                <PageContentBlock>
                    <Grid container className={"grid"}>
                        <Grid item xs={4}>
                            Backend version
                        </Grid>
                        <Grid item xs={8}>
                            {version.applicationVersion}
                        </Grid>
                    </Grid>
                    <Grid container className={"grid"}>
                        <Grid item xs={4}>
                            APIversion
                        </Grid>
                        <Grid item xs={8}>
                            {version.apiVersion}
                        </Grid>
                    </Grid>
                    <Grid container className={"grid"}>
                        <Grid item xs={4}>
                            Backend git hash
                        </Grid>
                        <Grid item xs={8}>
                            {version.gitHash}
                        </Grid>
                    </Grid>
                    <Grid container className={"grid"}>
                        <Grid item xs={4}>
                            Backend build number
                        </Grid>
                        <Grid item xs={8}>
                            {version.buildNumber}
                        </Grid>
                    </Grid>
                </PageContentBlock>
            </PageContainer>
        </div>
    );
})((theme) => {
    return {
        ".grid": {
            borderBottom: `1px solid ${theme.theme.palette.background.default}`,
        },
        ".grid>div:first-of-type": {
            textAlign: "right",
            paddingRight: "40px",
        },
        ".grid :last-of-type": {
            textAlign: "left",
        },
    };
});
