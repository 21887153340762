import * as React from "react";
import dayjs, {Dayjs} from "dayjs";
import TextField from "@mui/material/TextField";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {FilterType} from "./types";
import {styled} from "@mui/material";
interface InputDatePickerProps {
    label: string;
    boundColumn: keyof FilterType;
    valueSelected: Date;
    handleSelectFilter: ({
        filterName,
        newValue,
    }: {
        filterName: keyof FilterType;
        newValue: string | Date | null;
    }) => void;
    className?: string;
}

function InputDatePicker({label, boundColumn, valueSelected, handleSelectFilter, className}: InputDatePickerProps) {
    const [value, setValue] = React.useState<Dayjs | null>(dayjs(valueSelected));

    React.useEffect(() => {
        if (value !== null && typeof value !== "undefined" && value instanceof dayjs) {
            handleSelectFilter({filterName: boundColumn, newValue: value.toDate()});
        }
    }, [value]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={className}>
                <DatePicker
                    label={label}
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat={"YYYY-MM-DD"}
                />
            </div>
        </LocalizationProvider>
    );
}

export default styled(InputDatePicker)({});
