/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MeasurementGroup
 */
export interface MeasurementGroup {
    /**
     * 
     * @type {Date}
     * @memberof MeasurementGroup
     */
    timestamp: Date;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGroup
     */
    labeledName: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGroup
     */
    unlabeledName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGroup
     */
    complexName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGroup
     */
    instrumentType: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementGroup
     */
    instrumentId: string;
}

/**
 * Check if a given object implements the MeasurementGroup interface.
 */
export function instanceOfMeasurementGroup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "labeledName" in value;
    isInstance = isInstance && "instrumentType" in value;
    isInstance = isInstance && "instrumentId" in value;

    return isInstance;
}

export function MeasurementGroupFromJSON(json: any): MeasurementGroup {
    return MeasurementGroupFromJSONTyped(json, false);
}

export function MeasurementGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': (new Date(json['timestamp'])),
        'labeledName': json['labeled_name'],
        'unlabeledName': !exists(json, 'unlabeled_name') ? undefined : json['unlabeled_name'],
        'complexName': !exists(json, 'complex_name') ? undefined : json['complex_name'],
        'instrumentType': json['instrument_type'],
        'instrumentId': json['instrument_id'],
    };
}

export function MeasurementGroupToJSON(value?: MeasurementGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': (value.timestamp.toISOString()),
        'labeled_name': value.labeledName,
        'unlabeled_name': value.unlabeledName,
        'complex_name': value.complexName,
        'instrument_type': value.instrumentType,
        'instrument_id': value.instrumentId,
    };
}

