/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResourceOutput,
  TypeMapResponse,
  VersionInfoResponse,
} from '../models/index';
import {
    ResourceOutputFromJSON,
    ResourceOutputToJSON,
    TypeMapResponseFromJSON,
    TypeMapResponseToJSON,
    VersionInfoResponseFromJSON,
    VersionInfoResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class ServicesApi extends runtime.BaseAPI {

    /**
     * Produces a map that specifies the resources returned by each analysis type
     * Get Resource Definitions
     */
    async getResourceDefinitionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: ResourceOutput; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/services/resource_definitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, ResourceOutputFromJSON));
    }

    /**
     * Produces a map that specifies the resources returned by each analysis type
     * Get Resource Definitions
     */
    async getResourceDefinitions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: ResourceOutput; }> {
        const response = await this.getResourceDefinitionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Produces a map of internal experiment type enum value to friendly name
     * Get Type Map
     */
    async getTypeMapRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TypeMapResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/services/type_map`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeMapResponseFromJSON(jsonValue));
    }

    /**
     * Produces a map of internal experiment type enum value to friendly name
     * Get Type Map
     */
    async getTypeMap(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TypeMapResponse> {
        const response = await this.getTypeMapRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns various items related to versioning This API is intentionally unprotected.
     * Get Version
     */
    async getVersionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VersionInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/services/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionInfoResponseFromJSON(jsonValue));
    }

    /**
     * Returns various items related to versioning This API is intentionally unprotected.
     * Get Version
     */
    async getVersion(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VersionInfoResponse> {
        const response = await this.getVersionRaw(initOverrides);
        return await response.value();
    }

}
