import {styled} from "@mui/material";
import {useTheme} from "@mui/material/styles";

interface OutlineBorderProps {
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
    title?: string;
}

export default styled((props: OutlineBorderProps) => {
    const theme = useTheme();

    return (
        <div className={props.className}>
            {props.title && (
                <div
                    style={{
                        position: "absolute",
                        top: "-11px",
                        left: "8px",
                        fontSize: "0.8rem",
                        backgroundColor: theme.palette.background.paper,
                        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        color: theme.palette.text.disabled,
                    }}
                >
                    {props.title}
                </div>
            )}
            {props.children}
        </div>
    );
})((theme) => {
    return {
        borderWidth: "1px",
        borderColor: theme.theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.23)" : "rgba(0, 0, 0, 0.23)",
        "&:hover": {
            borderColor: theme.theme.palette.mode === "dark" ? "rgba(176, 176, 176, 1)" : "rgba(0, 0, 0, 1)",
        },
        borderStyle: "solid",
        borderRadius: "4px",
        position: "relative",
        marginTop: "10px",
        paddingLeft: "10px",
    };
});
