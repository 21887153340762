import {Box, styled, Typography} from "@mui/material";
import {ExperimentsApi, ExperimentSchema, ResponseError, StudiesApi, StudySummarySchema} from "../../api";
import ExperimentsTable from "../../pages/Study/Group/ExperimentsTable";
import {useEffect, useRef, useState} from "react";
import {apiConfig} from "../../ApiConfig";
import {GridColDef} from "@mui/x-data-grid";
import {formatStandardDate} from "../../helpers";
import {ColorPicker} from "mui-color";
import {randomColor} from "../../helpers/PlotColors";
const math = require("mathjs");

const experimentsApi = new ExperimentsApi(apiConfig);
const studiesApi = new StudiesApi(apiConfig);

interface StudyDetailsMultiAssayProps {
    className?: string;
    studyData: StudySummarySchema;
}

export default styled((props: StudyDetailsMultiAssayProps) => {
    const [experiments, setExperiments] = useState<ExperimentSchema[]>([]);
    const [study, setStudy] = useState<StudySummarySchema | undefined>(undefined);
    const studyRef = useRef<StudySummarySchema | undefined>();

    studyRef.current = study;

    useEffect(() => {
        if (props.studyData?.hasOwnProperty("id")) {
            setStudy(props.studyData);
        }
    }, [props.studyData]);

    useEffect(() => {
        let experimentsList: ExperimentSchema[] = [];

        const getExperiments = async () => {
            for (const e of props.studyData.extra?.experiments || []) {
                try {
                    const experiment = await experimentsApi.getExperimentById(e.experimentId);
                    if (experiment) {
                        experimentsList.push(experiment);
                    }
                } catch (e) {
                    const x: ResponseError = e as ResponseError;
                    console.log(`Exception getting experiment data ${x.response.status} ${x.response.statusText}`);
                }
            }

            setExperiments(experimentsList);
        };

        getExperiments();
    }, [props.studyData]);

    function getColor(id: string) {
        // @ts-ignore
        let c = studyRef.current?.extra?.experiments?.filter((e) => e.experimentId == id)[0]?.color;
        if (c) {
            return c;
        }

        return "#ffffff";
    }

    return (
        <>
            <Typography sx={{marginTop: "30px", paddingBottom: "15px"}} variant={"h4"}>
                Cell density
            </Typography>
            <Box>
                {props.studyData.extra?.cellDensity} (
                {math
                    .format(props.studyData.extra?.cellDensity, {notation: "engineering", lowerExp: -3, upperExp: 3})
                    .replace("e+0", "")
                    .replace("e+", "e")}
                )
                cells/L
            </Box>

            <Typography sx={{marginTop: "30px", paddingBottom: "15px"}} variant={"h4"}>
                Experiments
            </Typography>
            {study && (
                <ExperimentsTable
                    columns={{
                        [-1]: {
                            field: "",
                            headerName: "",
                            width: 40,
                            editable: false,
                            sortable: false,
                            filterable: false,
                            disableColumnMenu: true,
                            hide: false,
                            renderCell: (data) => {
                                return (
                                    <ColorPicker
                                        value={getColor(data.row.id)}
                                        disableTextfield={true}
                                        disableAlpha={true}
                                        hideTextfield={true}
                                        onChange={(c) => {
                                            let d = study?.extra?.experiments?.filter(
                                                (e) => e.experimentId == data.row.id
                                            )[0];
                                            if (d) {
                                                d.color = "#" + (c as any).hex;
                                            }
                                            setStudy({...study});
                                            // studiesApi.updateStudy(studyData.id, studyData);
                                        }}
                                        onOpen={(o) => {
                                            if (!o) {
                                                studiesApi.updateStudy(study.id, study);
                                            }
                                        }}
                                    />
                                );
                            },
                        },
                        [2]: {
                            field: "description",
                            headerName: "Description",
                            type: "string",
                            width: 250,
                            editable: false,
                            sortable: true,
                        },
                    }}
                    experiments={experiments}
                />
            )}
        </>
    );
})((theme) => {
    return {};
});
