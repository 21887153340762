import React, {useEffect} from "react";
import {apiConfig} from "../../ApiConfig";
import {AuthApi, LoginRequest, ResponseError} from "../../api";
import {Link, To, useNavigate, useSearchParams} from "react-router-dom";
import {getConfig, useConfig} from "../../RuntimeConfig";
import {enqueueSnackbar} from "notistack";
import {handleNetworkError, snackbarSuccess} from "../../helpers/error";
import {useAppDispatch, useAppSelector} from "../../ReduxStore";
import {setLoggedIn, setLoggedOut, setWhoAmI} from "../../reducers/LoginData";
import {useGlobalState} from "../../GlobalState";
import {PageButtonBar, PageContainer, PageContentBlock, PageHeader} from "../../helpers/HelperComponents";
import {Grid, IconButton, styled, Tooltip, Typography} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import {useTheme} from "@mui/material/styles";

const authApi = new AuthApi(apiConfig);

export function Login(): JSX.Element | null {
    const [searchParams, setSearchParams] = useSearchParams();

    const {getConfig} = useConfig();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    let code = searchParams.get("code");

    if (code === null) {
        if (getConfig("authUrl") !== "") {
            window.location.href = getConfig("authUrl");
            return null;
        } else {
            code = "noauth";
        }
    }

    var loginRequest: LoginRequest = {
        clientId: getConfig("clientId"),
        code: code,
        redirectUri: getConfig("redirectUri"),
    };

    authApi
        .login(loginRequest)
        .then((resp) => {
            if (resp.success) {
                authApi
                    .whoami()
                    .then((whoami) => {
                        let user = whoami;
                        dispatch(setWhoAmI(whoami));
                        dispatch(setLoggedIn());
                        if (!resp.relogin) enqueueSnackbar("Logged in successfully", snackbarSuccess);
                    })
                    .catch((resp: ResponseError) => {
                        console.log(resp);
                    });
                const url_pathname = "url_pathname";
                if (window.localStorage.getItem(url_pathname)) {
                    navigate("" + window.localStorage.getItem(url_pathname));
                    window.localStorage.removeItem(url_pathname);
                } else {
                    navigate("/");
                }
            } else {
                dispatch(setLoggedOut());
                navigate("/loginfail");
            }
        })
        .catch((response: ResponseError) => {
            handleNetworkError(response).then((target) => {
                if (target) {
                    navigate(target);
                }
            });
            dispatch(setLoggedOut);
            navigate("/loginfail");
        });

    return (
        <div
            style={{
                display: "inline-block",
                padding: "40px",
                margin: "auto",
                marginTop: "30vh",
                verticalAlign: "middle",
                backgroundColor: "white",
            }}
        >
            Logging you in
        </div>
    );
}

interface LoginFailedProps {
    className?: string;
}

//export function LoginFailed(props: LoginFailedProps) {
export const LoginFailed = styled((props: LoginFailedProps) => {
    // return (
    //     <div
    //         style={{
    //             display: "inline-block",
    //             padding: "40px",
    //             margin: "auto",
    //             marginTop: "30vh",
    //             verticalAlign: "middle",
    //             backgroundColor: "white",
    //             color: "red",
    //         }}
    //     >
    //         Login failed
    //     </div>
    // );

    const theme = useTheme();
    const loginData = useAppSelector((state) => state.loginState);
    const navigate = useNavigate();

    // Check if we're actually logged in and just navigated here somehow
    useEffect(() => {
        if (loginData.login) {
            navigate("/");
        }
    });

    if (loginData.login) {
        return <></>;
    }

    return (
        <div className={props.className}>
            <PageHeader text="Login failed" />
            <PageContainer>
                <PageContentBlock sx={{paddingBottom: "5px"}}>
                    <PageButtonBar>
                        <a href={getConfig("logoutUrl")}>
                            <Tooltip title={"Reset session"}>
                                <IconButton>
                                    <LogoutIcon />
                                </IconButton>
                            </Tooltip>
                        </a>
                    </PageButtonBar>
                </PageContentBlock>
                <PageContentBlock>
                    <Grid container alignItems={"center"}>
                        <Grid item xs={4} sx={{textAlign: "right"}}>
                            <LockIcon style={{height: "200px", width: "200px", color: theme.palette.error.main}} />
                        </Grid>
                        <Grid item xs={8} sx={{textAlign: "left"}}>
                            <Typography variant="h4">Your login attempt did not succeed</Typography>
                        </Grid>
                    </Grid>
                    Check your user name and password. Click the logout button to reset your session data and try again
                    <br />
                    &nbsp;
                </PageContentBlock>
            </PageContainer>
        </div>
    );
})((theme) => {
    return {};
});
