import {useEffect, useState, useMemo} from "react";
import {useTheme} from "@mui/material/styles";
import {apiConfig} from "../../ApiConfig";
import {ErrorApi, ErrorListItem} from "../../api";
import {handleNetworkError} from "../../helpers/error";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import ErrorsTable from "./ErrorsTable";
import {PageButtonBar, PageContainer, PageContentBlock, PageHeader} from "../../helpers/HelperComponents";
import {history} from "../../App";
import {IconButton, Tooltip} from "@mui/material";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import HistoryIcon from "@mui/icons-material/History";
import ErrorIcon from "@mui/icons-material/Error";

const errorApi = new ErrorApi(apiConfig);

function ErrorList(props: any): JSX.Element {
    const theme = useTheme();
    const navigate = useNavigate();
    const [errorList, setErrorList] = useState<ErrorListItem[]>([]);
    const [params] = useSearchParams();
    const errorRef = params.get("errorRef");

    const headerText = useMemo(() => (errorRef !== null ? "Related errors" : "Recent errors"), [errorRef]);

    useEffect(() => {
        if (errorRef !== null) {
            errorApi
                .getErrorsByRef(errorRef)
                .then((resp) => {
                    setErrorList(
                        resp.sort((a, b) => {
                            return a.errorTime < b.errorTime ? 1 : -1;
                        })
                    );
                })
                .catch((response) => {
                    handleNetworkError(response).then((target) => {
                        if (target) {
                            navigate(target);
                        }
                    });
                });
            return;
        }
        errorApi
            .listErrors()
            .then((resp) => {
                setErrorList(
                    resp.sort((a, b) => {
                        return a.errorTime < b.errorTime ? 1 : -1;
                    })
                );
            })
            .catch((response) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    }, []);

    return (
        <div className={props.className}>
            <PageHeader text={headerText} />
            <PageContainer>
                <PageContentBlock>
                    <PageButtonBar backLink={history.back}>
                        {errorRef !== null && errorRef !== undefined && (
                            <Link to="/errors?">
                                <Tooltip title="Show all recent errors">
                                    <IconButton>
                                        <Box
                                            sx={{
                                                width: "18px",
                                                height: "18px",
                                                backgroundColor: theme.palette.primary.main,
                                                position: "absolute",
                                                left: "18px",
                                                top: "17px",
                                                color: theme.palette.primary.main,
                                                borderRadius: "12px",
                                                zIndex: "900",
                                            }}
                                        />
                                        <HistoryIcon
                                            sx={{
                                                position: "absolute",
                                                left: "14px",
                                                top: "14px",
                                                transform: "scale(75%)",
                                                zIndex: "1000",
                                            }}
                                        />
                                        <ErrorIcon sx={{position: "relative", top: "-1px", left: "-3px"}} />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        )}
                    </PageButtonBar>
                    <ErrorsTable errorList={errorList} />
                </PageContentBlock>
            </PageContainer>
        </div>
    );
}

export default ErrorList;
