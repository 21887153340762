/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MeasurementHeader } from './MeasurementHeader';
import {
    MeasurementHeaderFromJSON,
    MeasurementHeaderFromJSONTyped,
    MeasurementHeaderToJSON,
} from './MeasurementHeader';
import type { MeasurementSchema } from './MeasurementSchema';
import {
    MeasurementSchemaFromJSON,
    MeasurementSchemaFromJSONTyped,
    MeasurementSchemaToJSON,
} from './MeasurementSchema';

/**
 * 
 * @export
 * @interface ReadSectionResponse
 */
export interface ReadSectionResponse {
    /**
     * 
     * @type {MeasurementHeader}
     * @memberof ReadSectionResponse
     */
    header: MeasurementHeader;
    /**
     * 
     * @type {Array<MeasurementSchema>}
     * @memberof ReadSectionResponse
     */
    items: Array<MeasurementSchema>;
}

/**
 * Check if a given object implements the ReadSectionResponse interface.
 */
export function instanceOfReadSectionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "header" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function ReadSectionResponseFromJSON(json: any): ReadSectionResponse {
    return ReadSectionResponseFromJSONTyped(json, false);
}

export function ReadSectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadSectionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'header': MeasurementHeaderFromJSON(json['header']),
        'items': ((json['items'] as Array<any>).map(MeasurementSchemaFromJSON)),
    };
}

export function ReadSectionResponseToJSON(value?: ReadSectionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'header': MeasurementHeaderToJSON(value.header),
        'items': ((value.items as Array<any>).map(MeasurementSchemaToJSON)),
    };
}

