/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AffinityConcentrationExtraArguments } from './AffinityConcentrationExtraArguments';
import {
    AffinityConcentrationExtraArgumentsFromJSON,
    AffinityConcentrationExtraArgumentsFromJSONTyped,
    AffinityConcentrationExtraArgumentsToJSON,
} from './AffinityConcentrationExtraArguments';
import type { MaffconDensityInfo } from './MaffconDensityInfo';
import {
    MaffconDensityInfoFromJSON,
    MaffconDensityInfoFromJSONTyped,
    MaffconDensityInfoToJSON,
} from './MaffconDensityInfo';
import type { MaffconExperimentInfo } from './MaffconExperimentInfo';
import {
    MaffconExperimentInfoFromJSON,
    MaffconExperimentInfoFromJSONTyped,
    MaffconExperimentInfoToJSON,
} from './MaffconExperimentInfo';
import type { MaffconMultiAssayExtraArguments } from './MaffconMultiAssayExtraArguments';
import {
    MaffconMultiAssayExtraArgumentsFromJSON,
    MaffconMultiAssayExtraArgumentsFromJSONTyped,
    MaffconMultiAssayExtraArgumentsToJSON,
} from './MaffconMultiAssayExtraArguments';
import type { MaffconMultiDensityExtraArguments } from './MaffconMultiDensityExtraArguments';
import {
    MaffconMultiDensityExtraArgumentsFromJSON,
    MaffconMultiDensityExtraArgumentsFromJSONTyped,
    MaffconMultiDensityExtraArgumentsToJSON,
} from './MaffconMultiDensityExtraArguments';

/**
 * 
 * @export
 * @interface Extra
 */
export interface Extra {
    /**
     * 
     * @type {string}
     * @memberof Extra
     */
    studyType: ExtraStudyTypeEnum;
    /**
     * 
     * @type {Array<MaffconDensityInfo>}
     * @memberof Extra
     */
    cellDensities?: Array<MaffconDensityInfo>;
    /**
     * 
     * @type {string}
     * @memberof Extra
     */
    experimentId: string;
    /**
     * 
     * @type {Array<MaffconExperimentInfo>}
     * @memberof Extra
     */
    experiments?: Array<MaffconExperimentInfo>;
    /**
     * 
     * @type {number}
     * @memberof Extra
     */
    cellDensity: number;
}


/**
 * @export
 */
export const ExtraStudyTypeEnum = {
    AffinityConcentration: 'AFFINITY-CONCENTRATION'
} as const;
export type ExtraStudyTypeEnum = typeof ExtraStudyTypeEnum[keyof typeof ExtraStudyTypeEnum];


/**
 * Check if a given object implements the Extra interface.
 */
export function instanceOfExtra(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "studyType" in value;
    isInstance = isInstance && "experimentId" in value;
    isInstance = isInstance && "cellDensity" in value;

    return isInstance;
}

export function ExtraFromJSON(json: any): Extra {
    return ExtraFromJSONTyped(json, false);
}

export function ExtraFromJSONTyped(json: any, ignoreDiscriminator: boolean): Extra {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studyType': json['study_type'],
        'cellDensities': !exists(json, 'cell_densities') ? undefined : ((json['cell_densities'] as Array<any>).map(MaffconDensityInfoFromJSON)),
        'experimentId': json['experiment_id'],
        'experiments': !exists(json, 'experiments') ? undefined : ((json['experiments'] as Array<any>).map(MaffconExperimentInfoFromJSON)),
        'cellDensity': json['cell_density'],
    };
}

export function ExtraToJSON(value?: Extra | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'study_type': value.studyType,
        'cell_densities': value.cellDensities === undefined ? undefined : ((value.cellDensities as Array<any>).map(MaffconDensityInfoToJSON)),
        'experiment_id': value.experimentId,
        'experiments': value.experiments === undefined ? undefined : ((value.experiments as Array<any>).map(MaffconExperimentInfoToJSON)),
        'cell_density': value.cellDensity,
    };
}

