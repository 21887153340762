import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {StudiesApi, UpdateStudyExperimentsGroupRequest} from "../../api";
import {apiConfig} from "../../ApiConfig";
import Plot from "react-plotly.js";
import Typography from "@mui/material/Typography";
import {ColorPicker} from "mui-color";
import {styled, useTheme} from "@mui/material/styles";
// @ts-ignore
import Plotly from "plotly.js/dist/plotly.js";
import MouseTooltip from "react-sticky-mouse-tooltip";
import {PlotHoverEvent} from "plotly.js";
import domtoimage from "dom-to-image";
// @ts-ignore
import mergeImages from "./merge-images";
import loadingAnimation from "../../Animations/79836-graph-insights.gif";
import {plotColors} from "../../helpers/PlotColors";

interface AffinityPlotProps {
    className?: string;
    studyId: string;
    analysisId: string;
    loadCallback?: () => void;
}

const studiesApi = new StudiesApi(apiConfig);

// prettier-ignore

export default styled((props: AffinityPlotProps): JSX.Element => {
    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const [imageData, setImageData] = useState<any>(undefined);
    const [apiData, setApiData] = useState<any>(undefined);
    const [groupData, setGroupData] = useState<any>(undefined);
    const [hoverData, setHoverData] = useState<Readonly<PlotHoverEvent> | undefined>(undefined);
    const [snapshot, setSnapshot] = useState<boolean>(false);
    const theme = useTheme();

    useEffect(() => {
        if (snapshot) {
            setTimeout(async () => {
                const div = document.querySelector(".js-plotly-plot");
                if (!div) {
                    return;
                }

                // @ts-ignore
                const imgBase64 = await Plotly.toImage(div, {
                    width: 800,
                    height: 640,
                    format: "png",
                });

                // @ts-ignore
                let dataUrl = await domtoimage.toPng(document.querySelector("#legend"));

                let canvas = document.createElement("canvas");
                canvas.width = 800 + 270;
                canvas.height = 640;
                let ctx = canvas.getContext("2d");
                // @ts-ignore
                ctx.fillStyle = theme.palette.background.paper;
                // @ts-ignore
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                let url = await mergeImages(
                    [
                        {
                            src: imgBase64,
                            x: 0,
                            y: 0,
                        },
                        {
                            src: dataUrl,
                            x: 800,
                            y: 100,
                        },
                    ],
                    {
                        Canvas: canvas,
                        width: 800 + 270,
                        height: 640,
                    }
                );

                canvas.toBlob((blob) => {
                    if (blob === null) {
                        return;
                    }

                    let downloadUrl = URL.createObjectURL(blob);

                    const a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = "image.png";
                    a.target = "_blank";
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(downloadUrl);
                });

                setSnapshot(false);
            }, 50);
        }
    }, [snapshot]);

    useEffect(() => {
        if (!props.studyId || !props.analysisId) {
            return; // when incorrect properties are passed, we do not fetch data
        }

        studiesApi.getStudyOutcomeWithId(props.studyId, "data", props.analysisId).then(async (json) => {
            setIsDataLoaded(true);

            var idx = 0;

            let newGroupData: any = {};

            for (let k of Object.keys(json.z)) {
                let resp = await studiesApi.getGroupById(props.studyId, json.z[k]["group"]);

                newGroupData[resp.id] = resp;
            }

            idx = 0;
            setImageData(
                Object.keys(json.z).reduce<Object[]>((acc, k) => {
                    return acc.concat([
                        {
                            key: k,
                            group: json.z[k]["group"],
                            name: json.z[k]["name"],
                            x: [json.z[k]["max"]["x"]],
                            y: [json.z[k]["max"]["y"]],
                            type: "scatter",
                            mode: "markers",
                            marker: {
                                color: newGroupData[json.z[k]["group"]]["color"] || plotColors[idx],
                                size: 5,
                            },
                            hoverinfo: "none",
                        },
                        {
                            key: k,
                            group: json.z[k]["group"],
                            x: json.x,
                            y: json.y,
                            type: "contour",
                            z: json.z[k]["data"],
                            line: {
                                smoothing: 0.85,
                            },
                            contours: {
                                coloring: "fill",
                                showlines: false,
                            },
                            showscale: false,
                            colorscale: [
                                [0, theme.palette.background.paper + "00"],
                                [1, newGroupData[json.z[k]["group"]]["color"] + "a0" || plotColors[idx++] + "a0"],
                            ],
                            hoverinfo: "none",
                        },
                    ]);
                }, [])
            );
            setApiData(json);
            setGroupData(newGroupData);
        });
    }, [props.studyId, props.analysisId]);

    const updateColors = () => {
        let newImageData = imageData.map((i: any) => {
            if (i.hasOwnProperty("type") && i.hasOwnProperty("key")) {
                if (i["type"] == "scatter") {
                    i["marker"]["color"] = groupData[i.group].color;
                    return i;
                } else if (i["type"] == "contour") {
                    i["colorscale"][1] = [1, groupData[i.group].color + "a0"];
                    return i;
                }
            } else {
                return i;
            }
        });

        setImageData(newImageData);
    };

    const btnCapture = {
        name: "Save Image",
        icon: Plotly.Icons.camera,
        direction: "up",
        title: "Save image",
        click: async (gd: any) => {
            setSnapshot(true);
        },
    };

    return (
        <div className={props.className} style={{position: "relative"}}>
            {snapshot && (
                <div
                    style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        right: "0px",
                        bottom: "0px",
                        zIndex: "5000",
                        backdropFilter: "blur(5px)",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: theme.palette.background.paper,
                            width: "100%",
                            height: "100%",
                            opacity: "0.6",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <Typography variant="h4">Preparing download</Typography>
                        <img
                            style={{
                                width: "150px",
                                height: "150px",
                                marginTop: "10px",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                            src={loadingAnimation}
                        />
                        <Typography variant="h4">Please wait</Typography>
                    </div>
                </div>
            )}
            <Plot
                divId="graph"
                data={imageData !== undefined ? imageData : []}
                layout={{
                    width: 800,
                    height: 640,
                    title: imageData && imageData.hasOwnProperty("title") ? imageData["title"] : "",
                    showlegend: false,
                    paper_bgcolor: theme.palette.background.paper,
                    plot_bgcolor: theme.palette.background.paper,
                    xaxis: {
                        showgrid: true,
                        scaleanchor: "y",
                        scaleratio: 1,
                        title: "-<i>log</i><sub>10</sub>(<i>K</i><sub>D</sub>/M)",
                        fixedrange: true,
                        gridcolor: theme.palette.text.secondary + "40",
                        zerolinecolor: theme.palette.text.primary,
                    },
                    yaxis: {
                        showgrid: true,
                        autorange: "reversed",
                        // scaleanchor: "x",
                        // scaleratio: 1,
                        title: "-<i>log</i><sub>10</sub>([Antibody binding sites] /M)",
                        fixedrange: true,
                        gridcolor: theme.palette.text.secondary + "40",
                        zerolinecolor: theme.palette.text.primary,
                    },
                    font: {
                        color: theme.palette.text.primary,
                    },
                }}
                config={{
                    displayModeBar: "hover",
                    modeBarButtonsToRemove: [
                        "pan2d",
                        "pan3d",
                        "lasso2d",
                        "zoom2d",
                        "zoom3d",
                        "zoomIn2d",
                        "zoomOut2d",
                        "resetScale2d",
                        "autoScale2d",
                        "select2d",
                        "toImage",
                    ],
                    modeBarButtonsToAdd: [btnCapture],
                    scrollZoom: false,
                }}
                onAfterPlot={() => {
                    if (props.loadCallback !== undefined && isDataLoaded) {
                        props.loadCallback();
                    }
                }}
                onHover={(e) => {
                    setHoverData(e);
                }}
                onUnhover={(e) => {
                    setHoverData(undefined);
                }}
            />
            <div
                style={{
                    position: "absolute",
                    zIndex: "500",
                    width: "270px",
                    height: "100%-100px",
                    right: "0px",
                    top: "100px",
                }}
            >
                <div id="legend" style={{backgroundColor: theme.palette.background.paper}}>
                    {groupData &&
                        (() => {
                            let keymap = Object.keys(groupData);
                            keymap.sort((a, b) => groupData[a].name.localeCompare(groupData[b].name));
                            return keymap.map((g: any) => {
                                return (
                                    <Grid container key={g}>
                                        <ColorPicker
                                            value={groupData[g].color}
                                            disableTextfield={true}
                                            disableAlpha={true}
                                            hideTextfield={true}
                                            onChange={(c) => {
                                                let newGroupData = {...groupData};
                                                newGroupData[g].color = "#" + (c as any).hex;
                                                setGroupData(newGroupData);
                                                updateColors();
                                            }}
                                            onOpen={(o) => {
                                                if (!o) {
                                                    updateColors();
                                                    let req: UpdateStudyExperimentsGroupRequest = {
                                                        name: groupData[g].name,
                                                        description: groupData[g].description,
                                                        color: groupData[g].color,
                                                    };
                                                    studiesApi.updateStudyExperimentsGroup(props.studyId, g, req);
                                                }
                                            }}
                                        />
                                        <Grid
                                            item
                                            flexGrow={1}
                                            style={{
                                                textAlign: "left",
                                                width: "4rem",
                                                paddingTop: "0.5rem",
                                                fontSize: "10pt",
                                            }}
                                        >
                                            <Typography>{groupData[g].name}</Typography>
                                        </Grid>
                                    </Grid>
                                );
                            });
                        })()}
                </div>
            </div>
            {hoverData !== undefined && (
                <MouseTooltip
                    style={{
                        zIndex: "5000",
                        pointerEvents: "none",
                        border: `1px solid ${theme.palette.secondary.dark}`,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.contrastText,
                        minWidth: "100px",
                        minHeight: "24px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        borderRadius: "4px",
                    }}
                >
                    -<i>log</i>
                    <sub>10</sub>(<i>K</i>
                    <sub>D</sub>/M)={Number(hoverData?.points[0].x).toFixed(4)} -<i>log</i>
                    <sub>10</sub>(conc)={Number(hoverData?.points[0].y).toFixed(4)}
                    {(() => {
                        let values = Object.keys(apiData.z).map((d: string) => {
                            let point = hoverData?.points[0];
                            // @ts-ignore
                            // prettier-ignore
                            if (point && point.hasOwnProperty("pointIndex") && point.pointIndex[0] !== undefined && point.pointIndex[1] !== undefined) {
                            return [
                                apiData.z[d]["group"],
                                apiData.z[d]["experiment_id"],
                                // @ts-ignore
                                apiData.z[d]["data"][point.pointIndex[0]][point.pointIndex[1]],
                                apiData.z[d].name
                            ];
                        }
                        else {
                            return [
                                apiData.z[d]["group"],
                                apiData.z[d]["experiment_id"],
                                // @ts-ignore
                                NaN,
                                apiData.z[d].name
                            ]
                        }
                        });

                        values.sort((a, b) => {
                            if ((!isFinite(a[2]) || a[2] == null) && !isFinite(b[2]) && b[2] == null) {
                                return 0;
                            }
                            if (!isFinite(a[2]) || a[2] == null) {
                                return 1;
                            }
                            if (!isFinite(b[2]) || b[2] == null) {
                                return -1;
                            }
                            return b[2] - a[2];
                        });

                        if (isFinite(values[0][2]) && values[0][2] != null) {
                            return (
                                <div>
                                    <div>
                                        z={Number(values[0][2]).toFixed(4)} {groupData[values[0][0]].name}
                                    </div>
                                    <div>{values[0][3]}</div>
                                </div>
                            );
                        }
                        return <></>;
                    })()}
                </MouseTooltip>
            )}
        </div>
    );
})((theme) => {
    return {};
});
