/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaffconDensityInfo
 */
export interface MaffconDensityInfo {
    /**
     * 
     * @type {number}
     * @memberof MaffconDensityInfo
     */
    cellDensity: number;
    /**
     * 
     * @type {string}
     * @memberof MaffconDensityInfo
     */
    color: string;
}

/**
 * Check if a given object implements the MaffconDensityInfo interface.
 */
export function instanceOfMaffconDensityInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cellDensity" in value;
    isInstance = isInstance && "color" in value;

    return isInstance;
}

export function MaffconDensityInfoFromJSON(json: any): MaffconDensityInfo {
    return MaffconDensityInfoFromJSONTyped(json, false);
}

export function MaffconDensityInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaffconDensityInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cellDensity': json['cell_density'],
        'color': json['color'],
    };
}

export function MaffconDensityInfoToJSON(value?: MaffconDensityInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cell_density': value.cellDensity,
        'color': value.color,
    };
}

