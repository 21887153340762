import FileUpload, {FileUploadProps} from "../../components/FileUpload";
import MeasurementList, {instrumentIcons, keyFromHeader} from "../../components/MeasurementList";
import {apiConfig} from "../../ApiConfig";
import {MeasurementsApi, ResponseError} from "../../api";
import {handleNetworkError, snackbarError, snackbarSuccess} from "../../helpers/error";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material";
import {useSnackbar} from "notistack";
import ListSelectionBar from "../../components/MeasurementFilter/ListSelectionBar";
import ListPagination from "../../components/MeasurementFilter/ListPagination";
import {FilterType, InputFilterType, ColumnFilterType, BoundColumnType} from "../../components/MeasurementFilter/types";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {useAppDispatch, useAppSelector} from "../../ReduxStore";
import {
    modifyMeasurementPage,
    modifyMeasurementPageRows,
    modifyStartDate,
    modifyEndDate,
} from "../../reducers/MeasurementsTable";
import {PageContainer, PageContentBlock, PageHeader} from "../../helpers/HelperComponents";
import React from "react";

const measurementsApi = new MeasurementsApi(apiConfig);

interface UploadPageProps {
    className?: string;
}

export default styled(function (props: UploadPageProps): JSX.Element {
    const [filesUploaded, setFilesUploaded] = React.useState<File[]>();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const state = useAppSelector((state) => state.measurementsTableReducer);
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const defaultFilters: FilterType = {
        startDate:
            state.startDate && state.startDate.length > 0
                ? new Date(Date.parse(state.startDate))
                : new Date(2000, 0, 1, 0, 0, 0, 0),
        endDate: state.endDate && state.endDate.length > 0 ? new Date(Date.parse(state.endDate)) : new Date(),
        columnFilters: [],
        pagination: {
            headersPerPage: 5,
            currentPage: 1,
        },
    };
    const [filtersApplied, setFiltersApplied] = React.useState<FilterType>(defaultFilters);

    const handleSelectFilter = ({
        filterName,
        newValue,
    }: {
        filterName: keyof FilterType;
        newValue: string | Date | null;
    }): void => {
        if (newValue instanceof Date && !isNaN(newValue.getTime())) {
            switch (filterName) {
                case "startDate":
                    dispatch(modifyStartDate(newValue.toISOString()));
                    break;
                case "endDate":
                    newValue.setHours(23);
                    newValue.setMinutes(59);
                    newValue.setSeconds(59);
                    newValue.setMilliseconds(999);
                    dispatch(modifyEndDate(newValue.toISOString()));
                    break;
            }
        }
        setFiltersApplied((oldFilters: FilterType) => ({...oldFilters, [filterName]: newValue}));
    };

    const handleSelectColumnFilter = ({
        boundColumn,
        newValue,
    }: {
        boundColumn: BoundColumnType;
        newValue: string;
    }): void => {
        const newColumnFilterPair: ColumnFilterType = {
            boundColumn: boundColumn,
            value: newValue,
        };
        // this pushes the new filter into the olderFilters.columnFilters list, deleting an old
        // filter for the same column (if it existed)
        setFiltersApplied((oldFilters: FilterType) => ({
            ...oldFilters,
            columnFilters: [
                ...oldFilters.columnFilters.filter((col) => col.boundColumn !== boundColumn),
                newColumnFilterPair,
            ],
        }));
    };

    const handleDeleteColumnFilter = ({boundColumn}: {boundColumn: BoundColumnType}): void => {
        setFiltersApplied((oldFilters: FilterType) => ({
            ...oldFilters,
            columnFilters: [...oldFilters.columnFilters.filter((col) => col.boundColumn !== boundColumn)],
        }));
    };

    const handleChangePaginationPage = (newPage: number): void => {
        dispatch(modifyMeasurementPage(newPage));
        setFiltersApplied((oldFilters: FilterType) => ({
            ...oldFilters,
            pagination: {...oldFilters.pagination, currentPage: newPage},
        }));
    };

    const handleChangeItemsPerPage = (newItemsNumber: number | undefined): void => {
        dispatch(modifyMeasurementPageRows(newItemsNumber ? newItemsNumber : 5));
        // Both applied and selected filters are updated because for pagination we want to see
        // changes immediately.

        if (newItemsNumber === undefined) {
            setFiltersApplied((oldFilters: FilterType) => {
                return {
                    ...oldFilters,
                    pagination: {...oldFilters.pagination, currentPage: 1, headersPerPage: newItemsNumber},
                };
            });

            return;
        }

        setFiltersApplied((oldFilters: FilterType) => {
            let newPageNumber = 1;

            if (oldFilters.pagination.headersPerPage !== undefined) {
                const firstVisibleItem = (oldFilters.pagination.currentPage - 1) * oldFilters.pagination.headersPerPage; // Zero based
                newPageNumber = Math.floor(firstVisibleItem / newItemsNumber) + 1;
            }

            return {
                ...oldFilters,
                pagination: {...oldFilters.pagination, currentPage: newPageNumber, headersPerPage: newItemsNumber},
            };
        });
    };

    React.useEffect(() => {
        if (!filesUploaded || filesUploaded.length === 0) return;

        const body = new FormData();

        for (let i = 0; i < filesUploaded.length; i++) {
            body.append(`files`, filesUploaded[i]);
        }

        measurementsApi
            .uploadMeasurements(filesUploaded)
            .then((resp) => {
                setFilesUploaded([]);
                enqueueSnackbar("All files successfully uploaded", snackbarSuccess);
            })
            .catch((response: ResponseError) => {
                if (response !== null && response.response !== null && response.response.status == 422) {
                    enqueueSnackbar("File upload failed", snackbarError);
                }
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    }, [filesUploaded, enqueueSnackbar]);

    const fileUploadProp: FileUploadProps = {
        backgroundColor: theme.palette.background.paper,
        accept: "application/json",
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files !== null && event.target?.files?.length > 0) {
                const uploadedFiles = [];
                for (let i = 0; i < event.target.files.length; i++) {
                    uploadedFiles.push(event.target.files[i]);
                }
                setFilesUploaded(uploadedFiles);
            }
        },
        onDrop: (event: React.DragEvent<HTMLElement>) => {
            if (event.dataTransfer.files !== null && event.dataTransfer?.files?.length > 0) {
                const uploadedFiles = [];
                for (let i = 0; i < event.dataTransfer.files.length; i++) {
                    uploadedFiles.push(event.dataTransfer.files[i]);
                }
                setFilesUploaded(uploadedFiles);
            }
        },
    };

    let listSx = {
        width: "100%",
        ".header-item": {
            "& .header-last span": {
                display: "none",
            },
            "& .header-field:nth-of-type(4)": {
                flexGrow: "400",
            },
        },
        ".item-wrapper": {
            "& .checkbox-wrap": {
                display: "none",
            },
        },
        ".list": {
            paddingTop: "0px",
        },
    };

    function renderListFilters(inputFiltersAvailable?: InputFilterType[]) {
        return (
            <ListSelectionBar
                filtersSelected={filtersApplied}
                handleSelectFilter={handleSelectFilter}
                handleSelectColumnFilter={handleSelectColumnFilter}
                handleDeleteColumnFilter={handleDeleteColumnFilter}
                inputFiltersAvailable={inputFiltersAvailable as InputFilterType[]}
            />
        );
    }

    function renderListPagination(numberOfPages: number, rowsCount: number) {
        return (
            <ListPagination
                currentPage={state.page}
                itemsPerPage={state.pageSize}
                rowsPerPageOptions={[5, 10, 20, 50]}
                rowsCount={rowsCount}
                handleChangePaginationPage={handleChangePaginationPage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
                numberOfPages={numberOfPages}
            />
        );
    }

    function renderListHeader() {
        return (
            <Box
                className="header-item"
                sx={{display: "flex", backgroundColor: theme.palette.background.paper, marginTop: "2px"}}
            >
                <div style={{width: "23%"}} className={"header-first"}>
                    Timestamp
                </div>
                <div className={"header-field"}>Sample</div>
                <div style={{textAlign: "left"}} className={"header-field"}>
                    Binding partner
                </div>
                <div style={{textAlign: "left"}} className={"header-field"}>
                    Instrument ID
                </div>
                <div style={{flexGrow: "500"}} className={"header-last"}></div>
            </Box>
        );
    }

    return (
        <div className={props.className}>
            <PageHeader text="Measurements" />
            <PageContainer>
                <PageContentBlock sx={{backgroundColor: theme.palette.background.default}}>
                    <MeasurementList
                        reducer={"measurements"}
                        listfiltersRenderFunction={renderListFilters}
                        listpaginationRenderFunction={renderListPagination}
                        listheaderRenderFunction={renderListHeader}
                        filtersApplied={filtersApplied}
                        sx={listSx}
                        filesUploaded={filesUploaded}
                    />
                </PageContentBlock>
            </PageContainer>
        </div>
    );
})((theme) => {
    return {};
});
