/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ErrorInformation
 */
export interface ErrorInformation {
    /**
     * 
     * @type {string}
     * @memberof ErrorInformation
     */
    errorTitle: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorInformation
     */
    errorContext: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorInformation
     */
    errorDescription: string;
    /**
     * 
     * @type {Date}
     * @memberof ErrorInformation
     */
    errorTime: Date;
    /**
     * 
     * @type {string}
     * @memberof ErrorInformation
     */
    errorRef?: string;
    /**
     * 
     * @type {object}
     * @memberof ErrorInformation
     */
    errorDetail?: object;
    /**
     * 
     * @type {object}
     * @memberof ErrorInformation
     */
    errorData?: object;
}

/**
 * Check if a given object implements the ErrorInformation interface.
 */
export function instanceOfErrorInformation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "errorTitle" in value;
    isInstance = isInstance && "errorContext" in value;
    isInstance = isInstance && "errorDescription" in value;
    isInstance = isInstance && "errorTime" in value;

    return isInstance;
}

export function ErrorInformationFromJSON(json: any): ErrorInformation {
    return ErrorInformationFromJSONTyped(json, false);
}

export function ErrorInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errorTitle': json['error_title'],
        'errorContext': json['error_context'],
        'errorDescription': json['error_description'],
        'errorTime': (new Date(json['error_time'])),
        'errorRef': !exists(json, 'error_ref') ? undefined : json['error_ref'],
        'errorDetail': !exists(json, 'error_detail') ? undefined : json['error_detail'],
        'errorData': !exists(json, 'error_data') ? undefined : json['error_data'],
    };
}

export function ErrorInformationToJSON(value?: ErrorInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error_title': value.errorTitle,
        'error_context': value.errorContext,
        'error_description': value.errorDescription,
        'error_time': (value.errorTime.toISOString()),
        'error_ref': value.errorRef,
        'error_detail': value.errorDetail,
        'error_data': value.errorData,
    };
}

