// import logo from "./fluidic_logo.svg";
import logo from "./Fluidity Intelligence Logo.png";

function LandingPage() {
    const sx: {[key: string]: React.CSSProperties} = {
        wrapper: {
            width: "100%",
            height: "85vh",
        },
        image: {
            width: "50vh",
            position: "relative",
            top: "30vh",
        },
    };

    return (
        <div style={sx.wrapper}>
            <img style={sx.image} src={logo} width="50vw" />
        </div>
    );
}

export default LandingPage;
