/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorInformation,
  ErrorListItem,
  HTTPValidationError,
} from '../models/index';
import {
    ErrorInformationFromJSON,
    ErrorInformationToJSON,
    ErrorListItemFromJSON,
    ErrorListItemToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models/index';

export interface DeleteErrorRequest {
    id: string;
}

export interface GetErrorByIdRequest {
    id: string;
}

export interface GetErrorsByContextRequest {
    context: string;
}

export interface GetErrorsByRefRequest {
    ref: string;
}

export interface StoreErrorRequest {
    errorInformation: ErrorInformation;
    apiKey?: string;
    apiSecret?: string;
    delegateUser?: string;
}

/**
 * 
 */
export class ErrorApi extends runtime.BaseAPI {

    /**
     * Delete Error
     */
    async deleteErrorRaw(requestParameters: DeleteErrorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteError.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/errors/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Error
     */
    async deleteError(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteErrorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get Error By Id
     */
    async getErrorByIdRaw(requestParameters: GetErrorByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ErrorInformation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getErrorById.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/errors/by_id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ErrorInformationFromJSON(jsonValue));
    }

    /**
     * Get Error By Id
     */
    async getErrorById(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ErrorInformation> {
        const response = await this.getErrorByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Get Errors By Context
     */
    async getErrorsByContextRaw(requestParameters: GetErrorsByContextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ErrorListItem>>> {
        if (requestParameters.context === null || requestParameters.context === undefined) {
            throw new runtime.RequiredError('context','Required parameter requestParameters.context was null or undefined when calling getErrorsByContext.');
        }

        const queryParameters: any = {};

        if (requestParameters.context !== undefined) {
            queryParameters['context'] = requestParameters.context;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/errors/by_context`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ErrorListItemFromJSON));
    }

    /**
     * Get Errors By Context
     */
    async getErrorsByContext(context: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ErrorListItem>> {
        const response = await this.getErrorsByContextRaw({ context: context }, initOverrides);
        return await response.value();
    }

    /**
     * Get Errors By Ref
     */
    async getErrorsByRefRaw(requestParameters: GetErrorsByRefRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ErrorListItem>>> {
        if (requestParameters.ref === null || requestParameters.ref === undefined) {
            throw new runtime.RequiredError('ref','Required parameter requestParameters.ref was null or undefined when calling getErrorsByRef.');
        }

        const queryParameters: any = {};

        if (requestParameters.ref !== undefined) {
            queryParameters['ref'] = requestParameters.ref;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/errors/by_ref`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ErrorListItemFromJSON));
    }

    /**
     * Get Errors By Ref
     */
    async getErrorsByRef(ref: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ErrorListItem>> {
        const response = await this.getErrorsByRefRaw({ ref: ref }, initOverrides);
        return await response.value();
    }

    /**
     * Listerrors
     */
    async listErrorsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ErrorListItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/errors/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ErrorListItemFromJSON));
    }

    /**
     * Listerrors
     */
    async listErrors(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ErrorListItem>> {
        const response = await this.listErrorsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Store Error
     */
    async storeErrorRaw(requestParameters: StoreErrorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.errorInformation === null || requestParameters.errorInformation === undefined) {
            throw new runtime.RequiredError('errorInformation','Required parameter requestParameters.errorInformation was null or undefined when calling storeError.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.apiKey !== undefined && requestParameters.apiKey !== null) {
            headerParameters['api-key'] = String(requestParameters.apiKey);
        }

        if (requestParameters.apiSecret !== undefined && requestParameters.apiSecret !== null) {
            headerParameters['api-secret'] = String(requestParameters.apiSecret);
        }

        if (requestParameters.delegateUser !== undefined && requestParameters.delegateUser !== null) {
            headerParameters['delegate-user'] = String(requestParameters.delegateUser);
        }

        const response = await this.request({
            path: `/errors/store`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ErrorInformationToJSON(requestParameters.errorInformation),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Store Error
     */
    async storeError(errorInformation: ErrorInformation, apiKey?: string, apiSecret?: string, delegateUser?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.storeErrorRaw({ errorInformation: errorInformation, apiKey: apiKey, apiSecret: apiSecret, delegateUser: delegateUser }, initOverrides);
        return await response.value();
    }

}
