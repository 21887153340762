/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateStudyExperimentsGroupRequest
 */
export interface UpdateStudyExperimentsGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateStudyExperimentsGroupRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudyExperimentsGroupRequest
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateStudyExperimentsGroupRequest
     */
    experiments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudyExperimentsGroupRequest
     */
    color?: string;
}

/**
 * Check if a given object implements the UpdateStudyExperimentsGroupRequest interface.
 */
export function instanceOfUpdateStudyExperimentsGroupRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function UpdateStudyExperimentsGroupRequestFromJSON(json: any): UpdateStudyExperimentsGroupRequest {
    return UpdateStudyExperimentsGroupRequestFromJSONTyped(json, false);
}

export function UpdateStudyExperimentsGroupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateStudyExperimentsGroupRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'experiments': !exists(json, 'experiments') ? undefined : json['experiments'],
        'color': !exists(json, 'color') ? undefined : json['color'],
    };
}

export function UpdateStudyExperimentsGroupRequestToJSON(value?: UpdateStudyExperimentsGroupRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'experiments': value.experiments,
        'color': value.color,
    };
}

