import React from "react";
import {
    ExperimentSummary,
    MeasurementHeader,
    MeasurementSchema,
    ResponseError,
    SingleExperimentResponse,
} from "../../api";
import MeasurementList, {instrumentIcons, keyFromHeader} from "../../components/MeasurementList";
import Checkbox from "@mui/material/Checkbox";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {useParams} from "react-router-dom";
import {apiConfig} from "../../ApiConfig";
import {ExperimentsApi} from "../../api";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {BoundColumnType, ColumnFilterType, FilterType, InputFilterType} from "../../components/MeasurementFilter/types";
import ListSelectionBar from "../../components/MeasurementFilter/ListSelectionBar";
import ListPagination from "../../components/MeasurementFilter/ListPagination";
import {StandardDateTimeDisplay} from "../../helpers/HelperComponents";
import {useTheme} from "@mui/material/styles";
import YinYang from "../../components/Svgs/YinYang";
import {colorMap} from "../../helpers";

const experimentsApi = new ExperimentsApi(apiConfig);
interface ExperimentDialogProps {
    className?: string;
    selectedMeasurements?: string[];
    initialShowSelectedOnly?: boolean;
    filterColor?: string;
}

export default function ExperimentDialog(props: ExperimentDialogProps) {
    const params = useParams();
    const [experimentData, setExperimentData] = React.useState<ExperimentSummary>({} as ExperimentSummary);
    const [experimentMeasurements, setExperimentMeasurements] = React.useState<MeasurementSchema[]>(
        [] as MeasurementSchema[]
    );
    const [showSelected, setShowSelected] = React.useState<boolean | undefined>(props.initialShowSelectedOnly);
    const navigate = useNavigate();
    const theme = useTheme();

    function renderHeader(
        header: MeasurementHeader,
        handleHeaderCheckbox: (e: any, id: string) => void,
        checkboxState: boolean | undefined,
        headerClick: (header: MeasurementHeader) => void,
        expanded: boolean,
        selectedCount: number | undefined
    ): JSX.Element {
        const colors = header.colorInfo?.toLowerCase().split(",") || ["red"];
        let color1: string, color2: string;
        if ((header.colorCount || 0) < 2) {
            color1 = colorMap[colors[0]];
            color2 = colorMap[colors[0]];
        } else {
            color1 = colorMap[colors[0]];
            color2 = colorMap[colors[1]];
        }

        return (
            <>
                <div className={"header-outer"}>
                    <Typography noWrap={true} component={"div"}>
                        <YinYang
                            sx={{position: "relative", top: "4px", marginRight: "8px"}}
                            width={18}
                            height={18}
                            color1={color1}
                            color2={color2}
                        />
                        <StandardDateTimeDisplay value={header.runTimestamp} />
                    </Typography>
                    <Typography noWrap={true} component={"div"}>
                        {header.labeledName}
                    </Typography>
                    <Typography noWrap={true} component={"div"}>
                        {(() => {
                            if (header.unlabeledName && header.complexName) {
                                return (
                                    <>
                                        {header.unlabeledName} / {header.complexName}
                                    </>
                                );
                            } else if (header.unlabeledName) {
                                return <>{header.unlabeledName}</>;
                            } else if (header.complexName) {
                                return <>{header.complexName}</>;
                            } else {
                                return <>Not set</>;
                            }
                        })()}
                    </Typography>
                    <div>
                        {selectedCount ? (
                            <>
                                ({selectedCount}/{header.count})
                            </>
                        ) : (
                            <>({header.count})</>
                        )}
                        <ListItemButton
                            className={"list-item-button"}
                            disableGutters={true}
                            onClick={(e) => {
                                headerClick(header);
                            }}
                        >
                            {expanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Checkbox
                            className={"checkbox"}
                            indeterminate={checkboxState === undefined}
                            checked={checkboxState || false}
                            onClick={(e) => {
                                handleHeaderCheckbox(e, keyFromHeader(header));
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }

    function renderItem(
        item: MeasurementSchema,
        header: MeasurementHeader,
        checkboxCallback: (e: any, itemId: string) => void,
        checked: boolean,
        setLastModifiedHeaders?: React.Dispatch<React.SetStateAction<MeasurementHeader[]>>
    ): JSX.Element {
        const color = colorMap[item.color?.toLowerCase() || "red"];

        return (
            <div
                className={"list-item-outer"}
                style={{
                    color: props.filterColor
                        ? (item.color?.toLowerCase() || "red") != props.filterColor?.toLowerCase()
                            ? theme.palette.text.disabled
                            : theme.palette.text.primary
                        : theme.palette.text.primary,
                }}
            >
                {item.color && (
                    <YinYang
                        sx={{position: "relative", top: "4px"}}
                        width={18}
                        height={18}
                        color1={color}
                        color2={color}
                    />
                )}
                <div
                    style={{
                        color:
                            item.labeledConcentration !== item.originalValues?.labeledConcentration
                                ? theme.palette.warning.dark
                                : "inherit",
                    }}
                >
                    <Typography sx={{textAlign: "right", paddingRight: "40px"}} component={"div"} noWrap={true}>
                        {item.labeledConcentration.toString().trim()}
                    </Typography>
                </div>
                <div
                    style={{
                        color:
                            item.unlabeledConcentration !== item.originalValues?.unlabeledConcentration
                                ? theme.palette.warning.dark
                                : "inherit",
                    }}
                >
                    <Typography sx={{textAlign: "right", paddingRight: "40px"}} component={"div"} noWrap={true}>
                        {item.unlabeledConcentration?.toString().trim()}
                    </Typography>
                </div>
                <div
                    style={{
                        color:
                            item.complexMixtureDilution !== item.originalValues?.complexMixtureDilution
                                ? theme.palette.warning.dark
                                : "inherit",
                    }}
                >
                    <Typography sx={{textAlign: "right", paddingRight: "40px"}} component={"div"} noWrap={true}>
                        {item.complexMixtureDilution !== undefined ? `${item.complexMixtureDilution} %` : ""}
                    </Typography>
                </div>
                <div>
                    <Typography sx={{textAlign: "right", paddingRight: "40px"}} component={"div"} noWrap={true}>
                        {item.size?.toFixed(2)}
                    </Typography>
                </div>
                <Typography component={"div"} noWrap={true}>
                    <Checkbox
                        className={"checkbox"}
                        id={"check" + item.id}
                        onChange={(e) => {
                            checkboxCallback(e, item.id);
                        }}
                        checked={checked || false}
                    ></Checkbox>
                </Typography>
            </div>
        );
    }

    function renderListHeader(): JSX.Element {
        return (
            <div className={"list-header-wrapper"}>
                <div className={"list-header-outer"}>
                    <div>Timestamp</div>
                    <div>Labeled</div>
                    <div>Binding partner</div>
                    <div></div>
                </div>
            </div>
        );
    }

    function renderSubHeader(header: MeasurementHeader): JSX.Element {
        return (
            <div className={"subheader-wrapper"}>
                <div className={"subheader-outer"}>
                    <div className={"subheader-top"}>
                        <div></div>
                        <div>Binding partner</div>
                    </div>
                    <div className={"subheader-bottom"}>
                        <div>Labeled conc.</div>
                        <div>Unlabeled conc.</div>
                        <div>Complex mixt. dil.</div>
                        <div>Size</div>
                    </div>
                </div>
            </div>
        );
    }

    const defaultFilters: FilterType = {
        startDate: new Date(2000, 1, 1, 0, 0, 0, 0),
        endDate: new Date(),
        columnFilters: [],
        pagination: {
            headersPerPage: 10,
            currentPage: 1,
        },
    };
    const [filtersApplied, setFiltersApplied] = React.useState<FilterType>(defaultFilters);

    const handleSelectFilter = ({
        filterName,
        newValue,
    }: {
        filterName: keyof FilterType;
        newValue: string | Date | null;
    }): void => {
        setFiltersApplied((oldFilters: FilterType) => ({...oldFilters, [filterName]: newValue}));
    };

    const handleSelectColumnFilter = ({
        boundColumn,
        newValue,
    }: {
        boundColumn: BoundColumnType;
        newValue: string;
    }): void => {
        const newColumnFilterPair: ColumnFilterType = {
            boundColumn: boundColumn,
            value: newValue,
        };
        // this pushes the new filter into the olderFilters.columnFilters list, deleting an old
        // filter for the same column (if it existed)
        setFiltersApplied((oldFilters: FilterType) => ({
            ...oldFilters,
            columnFilters: [
                ...oldFilters.columnFilters.filter((col) => col.boundColumn !== boundColumn),
                newColumnFilterPair,
            ],
        }));
    };

    const handleDeleteColumnFilter = ({boundColumn}: {boundColumn: BoundColumnType}): void => {
        setFiltersApplied((oldFilters: FilterType) => ({
            ...oldFilters,
            columnFilters: [...oldFilters.columnFilters.filter((col) => col.boundColumn !== boundColumn)],
        }));
    };

    const handleChangePaginationPage = (newPage: number): void => {
        setFiltersApplied((oldFilters: FilterType) => ({
            ...oldFilters,
            pagination: {...oldFilters.pagination, currentPage: newPage},
        }));
    };

    const handleChangeItemsPerPage = (newItemsNumber: number | undefined): void => {
        // Both applied and selected filters are updated because for pagination we want to see
        // changes immediately.

        if (newItemsNumber === undefined) {
            setFiltersApplied((oldFilters: FilterType) => {
                return {
                    ...oldFilters,
                    pagination: {...oldFilters.pagination, currentPage: 1, headersPerPage: newItemsNumber},
                };
            });

            return;
        }

        setFiltersApplied((oldFilters: FilterType) => {
            let newPageNumber = 1;

            if (oldFilters.pagination.headersPerPage !== undefined) {
                const firstVisibleItem = (oldFilters.pagination.currentPage - 1) * oldFilters.pagination.headersPerPage; // Zero based
                newPageNumber = Math.floor(firstVisibleItem / newItemsNumber) + 1;
            }

            return {
                ...oldFilters,
                pagination: {...oldFilters.pagination, currentPage: newPageNumber, headersPerPage: newItemsNumber},
            };
        });
    };

    function renderListFilters(inputFiltersAvailable?: InputFilterType[]) {
        return (
            <ListSelectionBar
                filtersSelected={filtersApplied}
                handleSelectFilter={handleSelectFilter}
                handleSelectColumnFilter={handleSelectColumnFilter}
                handleDeleteColumnFilter={handleDeleteColumnFilter}
                inputFiltersAvailable={inputFiltersAvailable as InputFilterType[]}
            />
        );
    }

    function handleShowSelected(showSelected: boolean) {
        setShowSelected(showSelected);
    }

    function renderListPagination(numberOfPages: number) {
        // if (numberOfPages === 0) {
        //     return <>Please wait...</>;
        // }
        return (
            <ListPagination
                currentPage={filtersApplied.pagination.currentPage}
                itemsPerPage={filtersApplied.pagination.headersPerPage}
                rowsPerPageOptions={[5, 10, 20, 50]}
                handleChangePaginationPage={handleChangePaginationPage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
                numberOfPages={numberOfPages}
                showSelected={showSelected}
                handleShowSelected={handleShowSelected}
            />
        );
    }

    return (
        <div>
            <MeasurementList
                showSelectedOnly={showSelected}
                sx={{
                    width: "820px",
                    position: "relative",
                    ".list-header-wrapper": {
                        "& .list-header-outer": {
                            display: "flex",
                            textAlign: "left",
                            backgroundColor: "background.paper",
                            paddingTop: "5px",
                            paddingBottom: "8px",
                            "&>div": {
                                display: "inline-block",
                                width: "20%",
                                fontSize: "9pt",
                            },
                            "&>div:first-of-type": {
                                width: "28%",
                            },
                            "&>div:last-of-type": {
                                display: "inline-block",
                                lineHeight: "26px",
                                width: "unset",
                                flexGrow: "400",
                            },
                        },
                    },
                    ".header-item": {
                        display: "block",
                        padding: "0px",
                        margin: "0px",
                    },
                    ".header-outer": {
                        display: "flex",
                        "&>div": {
                            width: "20%",
                        },
                        "&>div:first-of-type": {
                            width: "28%",
                        },
                        "&>div:last-of-type": {
                            lineHeight: "26px",
                            width: "unset",
                            flexGrow: "400",
                            textAlign: "right",
                        },
                        "& .list-item-button": {
                            display: "inline-block",
                            height: "26px",
                            width: "35px",
                            position: "relative",
                            top: "-7px",
                        },
                        "& .checkbox": {
                            width: "26px",
                            height: "26px",
                        },
                    },
                    ".item-wrapper": {
                        marginTop: "0px",
                        "& .item": {
                            marginBottom: "0px",
                            height: "unset",
                            "& .list-item-outer": {
                                display: "flex",
                                "&>div": {
                                    width: "20%",
                                    paddingLeft: "20px",
                                },
                                "&>div:first-of-type": {
                                    width: "22%",
                                },
                                "&>div:nth-of-type(3)": {
                                    width: "22%",
                                },
                                "&>div:last-of-type": {
                                    width: "unset",
                                    flexGrow: "400",
                                    textAlign: "right",
                                },
                                "& .checkbox": {
                                    padding: "0px",
                                    paddingRight: "1px",
                                    margin: "0px",
                                },
                            },
                        },
                    },
                    ".subheader-wrapper": {
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                        borderBottomColor: "#c0c0c0",
                        backgroundColor: "background.paper",
                        "& .subheader-outer": {
                            display: "block",
                            margin: 0,
                            padding: 0,
                            width: "100%",
                            textAlign: "left",
                            fontSize: "10pt",
                            "& .subheader-top": {
                                display: "flex",
                                "&>div:first-of-type": {
                                    width: "22%",
                                },
                                "&>div:nth-of-type(2)": {
                                    width: "42%",
                                    textAlign: "center",
                                    borderBottom: "1px solid #d0d0d0",
                                },
                            },
                            "& .subheader-bottom": {
                                display: "flex",
                                textAlign: "center",
                                "&>div": {
                                    width: "20%",
                                },
                                "&>div:first-of-type": {
                                    width: "22%",
                                },
                                "&>div:nth-of-type(3)": {
                                    width: "22%",
                                },
                            },
                        },
                    },
                }}
                itemRenderFunction={renderItem}
                headerRenderFunction={renderHeader}
                listheaderRenderFunction={renderListHeader}
                subheaderRenderFunction={renderSubHeader}
                reducer={"experimentMeasurements"}
                listfiltersRenderFunction={renderListFilters}
                listpaginationRenderFunction={renderListPagination}
                filtersApplied={filtersApplied}
                initialSelectedMeasurements={props.selectedMeasurements}
            />
        </div>
    );
}
