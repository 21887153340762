import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/EditTwoTone";
import {styled, useTheme} from "@mui/material";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";

import {
    MeasurementBatchUpdateRequest,
    MeasurementHeader,
    MeasurementsApi,
    MeasurementSchema,
    ResponseError,
} from "../../api";
import EditMeasurementTextField from "./EditMeasurementTextField";
import {apiConfig} from "../../ApiConfig";
import {handleNetworkError} from "../../helpers/error";

const measurementsApi = new MeasurementsApi(apiConfig);

type FieldSchemaParam = [keyof MeasurementSchema];

interface SimplifiedFieldtSchema {
    label: string;
    paramName: FieldSchemaParam;
    validationText: string;
    validate: (value: string | number) => boolean;
}

var editableValues: SimplifiedFieldtSchema[] = [
    {
        label: "Labeled species name",
        paramName: "labeledName" as unknown as FieldSchemaParam,
        validate: (val) => !!val,
        validationText: "Labeled species must not be empty",
    },
    {
        label: "Unlabeled species name",
        paramName: "unlabeledName" as unknown as FieldSchemaParam,
        validate: (val) => true,
        validationText: "",
    },
    {
        label: "Complex mixture name",
        paramName: "complexName" as unknown as FieldSchemaParam,
        validate: (val) => true,
        validationText: "",
    },
];

interface editSampleModalProps {
    header: MeasurementHeader;
    className?: string;
    setLastModifiedHeaders?: React.Dispatch<React.SetStateAction<MeasurementHeader[]>>;
}

function EditSapleModal({header, className, setLastModifiedHeaders}: editSampleModalProps) {
    const theme = useTheme();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
    const [allValuesAreValid, setAllValuesAreValid] = React.useState<boolean>(false);
    const [sampleData, setSampleData] = React.useState<MeasurementBatchUpdateRequest>(
        {} as MeasurementBatchUpdateRequest
    );
    const handleOpen = () => {
        /* Helper method to open the modal */
        setIsOpen(true);
    };

    const handleClose = () => {
        /* Helper method to close the modal */
        setIsOpen(false);
    };

    const consumeMeasurementsData = (measurementsData: Array<MeasurementSchema>) => {
        setSampleData({
            ids: measurementsData.map((m) => m.id),
            complexName: measurementsData[0].complexName,
            labeledName: measurementsData[0].labeledName,
            unlabeledName: measurementsData[0].unlabeledName,
        });
        setIsLoaded(true);
    };
    const fetchMeasurementsData = async (header: MeasurementHeader): Promise<Array<MeasurementSchema>> => {
        /* Fetch data for all provided measurements ids */
        const measurementsResponse = await measurementsApi.getMeasurementsDetails(
            header.runTimestamp,
            header.labeledName,
            header.instrumentType,
            header.instrumentId,
            header.unlabeledName,
            header.complexName
        );
        return measurementsResponse.items;
    };
    const checkIfAllValuesAreValid = () => {
        /* Run validation if all values are valid before allowing to send them to the server */
        const areAllValuesValid = editableValues.every((ev) => {
            const value = sampleData[ev.paramName as unknown as keyof typeof sampleData] as string | number;
            const isValid = ev.validate(value);
            return isValid;
        });

        setAllValuesAreValid(areAllValuesValid);
    };

    const handleInputChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        changeTarget: FieldSchemaParam
    ) => {
        /* Save input changes in sampleData object, that will be sent to the server */
        setSampleData((currentSampleData) => ({
            ...currentSampleData,
            [changeTarget as unknown as string]: event.target.value,
        }));
    };

    const handleEditSample = (): void => {
        const requestBody: MeasurementBatchUpdateRequest = {
            ...sampleData,
        };
        setIsOpen(false);
        measurementsApi
            .updateMultipleMeasurements(requestBody)
            .then(() => {
                enqueueSnackbar(`Measurements updated successfully.`, {variant: "success"});
                if (setLastModifiedHeaders) {
                    setLastModifiedHeaders([header]);
                }
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) navigate(target);
                });
            });
    };

    // Load and consume measurements data at the beginning
    React.useEffect(() => {
        fetchMeasurementsData(header)
            .then((measurementsData) => consumeMeasurementsData(measurementsData))
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) navigate(target);
                });
            });
    }, [isOpen, header]);

    // Run validation after editing payload data to API
    React.useEffect(checkIfAllValuesAreValid, [sampleData]);

    return (
        <>
            <ListItemButton onClick={handleOpen}>
                <EditIcon />
            </ListItemButton>

            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={className}>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        {header.labeledName}
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit {header.count} measurements
                    </Typography>
                    <Box sx={{minWidth: 120, paddingTop: "2rem", paddingBottom: "0.5rem"}}>
                        {editableValues.map((editableValue) => {
                            const valueKey = editableValue.paramName as unknown as keyof typeof sampleData;
                            const value = sampleData[valueKey] as string;
                            const isValid = editableValue.validate(value);

                            return (
                                <EditMeasurementTextField
                                    showReset={false}
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: undefined,
                                        },
                                    }}
                                    key={`${editableValue.label}_field`}
                                    label={editableValue.label}
                                    value={value}
                                    id={editableValue.label}
                                    paramName={editableValue.paramName}
                                    handleInputChange={handleInputChange}
                                    isValidated={isValid}
                                    validationText={isValid ? "" : editableValue.validationText}
                                />
                            );
                        })}
                    </Box>
                    <Typography id="modal-modal-description" sx={{mt: 2, textAlign: "center", margin: "auto"}}>
                        <Button
                            disabled={!isLoaded || !allValuesAreValid}
                            onClick={handleEditSample}
                            variant="contained"
                            tabIndex={-1}
                            size="medium"
                            sx={{marginTop: "1rem", marginBottom: "1rem"}}
                        >
                            Edit measurements data
                        </Button>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}

export default styled(EditSapleModal)(
    (theme) => `
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 24px;
    transform: translate(-50%, -50%);
    width: 650px;
    background-color: ${theme.theme.palette.background.paper};
    border: 2px solid #000;
    box-shadow: #606060 8px 8px;
    border-radius: 6px;
`
);
