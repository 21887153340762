/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MaapDataStats } from './MaapDataStats';
import {
    MaapDataStatsFromJSON,
    MaapDataStatsFromJSONTyped,
    MaapDataStatsToJSON,
} from './MaapDataStats';
import type { ResourceTypeListing } from './ResourceTypeListing';
import {
    ResourceTypeListingFromJSON,
    ResourceTypeListingFromJSONTyped,
    ResourceTypeListingToJSON,
} from './ResourceTypeListing';

/**
 * 
 * @export
 * @interface MaapOutput
 */
export interface MaapOutput {
    /**
     * 
     * @type {MaapDataStats}
     * @memberof MaapOutput
     */
    result?: MaapDataStats;
    /**
     * 
     * @type {ResourceTypeListing}
     * @memberof MaapOutput
     */
    resources: ResourceTypeListing;
}

/**
 * Check if a given object implements the MaapOutput interface.
 */
export function instanceOfMaapOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resources" in value;

    return isInstance;
}

export function MaapOutputFromJSON(json: any): MaapOutput {
    return MaapOutputFromJSONTyped(json, false);
}

export function MaapOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaapOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : MaapDataStatsFromJSON(json['result']),
        'resources': ResourceTypeListingFromJSON(json['resources']),
    };
}

export function MaapOutputToJSON(value?: MaapOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': MaapDataStatsToJSON(value.result),
        'resources': ResourceTypeListingToJSON(value.resources),
    };
}

