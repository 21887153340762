import React, {createContext, useContext, useState} from "react";

class Props {
    children: React.ReactNode;
}

// create the context object for delivering your state across your app.
const GlobalContext = createContext<[{[key: string]: any}, any]>([{}, null]);

// custom component to provide the state to your app
export function GlobalState(props: Props) {
    // declare the GlobalState
    const [globalState, setGlobalState] = useState<{[key: string]: any}>({});

    // create a function that'll make it easy to update one state property at a time
    const updateGlobalState = (key: string, newValue: any) => {
        setGlobalState((oldState) => {
            if (oldState[key] !== newValue) {
                const newState = {...oldState};
                newState[key] = newValue;
                return newState;
            } else {
                return oldState;
            }
        });
    };

    return <GlobalContext.Provider value={[globalState, updateGlobalState]}>{props.children}</GlobalContext.Provider>;
}

// custom hook for retrieving the provided state
export const useGlobalState = () => useContext(GlobalContext);
