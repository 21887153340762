import {Box, FormControl, Grid, InputLabel, MenuItem, Select, styled} from "@mui/material";
import {ExperimentType, ExperimentTypeFromJSON, StudySchema, StudyType} from "../../api";
import React, {useContext, useState} from "react";
import {ExperimentContext} from "../../App";

interface StudyTypeSelectorProps {
    className?: string;
    disabled?: boolean;
    studyData?: StudySchema;
    setStudyType: (type: ExperimentType, subtype: string) => void;
}

export default styled((props: StudyTypeSelectorProps) => {
    const experimentTypeMap = useContext(ExperimentContext);
    const [experimentType, setExperimentType] = useState<string>(
        props.studyData?.type || ExperimentType.SaffconBayesian
    );
    const [studyType, setStudyType] = useState<string>(
        props.studyData?.extra?.studyType === undefined || props.studyData.extra.studyType == StudyType.AffinityConcentration
            ? "affinity"
            : "occupancy"
    );

    return (
        <Grid container gap={1.2} flexWrap={"nowrap"}>
            <Grid item xs={6}>
                <Box sx={{minWidth: 120, paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
                    <FormControl fullWidth>
                        <InputLabel id="experiment-type-label">Experiment type filter</InputLabel>
                        <Select
                            required
                            labelId="experiment-type-label"
                            id="experiment-type"
                            value={experimentType}
                            label="Experiment type filter"
                            onChange={(e) => {
                                setExperimentType(e.target.value as ExperimentType);
                                let st = studyType;
                                if (
                                    (
                                        experimentTypeMap.subtypeMap[
                                            e.target.value as unknown as keyof typeof experimentTypeMap.subtypeMap
                                        ] as Array<any>
                                    ).filter((t) => t.identifier == studyType).length == 0
                                ) {
                                    st = (
                                        experimentTypeMap.subtypeMap[
                                            e.target.value as unknown as keyof typeof experimentTypeMap.subtypeMap
                                        ] as Array<any>
                                    )[0].identifier;
                                    setStudyType(st);
                                }
                                props.setStudyType(ExperimentTypeFromJSON(e.target.value), st);
                            }}
                            disabled={props.disabled}
                        >
                            {Object.entries(ExperimentType)
                                .filter(
                                    (key) => (experimentTypeMap.subtypeMap as {[key: string]: any})[key[1]].length > 0
                                )
                                .map((key) => (
                                    <MenuItem key={key[0]} value={key[1]}>
                                        {
                                            experimentTypeMap.nameMap[
                                                key[1] as unknown as keyof typeof experimentTypeMap.nameMap
                                            ]
                                        }
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{minWidth: 120, paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
                    <FormControl fullWidth>
                        <InputLabel id="study-type-label">Study type</InputLabel>
                        <Select
                            required
                            labelId="study-type-label"
                            id="study-type"
                            value={studyType}
                            label="Study Type"
                            onChange={(e) => {
                                setStudyType(e.target.value);
                                props.setStudyType(ExperimentTypeFromJSON(experimentType), e.target.value);
                            }}
                            disabled={
                                props.disabled ||
                                (
                                    experimentTypeMap.subtypeMap[
                                        experimentType as unknown as keyof typeof experimentTypeMap.subtypeMap
                                    ] as Array<any>
                                ).length < 2
                            }
                        >
                            {(
                                experimentTypeMap.subtypeMap[
                                    experimentType as unknown as keyof typeof experimentTypeMap.subtypeMap
                                ] as Array<any>
                            ).map((t) => (
                                <MenuItem key={t.identifier} value={t.identifier}>
                                    {t.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    );
})((theme) => {
    return {};
});
