import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import React, {Children, ReactNode, useContext, useEffect, useState} from "react";
import {Beforeunload} from "react-beforeunload";
import {apiConfig} from "../../ApiConfig";
import {
    CreateStudyRequest,
    ExperimentType,
    ExperimentTypeFromJSON,
    Extra,
    MaffconDensityInfo,
    MaffconExperimentInfo,
    MaffconMultiDensityExtraArguments,
    ResponseError, StandardExtraAguments, StandardExtraAgumentsExperimentTypeEnum,
    StudiesApi,
    StudySchema,
    StudyType,
    UpdateStudyRequest,
} from "../../api";
import {ExperimentContext} from "../../App";
import {useNavigate} from "react-router-dom";
import {handleNetworkError} from "../../helpers/error";
import {useTheme} from "@mui/material/styles";
import MultiDensityInput from "./MultiDensityInput";
import MultiAssayInput from "./MultiAssayInput";
import {randomColor} from "../../helpers/PlotColors";
import StudyTypeSelector from "../../components/StudyTypeSelector";

const studiesApi = new StudiesApi(apiConfig);

interface CreationModalProps {
    className?: String;
    children?: ReactNode;
    studyData?: StudySchema;
    studyUpdatedCallback?: () => void;
}

export default function CreationModal(props: CreationModalProps) {
    const [open, setOpen] = useState(false);
    const scrollType: DialogProps["scroll"] = "paper";
    const [studyName, setStudyName] = React.useState("");
    const [studyDescription, setStudyDescription] = React.useState<string | undefined>(undefined);
    const [experimentType, setExperimentType] = React.useState<ExperimentType>(ExperimentType.SaffconBayesian);
    const [studyType, setStudyType] = useState<string>("affinity");
    const [studyVariant, setStudyVariant] = React.useState<string>("multidensity");
    const [cellDensity, setCellDensity] = useState<number | undefined>(undefined);
    const [maffconExperimentInfo, setMaffconExperimentInfo] = useState<MaffconExperimentInfo[] | undefined>(undefined);
    const [maffconDensityInfo, setMaffconDensityInfo] = useState<MaffconDensityInfo[] | undefined>(undefined);
    const [selectedExperiment, setSelectedExperiment] = useState<string | undefined>(undefined);

    const navigate = useNavigate();
    const {studyData} = props;
    const theme = useTheme();

    const handleOpen = (e: any) => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleClose = () => {
        if (studyData) {
            //removing new values when cancel
            setStudyName(studyData.name);
            if (studyData.description) {
                setStudyDescription(studyData.description);
            }
        }
        setOpen(false);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setExperimentType(event.target.value as ExperimentType);
    };

    useEffect(() => {
        if (typeof studyData !== "undefined") {
            setStudyName(studyData.name);
            setStudyDescription(studyData.description);
            setExperimentType(ExperimentTypeFromJSON(studyData.type));

            if ("extra" in studyData && studyData.extra !== undefined) {
                switch (studyData.extra?.studyType) {
                    // @ts-ignore
                    case StudyType.MaffconMultiDensity:
                        setStudyType("occupancy");
                        setStudyVariant("multidensity");
                        setSelectedExperiment(studyData.extra.experimentId);
                        setMaffconDensityInfo(studyData.extra.cellDensities);
                        break;
                    // @ts-ignore
                    case StudyType.MaffconMultiAssay:
                        setStudyType("occupancy");
                        setStudyVariant("multiassay");
                        setMaffconExperimentInfo(studyData.extra.experiments);
                        setCellDensity(studyData.extra.cellDensity);
                        break;
                    // @ts-ignore
                    case StudyType.AffinityConcentration:
                        setStudyType("affinity");
                        break;
                    default:
                        setStudyType("affinity");
                        break;
                }
            }
            else {
                setStudyType("affinity")
            }
        }
    }, [studyData]);

    const createMaffconMultiDensityExtra = () => {
        return {
            // @ts-ignore
            studyType: StudyType.MaffconMultiDensity,
            cellDensities: maffconDensityInfo,
            experimentId: selectedExperiment,
        };
    };

    const createMaffconMultiAssayExtra = () => {
        return {
            studyType: StudyType.MaffconMultiAssay,
            cellDensity: cellDensity || 0,
            experiments: maffconExperimentInfo,
        };
    };

    const handleCreateStudy = (): void => {
        let createStudyRequest: CreateStudyRequest = {
            name: studyName,
            description: studyDescription,
            type: experimentType,
        };

        if (studyType == "occupancy") {
            if (experimentType == ExperimentType.MaffconBayesian && studyVariant == "multidensity") {
                // @ts-ignore
                createStudyRequest.extra = createMaffconMultiDensityExtra();
            }

            if (experimentType == ExperimentType.MaffconBayesian && studyVariant == "multiassay") {
                // @ts-ignore
                createStudyRequest.extra = createMaffconMultiAssayExtra();
            }
        }
        if (studyType == "affinity") {
            // @ts-ignore
            createStudyRequest.extra = {studyType: StudyType.AffinityConcentration} as StandardExtraAguments;
        }

        studiesApi
            .createStudy(createStudyRequest)
            .then((data) => {
                setOpen(false);
                navigate(`/study/${data.id}`);
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    };

    const handleSaveStudy = () => {
        if (studyData === undefined) {
            return;
        }
        let updateStudyRequest: UpdateStudyRequest = {
            id: studyData?.id,
            name: studyName,
            description: studyDescription,
        };

        if (experimentType == ExperimentType.MaffconBayesian && studyType == "occupancy" && studyVariant == "multidensity") {
            // @ts-ignore
            updateStudyRequest.extra = createMaffconMultiDensityExtra();
        }

        if (experimentType == ExperimentType.MaffconBayesian && studyType == "occupancy" && studyVariant == "multiassay") {
            // @ts-ignore
            updateStudyRequest.extra = createMaffconMultiAssayExtra();
        }

        studiesApi
            .updateStudy(studyData?.id, updateStudyRequest)
            .then((data: StudySchema) => {
                setOpen(false);
                props.studyUpdatedCallback && props.studyUpdatedCallback();
                navigate(`/study/${data.id}`);
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    };

    const validate = () => {
        if (studyName === "" || experimentType === undefined) {
            return false;
        }

        if (studyType == "occupancy") {
            if (experimentType === ExperimentType.MaffconBayesian) {
                if (studyVariant === "multidensity") {
                    if (maffconDensityInfo === undefined || maffconDensityInfo.length < 2) {
                        return false;
                    }
                    if (selectedExperiment === undefined || selectedExperiment === "") {
                        return false;
                    }
                }
                if (studyVariant === "multiassay") {
                    if (maffconExperimentInfo === undefined || maffconExperimentInfo.length < 2) {
                        return false;
                    }
                    if (cellDensity === undefined) {
                        return false;
                    }
                }
            }
        }

        return true;
    };

    return (
        <>
            {Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, {onClick: handleOpen});
            })}
            {open && <Beforeunload onBeforeunload={() => "Careful, you may lose your data if you close!"} />}
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scrollType}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth={false}
                PaperProps={{sx: {width: "750px", height: "80%"}}}
            >
                <DialogTitle id="scroll-dialog-title">
                    {studyData === undefined ? "New study" : "Edit study"}
                </DialogTitle>
                <DialogContent dividers={scrollType === "paper"} id="scroll-dialog-data" tabIndex={-1}>
                    <DialogContentText>
                        To proceed with creation, you need to define the study name and type.
                    </DialogContentText>
                    <Box>
                        <Box sx={{minWidth: 120, paddingTop: "2rem", paddingBottom: "0.5rem"}}>
                            <TextField
                                required
                                value={studyName}
                                fullWidth
                                onChange={(input: React.ChangeEvent<HTMLInputElement>) => {
                                    setStudyName(input.target.value);
                                }}
                                label="Study name"
                                variant="outlined"
                                size="small"
                            />
                        </Box>
                        <Box
                            sx={{
                                minWidth: 120,
                                paddingTop: "0.5rem",
                                paddingBottom: "0.5rem",
                            }}
                        >
                            <TextField
                                value={studyDescription}
                                fullWidth
                                onChange={(input: React.ChangeEvent<HTMLInputElement>) => {
                                    setStudyDescription(input.target.value);
                                }}
                                label="Study description"
                                variant="outlined"
                                size="small"
                            />
                        </Box>
                        <StudyTypeSelector
                            disabled={props.studyData !== undefined}
                            studyData={studyData}
                            setStudyType={(t, v) => {
                                setExperimentType(t);
                                setStudyType(v);
                            }}
                        />
                        {studyType == "occupancy" && (
                            <>
                                <Box
                                    sx={{
                                        minWidth: 120,
                                        paddingTop: "0.5rem",
                                        paddingBottom: "0.5rem",
                                    }}
                                >
                                    <FormControl
                                        fullWidth={true}
                                        sx={{
                                            borderWidth: "1px",
                                            borderColor:
                                                theme.palette.mode === "dark"
                                                    ? "rgba(255, 255, 255, 0.23)"
                                                    : "rgba(0, 0, 0, 0.23)",
                                            "&:hover": {
                                                borderColor:
                                                    theme.palette.mode === "dark"
                                                        ? "rgba(176, 176, 176, 1)"
                                                        : "rgba(0, 0, 0, 1)",
                                            },
                                            borderStyle: "solid",
                                            borderRadius: "4px",
                                            height: "40px",
                                            position: "relative",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "-11px",
                                                left: "8px",
                                                fontSize: "0.8rem",
                                                backgroundColor: theme.palette.background.paper,
                                                backgroundImage:
                                                    "linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))",
                                                paddingLeft: "5px",
                                                paddingRight: "5px",
                                                color: theme.palette.text.disabled,
                                            }}
                                        >
                                            Study variant
                                        </div>
                                        <RadioGroup
                                            defaultValue={"multidensity"}
                                            value={studyVariant}
                                            onChange={(e, val) => {
                                                setStudyVariant(val);
                                            }}
                                        >
                                            <Grid
                                                container
                                                sx={{
                                                    paddingLeft: "10px",
                                                    paddingRight: "10px",
                                                }}
                                            >
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        disabled={studyData !== undefined}
                                                        value="multidensity"
                                                        control={<Radio />}
                                                        label={"Multiple density"}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        disabled={studyData !== undefined}
                                                        value="multiassay"
                                                        control={<Radio />}
                                                        label={"Multiple assay"}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                {studyVariant === "multidensity" ? (
                                    <MultiDensityInput
                                        selectedExperiment={selectedExperiment}
                                        setSelectedExperiment={(selected) => {
                                            setSelectedExperiment(selected);
                                        }}
                                        maffconDensityInfo={maffconDensityInfo || []}
                                        setMaffconDensityInfo={(selected) => setMaffconDensityInfo(selected)}
                                    />
                                ) : (
                                    <MultiAssayInput
                                        maffconExperimentInfo={maffconExperimentInfo || []}
                                        setMaffconExperimentInfo={(selected) => setMaffconExperimentInfo(selected)}
                                        cellDensity={cellDensity}
                                        setCellDensity={setCellDensity}
                                    />
                                )}
                            </>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                    {studyData === undefined ? (
                        <Button disabled={!validate()} size="medium" onClick={handleCreateStudy} variant="contained">
                            Create now
                        </Button>
                    ) : (
                        <Button disabled={!validate()} size="medium" onClick={handleSaveStudy} variant="contained">
                            Save
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}
