/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExperimentType } from './ExperimentType';
import {
    ExperimentTypeFromJSON,
    ExperimentTypeFromJSONTyped,
    ExperimentTypeToJSON,
} from './ExperimentType';
import type { ExtraArguments } from './ExtraArguments';
import {
    ExtraArgumentsFromJSON,
    ExtraArgumentsFromJSONTyped,
    ExtraArgumentsToJSON,
} from './ExtraArguments';

/**
 * Experiment summary - listing measurement id only
 * @export
 * @interface ExperimentSummary
 */
export interface ExperimentSummary {
    /**
     * 
     * @type {string}
     * @memberof ExperimentSummary
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentSummary
     */
    description?: string;
    /**
     * 
     * @type {ExperimentType}
     * @memberof ExperimentSummary
     */
    type: ExperimentType;
    /**
     * 
     * @type {ExtraArguments}
     * @memberof ExperimentSummary
     */
    extraArguments?: ExtraArguments;
    /**
     * 
     * @type {boolean}
     * @memberof ExperimentSummary
     */
    qc: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExperimentSummary
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentSummary
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ExperimentSummary
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExperimentSummary
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExperimentSummary
     */
    validAnalysis?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExperimentSummary
     */
    measurementIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExperimentSummary
     */
    analysisStatus?: string;
}

/**
 * Check if a given object implements the ExperimentSummary interface.
 */
export function instanceOfExperimentSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "qc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;

    return isInstance;
}

export function ExperimentSummaryFromJSON(json: any): ExperimentSummary {
    return ExperimentSummaryFromJSONTyped(json, false);
}

export function ExperimentSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperimentSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': ExperimentTypeFromJSON(json['type']),
        'extraArguments': !exists(json, 'extra_arguments') ? undefined : ExtraArgumentsFromJSON(json['extra_arguments']),
        'qc': json['qc'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'validAnalysis': !exists(json, 'valid_analysis') ? undefined : json['valid_analysis'],
        'measurementIds': !exists(json, 'measurement_ids') ? undefined : json['measurement_ids'],
        'analysisStatus': !exists(json, 'analysis_status') ? undefined : json['analysis_status'],
    };
}

export function ExperimentSummaryToJSON(value?: ExperimentSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'type': ExperimentTypeToJSON(value.type),
        'extra_arguments': ExtraArgumentsToJSON(value.extraArguments),
        'qc': value.qc,
        'color': value.color,
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'valid_analysis': value.validAnalysis,
        'measurement_ids': value.measurementIds,
        'analysis_status': value.analysisStatus,
    };
}

