/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NllsResult } from './NllsResult';
import {
    NllsResultFromJSON,
    NllsResultFromJSONTyped,
    NllsResultToJSON,
} from './NllsResult';
import type { ResourceTypeListing } from './ResourceTypeListing';
import {
    ResourceTypeListingFromJSON,
    ResourceTypeListingFromJSONTyped,
    ResourceTypeListingToJSON,
} from './ResourceTypeListing';

/**
 * 
 * @export
 * @interface NllsOutput
 */
export interface NllsOutput {
    /**
     * 
     * @type {NllsResult}
     * @memberof NllsOutput
     */
    result?: NllsResult;
    /**
     * 
     * @type {ResourceTypeListing}
     * @memberof NllsOutput
     */
    resources: ResourceTypeListing;
}

/**
 * Check if a given object implements the NllsOutput interface.
 */
export function instanceOfNllsOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resources" in value;

    return isInstance;
}

export function NllsOutputFromJSON(json: any): NllsOutput {
    return NllsOutputFromJSONTyped(json, false);
}

export function NllsOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): NllsOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : NllsResultFromJSON(json['result']),
        'resources': ResourceTypeListingFromJSON(json['resources']),
    };
}

export function NllsOutputToJSON(value?: NllsOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': NllsResultToJSON(value.result),
        'resources': ResourceTypeListingToJSON(value.resources),
    };
}

