import {Box, Grid, Stack, styled, Typography} from "@mui/material";
import {
    ExperimentsApi,
    ExperimentSchema,
    MaffconDensityInfo,
    ResponseError,
    StudiesApi,
    StudySummarySchema,
    StudyType,
    UpdateStudyRequest,
} from "../../api";
import ExperimentsTable from "../../pages/Study/Group/ExperimentsTable";
import {apiConfig} from "../../ApiConfig";
import {useEffect, useState} from "react";
import {ColorPicker} from "mui-color";
import {handleNetworkError} from "../../helpers/error";
import {useNavigate} from "react-router-dom";
const math = require("mathjs");

const experimentsApi = new ExperimentsApi(apiConfig);
const studiesApi = new StudiesApi(apiConfig);
interface StudyDetailsMultiDensityProps {
    className?: string;
    studyData: StudySummarySchema;
}

export default styled((props: StudyDetailsMultiDensityProps) => {
    const [experiment, setExperiment] = useState<ExperimentSchema | undefined>(undefined);
    const [studyData, setStudyData] = useState<StudySummarySchema>(props.studyData);
    const navigate = useNavigate();

    useEffect(() => {
        setStudyData(props.studyData);
    }, [props.studyData]);

    useEffect(() => {
        experimentsApi.getExperimentById(props.studyData.extra?.experimentId as string)
            .then((resp) => {
                setExperiment(resp);
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });

    }, [props]);

    return (
        <>
            <Typography variant={"h4"}>Experiment</Typography>
            {experiment && <ExperimentsTable hideFooter={true} experiments={[experiment]} />}
            <Typography sx={{marginTop: "30px"}} variant={"h4"}>
                Cell Densities
            </Typography>
            <Stack sx={{marginTop: "10px", paddingBottom: "15px"}} spacing={"1rem"}>
                {[...(studyData.extra?.cellDensities || [])]
                    .sort((a, b) => a.cellDensity - b.cellDensity)
                    .map((d: MaffconDensityInfo) => {
                        return (
                            <Grid key={d.cellDensity} container justifyContent={"center"} gap={"20px"} flexWrap={"nowrap"}>
                                <Grid container item xs={5.5} justifyContent={"right"} flexWrap={"nowrap"}>
                                    <Grid item sx={{position: "relative", top: "-6px"}}>
                                        <ColorPicker
                                            value={d.color}
                                            disableTextfield={true}
                                            disableAlpha={true}
                                            hideTextfield={true}
                                            onChange={(c) => {
                                                d.color = "#" + (c as any).hex;
                                                setStudyData({...studyData});
                                            }}
                                            onOpen={(o) => {
                                                if (!o) {
                                                    studiesApi.updateStudy(studyData.id, studyData);
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item sx={{textAlign: "left"}} xs={6.5}>
                                    {d.cellDensity} (
                                    {math
                                        .format(d.cellDensity, {notation: "engineering", lowerExp: -3, upperExp: 3})
                                        .replace("e+0", "")
                                        .replace("e+", "e")}
                                    )
                                    cells/L
                                </Grid>
                            </Grid>
                        );
                    })}
            </Stack>
        </>
    );
})((theme) => {
    return {};
});
