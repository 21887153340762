/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Response model for get_version API
 * @export
 * @interface VersionInfoResponse
 */
export interface VersionInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof VersionInfoResponse
     */
    applicationVersion: string;
    /**
     * 
     * @type {string}
     * @memberof VersionInfoResponse
     */
    apiVersion: string;
    /**
     * 
     * @type {number}
     * @memberof VersionInfoResponse
     */
    buildNumber: number;
    /**
     * 
     * @type {string}
     * @memberof VersionInfoResponse
     */
    gitHash: string;
}

/**
 * Check if a given object implements the VersionInfoResponse interface.
 */
export function instanceOfVersionInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "applicationVersion" in value;
    isInstance = isInstance && "apiVersion" in value;
    isInstance = isInstance && "buildNumber" in value;
    isInstance = isInstance && "gitHash" in value;

    return isInstance;
}

export function VersionInfoResponseFromJSON(json: any): VersionInfoResponse {
    return VersionInfoResponseFromJSONTyped(json, false);
}

export function VersionInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationVersion': json['application_version'],
        'apiVersion': json['api_version'],
        'buildNumber': json['build_number'],
        'gitHash': json['git_hash'],
    };
}

export function VersionInfoResponseToJSON(value?: VersionInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'application_version': value.applicationVersion,
        'api_version': value.apiVersion,
        'build_number': value.buildNumber,
        'git_hash': value.gitHash,
    };
}

