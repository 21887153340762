import {SnackbarKey, useSnackbar} from "notistack";
import IconButton from "@mui/material/IconButton";
import {Close as IconClose} from "@mui/icons-material";
import React from "react";
import {styled, useTheme} from "@mui/material";

interface SnackbarCloseButtonProps {
    className?: string;
    snackbarKey: SnackbarKey;
    variant?: string;
}

export default styled(function (props: SnackbarCloseButtonProps) {
    const {closeSnackbar} = useSnackbar();
    const theme = useTheme();
    const palette = (theme.palette as any)[props.variant || "primary"];
    const color = palette.contrastText;

    return (
        <IconButton onClick={() => closeSnackbar(props.snackbarKey)}>
            <IconClose className={props.className} sx={{color: color}} />
        </IconButton>
    );
})``;
