import * as React from "react";
import Box from "@mui/material/Box";
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";

import {MaapTableOutputRow} from "../../types";

import {addTextStyles, extractAnalysisOutputResultsMAAP, formatValueToScientific} from "../../helpers";
import reactStringReplace from "react-string-replace";

import {MaapDataStats} from "../../api";
import {Collapse} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";
import {useTheme} from "@mui/material/styles";

const sciNotationFields = ["kd", "a0", "nReceptors"];

const columns: GridColDef[] = [
    {
        field: "parameter",
        headerName: "Parameter name",
        type: "string",
        width: 235,
        sortable: false,
        renderCell: (param: any) => <span key={param.value}>{addTextStyles(param.value)}</span>,
    },
    {
        field: "unit",
        headerName: "Unit",
        type: "string",
        sortable: false,
        width: 150,
    },
    {
        field: "mode",
        headerName: "Estimate (mode)",
        sortable: false,
        width: 130,
        renderCell: (data: GridRenderCellParams) => {
            if (sciNotationFields.includes(data.row.id)) {
                return <>{formatValueToScientific(data.row.mode, data.row.power)}</>;
            }
        },
    },
    {
        field: "mean",
        headerName: "Mean",
        description: "",
        sortable: false,
        width: 130,
        renderCell: (data: GridRenderCellParams) => {
            if (sciNotationFields.includes(data.row.id)) {
                return <>{formatValueToScientific(data.row.mean, data.row.power)}</>;
            }
        },
    },
    {
        field: "median",
        headerName: "Median",
        description: "",
        sortable: false,
        width: 130,
        renderCell: (data: GridRenderCellParams) => {
            if (sciNotationFields.includes(data.row.id)) {
                return <>{formatValueToScientific(data.row.median, data.row.power)}</>;
            }
        },
    },
    {
        field: "sd",
        headerName: "SD",
        description: "",
        sortable: false,
        width: 130,
        renderCell: (data: GridRenderCellParams) => {
            if (sciNotationFields.includes(data.row.id)) {
                return <>{formatValueToScientific(data.row.sd, data.row.power)}</>;
            }
        },
    },
    {
        field: "hdi25",
        headerName: "HDI 2.5%",
        description: "",
        sortable: false,
        width: 130,
        renderCell: (data: GridRenderCellParams) => {
            if (sciNotationFields.includes(data.row.id)) {
                return <>{formatValueToScientific(data.row.hdi25, data.row.power)}</>;
            }
        },
    },
    {
        field: "hdi975",
        headerName: "HDI 97.5%",
        description: "",
        sortable: false,
        width: 130,
        renderCell: (data: GridRenderCellParams) => {
            if (sciNotationFields.includes(data.row.id)) {
                return <>{formatValueToScientific(data.row.hdi975, data.row.power)}</>;
            }
        },
    },
];

export default function AnalysisDataTableBayesian({results}: {results: MaapDataStats}) {
    const [expanded, setExpanded] = useState<boolean>(false);
    const theme = useTheme();

    const rowsTop: MaapTableOutputRow[] = (results.mean ? extractAnalysisOutputResultsMAAP(results, true) : []).filter(
        (r) => r.mean !== undefined
    );
    const rowsBottom: MaapTableOutputRow[] = (
        results.mean ? extractAnalysisOutputResultsMAAP(results, false) : []
    ).filter((r) => r.mean !== undefined);

    return (
        <Box
            sx={{
                height: "auto",
                width: "100%",
            }}
        >
            <DataGrid
                autoHeight={true}
                rows={rowsTop}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                disableSelectionOnClick
                disableColumnFilter={true}
                disableColumnSelector={true}
                disableColumnMenu={true}
                hideFooter={true}
                getRowId={(row) => row.id}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    border: "none",
                    color: theme.palette.text.primary,
                    "& >.MuiDataGrid-main": {
                        "&>.MuiDataGrid-columnHeaders": {
                            borderColor: theme.palette.background.default,
                        },
                        "& div div div div >.MuiDataGrid-cell": {
                            borderColor: theme.palette.background.default,
                        },
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderColor: theme.palette.background.default,
                    },
                }}
            />
            <Box
                sx={{
                    lineHeight: "32px",
                    cursor: "pointer",
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                }}
                onClick={() => {
                    setExpanded(!expanded);
                }}
            >
                {expanded ? (
                    <>
                        Less <ExpandLessIcon sx={{position: "relative", top: "8px", height: "28px"}} />
                    </>
                ) : (
                    <>
                        More <ExpandMoreIcon sx={{position: "relative", top: "8px", height: "28px"}} />
                    </>
                )}
            </Box>
            <Collapse in={expanded}>
                <DataGrid
                    autoHeight={true}
                    rows={rowsBottom}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    disableSelectionOnClick
                    disableColumnFilter={true}
                    disableColumnSelector={true}
                    disableColumnMenu={true}
                    hideFooter={true}
                    headerHeight={0}
                    getRowId={(row) => row.id}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        border: "none",
                        color: theme.palette.text.primary,
                        "& >.MuiDataGrid-main": {
                            "&>.MuiDataGrid-columnHeaders": {
                                borderColor: theme.palette.background.default,
                            },
                            "& div div div div >.MuiDataGrid-cell": {
                                borderColor: theme.palette.background.default,
                            },
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderColor: theme.palette.background.default,
                        },
                    }}
                />
            </Collapse>
        </Box>
    );
}
