import React from "react";
import {IconButton, styled} from "@mui/material";
import {
    PageButtonBar,
    PageContainer,
    PageContentBlock,
    PageHeader,
    StandardDateTimeDisplay,
} from "../../helpers/HelperComponents";
import moment from "moment";
import FileDownload from "../../components/FileDownload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export default styled((props: any): JSX.Element => {
    return (
        <div className={props.className}>
            <PageHeader text={"Playground"} />
            <PageContainer>
                <PageContentBlock>
                    <PageButtonBar />
                    <FileDownload blobSource="http://localhost:8000/experiments/" filename="experiments.json">
                        <a>Download</a>
                    </FileDownload>
                </PageContentBlock>
            </PageContainer>
        </div>
    );
})``;
