import {Stack, Tooltip, Typography, styled, useTheme, Grid} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import {ExperimentContext} from "../../../App";
import {useContext, useEffect, useState} from "react";
import {ResponseError, StudiesApi, StudyExperimentsGroupSchema, StudySchema} from "../../../api";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {PageButtonBar, PageContainer, PageContentBlock, PageHeader} from "../../../helpers/HelperComponents";
import {apiConfig} from "../../../ApiConfig";
import {handleNetworkError, snackbarSuccess} from "../../../helpers/error";
import {enqueueSnackbar} from "notistack";
import GroupCreationModal from "./../Group/GroupCreationModal";
import DeletionModal from "./DeletionModal";
import ExperimentsTable from "./ExperimentsTable";
import {ColorPicker} from "mui-color";

const studiesApi = new StudiesApi(apiConfig);

export default styled(function (props: any) {
    const [groupData, setGroupData] = useState<StudyExperimentsGroupSchema>({} as StudyExperimentsGroupSchema);
    const experimentTypeMap = useContext(ExperimentContext);
    const {studyId, id} = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const [groupUpdated, setGroupUpdated] = useState<boolean>(false);
    const [groupNames, setGroupNames] = useState<string[]>([]);
    const [studyData, setStudyData] = useState<StudySchema | undefined>(undefined)
    // useEffect(() => {
    //     getGroupData(id as string);
    // }, []);

    useEffect(() => {
        getGroupData(id as string);
    }, [id, groupUpdated]);

    useEffect(() => {
        if (studyId && Object.keys(groupData).length === 0) {
            studiesApi
                .getById(studyId)
                .then((data: StudySchema) => {
                    setStudyData(data);
                    if (data.groups) {
                        setGroupNames(
                            data.groups.filter((item) => item.name !== groupData.name).map((item) => item.name)
                        );
                    }
                })
                .catch((response: ResponseError) => {
                    handleNetworkError(response).then((target) => {
                        if (target) {
                            navigate(target);
                        }
                    });
                });
        }
    }, [groupData]);

    function updatePage() {
        setGroupUpdated(!groupUpdated);
    }

    function getGroupData(groupId: string): void {
        studiesApi
            .getGroupById(studyId ? studyId : "", groupId)
            .then((data: StudyExperimentsGroupSchema) => {
                setGroupData(data);
            })
            .catch((response: ResponseError) => {
                if (response.response.status === 404) {
                    navigate("/404", {replace: true});
                } else if (response.response.status === 422) {
                    navigate("/422/" + response.response.statusText, {replace: true});
                } else {
                    handleNetworkError(response).then((target) => {
                        if (target) {
                            navigate(target);
                        }
                    });
                }
            });
    }

    const deleteGroup = (group_id: string) => {
        studiesApi
            .deleteStudyExperimentsGroup(studyId ? studyId : "", group_id)
            .then((_response) => {
                enqueueSnackbar(`Successfully deleted.`, snackbarSuccess);
                navigate(`/study/${studyId}`, {replace: true});
            })
            .catch((response: ResponseError) => {
                if (response.response.status === 204) {
                    enqueueSnackbar(`Successfully deleted.`, snackbarSuccess);
                    navigate(`/study/${studyId}`, {replace: true});
                }
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    };

    return (
        <div className={props.className}>
            <PageHeader>
                <Stack spacing={0.5}>
                    <Typography variant="h4">
                        <Grid container justifyContent={"center"}>
                            <Grid item>{groupData.name}</Grid>
                            <Grid
                                item
                                sx={{
                                    height: "24px",
                                    width: "24px",
                                    backgroundColor: groupData.color,
                                    position: "relative",
                                    top: "0.6rem",
                                    marginLeft: "10px",
                                    borderRadius: "4px",
                                    boxShadow: `2px 2px ${theme.palette.secondary.main}`,
                                }}
                            ></Grid>
                        </Grid>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Group
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {groupData.description}
                    </Typography>
                </Stack>
            </PageHeader>

            <PageContainer>
                <PageContentBlock>
                    <PageButtonBar
                        backLink={
                            <Link to={`/study/${studyId}`}>
                                <Tooltip title={"Back to studies"}>
                                    <IconButton
                                        sx={{
                                            marginLeft: "10px",
                                            backgroundColor: "primary.main",
                                            color: "primary.contrastText",
                                            "&:hover": {backgroundColor: "primary.dark"},
                                        }}
                                    >
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        }
                    >
                        {studyData && (
                            <GroupCreationModal
                                study={studyData}
                                studyId={studyId as string}
                                groupNames={groupNames}
                                groupData={groupData}
                                groupUpdatedCallback={updatePage}
                            >
                                <Tooltip title="Edit group">
                                    <IconButton
                                        sx={{
                                            marginLeft: "10px",
                                            backgroundColor: "primary.main",
                                            color: "primary.contrastText",
                                            "&:hover": {backgroundColor: "primary.dark"},
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </GroupCreationModal>
                            )
                        }

                        <DeletionModal groupId={id as string} deleteGroup={deleteGroup}>
                            <Tooltip title={"Delete group"}>
                                <IconButton
                                    style={{backgroundColor: theme.palette.error.main}}
                                    sx={{
                                        marginLeft: "10px",
                                        color: "primary.contrastText",
                                        "&:hover": {backgroundColor: "primary.dark"},
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </DeletionModal>
                    </PageButtonBar>
                    <Typography
                        variant="h5"
                        style={{
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            backgroundColor: theme.palette.background.paper,
                            width: "100%",
                            textAlign: "center",
                        }}
                    >
                        Experiments
                    </Typography>

                    <div style={{background: theme.palette.background.default}}>
                        <ExperimentsTable experiments={groupData.experiments} />
                    </div>
                </PageContentBlock>
            </PageContainer>
        </div>
    );
})((theme) => {
    return {};
});
