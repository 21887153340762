/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExperimentType } from './ExperimentType';
import {
    ExperimentTypeFromJSON,
    ExperimentTypeFromJSONTyped,
    ExperimentTypeToJSON,
} from './ExperimentType';
import type { ExtraArguments } from './ExtraArguments';
import {
    ExtraArgumentsFromJSON,
    ExtraArgumentsFromJSONTyped,
    ExtraArgumentsToJSON,
} from './ExtraArguments';
import type { MeasurementSchema } from './MeasurementSchema';
import {
    MeasurementSchemaFromJSON,
    MeasurementSchemaFromJSONTyped,
    MeasurementSchemaToJSON,
} from './MeasurementSchema';

/**
 * Experiment data structure as represented by the API.
 * @export
 * @interface SingleExperimentResponse
 */
export interface SingleExperimentResponse {
    /**
     * 
     * @type {string}
     * @memberof SingleExperimentResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SingleExperimentResponse
     */
    description?: string;
    /**
     * 
     * @type {ExperimentType}
     * @memberof SingleExperimentResponse
     */
    type: ExperimentType;
    /**
     * 
     * @type {ExtraArguments}
     * @memberof SingleExperimentResponse
     */
    extraArguments?: ExtraArguments;
    /**
     * 
     * @type {boolean}
     * @memberof SingleExperimentResponse
     */
    qc: boolean;
    /**
     * 
     * @type {string}
     * @memberof SingleExperimentResponse
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleExperimentResponse
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof SingleExperimentResponse
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof SingleExperimentResponse
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof SingleExperimentResponse
     */
    validAnalysis?: string;
    /**
     * 
     * @type {Array<MeasurementSchema>}
     * @memberof SingleExperimentResponse
     */
    measurements?: Array<MeasurementSchema>;
    /**
     * 
     * @type {string}
     * @memberof SingleExperimentResponse
     */
    analysisStatus?: string;
}

/**
 * Check if a given object implements the SingleExperimentResponse interface.
 */
export function instanceOfSingleExperimentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "qc" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;

    return isInstance;
}

export function SingleExperimentResponseFromJSON(json: any): SingleExperimentResponse {
    return SingleExperimentResponseFromJSONTyped(json, false);
}

export function SingleExperimentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleExperimentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': ExperimentTypeFromJSON(json['type']),
        'extraArguments': !exists(json, 'extra_arguments') ? undefined : ExtraArgumentsFromJSON(json['extra_arguments']),
        'qc': json['qc'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'id': json['id'],
        'created': (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'validAnalysis': !exists(json, 'valid_analysis') ? undefined : json['valid_analysis'],
        'measurements': !exists(json, 'measurements') ? undefined : ((json['measurements'] as Array<any>).map(MeasurementSchemaFromJSON)),
        'analysisStatus': !exists(json, 'analysis_status') ? undefined : json['analysis_status'],
    };
}

export function SingleExperimentResponseToJSON(value?: SingleExperimentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'type': ExperimentTypeToJSON(value.type),
        'extra_arguments': ExtraArgumentsToJSON(value.extraArguments),
        'qc': value.qc,
        'color': value.color,
        'id': value.id,
        'created': (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'valid_analysis': value.validAnalysis,
        'measurements': value.measurements === undefined ? undefined : ((value.measurements as Array<any>).map(MeasurementSchemaToJSON)),
        'analysis_status': value.analysisStatus,
    };
}

