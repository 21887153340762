import {enqueueSnackbar} from "notistack";
import {
    snackbarError,
    snackbarInfo,
    snackbarSuccess,
    snackbarWarning,
    snackbarWithClose,
    snackbarWithLink,
} from "./error";
import PubSub from "pubsub-js";
import {reduxStore} from "../ReduxStore";
import {history} from "../App";
import {ExperimentsApi, ResponseError, StudiesApi} from "../api";
import {apiConfig} from "../ApiConfig";
import {Simulate} from "react-dom/test-utils";

const experimentApi = new ExperimentsApi(apiConfig);
const studiesApi = new StudiesApi(apiConfig);

/* This module dispatches events that need to be modified. It is here in order
 * to keep knowledge of event details out of the event provider.
 */

export function dispatchEvent(event: any, msg: any) {
    let state = reduxStore.getState();

    if (state === undefined || state.loginState === undefined || state.loginState.user === undefined) {
        PubSub.publish(event.context, msg);
        return;
    }

    let userId = state.loginState.user.userId;

    if (event.type == "error") {
        if (event.user_id == userId) {
            enqueueSnackbar(
                `One or more error reports have been generated`,
                snackbarWithLink("View", `/errors?errorRef=${event.error_ref}`, snackbarWithClose(true, snackbarInfo))
            );
        }
    }

    if (event.context == "report") {
        if (event.user_id == userId) {
            if (event.action == "ready") {
                if (event.link) {
                    let link = apiConfig.basePath + event.link;
                    enqueueSnackbar(
                        `Requested report generated successfully`,
                        snackbarWithLink("Download", link, snackbarWithClose(true, snackbarSuccess))
                    );
                }
            }
            if (event.action == "failed") {
                enqueueSnackbar(`There was an error generating the report`, snackbarError);
            }
        }
    }
    if (event.context == "analysis" && event.action == "statuschange") {
        if (event.user_id == userId) {
            // Send snack to user
            let link = `/analysis/${event.id}`;
            experimentApi
                .getExperimentById(event.experiment_id)
                .then((experiment) => {
                    if (event.status == "completed") {
                        enqueueSnackbar(
                            `Analysis for ${experiment.name} completed successfully`,
                            snackbarWithLink("Go there", link, snackbarWithClose(true, snackbarSuccess))
                        );
                    } else if (event.status == "failed") {
                        enqueueSnackbar(`Analysis for experiment ${experiment.name} failed.`, snackbarError);
                    } else {
                        enqueueSnackbar(`Analysis for experiment ${experiment.name} ${event.status}`, snackbarInfo);
                    }
                })
                .catch((resp: ResponseError) => {
                    if (event.status == "completed") {
                        enqueueSnackbar(
                            "Analysis completed successfully but there was an error retrieving the experiment",
                            snackbarWithClose(true, snackbarSuccess)
                        );
                    } else if (event.status == "failed") {
                        enqueueSnackbar(
                            "Analysis failed and there was a problem retrieving the experiment",
                            snackbarError
                        );
                    } else {
                        enqueueSnackbar(`Analysis ${event.status}`, snackbarInfo);
                    }
                });
        }
    }

    if (event.context == "study-analysis" && event.action == "statuschange") {
        if (event.user_id == userId) {
            // Send snack to user
            let link = `/study/${event.study_id}/result`;
            studiesApi
                .getById(event.study_id)
                .then((study) => {
                    if (event.status == "completed") {
                        enqueueSnackbar(
                            `Analysis for ${study.name} completed successfully`,
                            snackbarWithLink("Go there", link, snackbarWithClose(true, snackbarSuccess))
                        );
                    } else if (event.status == "failed") {
                        enqueueSnackbar(`Analysis for study ${study.name} failed.`, snackbarError);
                    } else {
                        enqueueSnackbar(`Analysis for study ${study.name} ${event.status}`, snackbarInfo);
                    }
                })
                .catch((resp: ResponseError) => {
                    if (event.status == "completed") {
                        enqueueSnackbar(
                            "Study analysis completed successfully but there was an error retrieving the study",
                            snackbarWithClose(true, snackbarSuccess)
                        );
                    } else if (event.status == "failed") {
                        enqueueSnackbar(
                            "Study analysis failed and there was a problem retrieving the study",
                            snackbarError
                        );
                    } else {
                        enqueueSnackbar(`Study analysis ${event.status}`, snackbarInfo);
                    }
                });
        }
    }
    PubSub.publish(event.context, msg);
}
