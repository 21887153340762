/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MaffconExtraArguments } from './MaffconExtraArguments';
import {
    MaffconExtraArgumentsFromJSON,
    MaffconExtraArgumentsFromJSONTyped,
    MaffconExtraArgumentsToJSON,
} from './MaffconExtraArguments';
import type { StandardExtraAguments } from './StandardExtraAguments';
import {
    StandardExtraAgumentsFromJSON,
    StandardExtraAgumentsFromJSONTyped,
    StandardExtraAgumentsToJSON,
} from './StandardExtraAguments';

/**
 * 
 * @export
 * @interface ExtraArguments
 */
export interface ExtraArguments {
    /**
     * 
     * @type {string}
     * @memberof ExtraArguments
     */
    experimentType: ExtraArgumentsExperimentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ExtraArguments
     */
    numberOfCells: number;
    /**
     * 
     * @type {number}
     * @memberof ExtraArguments
     */
    sampleVolume: number;
}


/**
 * @export
 */
export const ExtraArgumentsExperimentTypeEnum = {
    BinaryBindingBayesian: 'BINARY-BINDING-BAYESIAN',
    BinaryBindingNlls: 'BINARY-BINDING-NLLS',
    SaffconBayesian: 'SAFFCON-BAYESIAN',
    SaffconNlls: 'SAFFCON-NLLS',
    NestBayesian: 'NEST-BAYESIAN',
    NestNlls: 'NEST-NLLS'
} as const;
export type ExtraArgumentsExperimentTypeEnum = typeof ExtraArgumentsExperimentTypeEnum[keyof typeof ExtraArgumentsExperimentTypeEnum];


/**
 * Check if a given object implements the ExtraArguments interface.
 */
export function instanceOfExtraArguments(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "experimentType" in value;
    isInstance = isInstance && "numberOfCells" in value;
    isInstance = isInstance && "sampleVolume" in value;

    return isInstance;
}

export function ExtraArgumentsFromJSON(json: any): ExtraArguments {
    return ExtraArgumentsFromJSONTyped(json, false);
}

export function ExtraArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtraArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'experimentType': json['experiment_type'],
        'numberOfCells': json['number_of_cells'],
        'sampleVolume': json['sample_volume'],
    };
}

export function ExtraArgumentsToJSON(value?: ExtraArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'experiment_type': value.experimentType,
        'number_of_cells': value.numberOfCells,
        'sample_volume': value.sampleVolume,
    };
}

