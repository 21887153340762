/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExperimentSummary } from './ExperimentSummary';
import {
    ExperimentSummaryFromJSON,
    ExperimentSummaryFromJSONTyped,
    ExperimentSummaryToJSON,
} from './ExperimentSummary';

/**
 * 
 * @export
 * @interface ListExperimentResponse
 */
export interface ListExperimentResponse {
    /**
     * 
     * @type {number}
     * @memberof ListExperimentResponse
     */
    size: number;
    /**
     * 
     * @type {Array<ExperimentSummary>}
     * @memberof ListExperimentResponse
     */
    experiments: Array<ExperimentSummary>;
}

/**
 * Check if a given object implements the ListExperimentResponse interface.
 */
export function instanceOfListExperimentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "experiments" in value;

    return isInstance;
}

export function ListExperimentResponseFromJSON(json: any): ListExperimentResponse {
    return ListExperimentResponseFromJSONTyped(json, false);
}

export function ListExperimentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListExperimentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'size': json['size'],
        'experiments': ((json['experiments'] as Array<any>).map(ExperimentSummaryFromJSON)),
    };
}

export function ListExperimentResponseToJSON(value?: ListExperimentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'size': value.size,
        'experiments': ((value.experiments as Array<any>).map(ExperimentSummaryToJSON)),
    };
}

