import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function DeletionModal({
    studyId,
    deleteStudy,
    children,
}: {
    studyId: string;
    deleteStudy: (studyId: string) => void;
    children?: React.ReactNode;
}): JSX.Element {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleDelete = () => {
        handleClose();
        deleteStudy(studyId);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {React.Children?.map(children, (child) => {
                return React.cloneElement(child as React.ReactElement, {onClick: handleClickOpen});
            })}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete this study?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wish to delete this study? All associated data will be lost.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={"contained"}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} autoFocus variant={"contained"}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
