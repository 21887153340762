import * as React from "react";
import {useContext, useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog, {DialogProps} from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

import Grid from "@mui/material/Grid";

import {useNavigate} from "react-router-dom";
import {apiConfig} from "../../ApiConfig";

import {
    CreateExperimentRequest,
    ExperimentsApi,
    ExperimentSummary,
    ExperimentType,
    ExtraArguments,
    ExtraArgumentsExperimentTypeEnum,
    MaffconExtraArgumentsExperimentTypeEnum,
    MeasurementSchema,
    ResponseError,
    SingleExperimentResponse,
} from "../../api";
import {handleNetworkError} from "../../helpers/error";
import MeasurementSelector from "./MeasurementSelector";
import {Beforeunload} from "react-beforeunload";

import {useAppDispatch, useAppSelector} from "../../ReduxStore";
import {ListState, setListData} from "../../reducers/MeasurementList";
import {ExperimentContext} from "../../App";
import {ListSubheader, Switch, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {colorMap} from "../../helpers";

const experimentsApi = new ExperimentsApi(apiConfig);
const cloneDeep = require("lodash.clonedeep");

interface CreationModalProps {
    className?: String;
    children?: React.ReactNode;
    experimentData?: ExperimentSummary;
    experimentMeasurements?: MeasurementSchema[];
    experimentUpdatedCallback?: () => void;
}

export default function CreationModal(props: CreationModalProps) {
    const [open, setOpen] = React.useState(false);
    const scrollType: DialogProps["scroll"] = "paper";
    const [experimentName, setExperimentName] = React.useState("");
    const [experimentDescription, setExperimentDescription] = React.useState("");
    const [experimentType, setExperimentType] = React.useState<ExperimentType | undefined>(undefined);
    const [numberOfCells, setNumberOfCells] = useState<number>(0);
    const [sampleVolume, setSampleVolume] = useState<number>(0);
    const [sampleVolumeInput, setSampleVolumeInput] = React.useState<string | undefined>(undefined);
    const [numberOfCellsInput, setNumberOfCellsInput] = React.useState<string | undefined>(undefined);
    const [detectOutliers, setDetectOutliers] = React.useState<boolean>(true);
    const [color, setColor] = React.useState<string>("red");
    const persistedListSelections: ListState = useAppSelector((state) => state.experimentMeasurements);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const experimentTypeMap = useContext(ExperimentContext);
    const theme = useTheme();

    const {experimentData, experimentMeasurements} = props;

    var listSelections = cloneDeep(persistedListSelections);

    const resetSelections = (): void => {
        dispatch(setListData("experimentMeasurements", {items: {}, sections: {}} as ListState));
    };

    const handleOpen = (e: any) => {
        if (experimentData && experimentMeasurements === undefined) {
        }

        resetSelections();
        setOpen(true);
        e.stopPropagation();
    };

    const handleClose = () => {
        setExperimentArguments();
        setOpen(false);
    };

    const selectedMeasurementIds = Object.keys(listSelections.items).filter((x) => listSelections.items[x].selected);

    const handleChange = (event: SelectChangeEvent) => {
        setExperimentType(event.target.value as ExperimentType);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    React.useEffect(() => {
        setExperimentArguments();
    }, [experimentData, experimentMeasurements]);

    React.useEffect(() => {
        if (numberOfCells && numberOfCellsInput === undefined) {
            if (numberOfCells > 1e5) {
                let numberOfCellsFormatted = numberOfCells.toExponential();
                let numberOfCellsInputFormatted = numberOfCellsFormatted.replace(/\+/, "");
                setNumberOfCellsInput(numberOfCellsInputFormatted);
            } else {
                setNumberOfCellsInput(numberOfCells.toString());
            }
        }
    }, [numberOfCells]);

    React.useEffect(() => {
        if (sampleVolume && sampleVolumeInput === undefined) {
            setSampleVolumeInput(sampleVolume.toString());
        }
    }, [sampleVolume]);

    function setExperimentArguments(): void {
        if (typeof experimentData !== "undefined") {
            if ("name" in experimentData) {
                const newName = experimentData.name;
                setExperimentName(newName);
            }

            if (
                "description" in experimentData &&
                typeof experimentData.description === "string" &&
                experimentData.description !== ""
            ) {
                const newDescription = experimentData.description;
                setExperimentDescription(newDescription);
            }

            if ("type" in experimentData) {
                setExperimentType(experimentData.type);
            }

            if ("extraArguments" in experimentData) {
                if (typeof experimentData.extraArguments !== "undefined") {
                    if ("numberOfCells" in experimentData.extraArguments) {
                        setNumberOfCells(experimentData.extraArguments.numberOfCells);
                    }
                    if ("sampleVolume" in experimentData.extraArguments) {
                        setSampleVolume(experimentData.extraArguments.sampleVolume);
                    }
                }
            }

            if ("qc" in experimentData) {
                setDetectOutliers(experimentData.qc);
            }

            if ("color" in experimentData) {
                setColor(experimentData.color || "red");
            }
        }
    }

    const handleCreateExperiment = (): void => {
        const experimentTypeNotUndefined = experimentType || ExperimentType.SaffconBayesian;

        let createExperimentRequest: CreateExperimentRequest = {
            description: experimentDescription,
            measurementIds: selectedMeasurementIds,
            name: experimentName,
            type: experimentTypeNotUndefined,
            qc: detectOutliers,
            color: color,
        };

        const isValueInEnum = (object: Object, value: string) => {
            return Object.values(object).includes(value);
        };

        let extraArgumentsSaved = {
            experimentType: experimentType,
        };

        if (isValueInEnum(MaffconExtraArgumentsExperimentTypeEnum, experimentTypeNotUndefined)) {
            extraArgumentsSaved = {
                ...extraArgumentsSaved,
                numberOfCells: numberOfCells,
                sampleVolume: sampleVolume,
            } as ExtraArguments;
        }

        createExperimentRequest.extraArguments = extraArgumentsSaved as ExtraArguments;

        experimentsApi
            .createExperiment(createExperimentRequest)
            .then((data) => {
                setOpen(false);
                navigate(`/experiment/${data.id}`);
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    };

    const handleSaveExperiment = () => {
        const experimentTypeNotUndefined = experimentType || ExperimentType.SaffconBayesian;
        if (experimentData === undefined) {
            return;
        }
        let createExperimentRequest: CreateExperimentRequest = {
            description: experimentDescription,
            measurementIds: selectedMeasurementIds,
            name: experimentName,
            type: experimentTypeNotUndefined,
            qc: detectOutliers,
            color: color,
        };

        const isValueInEnum = (object: Object, value: string) => {
            return Object.values(object).includes(value);
        };

        let extraArgumentsSaved = {
            ...experimentData.extraArguments,
            experimentType: experimentType,
        };

        if (isValueInEnum(MaffconExtraArgumentsExperimentTypeEnum, experimentTypeNotUndefined)) {
            extraArgumentsSaved = {
                ...extraArgumentsSaved,
                numberOfCells: numberOfCells,
                sampleVolume: sampleVolume,
            } as ExtraArguments;
        }

        createExperimentRequest.extraArguments = extraArgumentsSaved as ExtraArguments;

        experimentsApi
            .updateExperiment(experimentData?.id, createExperimentRequest)
            .then((data: SingleExperimentResponse) => {
                setOpen(false);
                props.experimentUpdatedCallback && props.experimentUpdatedCallback();
                navigate(`/experiment/${data.id}`);
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    };

    const handleNumberOfCellsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        const valueAsNumber = Number(value);

        setNumberOfCellsInput(value);
        if (!isNaN(valueAsNumber)) {
            setNumberOfCells(Math.round(valueAsNumber));
        }
    };

    const handleSampleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        let valueAsNumber: number = Number(value);

        setSampleVolumeInput(value);
        if (!isNaN(valueAsNumber)) {
            if ((valueAsNumber * 100) % 1 !== 0) {
                const valueFixed = valueAsNumber.toFixed(2);
                const valueAsNumberFixed = Number(valueFixed);
                setSampleVolumeInput(valueFixed);
                setSampleVolume(valueAsNumberFixed);
            } else {
                setSampleVolume(valueAsNumber);
            }
        }
    };

    const isMaffconCorrect = () => {
        if (!["MAFFCON-BAYESIAN", "MAFFCON-NLLS"].includes(experimentType || "")) {
            return true;
        }
        return (
            isNumberOfCellsInputCorrect() &&
            isSampleVolumeInputCorrect() &&
            numberOfCellsInput !== undefined &&
            sampleVolumeInput !== undefined
        );
    };

    const isNumberOfCellsInputCorrect = () =>
        !(
            isNaN(Number(numberOfCellsInput)) ||
            Number(numberOfCellsInput) < 1 ||
            Number(numberOfCellsInput) >= 9.9e18 ||
            Number(numberOfCellsInput) % 1 !== 0
        ) && numberOfCellsInput !== undefined;

    const isSampleVolumeInputCorrect = () =>
        !(isNaN(Number(sampleVolumeInput)) || Number(sampleVolumeInput) >= 100000 || Number(sampleVolumeInput) <= 0) &&
        sampleVolumeInput !== undefined;
    return (
        <>
            {React.Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, {onClick: handleOpen});
            })}
            {open && <Beforeunload onBeforeunload={() => "Careful, you may lose your data if you close!"} />}
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scrollType}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth={false}
                PaperProps={{sx: {width: "900px", height: "100%"}}}
            >
                <DialogTitle id="scroll-dialog-title">
                    {experimentData === undefined ? "New experiment" : "Edit experiment"}
                </DialogTitle>
                <DialogContent
                    dividers={scrollType === "paper"}
                    id="scroll-dialog-data"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <DialogContentText>
                        To proceed with creation, you need to define the experiment name and type and select the
                        measurements below.
                    </DialogContentText>
                    <Box>
                        <Box sx={{minWidth: 120, paddingTop: "2rem", paddingBottom: "0.5rem"}}>
                            <TextField
                                required
                                value={experimentName}
                                fullWidth
                                onChange={(input: React.ChangeEvent<HTMLInputElement>) => {
                                    setExperimentName(input.target.value);
                                }}
                                label="Experiment name"
                                variant="outlined"
                                size="small"
                            />
                        </Box>
                        <Box
                            sx={{
                                minWidth: 120,
                                paddingTop: "0.5rem",
                                paddingBottom: "0.5rem",
                            }}
                        >
                            <TextField
                                value={experimentDescription}
                                fullWidth
                                onChange={(input: React.ChangeEvent<HTMLInputElement>) => {
                                    setExperimentDescription(input.target.value);
                                }}
                                label="Experiment description"
                                variant="outlined"
                                size="small"
                            />
                        </Box>
                        <Box sx={{minWidth: 120, paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="experiment-type">Experiment type</InputLabel>
                                <Select
                                    required
                                    labelId="simple-select-label"
                                    id="simple-select"
                                    value={experimentType || ""}
                                    label="Experiment Type"
                                    onChange={handleChange}
                                    renderValue={(v) => {
                                        return experimentTypeMap.nameMap[
                                            v as unknown as keyof typeof experimentTypeMap.nameMap
                                        ];
                                    }}
                                >
                                    {experimentTypeMap.assayTypes.map((a) => {
                                        const ret: JSX.Element[] = [
                                            <ListSubheader
                                                sx={{
                                                    marginTop: "0px",
                                                    marginBottom: "0px",
                                                    paddingTop: "4px",
                                                    paddingBottom: "4px",
                                                    lineHeight: "1em",
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.action.active,
                                                }}
                                            >
                                                {a.friendlyName}
                                            </ListSubheader>,
                                        ];

                                        return ret.concat(
                                            a.possibleFits.map((f) => (
                                                <MenuItem
                                                    sx={{
                                                        paddingLeft: "48px",
                                                        lineHeight: "1.2em",
                                                    }}
                                                    key={a.name + "-" + f.name}
                                                    value={a.name + "-" + f.name}
                                                >
                                                    {f.friendlyName}
                                                </MenuItem>
                                            ))
                                        );
                                    })}
                                    {/*{Object.keys(ExperimentType).map((key) => (*/}
                                    {/*    // TODO: Reimplement this*/}
                                    {/*    <MenuItem key={key} value={ExperimentType[key as keyof typeof ExperimentType]}>*/}
                                    {/*        {experimentTypeMap.nameMap[ExperimentType[key as keyof typeof ExperimentType] as keyof typeof experimentTypeMap.nameMap]}*/}
                                    {/*    </MenuItem>*/}
                                    {/*))}*/}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                minWidth: 120,
                                paddingTop: "0.5rem",
                            }}
                        >
                            <Grid container spacing={2} flexWrap={"nowrap"}>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        sx={{
                                            display: ["MAFFCON-BAYESIAN", "MAFFCON-NLLS"].includes(
                                                experimentType as string
                                            )
                                                ? "block"
                                                : "none",
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: "100%",
                                            }}
                                            required
                                            size="small"
                                            label="Number of cells"
                                            value={numberOfCellsInput || ""}
                                            onChange={handleNumberOfCellsChange}
                                        />
                                    </FormControl>
                                    {!isNumberOfCellsInputCorrect() && numberOfCellsInput !== undefined && (
                                        <FormHelperText error>
                                            Number of cells must be a positive integer and smaller than 9.9e18
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        sx={{
                                            display: ["MAFFCON-BAYESIAN", "MAFFCON-NLLS"].includes(
                                                experimentType as string
                                            )
                                                ? "block"
                                                : "none",
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: "100%",
                                            }}
                                            required
                                            size="small"
                                            label="Sample volume"
                                            value={sampleVolumeInput || ""}
                                            onChange={handleSampleVolumeChange}
                                        />
                                        {!isSampleVolumeInputCorrect() && sampleVolumeInput !== undefined && (
                                            <FormHelperText error>
                                                Sample volume must be a positive float rounded to 2 decimal points and
                                                less than 100,000 mL
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={3}
                                    sx={{
                                        borderWidth: "1px",
                                        borderColor: "rgba(0, 0, 0, 0.23)",
                                        borderStyle: "solid",
                                        borderRadius: "4px",
                                        height: "40px",
                                        marginTop: "16px",
                                        marginLeft: "16px",
                                    }}
                                    style={{paddingTop: "8px"}}
                                >
                                    <Grid item flexGrow={1}>
                                        <Typography>Detect outliers</Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <Switch
                                                sx={{
                                                    position: "relative",
                                                    top: "-0.5rem",
                                                }}
                                                checked={detectOutliers}
                                                onChange={(e) => {
                                                    setDetectOutliers(e.target.checked);
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth={true}>
                                        <Select
                                            sx={{
                                                padding: "0px",
                                                height: "40px",
                                                "& >div:first-of-type": {
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                },
                                            }}
                                            onChange={(e) => {
                                                setColor(e.target.value);
                                            }}
                                            value={color}
                                        >
                                            <MenuItem value={"red"}>
                                                <Grid container direction={"row"} gap={1} alignItems={"center"}>
                                                    <Grid item>
                                                        <div
                                                            style={{
                                                                display: "inline-block",
                                                                width: "24px",
                                                                height: "24px",
                                                                backgroundColor: colorMap["red"],
                                                                position: "relative",
                                                                top: "3px",
                                                            }}
                                                        ></div>
                                                    </Grid>
                                                    <Grid item>Red</Grid>
                                                </Grid>
                                            </MenuItem>
                                            <MenuItem value={"green"}>
                                                <Grid container direction={"row"} gap={1} alignItems={"center"}>
                                                    <Grid item>
                                                        <div
                                                            style={{
                                                                display: "inline-block",
                                                                width: "24px",
                                                                height: "24px",
                                                                backgroundColor: colorMap["green"],
                                                                position: "relative",
                                                                top: "3px",
                                                            }}
                                                        ></div>
                                                    </Grid>
                                                    <Grid item>Green</Grid>
                                                </Grid>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                        <MeasurementSelector
                            initialShowSelectedOnly={experimentData !== undefined}
                            selectedMeasurements={experimentMeasurements?.map((m) => m.id)}
                            filterColor={color}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                    {experimentData === undefined ? (
                        <Button
                            disabled={
                                experimentName === "" ||
                                experimentType === undefined ||
                                !isMaffconCorrect() ||
                                selectedMeasurementIds.length < 1
                            }
                            size="medium"
                            onClick={handleCreateExperiment}
                            variant="contained"
                        >
                            Create now
                        </Button>
                    ) : (
                        <Button
                            disabled={
                                experimentName === "" ||
                                experimentType === undefined ||
                                !isMaffconCorrect() ||
                                selectedMeasurementIds.length < 1
                            }
                            size="medium"
                            onClick={handleSaveExperiment}
                            variant="contained"
                        >
                            Save
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}
