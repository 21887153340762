import {Box, styled, TextField} from "@mui/material";
import ExperimentsSelection from "./Group/ExperimentsSelection";
import React, {useEffect, useState} from "react";
import {randomColor} from "../../helpers/PlotColors";
import {MaffconExperimentInfo} from "../../api";

interface MultiAssayInputProps {
    className?: string;
    cellDensity: number | undefined;
    setCellDensity: (density: number | undefined) => void;
    maffconExperimentInfo: MaffconExperimentInfo[];
    setMaffconExperimentInfo: (selected: MaffconExperimentInfo[]) => void;
}

export default styled((props: MultiAssayInputProps) => {
    const [maffconExperimentInfo, setMaffconExperimentInfo] = useState<MaffconExperimentInfo[]>([]);
    const [cellDensity, setCellDensity] = useState<string>("");

    useEffect(() => {
        setMaffconExperimentInfo(props.maffconExperimentInfo);
    }, [props.maffconExperimentInfo]);

    useEffect(() => {
        setCellDensity(props.cellDensity?.toString() || "");
    }, [props.cellDensity]);

    const handleSelectExperimentId = (pageExpIds: string[], selExpIds: string[]) => {
        const cloneMaffconExperimentInfo = [...maffconExperimentInfo];
        pageExpIds.forEach((pageExp) => {
            const selected = selExpIds.includes(pageExp);
            if (selected && !cloneMaffconExperimentInfo.find((e) => e.experimentId == pageExp)) {
                cloneMaffconExperimentInfo.push({experimentId: pageExp, color: randomColor()});
            } else if (!selected && cloneMaffconExperimentInfo.find((e) => e.experimentId == pageExp)) {
                const indexToRemove: number = cloneMaffconExperimentInfo.findIndex((e) => e.experimentId == pageExp);
                if (indexToRemove !== -1) {
                    cloneMaffconExperimentInfo.splice(indexToRemove, 1);
                }
            }
        });
        setMaffconExperimentInfo(cloneMaffconExperimentInfo);
        props.setMaffconExperimentInfo(cloneMaffconExperimentInfo);
    };

    return (
        <>
            <Box
                sx={{
                    minWidth: 120,
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                }}
            >
                <TextField
                    value={cellDensity.toString()}
                    fullWidth
                    label="Cell density (cells/L)"
                    onChange={(input: React.ChangeEvent<HTMLInputElement>) => {
                        const n = parseInt(input.target.value || "");
                        if (input.target.value === "" || (isFinite(n) && n.toString() === input.target.value)) {
                            setCellDensity(input.target.value);
                            if (isFinite(n) && input.target.value != "") {
                                props.setCellDensity(n);
                            } else {
                                props.setCellDensity(undefined);
                            }
                        }
                    }}
                    variant="outlined"
                    size="small"
                />
            </Box>
            <ExperimentsSelection
                studyType={"MAFFCON-BAYESIAN"}
                selectedExperimentIds={maffconExperimentInfo.map((e) => e.experimentId)}
                handleSelectExperimentId={handleSelectExperimentId}
                multiple={true}
                hideStatus={true}
                hideType={true}
            />
        </>
    );
})((theme) => {
    return {};
});
