import React, {useContext} from "react";

import {Dialog, Grid, Stack, styled, Tooltip, Typography} from "@mui/material";

import {useParams} from "react-router-dom";
import {useConfig} from "../../RuntimeConfig";

import AnalysisDataTableNLLS from "./AnalysisDataTableNLLS";
import AnalysisDataTableBayesian from "./AnalysisDataTableBayesian";

import {useSnackbar} from "notistack";

import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
import {
    SingleExperimentResponse,
    NllsResult,
    AnalysisSummaryResponse,
    ExperimentsApi,
    MaapDataStats,
    ResourceTypeListing,
    ExperimentType,
    ResourceDefinition,
    ResourceType,
    QcData,
    CreateExperimentRequest,
    ResponseError,
    AnalysisApi,
} from "../../api";
import {apiConfig} from "../../ApiConfig";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import {useTheme} from "@mui/material/styles";
import {AnalysisContext, ExperimentContext} from "../../App";
import {PageButtonBar, PageContainer, PageContentBlock, PageHeader} from "../../helpers/HelperComponents";
import MeasurementEntry from "../../components/MeasurementEntry";
import {camelizeKeys} from "../../helpers/CamelCase";
import QualityConcerns from "../../components/QualityConcerns";
import {handleNetworkError} from "../../helpers/error";

const experimentsApi = new ExperimentsApi(apiConfig);
const analysisApi = new AnalysisApi(apiConfig);

export default styled(function (props: any) {
    const [experimentData, setExperimentData] = React.useState<SingleExperimentResponse>(
        {} as SingleExperimentResponse
    );
    const [currentExperimentData, setCurrentExperimentData] = React.useState<SingleExperimentResponse>(
        {} as SingleExperimentResponse
    );
    const [analysisData, setAnalysisData] = React.useState<AnalysisSummaryResponse>({} as AnalysisSummaryResponse);
    const [qc, setQc] = React.useState<QcData>({} as QcData);
    const [resultsMAAP, setResultsMAAP] = React.useState<MaapDataStats>({} as MaapDataStats);
    const [resultsNLLS, setResultsNLLS] = React.useState<NllsResult>({} as NllsResult);
    const [resources, setResources] = React.useState<ResourceDefinition[]>([] as ResourceDefinition[]);
    const {getConfig} = useConfig();
    const [historical, setHistorical] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const {id} = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const experimentTypeMap = useContext(ExperimentContext);
    const resourceDefinitions = useContext(AnalysisContext);

    const {enqueueSnackbar} = useSnackbar();

    React.useEffect(() => {
        getAnalysisData(id as string);
    }, [resourceDefinitions, id]);

    // We fetch experiment data since analysis does not do that
    // const getExperimentData = (experimentId: string): void => {
    //     experimentsApi.getExperimentById(experimentId).then((data: any) => {
    //         setExperimentData(data);
    //     });
    // };

    const getAnalysisData = (analysisId: string): void => {
        analysisApi
            .getAnalysisSummary(analysisId)
            .then((data: any) => {
                setAnalysisData(data);
                setExperimentData(data.experiment);
                experimentsApi
                    .getExperimentById(data.experimentId)
                    .then((currentExperiment) => {
                        setCurrentExperimentData(currentExperiment);
                        if (currentExperiment.validAnalysis != analysisId) {
                            setHistorical(true);
                        }
                    })
                    .catch((resp: ResponseError) => {
                        if (resp.response.status == 404) {
                            setHistorical(true);
                        }
                    });
                // getExperimentData(data.experimentId as string);

                analysisApi
                    .getAnalysisResource(data.id, "qc")
                    .then((qcInfo: any) => {
                        setQc(camelizeKeys(qcInfo) as QcData);
                    })
                    .catch((resp: ResponseError) => {});
                let analysisType;
                let resultsData;
                let resourceData;

                if ("experiment" in data && data.experiment.type === ExperimentType.SaffconBayesian) {
                    analysisType = ExperimentType.SaffconBayesian;
                    resultsData = data.maap.result as MaapDataStats;
                    resourceData = data.maap.resources as ResourceTypeListing;
                    setResultsMAAP(resultsData);
                } else if ("experiment" in data && data.experiment.type === ExperimentType.MaffconBayesian) {
                    analysisType = ExperimentType.MaffconBayesian;
                    resultsData = data.maap.result as MaapDataStats;
                    resourceData = data.maap.resources as ResourceTypeListing;
                    setResultsMAAP(resultsData);
                } else if ("experiment" in data && data.experiment.type === ExperimentType.SaffconNlls) {
                    analysisType = ExperimentType.SaffconNlls;
                    resultsData = data.nlls.result as NllsResult;
                    resourceData = data.nlls.resources as ResourceTypeListing;
                    setResultsNLLS(resultsData);
                } else if ("experiment" in data && data.experiment.type === ExperimentType.MaffconNlls) {
                    analysisType = ExperimentType.MaffconNlls;
                    resultsData = data.nlls.result as NllsResult;
                    resourceData = data.nlls.resources as ResourceTypeListing;
                    setResultsNLLS(resultsData);
                } else if ("experiment" in data && data.experiment.type === ExperimentType.BinaryBindingBayesian) {
                    analysisType = ExperimentType.BinaryBindingBayesian;
                    resultsData = data.maap.result as MaapDataStats;
                    resourceData = data.maap.resources as ResourceTypeListing;
                    setResultsMAAP(resultsData);
                } else if ("experiment" in data && data.experiment.type === ExperimentType.BinaryBindingNlls) {
                    analysisType = ExperimentType.BinaryBindingNlls;
                    resultsData = data.nlls.result as NllsResult;
                    resourceData = data.nlls.resources as ResourceTypeListing;
                    setResultsNLLS(resultsData);
                } else if ("experiment" in data && data.experiment.type === ExperimentType.MaffconBayesian) {
                    analysisType = ExperimentType.MaffconBayesian;
                    resultsData = data.maap.result as MaapDataStats;
                    resourceData = data.maap.resources as ResourceTypeListing;
                    setResultsMAAP(resultsData);
                } else if ("experiment" in data && data.experiment.type === ExperimentType.MaffconNlls) {
                    analysisType = ExperimentType.MaffconNlls;
                    resultsData = data.nlls.result as NllsResult;
                    resourceData = data.nlls.resources as ResourceTypeListing;
                    setResultsNLLS(resultsData);
                } else if ("experiment" in data && data.experiment.type === ExperimentType.NestBayesian) {
                    analysisType = ExperimentType.NestBayesian;
                    resultsData = data.maap.result as MaapDataStats;
                    resourceData = data.maap.resources as ResourceTypeListing;
                    setResultsMAAP(resultsData);
                } else if ("experiment" in data && data.experiment.type === ExperimentType.NestNlls) {
                    analysisType = ExperimentType.NestNlls;
                    resultsData = data.nlls.result as NllsResult;
                    resourceData = data.nlls.resources as ResourceTypeListing;
                    setResultsNLLS(resultsData);
                } else {
                    resourceData = {} as ResourceTypeListing;
                }

                displayResourceData(resourceData, analysisType);
            })
            .catch((response: ResponseError) => {
                if (response.response.status === 404) {
                    navigate("/404", {replace: true});
                } else if (response.response.status === 422) {
                    navigate("/422/" + response.response.statusText, {replace: true});
                } else {
                    handleNetworkError(response).then((target) => {
                        if (target) {
                            navigate(target);
                        }
                    });
                }
            });
    };

    const displayResourceData = (resource_data: ResourceTypeListing, analysis_type: string | undefined): void => {
        const resources_list: string[] = resource_data.data.concat(resource_data.images);

        if (resourceDefinitions[analysis_type as string] === undefined) {
            return;
        }

        let definitions = resourceDefinitions[analysis_type as string].resourceDefinitions;

        let result = definitions.reduce(
            (map: {[key: string]: ResourceDefinition}, obj) => ((map[obj.name] = obj), map),
            {}
        );

        const included_resources = resources_list
            .filter((res) => res in result && result[res].included)
            .map((res) => result[res])
            .sort((res1, res2) => res1.priorityScore - res2.priorityScore);

        setResources(included_resources);
    };

    const AnalysisDataTable = () => {
        if ("experiment" in analysisData) {
            switch (analysisData.experiment.type) {
                case ExperimentType.SaffconBayesian:
                case ExperimentType.NestBayesian:
                case ExperimentType.BinaryBindingBayesian:
                case ExperimentType.MaffconBayesian:
                    return <AnalysisDataTableBayesian results={resultsMAAP ? resultsMAAP : ({} as MaapDataStats)} />;

                case ExperimentType.SaffconNlls:
                case ExperimentType.NestNlls:
                case ExperimentType.MaffconNlls:
                case ExperimentType.BinaryBindingNlls:
                    return <AnalysisDataTableNLLS results={resultsNLLS ? resultsNLLS : ({} as NllsResult)} />;

                default:
                    return <></>;
            }
        } else {
            return <></>;
        }
    };

    return (
        <div className={props.className} data-testid="experiment-analysis">
            <PageHeader>
                <Stack spacing={0.5}>
                    {(experimentData.hasOwnProperty("type") && (
                        <>
                            <Typography variant="h4">Analysis results of {experimentData.name}</Typography>
                            <Typography variant="body2" color="text.secondary">
                                {
                                    experimentTypeMap.nameMap[
                                        experimentData.type as unknown as keyof typeof experimentTypeMap.nameMap
                                    ]
                                }
                                &nbsp;analysis
                            </Typography>
                        </>
                    )) || (
                        <>
                            <Typography className={"loading"} variant="h4">
                                Analysis results of whatever experiment
                            </Typography>
                            <Typography className={"loading"} variant="body2" color="text.secondary">
                                analysis
                            </Typography>
                        </>
                    )}
                    <Typography variant="body1" color="text.secondary">
                        {experimentData.description}
                    </Typography>
                </Stack>
            </PageHeader>
            <PageContainer>
                {historical && (
                    <PageContentBlock sx={{paddingTop: "0px", paddingBottom: "0px"}}>
                        <Typography sx={{lineHeight: "50px"}}>
                            <WarningIcon sx={{position: "relative", top: "5px"}} /> Note: This analysis is historical.
                            It doesn't reflect the experiment as it is currently set up. &nbsp;&nbsp;&nbsp;
                            <a
                                href=""
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setOpen(true);
                                    return false;
                                }}
                            >
                                Show underlying measurements
                            </a>
                        </Typography>
                        <Dialog
                            fullWidth={true}
                            maxWidth={false}
                            open={open}
                            onClose={() => {
                                setOpen(false);
                            }}
                            PaperProps={{sx: {width: "90vw", height: "90vh"}}}
                            scroll={undefined}
                        >
                            <PageButtonBar sx={{position: "relative", top: "0px", left: "0px"}}>
                                <Tooltip title={"Close list"}>
                                    <IconButton onClick={() => setOpen(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </PageButtonBar>
                            <div style={{width: "100%", height: "100%", overflowY: "scroll"}}>
                                {analysisData.experiment.measurements?.map((m) => {
                                    return <MeasurementEntry key={m.id} measurement={m} useNames={true} />;
                                })}
                            </div>
                        </Dialog>
                    </PageContentBlock>
                )}
                <PageContentBlock>
                    <PageButtonBar backLink={`/experiment/${analysisData.experimentId}`} />

                    <Typography variant="h5" style={{paddingTop: "1rem", paddingBottom: "1rem", textAlign: "center"}}>
                        Key results
                    </Typography>

                    <AnalysisDataTable />
                </PageContentBlock>
                {experimentData.qc && (
                    <QualityConcerns analysisId={analysisData.id} qc={qc} experiment={currentExperimentData} />
                )}
                {resources.map((res: ResourceDefinition) => {
                    if (res.type === ResourceType.Png) {
                        return (
                            <PageContentBlock key={res.title}>
                                <Typography
                                    variant="h5"
                                    style={{paddingTop: "1rem", paddingBottom: "1rem", textAlign: "center"}}
                                >
                                    {res.title}
                                </Typography>
                                <img
                                    alt={res.name}
                                    style={{maxWidth: "100%"}}
                                    src={`${getConfig("backendUrl")}/analysis/${analysisData.id}/resource/${res.name}`}
                                />
                            </PageContentBlock>
                        );
                    }
                    return <React.Fragment key={res.title}></React.Fragment>;
                })}
            </PageContainer>
        </div>
    );
})((theme) => {
    return {};
});
