import React from "react";
import {
    Grid,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import {ErrorInformation} from "../../api";
import {StandardDateTimeDisplay} from "../../helpers/HelperComponents";
import FileDownload from "../../components/FileDownload";
import {getConfig} from "../../RuntimeConfig";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export type ErrorDetailsType = {[key: string]: string | string[]};
export type ErrorDataType = {[key: string]: any};

interface ErrorDisplayProps {
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
}

export default styled((props: ErrorDisplayProps): JSX.Element => {
    return (
        <TableContainer className={props.className}>
            <Table>
                <TableBody>{props.children}</TableBody>
            </Table>
        </TableContainer>
    );
})`
    width: 100%;
    max-width: 100%;
`;

interface ErrorDisplayCommonProps {
    className?: string;
    error: ErrorInformation;
    filename?: string;
}

export const ErrorDisplayCommon = styled((props: ErrorDisplayCommonProps): JSX.Element => {
    const errorDetails = props.error.errorDetail as ErrorDetailsType;
    const theme = useTheme();

    return (
        <>
            <ErrorDisplayLine label={"Title"}>{props.error.errorTitle}</ErrorDisplayLine>
            <ErrorDisplayLine label={"Description"}>{props.error.errorDescription}</ErrorDisplayLine>
            <ErrorDisplayLine label={"Time"}>
                <StandardDateTimeDisplay value={props.error.errorTime} />
            </ErrorDisplayLine>
            {props.error.errorRef && (
                <ErrorDisplayLine label={"Referenced object"}>
                    <ErrorRefObject error={props.error} filename={props.filename} />
                </ErrorDisplayLine>
            )}
            <ErrorDisplayLine label={"Download error JSON"}>
                <FileDownload
                    blob={new Blob([JSON.stringify(props.error, null, 2)], {type: "application/json"})}
                    filename={`ErrorInfo-${props.error.errorTime}`}
                >
                    <Grid container spacing="10px">
                        <Grid item>
                            <CloudDownloadIcon />
                        </Grid>
                        <Grid item>
                            <Typography sx={{cursor: "pointer", color: theme.palette.primary.main}}>
                                Download JSON
                            </Typography>
                        </Grid>
                    </Grid>
                </FileDownload>
            </ErrorDisplayLine>
            {errorDetails !== undefined && (
                <ErrorDisplayLine label={"Details"}>
                    {Object.keys(errorDetails).map((k) => (
                        <Grid key={k.toLowerCase()} container wrap="nowrap" spacing="10px">
                            <Grid item>
                                <Typography noWrap={true} textTransform={"capitalize"}>
                                    {k.toLowerCase().replace("_", " ")}:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography noWrap={false} style={{whiteSpace: "pre-line"}}>
                                    {errorDetails[k].constructor === Array
                                        ? // @ts-ignore
                                          errorDetails[k].join(", ")
                                        : errorDetails[k]}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </ErrorDisplayLine>
            )}
        </>
    );
})``;

interface ErrorDisplayLineProps {
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
    label: string;
}

export const ErrorDisplayLine = styled((props: ErrorDisplayLineProps): JSX.Element => {
    return (
        <TableRow className={props.className}>
            <TableCell align="right">
                <Typography component="div" noWrap={true}>
                    {props.label}:
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography component="div" noWrap={true}>
                    {props.children}
                </Typography>
            </TableCell>
        </TableRow>
    );
})`
    td {
        vertical-align: top;
    }
    td:first-of-type > p {
        font-weight: bold;
        padding-left: 15px;
    }
    td:last-of-type > p {
        padding-right: 15px;
    }
    max-width: 100%;
`;

interface ErrorRefObjectProps {
    className?: string;
    error: ErrorInformation;
    filename?: string;
}

export const ErrorRefObject = styled((props: ErrorRefObjectProps): JSX.Element => {
    const theme = useTheme();

    return (
        <FileDownload
            blobSource={getConfig("backendUrl") + "/objects/" + props.error.errorContext + "/" + props.error.errorRef}
            filename={props.filename}
        >
            <Grid container spacing="10px">
                <Grid item>
                    <CloudDownloadIcon />
                </Grid>
                <Grid item>
                    <Typography sx={{cursor: "pointer", color: theme.palette.primary.main}}>Download</Typography>
                </Grid>
            </Grid>
        </FileDownload>
    );
})``;
