/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OutputParameters } from './OutputParameters';
import {
    OutputParametersFromJSON,
    OutputParametersFromJSONTyped,
    OutputParametersToJSON,
} from './OutputParameters';

/**
 * Model for the MAAP NLLS computed output
 * @export
 * @interface NllsResult
 */
export interface NllsResult {
    /**
     * 
     * @type {OutputParameters}
     * @memberof NllsResult
     */
    globalFit: OutputParameters;
}

/**
 * Check if a given object implements the NllsResult interface.
 */
export function instanceOfNllsResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "globalFit" in value;

    return isInstance;
}

export function NllsResultFromJSON(json: any): NllsResult {
    return NllsResultFromJSONTyped(json, false);
}

export function NllsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): NllsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'globalFit': OutputParametersFromJSON(json['global_fit']),
    };
}

export function NllsResultToJSON(value?: NllsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'global_fit': OutputParametersToJSON(value.globalFit),
    };
}

