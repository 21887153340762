/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AnalysisSchema,
  AnalysisSummaryResponse,
  HTTPValidationError,
  ZippedAnalysisRequest,
  ZippedAnalysisResponse,
} from '../models/index';
import {
    AnalysisSchemaFromJSON,
    AnalysisSchemaToJSON,
    AnalysisSummaryResponseFromJSON,
    AnalysisSummaryResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ZippedAnalysisRequestFromJSON,
    ZippedAnalysisRequestToJSON,
    ZippedAnalysisResponseFromJSON,
    ZippedAnalysisResponseToJSON,
} from '../models/index';

export interface CreateAnalysisRequest {
    experimentId: string;
}

export interface GetAnalysisResourceRequest {
    analysisId: string;
    resourceName: string;
}

export interface GetAnalysisSummaryRequest {
    analysisId: string;
}

export interface GetAnalysisZippedRequest {
    analysisId: string;
}

export interface GetReportStatusRequest {
    analysisId: string;
}

export interface RequestReportRequest {
    analysisId: string;
    zippedAnalysisRequest: ZippedAnalysisRequest;
}

/**
 * 
 */
export class AnalysisApi extends runtime.BaseAPI {

    /**
     * Start executing analysis on the experiment
     * Create Analysis
     */
    async createAnalysisRaw(requestParameters: CreateAnalysisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnalysisSchema>> {
        if (requestParameters.experimentId === null || requestParameters.experimentId === undefined) {
            throw new runtime.RequiredError('experimentId','Required parameter requestParameters.experimentId was null or undefined when calling createAnalysis.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analysis/{experiment_id}`.replace(`{${"experiment_id"}}`, encodeURIComponent(String(requestParameters.experimentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalysisSchemaFromJSON(jsonValue));
    }

    /**
     * Start executing analysis on the experiment
     * Create Analysis
     */
    async createAnalysis(experimentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnalysisSchema> {
        const response = await this.createAnalysisRaw({ experimentId: experimentId }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve vector or image data that has been generated by the respective analysis run
     * Get Analysis Resource
     */
    async getAnalysisResourceRaw(requestParameters: GetAnalysisResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.analysisId === null || requestParameters.analysisId === undefined) {
            throw new runtime.RequiredError('analysisId','Required parameter requestParameters.analysisId was null or undefined when calling getAnalysisResource.');
        }

        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName','Required parameter requestParameters.resourceName was null or undefined when calling getAnalysisResource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analysis/{analysis_id}/resource/{resource_name}`.replace(`{${"analysis_id"}}`, encodeURIComponent(String(requestParameters.analysisId))).replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Retrieve vector or image data that has been generated by the respective analysis run
     * Get Analysis Resource
     */
    async getAnalysisResource(analysisId: string, resourceName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getAnalysisResourceRaw({ analysisId: analysisId, resourceName: resourceName }, initOverrides);
        return await response.value();
    }

    /**
     * Get analysis status. If resource is not set, then analysis summary is return. A completed analysis summary will provide the resource values representing a list of retrievable analysis detailed outcome that accompanies the analysis result. This outcome can be retrieved by specifying the value in the resource query parameters
     * Get Analysis
     */
    async getAnalysisSummaryRaw(requestParameters: GetAnalysisSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnalysisSummaryResponse>> {
        if (requestParameters.analysisId === null || requestParameters.analysisId === undefined) {
            throw new runtime.RequiredError('analysisId','Required parameter requestParameters.analysisId was null or undefined when calling getAnalysisSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analysis/{analysis_id}`.replace(`{${"analysis_id"}}`, encodeURIComponent(String(requestParameters.analysisId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnalysisSummaryResponseFromJSON(jsonValue));
    }

    /**
     * Get analysis status. If resource is not set, then analysis summary is return. A completed analysis summary will provide the resource values representing a list of retrievable analysis detailed outcome that accompanies the analysis result. This outcome can be retrieved by specifying the value in the resource query parameters
     * Get Analysis
     */
    async getAnalysisSummary(analysisId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnalysisSummaryResponse> {
        const response = await this.getAnalysisSummaryRaw({ analysisId: analysisId }, initOverrides);
        return await response.value();
    }

    /**
     * Unlike normal API, this will be called by the browser directly, not in an FE api call
     * Get Analysis Zipped
     */
    async getAnalysisZippedRaw(requestParameters: GetAnalysisZippedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.analysisId === null || requestParameters.analysisId === undefined) {
            throw new runtime.RequiredError('analysisId','Required parameter requestParameters.analysisId was null or undefined when calling getAnalysisZipped.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analysis/{analysis_id}/zipfile`.replace(`{${"analysis_id"}}`, encodeURIComponent(String(requestParameters.analysisId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unlike normal API, this will be called by the browser directly, not in an FE api call
     * Get Analysis Zipped
     */
    async getAnalysisZipped(analysisId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getAnalysisZippedRaw({ analysisId: analysisId }, initOverrides);
    }

    /**
     * Get Report Status
     */
    async getReportStatusRaw(requestParameters: GetReportStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ZippedAnalysisResponse>> {
        if (requestParameters.analysisId === null || requestParameters.analysisId === undefined) {
            throw new runtime.RequiredError('analysisId','Required parameter requestParameters.analysisId was null or undefined when calling getReportStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/analysis/{analysis_id}/getreportstatus`.replace(`{${"analysis_id"}}`, encodeURIComponent(String(requestParameters.analysisId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZippedAnalysisResponseFromJSON(jsonValue));
    }

    /**
     * Get Report Status
     */
    async getReportStatus(analysisId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ZippedAnalysisResponse> {
        const response = await this.getReportStatusRaw({ analysisId: analysisId }, initOverrides);
        return await response.value();
    }

    /**
     * Request Report
     */
    async requestReportRaw(requestParameters: RequestReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ZippedAnalysisResponse>> {
        if (requestParameters.analysisId === null || requestParameters.analysisId === undefined) {
            throw new runtime.RequiredError('analysisId','Required parameter requestParameters.analysisId was null or undefined when calling requestReport.');
        }

        if (requestParameters.zippedAnalysisRequest === null || requestParameters.zippedAnalysisRequest === undefined) {
            throw new runtime.RequiredError('zippedAnalysisRequest','Required parameter requestParameters.zippedAnalysisRequest was null or undefined when calling requestReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/analysis/{analysis_id}/requestreport`.replace(`{${"analysis_id"}}`, encodeURIComponent(String(requestParameters.analysisId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ZippedAnalysisRequestToJSON(requestParameters.zippedAnalysisRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZippedAnalysisResponseFromJSON(jsonValue));
    }

    /**
     * Request Report
     */
    async requestReport(analysisId: string, zippedAnalysisRequest: ZippedAnalysisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ZippedAnalysisResponse> {
        const response = await this.requestReportRaw({ analysisId: analysisId, zippedAnalysisRequest: zippedAnalysisRequest }, initOverrides);
        return await response.value();
    }

}
