/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateMeasurementResponse,
  HTTPValidationError,
  MeasurementBatchUpdateRequest,
  MeasurementFileInfo,
  MeasurementFileSchema,
  MeasurementGroup,
  MeasurementSchema,
  MeasurementUpdateRequest,
  ReadHeadersResponse,
  ReadSectionResponse,
} from '../models/index';
import {
    CreateMeasurementResponseFromJSON,
    CreateMeasurementResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    MeasurementBatchUpdateRequestFromJSON,
    MeasurementBatchUpdateRequestToJSON,
    MeasurementFileInfoFromJSON,
    MeasurementFileInfoToJSON,
    MeasurementFileSchemaFromJSON,
    MeasurementFileSchemaToJSON,
    MeasurementGroupFromJSON,
    MeasurementGroupToJSON,
    MeasurementSchemaFromJSON,
    MeasurementSchemaToJSON,
    MeasurementUpdateRequestFromJSON,
    MeasurementUpdateRequestToJSON,
    ReadHeadersResponseFromJSON,
    ReadHeadersResponseToJSON,
    ReadSectionResponseFromJSON,
    ReadSectionResponseToJSON,
} from '../models/index';

export interface GetMeasurementByIdRequest {
    measurementId: string;
}

export interface GetMeasurementFileRequest {
    id: string;
}

export interface GetMeasurementGroupsByIdsRequest {
    ids?: Array<string>;
}

export interface GetMeasurementHeadersRequest {
    startDate: Date;
    endDate: Date;
}

export interface GetMeasurementsDetailsRequest {
    runTimestamp: Date;
    labeledName: string;
    instrumentType: string;
    instrumentId: string;
    unlabeledName?: string;
    complexName?: string;
}

export interface UpdateMeasurementByIdRequest {
    measurementId: string;
    measurementUpdateRequest: MeasurementUpdateRequest;
}

export interface UpdateMultipleMeasurementsRequest {
    measurementBatchUpdateRequest: MeasurementBatchUpdateRequest;
}

export interface UploadMeasurementsRequest {
    files: Array<Blob>;
}

/**
 * 
 */
export class MeasurementsApi extends runtime.BaseAPI {

    /**
     * Fetch a single measurement identified by the id
     * Read Measurement
     */
    async getMeasurementByIdRaw(requestParameters: GetMeasurementByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeasurementSchema>> {
        if (requestParameters.measurementId === null || requestParameters.measurementId === undefined) {
            throw new runtime.RequiredError('measurementId','Required parameter requestParameters.measurementId was null or undefined when calling getMeasurementById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/measurements/byid/{measurement_id}`.replace(`{${"measurement_id"}}`, encodeURIComponent(String(requestParameters.measurementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementSchemaFromJSON(jsonValue));
    }

    /**
     * Fetch a single measurement identified by the id
     * Read Measurement
     */
    async getMeasurementById(measurementId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeasurementSchema> {
        const response = await this.getMeasurementByIdRaw({ measurementId: measurementId }, initOverrides);
        return await response.value();
    }

    /**
     * Request a measurement file as uploaded
     * Get Measurement File
     */
    async getMeasurementFileRaw(requestParameters: GetMeasurementFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeasurementFileSchema>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMeasurementFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/measurements/measurement_file`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementFileSchemaFromJSON(jsonValue));
    }

    /**
     * Request a measurement file as uploaded
     * Get Measurement File
     */
    async getMeasurementFile(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeasurementFileSchema> {
        const response = await this.getMeasurementFileRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Request the group info for a list of measurements
     * Measurement Groups By Ids
     */
    async getMeasurementGroupsByIdsRaw(requestParameters: GetMeasurementGroupsByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MeasurementGroup>>> {
        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/measurements/measurement_groups_by_ids`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeasurementGroupFromJSON));
    }

    /**
     * Request the group info for a list of measurements
     * Measurement Groups By Ids
     */
    async getMeasurementGroupsByIds(ids?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MeasurementGroup>> {
        const response = await this.getMeasurementGroupsByIdsRaw({ ids: ids }, initOverrides);
        return await response.value();
    }

    /**
     * Query Measurement Headers
     */
    async getMeasurementHeadersRaw(requestParameters: GetMeasurementHeadersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadHeadersResponse>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getMeasurementHeaders.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getMeasurementHeaders.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/measurements/headers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadHeadersResponseFromJSON(jsonValue));
    }

    /**
     * Query Measurement Headers
     */
    async getMeasurementHeaders(startDate: Date, endDate: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadHeadersResponse> {
        const response = await this.getMeasurementHeadersRaw({ startDate: startDate, endDate: endDate }, initOverrides);
        return await response.value();
    }

    /**
     * Query Measurements Section
     */
    async getMeasurementsDetailsRaw(requestParameters: GetMeasurementsDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadSectionResponse>> {
        if (requestParameters.runTimestamp === null || requestParameters.runTimestamp === undefined) {
            throw new runtime.RequiredError('runTimestamp','Required parameter requestParameters.runTimestamp was null or undefined when calling getMeasurementsDetails.');
        }

        if (requestParameters.labeledName === null || requestParameters.labeledName === undefined) {
            throw new runtime.RequiredError('labeledName','Required parameter requestParameters.labeledName was null or undefined when calling getMeasurementsDetails.');
        }

        if (requestParameters.instrumentType === null || requestParameters.instrumentType === undefined) {
            throw new runtime.RequiredError('instrumentType','Required parameter requestParameters.instrumentType was null or undefined when calling getMeasurementsDetails.');
        }

        if (requestParameters.instrumentId === null || requestParameters.instrumentId === undefined) {
            throw new runtime.RequiredError('instrumentId','Required parameter requestParameters.instrumentId was null or undefined when calling getMeasurementsDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.runTimestamp !== undefined) {
            queryParameters['run_timestamp'] = (requestParameters.runTimestamp as any).toISOString();
        }

        if (requestParameters.labeledName !== undefined) {
            queryParameters['labeled_name'] = requestParameters.labeledName;
        }

        if (requestParameters.instrumentType !== undefined) {
            queryParameters['instrument_type'] = requestParameters.instrumentType;
        }

        if (requestParameters.instrumentId !== undefined) {
            queryParameters['instrument_id'] = requestParameters.instrumentId;
        }

        if (requestParameters.unlabeledName !== undefined) {
            queryParameters['unlabeled_name'] = requestParameters.unlabeledName;
        }

        if (requestParameters.complexName !== undefined) {
            queryParameters['complex_name'] = requestParameters.complexName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/measurements/section`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadSectionResponseFromJSON(jsonValue));
    }

    /**
     * Query Measurements Section
     */
    async getMeasurementsDetails(runTimestamp: Date, labeledName: string, instrumentType: string, instrumentId: string, unlabeledName?: string, complexName?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadSectionResponse> {
        const response = await this.getMeasurementsDetailsRaw({ runTimestamp: runTimestamp, labeledName: labeledName, instrumentType: instrumentType, instrumentId: instrumentId, unlabeledName: unlabeledName, complexName: complexName }, initOverrides);
        return await response.value();
    }

    /**
     * Request a measurement file as uploaded
     * Get Recent Uploads
     */
    async getRecentUploadsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MeasurementFileInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/measurements/recent_uploads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeasurementFileInfoFromJSON));
    }

    /**
     * Request a measurement file as uploaded
     * Get Recent Uploads
     */
    async getRecentUploads(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MeasurementFileInfo>> {
        const response = await this.getRecentUploadsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update an existing measurement. Only limited fields: labeled, unlabeled, complex name and values can be updated. For valid updates, related experiment that has valid analysis will be reset as the analysis result is not valid anymore.
     * Update Measurement
     */
    async updateMeasurementByIdRaw(requestParameters: UpdateMeasurementByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeasurementSchema>> {
        if (requestParameters.measurementId === null || requestParameters.measurementId === undefined) {
            throw new runtime.RequiredError('measurementId','Required parameter requestParameters.measurementId was null or undefined when calling updateMeasurementById.');
        }

        if (requestParameters.measurementUpdateRequest === null || requestParameters.measurementUpdateRequest === undefined) {
            throw new runtime.RequiredError('measurementUpdateRequest','Required parameter requestParameters.measurementUpdateRequest was null or undefined when calling updateMeasurementById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/measurements/byid/{measurement_id}`.replace(`{${"measurement_id"}}`, encodeURIComponent(String(requestParameters.measurementId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeasurementUpdateRequestToJSON(requestParameters.measurementUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementSchemaFromJSON(jsonValue));
    }

    /**
     * Update an existing measurement. Only limited fields: labeled, unlabeled, complex name and values can be updated. For valid updates, related experiment that has valid analysis will be reset as the analysis result is not valid anymore.
     * Update Measurement
     */
    async updateMeasurementById(measurementId: string, measurementUpdateRequest: MeasurementUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeasurementSchema> {
        const response = await this.updateMeasurementByIdRaw({ measurementId: measurementId, measurementUpdateRequest: measurementUpdateRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Edit multiple measurements at once It takes ids from the request body and updates the corresponding measurements. If any value is None, the original value is kept.
     * Update Multiple Measurements
     */
    async updateMultipleMeasurementsRaw(requestParameters: UpdateMultipleMeasurementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MeasurementSchema>>> {
        if (requestParameters.measurementBatchUpdateRequest === null || requestParameters.measurementBatchUpdateRequest === undefined) {
            throw new runtime.RequiredError('measurementBatchUpdateRequest','Required parameter requestParameters.measurementBatchUpdateRequest was null or undefined when calling updateMultipleMeasurements.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/measurements/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeasurementBatchUpdateRequestToJSON(requestParameters.measurementBatchUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeasurementSchemaFromJSON));
    }

    /**
     * Edit multiple measurements at once It takes ids from the request body and updates the corresponding measurements. If any value is None, the original value is kept.
     * Update Multiple Measurements
     */
    async updateMultipleMeasurements(measurementBatchUpdateRequest: MeasurementBatchUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MeasurementSchema>> {
        const response = await this.updateMultipleMeasurementsRaw({ measurementBatchUpdateRequest: measurementBatchUpdateRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Creates MeasurementFile and corresponding Measurements located in uploaded file. If user uploads multiple files, the process is repeated for each file and a merged list is returned.
     * Create Measurement
     */
    async uploadMeasurementsRaw(requestParameters: UploadMeasurementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateMeasurementResponse>> {
        if (requestParameters.files === null || requestParameters.files === undefined) {
            throw new runtime.RequiredError('files','Required parameter requestParameters.files was null or undefined when calling uploadMeasurements.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files', element as any);
            })
        }

        const response = await this.request({
            path: `/measurements/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateMeasurementResponseFromJSON(jsonValue));
    }

    /**
     * Creates MeasurementFile and corresponding Measurements located in uploaded file. If user uploads multiple files, the process is repeated for each file and a merged list is returned.
     * Create Measurement
     */
    async uploadMeasurements(files: Array<Blob>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateMeasurementResponse> {
        const response = await this.uploadMeasurementsRaw({ files: files }, initOverrides);
        return await response.value();
    }

}
