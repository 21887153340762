import {
    Grid,
    IconButton,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React, {useEffect, useState} from "react";
import {ErrorApi, ErrorInformation} from "../../api";
import {handleNetworkError} from "../../helpers/error";
import {apiConfig} from "../../ApiConfig";
import ErrorDisplay, {ErrorDataType, ErrorDisplayCommon, ErrorDisplayLine} from "./ErrorDisplay";
import {PageButtonBar, PageContainer, PageContentBlock, PageHeader} from "../../helpers/HelperComponents";
import {history} from "../../App";

interface Props {
    className?: string;
}

const errorApi = new ErrorApi(apiConfig);

function ErrorImport(props: Props): JSX.Element {
    const {id} = useParams();
    const navigate = useNavigate();
    const [errorInformation, setErrorInformation] = useState<ErrorInformation>();

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        errorApi
            .getErrorById(id)
            .then((resp) => {
                setErrorInformation(resp);
            })
            .catch((response) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    }, []);

    if (errorInformation === undefined) {
        return <></>;
    }

    let analysisId = errorInformation.errorRef;
    errorInformation.errorRef = undefined;

    return (
        <div className={props.className}>
            <PageHeader text="Analysis error details" />
            <PageContainer>
                <PageContentBlock>
                    <PageButtonBar backLink={history.back} backLinkTooltip="Back to errors" />
                    <ErrorDisplay>
                        <ErrorDisplayCommon
                            error={errorInformation}
                            filename={(errorInformation.errorData as ErrorDataType)["filename"]}
                        />
                        {/*TODO: Implement this once the API changes are done*/}
                        {/*<ErrorDisplayLine label={"Referenced analysis"}>*/}
                        {/*    <Link to={"/"}></Link>*/}
                        {/*</ErrorDisplayLine>*/}
                    </ErrorDisplay>
                </PageContentBlock>
            </PageContainer>
        </div>
    );
}

export default styled(ErrorImport)``;
