import React from "react";
import DataTable from "./DataTable";
import {useSnackbar} from "notistack";
import {apiConfig} from "../../ApiConfig";
import {ExperimentsApi, ExperimentSummary, ListExperimentResponse, ResponseError} from "../../api";
import CreationModal from "../Experiment/CreationModal";

import {handleNetworkError} from "../../helpers/error";
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import {styled, Tooltip} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {PageButtonBar, PageContainer, PageContentBlock, PageHeader} from "../../helpers/HelperComponents";
import {useAppSelector} from "../../ReduxStore";

const experimentsApi = new ExperimentsApi(apiConfig);

export default styled(function (props: any) {
    const [filesUploaded, setFilesUploaded] = React.useState<File[]>();
    const [experiments, setExperiments] = React.useState<ExperimentSummary[]>([]);
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const theme = useTheme();
    const state = useAppSelector((state) => state.experimentsTable);
    const [rowsNumber, setRowsNumber] = React.useState<number>();
    const {eventMessage} = props;

    React.useEffect(() => {
        let notRunFilter = undefined;
        if (state && state.filterModel && state.filterModel.items.length > 0) {
            if (
                state.filterModel.items[0].columnField === "analysisStatus" &&
                state.filterModel.items[0].value === "Not run"
            ) {
                notRunFilter = {
                    columnField: "analysisStatus",
                    id: state.filterModel.items[0].id,
                    operatorValue: "isEmpty",
                };
            }
        }
        updateExperimentsPerPage(
            state.pageSize,
            state.page,
            state.filterModel ? JSON.stringify(notRunFilter ? notRunFilter : state.filterModel.items[0]) : undefined,
            state.sortModel ? JSON.stringify(state.sortModel[0]) : undefined
        );
    }, [eventMessage]);

    function updateExperimentsPerPage(
        pageSize: number,
        pageNumber: number,
        filter: string | undefined,
        sort: string | undefined
    ) {
        const from = pageSize * pageNumber;
        experimentsApi
            .getExperimentCount(filter)
            .then((resp: any) => {
                setRowsNumber(parseInt(resp));
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
        experimentsApi
            .listExperiments(from, pageSize, filter, sort)
            .then((resp: ListExperimentResponse) => {
                const tableExperiments = resp.experiments.map((obj) => {
                    if (obj.analysisStatus === undefined) {
                        return {...obj, analysisStatus: "Not run"};
                    }
                    return obj;
                });
                setExperiments(tableExperiments);
            })

            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    }

    return (
        <div className={props.className}>
            <PageHeader text="Experiments" />
            <PageContainer>
                <PageContentBlock>
                    <PageButtonBar>
                        <CreationModal>
                            <Tooltip title={"New experiment"}>
                                <IconButton
                                    sx={{
                                        backgroundColor: "primary.main",
                                        color: "primary.contrastText",
                                        "&:hover": {backgroundColor: "primary.dark"},
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </CreationModal>
                    </PageButtonBar>
                    <DataTable
                        experiments={experiments}
                        updateExperimentsPerPage={updateExperimentsPerPage}
                        rowsNumber={rowsNumber}
                    />
                </PageContentBlock>
            </PageContainer>
        </div>
    );
})((theme) => {
    return {};
});
