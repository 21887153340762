/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration
 * @export
 */
export const StudyType = {
    MaffconMultiDensity: 'MAFFCON-MULTI-DENSITY',
    MaffconMultiAssay: 'MAFFCON-MULTI-ASSAY',
    AffinityConcentration: 'AFFINITY-CONCENTRATION'
} as const;
export type StudyType = typeof StudyType[keyof typeof StudyType];


export function StudyTypeFromJSON(json: any): StudyType {
    return StudyTypeFromJSONTyped(json, false);
}

export function StudyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyType {
    return json as StudyType;
}

export function StudyTypeToJSON(value?: StudyType | null): any {
    return value as any;
}

