import {Dialog, styled} from "@mui/material";
import React, {Children, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import ExperimentsSelection from "../../pages/Study/Group/ExperimentsSelection";

interface ExperimentSelectionDialogProps {
    children: React.ReactNode;
    multiple?: boolean;
    setSelectedExperiments: (experiments: string[]) => void;
}

export default styled((props: ExperimentSelectionDialogProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedExperimentIds, setSelectedExperimentIds] = useState<string[]>([]);

    const handleSelectExperimentId = (pageExpIds: string[], selExpIds: string[]) => {
        if (!props.multiple) {
            setOpen(false);
            props.setSelectedExperiments(selExpIds);
        }
        const cloneSelectedExperimentIds = [...selectedExperimentIds];
        pageExpIds.forEach((pageExp) => {
            const selected = selExpIds.includes(pageExp);
            if (selected && !cloneSelectedExperimentIds.includes(pageExp)) {
                cloneSelectedExperimentIds.push(pageExp);
            } else if (!selected && cloneSelectedExperimentIds.includes(pageExp)) {
                const indexToRemove: number = cloneSelectedExperimentIds.indexOf(pageExp);
                if (indexToRemove !== -1) {
                    cloneSelectedExperimentIds.splice(indexToRemove, 1);
                }
            }
        });
        setSelectedExperimentIds(cloneSelectedExperimentIds);
        props.setSelectedExperiments(cloneSelectedExperimentIds);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            {Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, {onClick: handleOpen});
            })}
            <Dialog
                open={open}
                fullWidth
                // sx={{
                //     maxWidth: "85vw",
                // }}
                PaperProps={{
                    sx: {
                        maxWidth: "75vw",
                    },
                }}
                onClose={() => setOpen(false)}
            >
                {props.multiple ? (
                    <DialogTitle>Select experiments</DialogTitle>
                ) : (
                    <DialogTitle>Select experiment</DialogTitle>
                )}
                <ExperimentsSelection
                    studyType={"MAFFCON-BAYESIAN"}
                    selectedExperimentIds={selectedExperimentIds}
                    handleSelectExperimentId={handleSelectExperimentId}
                    multiple={props.multiple}
                />
            </Dialog>
        </>
    );
})((theme) => {
    return {};
});
