/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MeasurementHeader } from './MeasurementHeader';
import {
    MeasurementHeaderFromJSON,
    MeasurementHeaderFromJSONTyped,
    MeasurementHeaderToJSON,
} from './MeasurementHeader';

/**
 * 
 * @export
 * @interface ReadHeadersResponse
 */
export interface ReadHeadersResponse {
    /**
     * 
     * @type {Date}
     * @memberof ReadHeadersResponse
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadHeadersResponse
     */
    endDate: Date;
    /**
     * 
     * @type {Array<MeasurementHeader>}
     * @memberof ReadHeadersResponse
     */
    headers: Array<MeasurementHeader>;
}

/**
 * Check if a given object implements the ReadHeadersResponse interface.
 */
export function instanceOfReadHeadersResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "headers" in value;

    return isInstance;
}

export function ReadHeadersResponseFromJSON(json: any): ReadHeadersResponse {
    return ReadHeadersResponseFromJSONTyped(json, false);
}

export function ReadHeadersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadHeadersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': (new Date(json['start_date'])),
        'endDate': (new Date(json['end_date'])),
        'headers': ((json['headers'] as Array<any>).map(MeasurementHeaderFromJSON)),
    };
}

export function ReadHeadersResponseToJSON(value?: ReadHeadersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': (value.startDate.toISOString()),
        'end_date': (value.endDate.toISOString()),
        'headers': ((value.headers as Array<any>).map(MeasurementHeaderToJSON)),
    };
}

