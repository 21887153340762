// @ts-nocheck
import {IconButton, styled, Tooltip, useTheme} from "@mui/material";
import {PageButtonBar, PageContainer, PageContentBlock, PageHeader} from "../../helpers/HelperComponents";
import {useState, useEffect} from "react";
import loadingAnimation from "../../Animations/79836-graph-insights.gif";
import Typography from "@mui/material/Typography";
import {Link, useParams} from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AffinityPlot from "../../components/AffinityPlot";
import {history} from "../../App";
import {enqueueSnackbar} from "notistack";
import {snackbarWarning} from "../../helpers/error";
import {StudiesApi, StudySummarySchema, StudyType} from "../../api";
import {apiConfig} from "../../ApiConfig";
import OccupancyPlot from "../../components/OccupancyPlot";

const studiesApi = new StudiesApi(apiConfig);

export default styled((props: any): JSX.Element => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const {studyId} = useParams();
    const [analysisId, setAnalysisId] = useState<string>(null);
    const theme = useTheme();
    const [studyData, setStudyData] = useState<StudySummarySchema | undefined>(undefined);

    useEffect(() => {
        studiesApi
            .getById(studyId)
            .then((response) => {
                setStudyData(response);
                if (response.analysisStatus == "COMPLETED" && response.validAnalysis != null) {
                    setAnalysisId(response.validAnalysis);
                } else {
                    enqueueSnackbar("Study does not have an analysis", snackbarWarning);
                    history.push(`/study/${studyId}`);
                }
            })
            .catch((response) => {
                enqueueSnackbar(`Study does not exist.`, snackbarWarning);
                history.push(`/studies`);
            });
    }, [studyId]);

    return (
        <div className={props.className}>
            <PageContainer>
                <PageHeader text={"Study result"} />
                <PageContentBlock>
                    <PageButtonBar
                        backLink={
                            <Link to={`/study/${studyId}`}>
                                <Tooltip title={"Back to studies"}>
                                    <IconButton
                                        sx={{
                                            marginLeft: "10px",
                                            backgroundColor: "primary.main",
                                            color: "primary.contrastText",
                                            "&:hover": {backgroundColor: "primary.dark"},
                                        }}
                                    >
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        }
                    />
                    <div style={{position: "relative"}}>
                        {studyData &&
                            (studyData.extra === undefined || studyData.extra.studyType === undefined || studyData.extra?.studyType == "AFFINITY-CONCENTRATION")
                                ? (
                                <AffinityPlot
                                    studyId={studyId as string}
                                    analysisId={analysisId as string}
                                    loadCallback={() => {
                                        setLoaded(true);
                                    }}
                                />)
                                : (
                                <OccupancyPlot
                                    studyId={studyId as string}
                                    analysisId={analysisId as string}
                                    loadCallback={() => {
                                        setLoaded(true);
                                    }}
                                />)
                        }
                        {loaded || (
                            <div
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    top: "0px",
                                    left: "0px",
                                    backdropFilter: "blur(5px)",
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: theme.palette.background.paper,
                                        width: "800px",
                                        height: "640px",
                                        margin: "auto",
                                        opacity: "0.6",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            paddingBottom: "80px",
                                        }}
                                    >
                                        <Typography variant="h4">Calculating graph</Typography>
                                        <img
                                            style={{
                                                width: "150px",
                                                height: "150px",
                                                marginTop: "10px",
                                            }}
                                            src={loadingAnimation}
                                        />
                                        <Typography variant="h4">Please wait</Typography>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </PageContentBlock>
            </PageContainer>
        </div>
    );
})((theme) => {
    return {};
});
