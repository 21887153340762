import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import React, {ChangeEvent, useEffect} from "react";
import {Children, ReactNode, useState} from "react";
import {Beforeunload} from "react-beforeunload";
import {apiConfig} from "../../../ApiConfig";
import {
    CreateStudyExperimentsGroupRequest,
    ResponseError,
    StudiesApi,
    StudyExperimentsGroupSchema, StudySummarySchema,
    UpdateStudyExperimentsGroupRequest,
} from "../../../api";
import {useNavigate} from "react-router-dom";
import {handleNetworkError} from "../../../helpers/error";
import ExperimentsSelection from "./ExperimentsSelection";
import {ColorPicker} from "mui-color";
import {plotColors} from "../../../helpers/PlotColors";

const studiesApi = new StudiesApi(apiConfig);

interface GroupCreationModalProps {
    className?: String;
    children?: ReactNode;
    study: StudySummarySchema;
    studyId: string;
    groupNames: string[] | undefined;
    groupData?: StudyExperimentsGroupSchema;
    groupUpdatedCallback?: () => void;
}

export default function GroupCreationModal(props: GroupCreationModalProps) {
    const [open, setOpen] = useState(false);
    const scrollType: DialogProps["scroll"] = "paper";

    const [groupName, setGroupName] = useState("");
    const [groupNameErr, setGroupNameErr] = useState<boolean>(false);
    const [groupDescription, setGroupDescription] = useState("");
    const [color, setColor] = useState<string>(plotColors[Math.floor(Math.random() * plotColors.length)]);
    const [selectedExperimentIds, setSelectedExperimentIds] = useState<string[]>([]);
    const {studyId, groupData} = props;
    const navigate = useNavigate();

    const handleOpen = (e: any) => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleClose = () => {
        setGroupName("");
        setGroupDescription("");
        setColor(plotColors[Math.floor(Math.random() * plotColors.length)]);
        if (typeof groupData !== "undefined") {
            setSelectedExperimentIds(groupData.experiments ? groupData.experiments.map((exp) => exp.id as string) : []);
        } else {
            setSelectedExperimentIds([]);
        }
        if (groupData) {
            //removing new values when cancel
            setGroupName(groupData.name);
            if (groupData.description) {
                setGroupDescription(groupData.description);
            }
            setColor(groupData.color || plotColors[Math.floor(Math.random() * plotColors.length)]);
        }
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    useEffect(() => {
        if (typeof groupData !== "undefined") {
            if ("name" in groupData) {
                const newName = groupData.name;
                setGroupName(newName);
            }
            if (
                "description" in groupData &&
                typeof groupData.description === "string" &&
                groupData.description !== ""
            ) {
                const newDescription = groupData.description;
                setGroupDescription(newDescription);
            }
            setColor(groupData.color || plotColors[Math.floor(Math.random() * plotColors.length)]);
            setSelectedExperimentIds(groupData.experiments ? groupData.experiments.map((exp) => exp.id as string) : []);
        }
    }, [groupData]);

    const handleCreateGroup = (): void => {
        let createStudyExperimentsGroupRequest: CreateStudyExperimentsGroupRequest = {
            studyId: studyId,
            name: groupName,
            description: groupDescription,
            experiments: selectedExperimentIds,
            color: color,
        };

        studiesApi
            .createStudyExperimentsGroup(studyId, createStudyExperimentsGroupRequest)
            .then((data) => {
                setOpen(false);
                props.groupUpdatedCallback && props.groupUpdatedCallback();
                navigate(`/study/${studyId}`);
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
        handleClose();
    };

    const handleSaveGroup = (): void => {
        if (groupData === undefined) {
            return;
        }
        let updateStudyExperimentsGroupRequest: UpdateStudyExperimentsGroupRequest = {
            name: groupName,
            description: groupDescription,
            experiments: selectedExperimentIds,
            color: color,
        };

        studiesApi
            .updateStudyExperimentsGroup(studyId, groupData?.id, updateStudyExperimentsGroupRequest)
            .then((data: StudyExperimentsGroupSchema) => {
                setOpen(false);
                props.groupUpdatedCallback && props.groupUpdatedCallback();
                navigate(`/study/${studyId}/group/${data.id}`);
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    };

    const handleSelectExperimentId = (pageExpIds: string[], selExpIds: string[]) => {
        const cloneSelectedExperimentIds = [...selectedExperimentIds];
        pageExpIds.forEach((pageExp) => {
            const selected = selExpIds.includes(pageExp);
            if (selected && !cloneSelectedExperimentIds.includes(pageExp)) {
                cloneSelectedExperimentIds.push(pageExp);
            } else if (!selected && cloneSelectedExperimentIds.includes(pageExp)) {
                const indexToRemove: number = cloneSelectedExperimentIds.indexOf(pageExp);
                if (indexToRemove !== -1) {
                    cloneSelectedExperimentIds.splice(indexToRemove, 1);
                }
            }
        });
        setSelectedExperimentIds(cloneSelectedExperimentIds);
    };

    const handleGroupName = (event: ChangeEvent<HTMLInputElement>) => {
        setGroupName(event.target.value);
        const nameExists = props.groupNames?.includes(event.target.value);
        if (!groupNameErr && nameExists) setGroupNameErr(true);
        else if (groupNameErr && !nameExists) setGroupNameErr(false);
    };

    return (
        <>
            {Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, {onClick: handleOpen});
            })}
            {open && <Beforeunload onBeforeunload={() => "Careful, you may lose your data if you close!"} />}
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scrollType}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth={false}
                PaperProps={{sx: {width: "1200px", height: "100%"}}}
            >
                <DialogTitle id="scroll-dialog-title">
                    {groupData === undefined ? "New group" : "Edit group"}
                </DialogTitle>
                <DialogContent
                    dividers={scrollType === "paper"}
                    id="scroll-dialog-data"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <DialogContentText>
                        To proceed with creation, you need to define the group name and select the experiments below.
                    </DialogContentText>
                    <Box>
                        <Box sx={{minWidth: 100, paddingTop: "2rem", paddingBottom: "0.5rem"}}>
                            <TextField
                                required
                                error={groupNameErr}
                                helperText={groupNameErr ? "Group exists" : ""}
                                value={groupName}
                                fullWidth
                                onChange={handleGroupName}
                                label="Group name"
                                variant="outlined"
                                size="small"
                            />
                        </Box>
                        <Box
                            sx={{
                                minWidth: 120,
                                paddingTop: "0.5rem",
                                paddingBottom: "0.5rem",
                            }}
                        >
                            <TextField
                                value={groupDescription}
                                fullWidth
                                onChange={(input: React.ChangeEvent<HTMLInputElement>) => {
                                    setGroupDescription(input.target.value);
                                }}
                                label="Group description"
                                variant="outlined"
                                size="small"
                            />
                        </Box>
                        <Box
                            sx={{
                                minWidth: 120,
                                paddingTop: "0.5rem",
                                paddingBottom: "0.5rem",
                            }}
                        >
                            <Grid container>
                                <Grid item sx={{position: "relative", top: "0.4rem"}}>
                                    Group color
                                </Grid>
                                <Grid item>
                                    <ColorPicker
                                        value={color}
                                        disableTextfield={true}
                                        disableAlpha={true}
                                        hideTextfield={true}
                                        onChange={(c) => {
                                            setColor("#" + (c as any).hex);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <ExperimentsSelection
                                studyType={props.study.type}
                                selectedExperimentIds={selectedExperimentIds}
                                handleSelectExperimentId={handleSelectExperimentId}
                                multiple={true}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                    {groupData === undefined ? (
                        <Button
                            disabled={groupNameErr || groupName === "" || selectedExperimentIds.length < 1}
                            size="medium"
                            onClick={handleCreateGroup}
                            variant="contained"
                        >
                            CREATE NOW
                        </Button>
                    ) : (
                        <Button
                            disabled={groupNameErr || groupName === "" || selectedExperimentIds.length < 1}
                            size="medium"
                            onClick={handleSaveGroup}
                            variant="contained"
                        >
                            Save
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}
