/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ExperimentType = {
    SaffconBayesian: 'SAFFCON-BAYESIAN',
    SaffconNlls: 'SAFFCON-NLLS',
    NestBayesian: 'NEST-BAYESIAN',
    NestNlls: 'NEST-NLLS',
    MaffconBayesian: 'MAFFCON-BAYESIAN',
    MaffconNlls: 'MAFFCON-NLLS',
    BinaryBindingBayesian: 'BINARY-BINDING-BAYESIAN',
    BinaryBindingNlls: 'BINARY-BINDING-NLLS'
} as const;
export type ExperimentType = typeof ExperimentType[keyof typeof ExperimentType];


export function ExperimentTypeFromJSON(json: any): ExperimentType {
    return ExperimentTypeFromJSONTyped(json, false);
}

export function ExperimentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperimentType {
    return json as ExperimentType;
}

export function ExperimentTypeToJSON(value?: ExperimentType | null): any {
    return value as any;
}

