import * as React from "react";
import Box from "@mui/material/Box";
import {
    DataGrid,
    GridColDef,
    GridEventListener,
    GridFilterModel,
    GridSelectionModel,
    GridSortModel,
    getGridStringOperators,
} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import {ExperimentSummary} from "../../api";

import {useAppDispatch, useAppSelector} from "../../ReduxStore";
import {
    modifyExperimentPageRows,
    modifyExperimentPage,
    modifySortModel,
    modifyFilterModel,
} from "../../reducers/ExperimentsTable";
import {useTheme} from "@mui/material/styles";
import {useContext} from "react";
import {ExperimentContext} from "../../App";
import {formatStandardDate} from "../../helpers";
import {StatusInputValue} from "./StatusInputValue";
import {TypeInputValue} from "./TypeInputValue";
import CustomPagination from "../../components/CustomPagination";

interface DataTableProps {
    experiments: ExperimentSummary[];
    updateExperimentsPerPage: (
        pageNumber: number,
        pageSize: number,
        filter: string | undefined,
        sort: string | undefined
    ) => void;
    rowsNumber: number | undefined;
}

export default function DataTable(props: DataTableProps) {
    const navigate = useNavigate();

    const defaultSortModel: GridSortModel = [{field: "created", sort: "desc"}];
    const newSortModel: any = [{field: "created", sort: "asc"}];

    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
    const [sortModel, setSortModel] = React.useState<GridSortModel>(defaultSortModel);
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>();

    const state = useAppSelector((state) => state.experimentsTable);
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const experimentTypeMap = useContext(ExperimentContext);
    const rowsPerPageOptions = [10, 20, 50, 100];

    // used to save the sort model
    React.useEffect(() => {
        setSortModel(state.sortModel);
        setFilterModel(state.filterModel);
    }, []);

    const columns: GridColDef[] = [
        {
            field: "created",
            headerName: "Date created",
            width: 150,
            editable: false,
            sortable: true,
            filterable: false,
            hide: false,
            valueFormatter: ({value}) => formatStandardDate(value),
        },
        {
            field: "name",
            headerName: "Name",
            type: "string",
            width: 200,
            editable: false,
        },
        {
            field: "description",
            headerName: "Description",
            type: "string",
            width: 250,
            editable: false,
            sortable: true,
        },
        {
            field: "type",
            headerName: "Type",
            description: "",
            editable: false,
            sortable: true,
            filterable: true,
            width: 300,
            filterOperators: getGridStringOperators()
                .filter((operator) => operator.value === "equals")
                .map((operator) => {
                    return {
                        ...operator,
                        InputComponent: TypeInputValue,
                    };
                }),
            valueFormatter: ({value}) =>
                experimentTypeMap.nameMap[value as unknown as keyof typeof experimentTypeMap.nameMap],
        },
        {
            field: "analysisStatus",
            headerName: "Status",
            description: "",
            editable: false,
            sortable: false,
            filterable: true,
            width: 200,
            filterOperators: getGridStringOperators()
                .filter((operator) => operator.value === "equals")
                .map((operator) => {
                    return {
                        ...operator,
                        InputComponent: StatusInputValue,
                    };
                }),
            renderCell: (data) => {
                return data.value ? (
                    <span style={{textTransform: "capitalize"}}>{data.value.toLowerCase()}</span>
                ) : (
                    "Not run"
                );
            },
        },
    ];

    const handleRowClick: GridEventListener<"rowClick"> = (params) => {
        const clickedExperimentId = params.row.id;
        navigate(`/experiment/${clickedExperimentId}`);
    };

    function handlePageSize(newSize: number) {
        dispatch(modifyExperimentPageRows(newSize));
        props.updateExperimentsPerPage(
            newSize,
            state.page,
            getFilterModelOrUndefined(state.filterModel),
            getSortModelOrUndefined(state.sortModel)
        );
    }

    function handlePageChange(newPage: number) {
        dispatch(modifyExperimentPage(newPage));
        props.updateExperimentsPerPage(
            state.pageSize,
            newPage,
            getFilterModelOrUndefined(state.filterModel),
            getSortModelOrUndefined(state.sortModel)
        );
    }

    function getFilterModelOrUndefined(filterModel: GridFilterModel): string | undefined {
        let notRunFilter = undefined;
        if (
            filterModel &&
            filterModel.items.length > 0 &&
            filterModel.items[0].columnField === "analysisStatus" &&
            filterModel.items[0].value === "Not run"
        ) {
            notRunFilter = {
                columnField: "analysisStatus",
                id: filterModel.items[0].id,
                operatorValue: "isEmpty",
            };
        }
        return filterModel ? JSON.stringify(notRunFilter ? notRunFilter : filterModel.items[0]) : undefined;
    }

    function getSortModelOrUndefined(sortModel: GridSortModel): string | undefined {
        return sortModel ? JSON.stringify(sortModel[0]) : undefined;
    }

    return (
        <Box
            sx={{
                height: "auto",
                width: "100%",
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <DataGrid
                sx={{
                    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
                        display: "none",
                    },
                    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                        color: theme.palette.text.primary,
                    },
                    "& .MuiDataGrid-cell:focus,& .MuiDataGrid-cell:active, & .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader:focus,& .MuiDataGrid-columnHeader:active, & .MuiDataGrid-columnHeader:focus-within":
                        {
                            outline: "none",
                        },
                    "& .MuiDataGrid-cell:nth-of-type(6)": {
                        textAlign: "right",
                    },
                    border: "none",
                    color: theme.palette.text.primary,
                    "& >.MuiDataGrid-main": {
                        "&>.MuiDataGrid-columnHeaders": {
                            borderColor: theme.palette.background.default,
                        },
                        "& div div div div >.MuiDataGrid-cell": {
                            borderColor: theme.palette.background.default,
                        },
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderColor: theme.palette.background.default,
                    },
                    "& .MuiDataGrid-row": {cursor: "pointer"},
                }}
                checkboxSelection={false}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                onSortModelChange={(newSortModel) => {
                    setSortModel(newSortModel);
                    dispatch(modifySortModel(newSortModel));
                    props.updateExperimentsPerPage(
                        state.pageSize,
                        state.page,
                        getFilterModelOrUndefined(state.filterModel),
                        getSortModelOrUndefined(newSortModel)
                    );
                }}
                onFilterModelChange={(newFilterModel) => {
                    if (newFilterModel.items.length > 0) {
                        //assume there is only one filter
                        setFilterModel(newFilterModel);
                        dispatch(modifyFilterModel(newFilterModel));
                        props.updateExperimentsPerPage(
                            state.pageSize,
                            state.page,
                            getFilterModelOrUndefined(newFilterModel),
                            getSortModelOrUndefined(state.sortModel)
                        );
                    }
                }}
                paginationMode="server"
                selectionModel={selectionModel}
                sortModel={sortModel}
                filterModel={filterModel}
                autoHeight={true}
                rows={props.experiments}
                columns={columns}
                pageSize={state.pageSize}
                page={state.page}
                rowCount={props.rowsNumber ? props.rowsNumber : 0}
                rowsPerPageOptions={rowsPerPageOptions}
                disableSelectionOnClick
                components={{
                    Footer: () => (
                        <CustomPagination rowsCount={props.rowsNumber} rowsPerPageOptions={rowsPerPageOptions} />
                    ),
                }}
                onRowClick={handleRowClick}
                onPageSizeChange={handlePageSize}
                onPageChange={handlePageChange}
            />
        </Box>
    );
}
