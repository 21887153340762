/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Extra } from './Extra';
import {
    ExtraFromJSON,
    ExtraFromJSONTyped,
    ExtraToJSON,
} from './Extra';
import type { StudyExperimentsGroupSchema } from './StudyExperimentsGroupSchema';
import {
    StudyExperimentsGroupSchemaFromJSON,
    StudyExperimentsGroupSchemaFromJSONTyped,
    StudyExperimentsGroupSchemaToJSON,
} from './StudyExperimentsGroupSchema';

/**
 * 
 * @export
 * @interface StudySchema
 */
export interface StudySchema {
    /**
     * 
     * @type {string}
     * @memberof StudySchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StudySchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StudySchema
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof StudySchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudySchema
     */
    updated?: Date;
    /**
     * 
     * @type {Array<StudyExperimentsGroupSchema>}
     * @memberof StudySchema
     */
    groups?: Array<StudyExperimentsGroupSchema>;
    /**
     * 
     * @type {string}
     * @memberof StudySchema
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof StudySchema
     */
    validAnalysis?: string;
    /**
     * 
     * @type {Extra}
     * @memberof StudySchema
     */
    extra?: Extra;
}

/**
 * Check if a given object implements the StudySchema interface.
 */
export function instanceOfStudySchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function StudySchemaFromJSON(json: any): StudySchema {
    return StudySchemaFromJSONTyped(json, false);
}

export function StudySchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudySchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'created': (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'groups': !exists(json, 'groups') ? undefined : ((json['groups'] as Array<any>).map(StudyExperimentsGroupSchemaFromJSON)),
        'type': json['type'],
        'validAnalysis': !exists(json, 'valid_analysis') ? undefined : json['valid_analysis'],
        'extra': !exists(json, 'extra') ? undefined : ExtraFromJSON(json['extra']),
    };
}

export function StudySchemaToJSON(value?: StudySchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'created': (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'groups': value.groups === undefined ? undefined : ((value.groups as Array<any>).map(StudyExperimentsGroupSchemaToJSON)),
        'type': value.type,
        'valid_analysis': value.validAnalysis,
        'extra': ExtraToJSON(value.extra),
    };
}

