/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadReport } from './FileUploadReport';
import {
    FileUploadReportFromJSON,
    FileUploadReportFromJSONTyped,
    FileUploadReportToJSON,
} from './FileUploadReport';

/**
 * 
 * @export
 * @interface CreateMeasurementResponse
 */
export interface CreateMeasurementResponse {
    /**
     * 
     * @type {{ [key: string]: FileUploadReport; }}
     * @memberof CreateMeasurementResponse
     */
    files: { [key: string]: FileUploadReport; };
    /**
     * 
     * @type {number}
     * @memberof CreateMeasurementResponse
     */
    totalErrors: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMeasurementResponse
     */
    totalValid: number;
}

/**
 * Check if a given object implements the CreateMeasurementResponse interface.
 */
export function instanceOfCreateMeasurementResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "files" in value;
    isInstance = isInstance && "totalErrors" in value;
    isInstance = isInstance && "totalValid" in value;

    return isInstance;
}

export function CreateMeasurementResponseFromJSON(json: any): CreateMeasurementResponse {
    return CreateMeasurementResponseFromJSONTyped(json, false);
}

export function CreateMeasurementResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMeasurementResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'files': (mapValues(json['files'], FileUploadReportFromJSON)),
        'totalErrors': json['total_errors'],
        'totalValid': json['total_valid'],
    };
}

export function CreateMeasurementResponseToJSON(value?: CreateMeasurementResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'files': (mapValues(value.files, FileUploadReportToJSON)),
        'total_errors': value.totalErrors,
        'total_valid': value.totalValid,
    };
}

