import React, {useEffect, useState} from "react";
import {useBeforeunload} from "react-beforeunload";
import PubSub from "pubsub-js";

interface EventListenerFilters {
    [key: string]: string | string[];
}

interface EventListenerProps {
    children?: React.ReactNode;
    topic: string | string[];
    prop: string;
    filters?: EventListenerFilters;
}

function EventListener(props: EventListenerProps) {
    const [lastMessage, setLastMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        let listener: string[] = [];

        if (Array.isArray(props.topic)) {
            props.topic.map((t) => {
                listener.push(PubSub.subscribe(t, onMessage));
            });
        } else {
            listener.push(PubSub.subscribe(props.topic, onMessage));
        }

        return () => {
            listener.map((l) => {
                PubSub.unsubscribe(l);
            });
        };
    }, [props.topic]);

    function onMessage(topic: any, message: any) {
        let msg = JSON.parse(message);

        if (checkFilters(msg)) {
            setLastMessage(message);
        }
    }

    function checkFilters(msg: any) {
        if (props.filters === undefined || Object.keys(props.filters).length == 0) {
            return true;
        }

        var accepted = false;

        Object.keys(props.filters).map((k) => {
            if (msg.hasOwnProperty(k)) {
                // @ts-ignore
                if (Array.isArray(props.filters[k])) {
                    // @ts-ignore
                    props.filters[k].map((v) => {
                        if (msg[k] === v) {
                            accepted = true;
                        }
                    });
                } else {
                    // @ts-ignore
                    if (msg[k] === props.filters[k]) {
                        accepted = true;
                    }
                }
            }
        });

        return accepted;
    }

    let childProps: any = {};
    childProps[props.prop] = lastMessage;

    return (
        <>
            {React.Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, childProps);
            })}
        </>
    );
}

export default EventListener;
