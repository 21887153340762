import Box from "@mui/material/Box";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import {ExperimentSchema} from "../../../api";
import {useTheme} from "@mui/material/styles";
import {useContext, useState} from "react";
import {ExperimentContext} from "../../../App";
import {formatStandardDate, capitalizeFirstLetterOrNotRunWhenNull} from "../../../helpers";
import CustomPagination from "../../../components/CustomPagination";
import {sort} from "mathjs";

interface ExperimentsTableProps {
    experiments: ExperimentSchema[] | undefined;
    hideFooter?: boolean;
    columns?: {[key: number]: GridColDef};
}

export default function ExperimentsTable(props: ExperimentsTableProps) {
    const experimentTypeMap = useContext(ExperimentContext);

    const defaultColumns: GridColDef[] = [
        {
            field: "created",
            headerName: "Date created",
            width: 150,
            editable: false,
            sortable: true,
            filterable: false,
            hide: false,
            valueFormatter: ({value}) => formatStandardDate(value),
        },
        {
            field: "name",
            headerName: "Name",
            type: "string",
            width: 300,
            editable: false,
        },
        {
            field: "description",
            headerName: "Description",
            type: "string",
            width: 300,
            editable: false,
            sortable: true,
        },
        {
            field: "type",
            headerName: "Type",
            description: "",
            editable: false,
            sortable: true,
            filterable: false,
            width: 300,
            valueFormatter: ({value}) => experimentTypeMap[value as unknown as keyof typeof experimentTypeMap.nameMap],
        },
        {
            field: "analysisStatus",
            headerName: "Status",
            description: "",
            editable: false,
            sortable: true,
            filterable: false,
            width: 150,
            valueFormatter: ({value}) => capitalizeFirstLetterOrNotRunWhenNull(value),
        },
    ];

    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [columns, setColumns] = useState<{[key: number]: GridColDef}>({...defaultColumns, ...(props.columns || {})});
    const theme = useTheme();
    const rowsPerPageOptions = [10, 20, 50, 100];

    function handlePageSize(newSize: number) {
        setPageSize(newSize);
    }

    function handlePageChange(newPage: number) {
        setPage(newPage);
    }

    return (
        <Box
            sx={{
                height: "auto",
                width: "100%",
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <DataGrid
                sx={{
                    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
                        display: "none",
                    },
                    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                        color: theme.palette.text.primary,
                    },
                    "& .MuiDataGrid-cell:focus,& .MuiDataGrid-cell:active, & .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader:focus,& .MuiDataGrid-columnHeader:active, & .MuiDataGrid-columnHeader:focus-within":
                        {
                            outline: "none",
                        },
                    "& .MuiDataGrid-cell:nth-of-type(6)": {
                        textAlign: "right",
                    },
                    border: "none",
                    color: theme.palette.text.primary,
                    "& >.MuiDataGrid-main": {
                        "&>.MuiDataGrid-columnHeaders": {
                            borderColor: theme.palette.background.default,
                        },
                        "& div div div div >.MuiDataGrid-cell": {
                            borderColor: theme.palette.background.default,
                        },
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderColor: theme.palette.background.default,
                    },
                    "& .MuiDataGrid-row": {cursor: "default"},
                    "& .MuiDataGrid-row:hover": {
                        backgroundColor: theme.palette.background.paper,
                    },
                }}
                checkboxSelection={false}
                autoHeight={true}
                rows={props.experiments || []}
                columns={Array.from(Object.entries(columns).sort((a, b) => parseInt(a[0]) - parseInt(b[0]))).map(
                    (e) => e[1]
                )}
                pageSize={pageSize}
                page={page}
                rowCount={props.experiments?.length}
                rowsPerPageOptions={rowsPerPageOptions}
                disableSelectionOnClick
                onPageSizeChange={handlePageSize}
                onPageChange={handlePageChange}
                hideFooter={props.hideFooter}
                components={{
                    Footer: () => (
                        <CustomPagination
                            rowsCount={props.experiments?.length || 10}
                            rowsPerPageOptions={rowsPerPageOptions}
                        />
                    ),
                }}
            />
        </Box>
    );
}
