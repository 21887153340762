import Box from "@mui/material/Box";
import {
    DataGrid,
    GridColDef,
    GridEventListener,
    GridFilterModel,
    GridSelectionModel,
    GridSortModel,
} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import {StudyExperimentsGroupSchema, StudySchema, StudySummarySchema} from "../../api";
import {useTheme} from "@mui/material/styles";
import {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomPagination from "../../components/CustomPagination";

interface StudyExperimentsGroupSchemaWithValid extends StudyExperimentsGroupSchema {
    valid: boolean;
}

interface GroupsTableProps {
    study: StudySummarySchema;
}

export default function GroupsTable(props: GroupsTableProps) {
    const navigate = useNavigate();

    const defaultSortModel: GridSortModel = [{field: "name", sort: "asc"}];
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [groups, setGroups] = useState<StudyExperimentsGroupSchemaWithValid[]>([]);
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const theme = useTheme();
    const rowsPerPageOptions = [10, 20, 50, 100];

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            type: "string",
            width: 300,
            editable: false,
            renderCell: (d) => {
                return (
                    <Grid container gap="10px" flexWrap={"nowrap"}>
                        <Grid
                            item
                            sx={{
                                height: "18px",
                                width: "18px",
                                minWidth: "18px",
                                backgroundColor: d.row.color,
                                marginLeft: "10px",
                                borderRadius: "4px",
                                boxShadow: `2px 2px ${theme.palette.secondary.main}`,
                            }}
                        ></Grid>
                        <Grid
                            item
                            whiteSpace={"nowrap"}
                            sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                textAlign: "left",
                            }}
                        >
                            {d.row.name}
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: "description",
            headerName: "Description",
            type: "string",
            width: 350,
            editable: false,
            sortable: true,
        },
        {
            field: "valid",
            headerName: "Valid",
            type: "boolean",
            width: 200,
            editable: false,
            sortable: true,
        },
    ];

    useEffect(() => {
        if (props.study.groups) {
            const newGroups = props.study.groups.map((g) => ({
                ...g,
                valid:
                    g.experiments?.length !== undefined &&
                    g.experiments.length > 0 &&
                    g.experiments.every((experiment: any) => experiment.analysisStatus === "COMPLETED"),
            }));
            setGroups(newGroups);
        }
    }, [props.study.groups]);

    const handleRowClick: GridEventListener<"rowClick"> = (params) => {
        const clickedStudyId = params.row.id;
        navigate(`/study/${props.study.id}/group/${clickedStudyId}`);
    };

    function handlePageSize(newSize: number) {
        setPageSize(newSize);
    }

    function handlePageChange(newPage: number) {
        setPage(newPage);
    }

    return (
        <Box
            sx={{
                height: "auto",
                width: "100%",
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <DataGrid
                sx={{
                    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
                        display: "none",
                    },
                    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                        color: theme.palette.text.primary,
                    },
                    "& .MuiDataGrid-cell:focus,& .MuiDataGrid-cell:active, & .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader:focus,& .MuiDataGrid-columnHeader:active, & .MuiDataGrid-columnHeader:focus-within":
                        {
                            outline: "none",
                        },
                    "& .MuiDataGrid-cell:nth-of-type(6)": {
                        textAlign: "right",
                    },
                    border: "none",
                    color: theme.palette.text.primary,
                    "& >.MuiDataGrid-main": {
                        "&>.MuiDataGrid-columnHeaders": {
                            borderColor: theme.palette.background.default,
                        },
                        "& div div div div >.MuiDataGrid-cell": {
                            borderColor: theme.palette.background.default,
                        },
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderColor: theme.palette.background.default,
                    },
                    "& .MuiDataGrid-row": {cursor: "pointer"},
                }}
                checkboxSelection={false}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                onSortModelChange={(newSortModel) => {
                    setSortModel(newSortModel);
                }}
                onFilterModelChange={(newFilterModel) => {
                    if (newFilterModel.items.length > 0) {
                        setFilterModel(newFilterModel);
                    }
                }}
                selectionModel={selectionModel}
                sortModel={sortModel}
                filterModel={filterModel}
                autoHeight={true}
                rows={groups || []}
                columns={columns}
                pageSize={pageSize}
                page={page}
                rowCount={groups?.length}
                rowsPerPageOptions={rowsPerPageOptions}
                disableSelectionOnClick
                onRowClick={handleRowClick}
                onPageSizeChange={handlePageSize}
                onPageChange={handlePageChange}
                components={{
                    Footer: () => (
                        <CustomPagination rowsCount={groups?.length || 10} rowsPerPageOptions={rowsPerPageOptions} />
                    ),
                }}
            />
        </Box>
    );
}
