import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";

interface MeasurementsTableState {
    pageSize: number;
    page: number;
    startDate: string;
    endDate: string;
}

const initialState = {pageSize: 10, page: 0} as MeasurementsTableState;

const MeasurementsTableStateSlice = createSlice({
    name: "MeasurementsTableState",
    initialState,
    reducers: {
        modifyMeasurementPageRows(state, action: PayloadAction<number>) {
            state.pageSize = action.payload;
        },
        modifyMeasurementPage(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        modifyStartDate(state, action: PayloadAction<string>) {
            state.startDate = action.payload;
        },
        modifyEndDate(state, action: PayloadAction<string>) {
            state.endDate = action.payload;
        },
    },
});

export const {modifyMeasurementPageRows, modifyMeasurementPage, modifyStartDate, modifyEndDate} =
    MeasurementsTableStateSlice.actions;
export default MeasurementsTableStateSlice.reducer;
