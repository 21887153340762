import React, {useState, useEffect} from "react";
import {Alert, Fade} from "@mui/material";
import {ExperimentSummary, ExperimentType, MeasurementSchema} from "../../api";

interface AnalysisInputDataValidationProps {
    experimentData: ExperimentSummary;
    experimentMeasurements: MeasurementSchema[];
    isAnalysisInputDataValid: boolean | null;
    setIsAnalysisInputDataValid: (isValid: boolean) => void;
}

const AnalysisInputDataValidation = (props: AnalysisInputDataValidationProps) => {
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    const validateInputData = () => {
        const experimentType: ExperimentType = props.experimentData.type;
        let errors: (string | undefined)[] = [] as (string | undefined)[];

        // Basic check
        errors.push(checkIfAnyMeasurements());

        if (
            experimentType === ExperimentType.BinaryBindingBayesian ||
            experimentType === ExperimentType.BinaryBindingNlls ||
            experimentType === ExperimentType.NestBayesian ||
            experimentType === ExperimentType.NestNlls
        ) {
            errors.push(checkIfAnyUnlabeledConcentrationValue());
        }

        if (
            experimentType === ExperimentType.MaffconBayesian ||
            experimentType === ExperimentType.MaffconNlls ||
            experimentType === ExperimentType.SaffconBayesian ||
            experimentType === ExperimentType.SaffconNlls
        ) {
            errors.push(checkIfAnyComplexMixtureDilutionValue());
        }

        errors = errors.filter((e) => typeof e === "string"); // remove empty values
        setValidationErrors(errors as string[]);
        props.setIsAnalysisInputDataValid(errors.length === 0);
    };

    const checkIfAnyMeasurements = (): string | undefined => {
        // Check if there are any measurements in the experiment
        if (props.experimentMeasurements.length < 1) {
            return "There are no measurements in this experiment.";
        }
    };
    const checkIfAnyUnlabeledConcentrationValue = (): string | undefined => {
        // Checks if unlabeled concentration is defined for any of experiment's measurements
        if (
            props.experimentMeasurements &&
            !props.experimentMeasurements.some(
                (e) => e.unlabeledConcentration !== null && e.unlabeledConcentration !== undefined
            )
        ) {
            return "There are no measurements that have unlabeled concentration values defined";
        }
    };

    const checkIfAnyComplexMixtureDilutionValue = (): string | undefined => {
        // Checks if complex mixture dilution is defined for any of experiment's measurements
        if (
            props.experimentMeasurements &&
            !props.experimentMeasurements.some(
                (e) => e.complexMixtureDilution !== null && e.complexMixtureDilution !== undefined
            )
        ) {
            return "There are no measurements that have complex mixture dilution values defined";
        }
    };

    useEffect(validateInputData, [props.experimentData, props.experimentMeasurements]);

    return (
        <>
            {props.isAnalysisInputDataValid === false && validationErrors.length > 0 && (
                <div
                    style={{
                        width: "fit-content",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                    data-testid="analysis-input-data-validation"
                >
                    {validationErrors.map((error, index) => (
                        <Fade in key={index}>
                            <Alert severity="warning">{error}</Alert>
                        </Fade>
                    ))}
                </div>
            )}
        </>
    );
};

export default AnalysisInputDataValidation;
