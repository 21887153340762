import {InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {GridFilterInputValueProps} from "@mui/x-data-grid";

export function StatusInputValue(props: GridFilterInputValueProps) {
    const {item, applyValue, focusElementRef} = props;

    const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
        const newValue = event.target.value as string; // Assuming the value is of type string
        if (newValue !== "") {
            applyValue({...item, value: newValue});
        }
    };

    const defaultValue = item.value ?? "";
    const options = [
        {key: "Completed", value: "Completed"},
        {key: "Failed", value: "Failed"},
        {key: "Not run", value: "Not run"},
    ];

    return (
        <>
            <InputLabel id="select-label">Value</InputLabel>
            <Select labelId="select-label" id="MultiInputLabelRoot" value={defaultValue} onChange={handleSelectChange}>
                {options.map((option) => (
                    <MenuItem key={option.key} value={option.value}>
                        {option.value}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}
