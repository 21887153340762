import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";
import {MeasurementHeader, MeasurementSchema, WhoAmIResponse} from "../api";
import {reduxStore, RootState} from "../ReduxStore";

export interface SectionState {
    unrolled: boolean;
    selected?: boolean;
}

export interface ItemState {
    selected: boolean;
}

export interface ListState {
    sections: {[key: string]: SectionState};
    items: {[key: string]: ItemState};
}

const initialState: ListState = {
    sections: {},
    items: {},
} as ListState;

export function setListData(reducer: string, payload: ListState) {
    return {
        type: reducer + "/setListData",
        payload: payload,
    };
}

function measurementListReducer(state = initialState, action: any) {
    if (action.type === PURGE) {
        return initialState;
    }

    let pieces: string[] = action.type.split("/", 2);
    if (pieces.length == 1) return state;

    if (pieces[1] == "setListData") {
        // This string must be unique across all the reducers!
        if (state !== action.payload) {
            return {
                ...state,
                ...action.payload,
            };
        }
    }

    return state;
}

export default measurementListReducer;
