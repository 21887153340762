import {styled} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";

interface FileDownloadProps {
    className?: string;
    children?: React.ReactNode;
    blobSource?: string;
    blob?: Blob;
    directUrl?: string;
    filename?: string;
}

export default styled((props: FileDownloadProps): JSX.Element => {
    const [data, setData] = useState<Blob | undefined>(undefined);

    const startDownload = () => {
        let filename: string = props.directUrl?.split("/").pop() || "";
        if (props.directUrl) {
            download(props.directUrl, props.filename ? props.filename : filename);
        } else if (props.blobSource) {
            fetch(props.blobSource, {credentials: "include"}).then((resp) => {
                resp.blob().then((blob) => {
                    setData(blob);
                });
            });
        } else if (props.blob) {
            const url = URL.createObjectURL(props.blob);
            let filename: string = props.directUrl?.split("/").pop() || "";
            download(url, props.filename ? props.filename : filename);

            URL.revokeObjectURL(url);
        }
    };

    function download(url: string, filename: string) {
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    useEffect(() => {
        if (data === undefined) {
            return;
        }
        const url = URL.createObjectURL(new Blob([data]));
        let filename: string = props.directUrl?.split("/").pop() || "";
        download(url, props.filename ? props.filename : filename);

        URL.revokeObjectURL(url);
    }, [data]);

    return (
        <div className={props.className}>
            {React.Children?.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, {onClick: startDownload});
            })}
        </div>
    );
})((theme) => {
    return {};
});
