import React from "react";
import {styled} from "@mui/material";
import {useTheme} from "@mui/material/styles";

interface TopBarRightProps {
    className?: string;
    pixelWidth: number;
    pixelHeight: number;
}

function TopBarRight(props: TopBarRightProps): JSX.Element {
    const theme = useTheme();

    return (
        <svg
            className={props.className}
            width={`${props.pixelWidth}px`}
            height={`${props.pixelHeight}px`}
            viewBox={`0 0 ${props.pixelWidth} ${props.pixelHeight}`}
            version="1.1"
            id="svg5"
        >
            <g id="layer1">
                <path
                    d={`M ${props.pixelHeight} 0
                        h ${props.pixelWidth - props.pixelHeight}
                        v ${props.pixelHeight}
                        H 0
                        Z`}
                    id="rect162"
                    fill={`${theme.palette.primary.main}`}
                />
            </g>
        </svg>
    );
}

export default styled(TopBarRight)``;
