import React, {useState} from "react";
import {Grid, IconButton, styled, Tooltip, Typography} from "@mui/material";
import {Link, To} from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {isFunction} from "./index";
import {formatStandardDate} from "./index";
import {AuthApi, ResponseError} from "../api";
import {apiConfig} from "../ApiConfig";
import {useAppDispatch, useAppSelector} from "../ReduxStore";
import {updateUserNames} from "../reducers/UserNames";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import Box from "@mui/material/Box";
import HistoryIcon from "@mui/icons-material/History";
import {useTheme} from "@mui/material/styles";

const authApi = new AuthApi(apiConfig);

interface PageHeaderProps {
    className?: string;
    text?: string;
    children?: React.ReactNode | React.ReactNode[];
}
export const PageHeader = styled((props: PageHeaderProps): JSX.Element => {
    return (
        <div className={props.className}>
            {props.text !== undefined && (
                <Typography align={"center"} noWrap={true} variant={"h4"}>
                    {props.text}
                </Typography>
            )}
            {props.children !== undefined && <div style={{width: "100%", textAlign: "center"}}>{props.children}</div>}
        </div>
    );
})((theme) => {
    return {
        padding: "10px",
        backgroundColor: theme.theme.palette.background.paper,
        width: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "2rem",
        marginTop: "2rem",
        h4: {
            width: "100%",
        },
    };
});

interface PageContainerProps {
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
}
export const PageContainer = styled((props: PageContainerProps): JSX.Element => {
    return <div className={props.className}>{props.children}</div>;
})((theme) => {
    return {
        width: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        "& > .PageContentBlock:last-of-type": {
            paddingBottom: "0px",
        },
        textAlign: "center",
    };
});

interface PageContentBlockProps {
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
    key?: string;
}

export const PageContentBlock = styled((props: PageContentBlockProps): JSX.Element => {
    return (
        <div key={props.key} className={`${props.className} PageContentBlock`}>
            {props.children}
        </div>
    );
})((theme) => {
    return {
        textAlign: "center",
        backgroundColor: theme.theme.palette.background.paper,
        marginBottom: "2rem",
        paddingBottom: "2rem",
        width: "100%",
    };
});

interface PageButtonBarProps {
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
    backLink?: To | JSX.Element | Function;
    backLinkTooltip?: string;
}

export const PageButtonBar = styled((props: PageButtonBarProps): JSX.Element => {
    return (
        <Grid container className={props.className}>
            <Grid item xs="auto">
                {props.backLink &&
                    (React.isValidElement(props.backLink) ? (
                        props.backLink
                    ) : !isFunction(props.backLink) ? (
                        <Link to={props.backLink as To}>
                            <Tooltip title={props.backLinkTooltip || "Back"}>
                                <IconButton>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </Tooltip>
                        </Link>
                    ) : (
                        <Tooltip title={props.backLinkTooltip || "Back"}>
                            <IconButton
                                onClick={() => {
                                    props.backLink && (props.backLink as Function)();
                                }}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        </Tooltip>
                    ))}
            </Grid>
            <Grid item xs></Grid>
            {Array.isArray(props.children) ? (
                props.children.map((e, id) => (
                    <Grid item xs="auto" key={id}>
                        {e}
                    </Grid>
                ))
            ) : (
                <Grid item xs="auto" key={props.className}>
                    {props.children}
                </Grid>
            )}
        </Grid>
    );
})((theme) => {
    return {
        ">:last-of-type": {
            paddingRight: "15px",
        },
        button: {
            marginLeft: "10px",
            marginTop: "10px",
            marginBottom: "5px",
            backgroundColor: theme.theme.palette.primary.main,
            color: theme.theme.palette.primary.contrastText,
            "&:hover": {backgroundColor: theme.theme.palette.primary.dark},
        },
    };
});

interface StandardDateTimeDisplayProps {
    className?: string;
    value: Date;
}

export const StandardDateTimeDisplay = styled((props: StandardDateTimeDisplayProps): JSX.Element => {
    return <span className={props.className}>{formatStandardDate(props.value)}</span>;
})``;

export const UserName = styled((props: {user: string}) => {
    const userNames = useAppSelector((state) => state.userNames);
    const dispatch = useAppDispatch();
    const [userName, setUserName] = useState<string>("");

    if (userName != "") {
        return <>{userName}</>;
    }
    if (userNames.userNameMap.hasOwnProperty(props.user)) {
        return <>{userNames.userNameMap[props.user]}</>;
    }
    const user_id = props.user;

    authApi
        .getUserDetails(props.user)
        .then((resp) => {
            setUserName(resp.fullname);
            let userNamesCopy = {...userNames.userNameMap};
            userNamesCopy[props.user] = resp.fullname;
            dispatch(updateUserNames(userNamesCopy));
        })
        .catch((resp: ResponseError) => {
            if (resp.response.status == 404) {
                setUserName("Unknown");
            }
        });

    return <>{userName}</>;
})``;

const IconParking: React.FC<any> = (props: any): JSX.Element => {
    const theme = useTheme();

    return (
        <>
            <IconButton>
                <VisibilityIcon
                    sx={{position: "absolute", left: "16px", top: "16px", color: theme.palette.primary.main}}
                />
                <VisibilityIcon sx={{position: "absolute", left: "16px", top: "16px", transform: "scale(75%)"}} />
                <FormatAlignJustifyIcon />
            </IconButton>
            <IconButton>
                <Box
                    sx={{
                        width: "18px",
                        height: "18px",
                        backgroundColor: theme.palette.primary.main,
                        position: "absolute",
                        left: "18px",
                        top: "17px",
                        color: theme.palette.primary.main,
                        borderRadius: "12px",
                        zIndex: "900",
                    }}
                />
                <HistoryIcon
                    sx={{position: "absolute", left: "14px", top: "14px", transform: "scale(75%)", zIndex: "1000"}}
                />
                <FormatAlignJustifyIcon />
            </IconButton>
            <IconButton>
                <Box
                    sx={{
                        width: "18px",
                        height: "18px",
                        backgroundColor: theme.palette.primary.main,
                        position: "absolute",
                        left: "18px",
                        top: "17px",
                        color: theme.palette.primary.main,
                        borderRadius: "12px",
                        zIndex: "900",
                    }}
                />
                <HistoryIcon
                    sx={{position: "absolute", left: "14px", top: "14px", transform: "scale(75%)", zIndex: "1000"}}
                />
                <VisibilityIcon sx={{position: "relative", top: "-2px", left: "-1px"}} />
            </IconButton>
        </>
    );
};
// We have no default export here
export default {};
