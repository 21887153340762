/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateStudyAnalysisRequest,
  CreateStudyExperimentsGroupRequest,
  CreateStudyRequest,
  GetStudyCountResponse,
  HTTPValidationError,
  StudyAnalysisSchema,
  StudyCreateResponseSchema,
  StudyExperimentsGroupSchema,
  StudySchema,
  StudySummarySchema,
  UpdateStudyExperimentsGroupRequest,
  UpdateStudyRequest,
} from '../models/index';
import {
    CreateStudyAnalysisRequestFromJSON,
    CreateStudyAnalysisRequestToJSON,
    CreateStudyExperimentsGroupRequestFromJSON,
    CreateStudyExperimentsGroupRequestToJSON,
    CreateStudyRequestFromJSON,
    CreateStudyRequestToJSON,
    GetStudyCountResponseFromJSON,
    GetStudyCountResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    StudyAnalysisSchemaFromJSON,
    StudyAnalysisSchemaToJSON,
    StudyCreateResponseSchemaFromJSON,
    StudyCreateResponseSchemaToJSON,
    StudyExperimentsGroupSchemaFromJSON,
    StudyExperimentsGroupSchemaToJSON,
    StudySchemaFromJSON,
    StudySchemaToJSON,
    StudySummarySchemaFromJSON,
    StudySummarySchemaToJSON,
    UpdateStudyExperimentsGroupRequestFromJSON,
    UpdateStudyExperimentsGroupRequestToJSON,
    UpdateStudyRequestFromJSON,
    UpdateStudyRequestToJSON,
} from '../models/index';

export interface CreateStudyOperationRequest {
    createStudyRequest: CreateStudyRequest;
}

export interface CreateStudyAnalysisOperationRequest {
    studyId: string;
    createStudyAnalysisRequest: CreateStudyAnalysisRequest;
}

export interface CreateStudyExperimentsGroupOperationRequest {
    studyId: string;
    createStudyExperimentsGroupRequest: CreateStudyExperimentsGroupRequest;
}

export interface DeleteStudyExperimentsGroupRequest {
    studyId: string;
    studyGroupId: string;
}

export interface DeleteStudySoftRequest {
    studyId: string;
}

export interface GetByIdRequest {
    studyId: string;
}

export interface GetGroupByIdRequest {
    studyId: string;
    studyGroupId: string;
}

export interface GetStudyAnalysisRequest {
    studyId: string;
}

export interface GetStudyAnalysisZippedRequest {
    studyId: string;
}

export interface GetStudyCountRequest {
    queryFilter?: string;
}

export interface GetStudyOutcomeWithIdRequest {
    studyId: string;
    resourceName: string;
    analysisId: string;
}

export interface ListStudiesRequest {
    start?: number;
    count?: number;
    queryFilter?: string;
    sort?: string;
}

export interface UpdateStudyOperationRequest {
    studyId: string;
    updateStudyRequest: UpdateStudyRequest;
}

export interface UpdateStudyExperimentsGroupOperationRequest {
    studyId: string;
    studyGroupId: string;
    updateStudyExperimentsGroupRequest: UpdateStudyExperimentsGroupRequest;
}

/**
 * 
 */
export class StudiesApi extends runtime.BaseAPI {

    /**
     * Id of the new study
     * Create Study
     */
    async createStudyRaw(requestParameters: CreateStudyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StudyCreateResponseSchema>> {
        if (requestParameters.createStudyRequest === null || requestParameters.createStudyRequest === undefined) {
            throw new runtime.RequiredError('createStudyRequest','Required parameter requestParameters.createStudyRequest was null or undefined when calling createStudy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/studies/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStudyRequestToJSON(requestParameters.createStudyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyCreateResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Id of the new study
     * Create Study
     */
    async createStudy(createStudyRequest: CreateStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StudyCreateResponseSchema> {
        const response = await this.createStudyRaw({ createStudyRequest: createStudyRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Create study analysis
     * Create Study Analysis
     */
    async createStudyAnalysisRaw(requestParameters: CreateStudyAnalysisOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StudyAnalysisSchema>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling createStudyAnalysis.');
        }

        if (requestParameters.createStudyAnalysisRequest === null || requestParameters.createStudyAnalysisRequest === undefined) {
            throw new runtime.RequiredError('createStudyAnalysisRequest','Required parameter requestParameters.createStudyAnalysisRequest was null or undefined when calling createStudyAnalysis.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/studies/{study_id}/analysis/`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStudyAnalysisRequestToJSON(requestParameters.createStudyAnalysisRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyAnalysisSchemaFromJSON(jsonValue));
    }

    /**
     * Create study analysis
     * Create Study Analysis
     */
    async createStudyAnalysis(studyId: string, createStudyAnalysisRequest: CreateStudyAnalysisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StudyAnalysisSchema> {
        const response = await this.createStudyAnalysisRaw({ studyId: studyId, createStudyAnalysisRequest: createStudyAnalysisRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Id of the new study experiment group
     * Create Study Experiment Group
     */
    async createStudyExperimentsGroupRaw(requestParameters: CreateStudyExperimentsGroupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StudyExperimentsGroupSchema>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling createStudyExperimentsGroup.');
        }

        if (requestParameters.createStudyExperimentsGroupRequest === null || requestParameters.createStudyExperimentsGroupRequest === undefined) {
            throw new runtime.RequiredError('createStudyExperimentsGroupRequest','Required parameter requestParameters.createStudyExperimentsGroupRequest was null or undefined when calling createStudyExperimentsGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/studies/{study_id}/group`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStudyExperimentsGroupRequestToJSON(requestParameters.createStudyExperimentsGroupRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyExperimentsGroupSchemaFromJSON(jsonValue));
    }

    /**
     * Id of the new study experiment group
     * Create Study Experiment Group
     */
    async createStudyExperimentsGroup(studyId: string, createStudyExperimentsGroupRequest: CreateStudyExperimentsGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StudyExperimentsGroupSchema> {
        const response = await this.createStudyExperimentsGroupRaw({ studyId: studyId, createStudyExperimentsGroupRequest: createStudyExperimentsGroupRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Delete Study Experiments Group
     */
    async deleteStudyExperimentsGroupRaw(requestParameters: DeleteStudyExperimentsGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling deleteStudyExperimentsGroup.');
        }

        if (requestParameters.studyGroupId === null || requestParameters.studyGroupId === undefined) {
            throw new runtime.RequiredError('studyGroupId','Required parameter requestParameters.studyGroupId was null or undefined when calling deleteStudyExperimentsGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/studies/{study_id}/group/{study_group_id}`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"study_group_id"}}`, encodeURIComponent(String(requestParameters.studyGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Study Experiments Group
     */
    async deleteStudyExperimentsGroup(studyId: string, studyGroupId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStudyExperimentsGroupRaw({ studyId: studyId, studyGroupId: studyGroupId }, initOverrides);
    }

    /**
     * Delete Study Soft
     */
    async deleteStudySoftRaw(requestParameters: DeleteStudySoftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling deleteStudySoft.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/studies/{study_id}`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Study Soft
     */
    async deleteStudySoft(studyId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStudySoftRaw({ studyId: studyId }, initOverrides);
    }

    /**
     * Get Study
     */
    async getByIdRaw(requestParameters: GetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StudySummarySchema>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling getById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/studies/{study_id}`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudySummarySchemaFromJSON(jsonValue));
    }

    /**
     * Get Study
     */
    async getById(studyId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StudySummarySchema> {
        const response = await this.getByIdRaw({ studyId: studyId }, initOverrides);
        return await response.value();
    }

    /**
     * Get Study Experiments Group By Id
     */
    async getGroupByIdRaw(requestParameters: GetGroupByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StudyExperimentsGroupSchema>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling getGroupById.');
        }

        if (requestParameters.studyGroupId === null || requestParameters.studyGroupId === undefined) {
            throw new runtime.RequiredError('studyGroupId','Required parameter requestParameters.studyGroupId was null or undefined when calling getGroupById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/studies/{study_id}/group/{study_group_id}`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"study_group_id"}}`, encodeURIComponent(String(requestParameters.studyGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyExperimentsGroupSchemaFromJSON(jsonValue));
    }

    /**
     * Get Study Experiments Group By Id
     */
    async getGroupById(studyId: string, studyGroupId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StudyExperimentsGroupSchema> {
        const response = await this.getGroupByIdRaw({ studyId: studyId, studyGroupId: studyGroupId }, initOverrides);
        return await response.value();
    }

    /**
     * Get study analysis information and execution status
     * Get Study Analysis
     */
    async getStudyAnalysisRaw(requestParameters: GetStudyAnalysisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling getStudyAnalysis.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/studies/{study_id}/analysis/`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get study analysis information and execution status
     * Get Study Analysis
     */
    async getStudyAnalysis(studyId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getStudyAnalysisRaw({ studyId: studyId }, initOverrides);
        return await response.value();
    }

    /**
     * Unlike normal API, this will be called by the browser directly, not in an FE api call
     * Get Analysis Zipped
     */
    async getStudyAnalysisZippedRaw(requestParameters: GetStudyAnalysisZippedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling getStudyAnalysisZipped.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/studies/{study_id}/zipfile`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unlike normal API, this will be called by the browser directly, not in an FE api call
     * Get Analysis Zipped
     */
    async getStudyAnalysisZipped(studyId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getStudyAnalysisZippedRaw({ studyId: studyId }, initOverrides);
    }

    /**
     * List all existing experiments, excluding soft deleted experiments
     * Get Study Count
     */
    async getStudyCountRaw(requestParameters: GetStudyCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStudyCountResponse>> {
        const queryParameters: any = {};

        if (requestParameters.queryFilter !== undefined) {
            queryParameters['query_filter'] = requestParameters.queryFilter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/studies/count/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStudyCountResponseFromJSON(jsonValue));
    }

    /**
     * List all existing experiments, excluding soft deleted experiments
     * Get Study Count
     */
    async getStudyCount(queryFilter?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStudyCountResponse> {
        const response = await this.getStudyCountRaw({ queryFilter: queryFilter }, initOverrides);
        return await response.value();
    }

    /**
     * Get study outcome.
     * Get Study Outcome
     */
    async getStudyOutcomeWithIdRaw(requestParameters: GetStudyOutcomeWithIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling getStudyOutcomeWithId.');
        }

        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName','Required parameter requestParameters.resourceName was null or undefined when calling getStudyOutcomeWithId.');
        }

        if (requestParameters.analysisId === null || requestParameters.analysisId === undefined) {
            throw new runtime.RequiredError('analysisId','Required parameter requestParameters.analysisId was null or undefined when calling getStudyOutcomeWithId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/studies/{study_id}/outcome/{analysis_id}/{resource_name}`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))).replace(`{${"analysis_id"}}`, encodeURIComponent(String(requestParameters.analysisId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get study outcome.
     * Get Study Outcome
     */
    async getStudyOutcomeWithId(studyId: string, resourceName: string, analysisId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getStudyOutcomeWithIdRaw({ studyId: studyId, resourceName: resourceName, analysisId: analysisId }, initOverrides);
        return await response.value();
    }

    /**
     * List all studies
     * List Studies
     */
    async listStudiesRaw(requestParameters: ListStudiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StudySummarySchema>>> {
        const queryParameters: any = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.queryFilter !== undefined) {
            queryParameters['query_filter'] = requestParameters.queryFilter;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/studies/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StudySummarySchemaFromJSON));
    }

    /**
     * List all studies
     * List Studies
     */
    async listStudies(start?: number, count?: number, queryFilter?: string, sort?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StudySummarySchema>> {
        const response = await this.listStudiesRaw({ start: start, count: count, queryFilter: queryFilter, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Update Study
     */
    async updateStudyRaw(requestParameters: UpdateStudyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StudySchema>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling updateStudy.');
        }

        if (requestParameters.updateStudyRequest === null || requestParameters.updateStudyRequest === undefined) {
            throw new runtime.RequiredError('updateStudyRequest','Required parameter requestParameters.updateStudyRequest was null or undefined when calling updateStudy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/studies/{study_id}`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters.studyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStudyRequestToJSON(requestParameters.updateStudyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudySchemaFromJSON(jsonValue));
    }

    /**
     * Update Study
     */
    async updateStudy(studyId: string, updateStudyRequest: UpdateStudyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StudySchema> {
        const response = await this.updateStudyRaw({ studyId: studyId, updateStudyRequest: updateStudyRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Update Study Experiments Group
     */
    async updateStudyExperimentsGroupRaw(requestParameters: UpdateStudyExperimentsGroupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StudyExperimentsGroupSchema>> {
        if (requestParameters.studyId === null || requestParameters.studyId === undefined) {
            throw new runtime.RequiredError('studyId','Required parameter requestParameters.studyId was null or undefined when calling updateStudyExperimentsGroup.');
        }

        if (requestParameters.studyGroupId === null || requestParameters.studyGroupId === undefined) {
            throw new runtime.RequiredError('studyGroupId','Required parameter requestParameters.studyGroupId was null or undefined when calling updateStudyExperimentsGroup.');
        }

        if (requestParameters.updateStudyExperimentsGroupRequest === null || requestParameters.updateStudyExperimentsGroupRequest === undefined) {
            throw new runtime.RequiredError('updateStudyExperimentsGroupRequest','Required parameter requestParameters.updateStudyExperimentsGroupRequest was null or undefined when calling updateStudyExperimentsGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/studies/{study_id}/group/{study_group_id}`.replace(`{${"study_id"}}`, encodeURIComponent(String(requestParameters.studyId))).replace(`{${"study_group_id"}}`, encodeURIComponent(String(requestParameters.studyGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStudyExperimentsGroupRequestToJSON(requestParameters.updateStudyExperimentsGroupRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyExperimentsGroupSchemaFromJSON(jsonValue));
    }

    /**
     * Update Study Experiments Group
     */
    async updateStudyExperimentsGroup(studyId: string, studyGroupId: string, updateStudyExperimentsGroupRequest: UpdateStudyExperimentsGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StudyExperimentsGroupSchema> {
        const response = await this.updateStudyExperimentsGroupRaw({ studyId: studyId, studyGroupId: studyGroupId, updateStudyExperimentsGroupRequest: updateStudyExperimentsGroupRequest }, initOverrides);
        return await response.value();
    }

}
