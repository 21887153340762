import createConfig from "react-runtime-config";

export const {useConfig, useAdminConfig, getConfig} = createConfig({
    namespace: "PEGASUS",
    schema: {
        authUrl: {
            type: "string",
            description: "URL to send the browser to for authentication",
            default:
                "https://auth.fluidic.com/oauth2/authorize?client_id=ae85a78d-86e5-4e53-944e-a1cf9c19ed71&redirect_uri=https://cloud.fluidic.com/login&response_type=code&tenantId=2628d40a-a0e3-e60d-7702-12d3c7c07f13&scope=openid+offline_access",
        },
        redirectUri: {
            type: "string",
            description: "URI to redirect to at successful login",
            default: "https://cloud.fluidic.com/login",
        },
        backendUrl: {
            type: "string",
            description: "URL to make backend requests to",
            default: "https://cloud.fluidic.com/api",
        },
        logoutUrl: {
            type: "string",
            description: "URL to send the browser to for completing logout",
            default:
                "https://auth.fluidic.com/oauth2/logout?client_id=ae85a78d-86e5-4e53-944e-a1cf9c19ed71",
        },
        clientId: {
            type: "string",
            description: "Client ID (UUID) for authentication",
            default: "ae85a78d-86e5-4e53-944e-a1cf9c19ed71",
        },
        gitHash: {
            type: "string",
            description: 'Git hash the webpack was built from, or "debug build" if not run from azure',
            default: "debug build",
        },
        showVersion: {
            type: "boolean",
            description: "Show the versions in the top right corner",
            default: false,
        },
    },
});
