/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExperimentType } from './ExperimentType';
import {
    ExperimentTypeFromJSON,
    ExperimentTypeFromJSONTyped,
    ExperimentTypeToJSON,
} from './ExperimentType';
import type { ResourceDefinition } from './ResourceDefinition';
import {
    ResourceDefinitionFromJSON,
    ResourceDefinitionFromJSONTyped,
    ResourceDefinitionToJSON,
} from './ResourceDefinition';

/**
 * 
 * @export
 * @interface ResourceOutput
 */
export interface ResourceOutput {
    /**
     * 
     * @type {ExperimentType}
     * @memberof ResourceOutput
     */
    experimentType: ExperimentType;
    /**
     * 
     * @type {Array<ResourceDefinition>}
     * @memberof ResourceOutput
     */
    resourceDefinitions: Array<ResourceDefinition>;
}

/**
 * Check if a given object implements the ResourceOutput interface.
 */
export function instanceOfResourceOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "experimentType" in value;
    isInstance = isInstance && "resourceDefinitions" in value;

    return isInstance;
}

export function ResourceOutputFromJSON(json: any): ResourceOutput {
    return ResourceOutputFromJSONTyped(json, false);
}

export function ResourceOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'experimentType': ExperimentTypeFromJSON(json['experiment_type']),
        'resourceDefinitions': ((json['resource_definitions'] as Array<any>).map(ResourceDefinitionFromJSON)),
    };
}

export function ResourceOutputToJSON(value?: ResourceOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'experiment_type': ExperimentTypeToJSON(value.experimentType),
        'resource_definitions': ((value.resourceDefinitions as Array<any>).map(ResourceDefinitionToJSON)),
    };
}

