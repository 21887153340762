/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Output parameters
 * @export
 * @interface OutputParameters
 */
export interface OutputParameters {
    /**
     * 
     * @type {number}
     * @memberof OutputParameters
     */
    rhFree: number;
    /**
     * 
     * @type {number}
     * @memberof OutputParameters
     */
    rhComplex: number;
    /**
     * 
     * @type {number}
     * @memberof OutputParameters
     */
    a?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputParameters
     */
    kd: number;
    /**
     * 
     * @type {number}
     * @memberof OutputParameters
     */
    nReceptors?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputParameters
     */
    nA?: number;
}

/**
 * Check if a given object implements the OutputParameters interface.
 */
export function instanceOfOutputParameters(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rhFree" in value;
    isInstance = isInstance && "rhComplex" in value;
    isInstance = isInstance && "kd" in value;

    return isInstance;
}

export function OutputParametersFromJSON(json: any): OutputParameters {
    return OutputParametersFromJSONTyped(json, false);
}

export function OutputParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rhFree': json['rh_free'],
        'rhComplex': json['rh_complex'],
        'a': !exists(json, 'a') ? undefined : json['a'],
        'kd': json['kd'],
        'nReceptors': !exists(json, 'n_receptors') ? undefined : json['n_receptors'],
        'nA': !exists(json, 'n_a') ? undefined : json['n_a'],
    };
}

export function OutputParametersToJSON(value?: OutputParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rh_free': value.rhFree,
        'rh_complex': value.rhComplex,
        'a': value.a,
        'kd': value.kd,
        'n_receptors': value.nReceptors,
        'n_a': value.nA,
    };
}

