import {InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {GridFilterInputValueProps} from "@mui/x-data-grid";
import {ExperimentType} from "../../api";
import {useContext} from "react";
import {ExperimentContext} from "../../App";

export function TypeInputValue(props: GridFilterInputValueProps) {
    const experimentTypeMap = useContext(ExperimentContext);
    const {item, applyValue, focusElementRef} = props;

    const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
        const newValue = event.target.value as string; // Assuming the value is of type string
        if (newValue !== "") {
            applyValue({...item, value: newValue});
        }
    };

    const defaultValue = item.value ?? "";

    const options = Object.keys(ExperimentType).map((key) => {
        const experimentType = ExperimentType[key as keyof typeof ExperimentType];
        return {
            key: experimentType,
            value: experimentTypeMap.nameMap[experimentType as unknown as keyof typeof experimentTypeMap.nameMap],
        };
    });

    return (
        <>
            <InputLabel id="select-label">Value</InputLabel>
            <Select labelId="select-label" id="MultiInputLabelRoot" value={defaultValue} onChange={handleSelectChange}>
                {options.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.value}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}
