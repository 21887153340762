// Defaults
const defaultOptions = {
    format: "image/png",
    quality: 0.92,
    width: undefined,
    height: undefined,
    Canvas: undefined,
    crossOrigin: undefined,
};

const handler = {
    construct() {
        return handler;
    },
}; //Must return ANY object, so reuse one

const isConstructor = (x) => {
    try {
        return !!new new Proxy(x, handler)();
    } catch (e) {
        return false;
    }
};

// Return Promise
const mergeImages = (sources = [], options = {}) =>
    new Promise((resolve) => {
        options = Object.assign({}, defaultOptions, options);

        let canvas = undefined;
        // Setup browser/Node.js specific variables
        if (options.Canvas) {
            if (isConstructor(options.Canvas)) {
                canvas = new options.Canvas();
            } else {
                canvas = options.Canvas;
            }
        } else {
            canvas = window.document.createElement("canvas");
        }
        const Image = options.Image || window.Image;

        // Load sources
        const images = sources.map(
            (source) =>
                new Promise((resolve, reject) => {
                    // Convert sources to objects
                    if (source.constructor.name !== "Object") {
                        source = {src: source};
                    }

                    // Resolve source and img when loaded
                    const img = new Image();
                    img.crossOrigin = options.crossOrigin;
                    img.onerror = () => reject(new Error("Couldn't load image"));
                    img.onload = () => resolve(Object.assign({}, source, {img}));
                    img.src = source.src;
                })
        );

        // Get canvas context
        const ctx = canvas.getContext("2d");

        // When sources have loaded
        resolve(
            Promise.all(images).then((images) => {
                // Set canvas dimensions if necessary
                const getSize = (dim) => options[dim] || Math.max(...images.map((image) => image.img[dim]));
                if (canvas.width < getSize("width")) {
                    canvas.width = getSize("width");
                }
                if (canvas.height < getSize("height")) {
                    canvas.height = getSize("height");
                }
                // ctx.fillStyle = "blue";
                // ctx.fillRect(0, 0, canvas.width, canvas.height);

                // Draw images to canvas
                images.forEach((image) => {
                    ctx.globalAlpha = image.opacity ? image.opacity : 1;
                    return ctx.drawImage(image.img, image.x || 0, image.y || 0);
                });

                if (options.Canvas && options.format === "image/jpeg") {
                    // Resolve data URI for node-canvas jpeg async
                    return new Promise((resolve, reject) => {
                        canvas.toDataURL(
                            options.format,
                            {
                                quality: options.quality,
                                progressive: false,
                            },
                            (err, jpeg) => {
                                if (err) {
                                    reject(err);
                                    return;
                                }
                                resolve(jpeg);
                            }
                        );
                    });
                }

                // Resolve all other data URIs sync
                return canvas.toDataURL(options.format, options.quality);
            })
        );
    });

export default mergeImages;
