/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MeasurementOriginalValues } from './MeasurementOriginalValues';
import {
    MeasurementOriginalValuesFromJSON,
    MeasurementOriginalValuesFromJSONTyped,
    MeasurementOriginalValuesToJSON,
} from './MeasurementOriginalValues';

/**
 * MeasurementSchema after being created by the database
 * @export
 * @interface MeasurementSchema
 */
export interface MeasurementSchema {
    /**
     * 
     * @type {string}
     * @memberof MeasurementSchema
     */
    circuitUuid: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementSchema
     */
    circuitId: string;
    /**
     * 
     * @type {Date}
     * @memberof MeasurementSchema
     */
    runTimestamp: Date;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    pressure: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    temperature: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    viscosity: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    diffusedRawFilled: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    diffusedRawEmpty: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    undiffusedRawFilled: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    undiffusedRawEmpty: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    ratioUp: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    ratioDown: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    resistance: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    aspectRatio: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    unlabeledConcentration?: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    complexMixtureDilution?: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    labeledConcentration: number;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof MeasurementSchema
     */
    instrumentId: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementSchema
     */
    instrumentType: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementSchema
     */
    labeledName: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementSchema
     */
    complexName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementSchema
     */
    unlabeledName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementSchema
     */
    color?: string;
    /**
     * 
     * @type {number}
     * @memberof MeasurementSchema
     */
    totalIntensity?: number;
    /**
     * 
     * @type {MeasurementOriginalValues}
     * @memberof MeasurementSchema
     */
    originalValues?: MeasurementOriginalValues;
    /**
     * 
     * @type {string}
     * @memberof MeasurementSchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementSchema
     */
    fileId: string;
    /**
     * 
     * @type {Date}
     * @memberof MeasurementSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof MeasurementSchema
     */
    updated?: Date;
}

/**
 * Check if a given object implements the MeasurementSchema interface.
 */
export function instanceOfMeasurementSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "circuitUuid" in value;
    isInstance = isInstance && "circuitId" in value;
    isInstance = isInstance && "runTimestamp" in value;
    isInstance = isInstance && "pressure" in value;
    isInstance = isInstance && "temperature" in value;
    isInstance = isInstance && "viscosity" in value;
    isInstance = isInstance && "diffusedRawFilled" in value;
    isInstance = isInstance && "diffusedRawEmpty" in value;
    isInstance = isInstance && "undiffusedRawFilled" in value;
    isInstance = isInstance && "undiffusedRawEmpty" in value;
    isInstance = isInstance && "ratioUp" in value;
    isInstance = isInstance && "ratioDown" in value;
    isInstance = isInstance && "resistance" in value;
    isInstance = isInstance && "aspectRatio" in value;
    isInstance = isInstance && "labeledConcentration" in value;
    isInstance = isInstance && "instrumentId" in value;
    isInstance = isInstance && "instrumentType" in value;
    isInstance = isInstance && "labeledName" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "fileId" in value;
    isInstance = isInstance && "created" in value;

    return isInstance;
}

export function MeasurementSchemaFromJSON(json: any): MeasurementSchema {
    return MeasurementSchemaFromJSONTyped(json, false);
}

export function MeasurementSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'circuitUuid': json['circuit_uuid'],
        'circuitId': json['circuit_id'],
        'runTimestamp': (new Date(json['run_timestamp'])),
        'pressure': json['pressure'],
        'temperature': json['temperature'],
        'viscosity': json['viscosity'],
        'diffusedRawFilled': json['diffused_raw_filled'],
        'diffusedRawEmpty': json['diffused_raw_empty'],
        'undiffusedRawFilled': json['undiffused_raw_filled'],
        'undiffusedRawEmpty': json['undiffused_raw_empty'],
        'ratioUp': json['ratio_up'],
        'ratioDown': json['ratio_down'],
        'resistance': json['resistance'],
        'aspectRatio': json['aspect_ratio'],
        'unlabeledConcentration': !exists(json, 'unlabeled_concentration') ? undefined : json['unlabeled_concentration'],
        'complexMixtureDilution': !exists(json, 'complex_mixture_dilution') ? undefined : json['complex_mixture_dilution'],
        'labeledConcentration': json['labeled_concentration'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'instrumentId': json['instrument_id'],
        'instrumentType': json['instrument_type'],
        'labeledName': json['labeled_name'],
        'complexName': !exists(json, 'complex_name') ? undefined : json['complex_name'],
        'unlabeledName': !exists(json, 'unlabeled_name') ? undefined : json['unlabeled_name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'totalIntensity': !exists(json, 'total_intensity') ? undefined : json['total_intensity'],
        'originalValues': !exists(json, 'original_values') ? undefined : MeasurementOriginalValuesFromJSON(json['original_values']),
        'id': json['id'],
        'fileId': json['file_id'],
        'created': (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
    };
}

export function MeasurementSchemaToJSON(value?: MeasurementSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'circuit_uuid': value.circuitUuid,
        'circuit_id': value.circuitId,
        'run_timestamp': (value.runTimestamp.toISOString()),
        'pressure': value.pressure,
        'temperature': value.temperature,
        'viscosity': value.viscosity,
        'diffused_raw_filled': value.diffusedRawFilled,
        'diffused_raw_empty': value.diffusedRawEmpty,
        'undiffused_raw_filled': value.undiffusedRawFilled,
        'undiffused_raw_empty': value.undiffusedRawEmpty,
        'ratio_up': value.ratioUp,
        'ratio_down': value.ratioDown,
        'resistance': value.resistance,
        'aspect_ratio': value.aspectRatio,
        'unlabeled_concentration': value.unlabeledConcentration,
        'complex_mixture_dilution': value.complexMixtureDilution,
        'labeled_concentration': value.labeledConcentration,
        'size': value.size,
        'instrument_id': value.instrumentId,
        'instrument_type': value.instrumentType,
        'labeled_name': value.labeledName,
        'complex_name': value.complexName,
        'unlabeled_name': value.unlabeledName,
        'color': value.color,
        'total_intensity': value.totalIntensity,
        'original_values': MeasurementOriginalValuesToJSON(value.originalValues),
        'id': value.id,
        'file_id': value.fileId,
        'created': (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
    };
}

