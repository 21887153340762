import * as React from "react";
import Box from "@mui/material/Box";
import {
    DataGrid,
    GridColDef,
    GridFilterItem,
    GridFilterModel,
    GridRowId,
    GridSortModel,
    getGridStringOperators,
} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import {ExperimentsApi, ExperimentSummary, ListExperimentResponse, ResponseError} from "../../../api";

import {useTheme} from "@mui/material/styles";
import {useContext, useEffect, useState} from "react";
import {apiConfig} from "../../../ApiConfig";
import {ExperimentContext} from "../../../App";
import {formatStandardDate} from "../../../helpers";
import {handleNetworkError} from "../../../helpers/error";
import {StatusInputValue} from "../../ExperimentsTable/StatusInputValue";
import CustomPagination from "../../../components/CustomPagination";

interface GroupDataTableProps {
    studyType: string;
    selectedExperimentIds: string[];
    handleSelectExperimentId: (pageExperiments: string[], selectedExeriments: string[]) => void;
    multiple?: boolean;
    hideType?: boolean;
    hideStatus?: boolean;
}

const experimentsApi = new ExperimentsApi(apiConfig);

export default function GroupDataTable(props: GroupDataTableProps) {
    const navigate = useNavigate();

    const defaultSortModel: GridSortModel = [{field: "created", sort: "desc"}];
    const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [typeFilterModel, setTypeFilterModel] = useState<GridFilterItem>();
    const [statusFilterModel, setStatusFilterModel] = useState<GridFilterItem>();
    const [pageSize, setPageSize] = useState<number>(5);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const theme = useTheme();

    const experimentTypeMap = useContext(ExperimentContext);
    const [experiments, setExperiments] = useState<ExperimentSummary[]>([]);
    const [rowsNumber, setRowsNumber] = useState<number>();
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
    const [stopHandleToParent, setStopHandleToParent] = useState<boolean>(false);
    const [showOnlySelected, setShowOnlySelected] = useState<boolean>(!!props.selectedExperimentIds.length);
    const rowsPerPageOptions = [5, 10, 20, 50, 100];
    const columns: GridColDef[] = [
        {
            field: "created",
            headerName: "Date created",
            width: 150,
            editable: false,
            sortable: true,
            filterable: false,
            hide: false,
            valueFormatter: ({value}) => formatStandardDate(value),
        },
        {
            field: "name",
            headerName: "Name",
            type: "string",
            width: 200,
            editable: false,
        },
        {
            field: "description",
            headerName: "Description",
            type: "string",
            width: 250,
            editable: false,
            sortable: true,
        },
        {
            field: "type",
            headerName: "Type",
            description: "",
            editable: false,
            sortable: true,
            filterable: false,
            width: 300,
            hide: props.hideType,
            valueFormatter: ({value}) => experimentTypeMap[value as unknown as keyof typeof experimentTypeMap.nameMap],
        },
        {
            field: "analysisStatus",
            headerName: "Status",
            description: "",
            editable: false,
            sortable: true,
            filterable: true,
            width: 100,
            hide: props.hideStatus,
            filterOperators: getGridStringOperators()
                .filter((operator) => operator.value === "equals")
                .map((operator) => {
                    return {
                        ...operator,
                        InputComponent: StatusInputValue,
                    };
                }),
            renderCell: (data) => {
                return data.value ? (
                    <span style={{textTransform: "capitalize"}}>{data.value.toLowerCase()}</span>
                ) : (
                    "Not run"
                );
            },
        },
    ];

    useEffect(() => {
        setTypeFilterModel({columnField: "type", operatorValue: "equals", value: props.studyType});
        setStatusFilterModel({columnField: "analysisStatus", operatorValue: "equals", value: "Completed"});
    }, []);

    useEffect(() => {
        updateExperimentsPerPage(
            pageSize,
            pageNumber,
            getFilterItemOrUndefined(filterModel),
            sortModel ? JSON.stringify(sortModel[0]) : undefined,
            typeFilterModel,
            statusFilterModel
        );
    }, [typeFilterModel]);

    function handlePageSize(newSize: number) {
        setPageSize(newSize);
        updateExperimentsPerPage(
            newSize,
            pageNumber,
            getFilterItemOrUndefined(filterModel),
            getSortModelOrUndefined(sortModel),
            typeFilterModel,
            statusFilterModel
        );
    }

    function handlePageChange(newPage: number) {
        setPageNumber(newPage);
        updateExperimentsPerPage(
            pageSize,
            newPage,
            getFilterItemOrUndefined(filterModel),
            getSortModelOrUndefined(sortModel),
            typeFilterModel,
            statusFilterModel
        );
    }

    function getFilterItemOrUndefined(filterModel: GridFilterModel | undefined): GridFilterItem | undefined {
        return filterModel &&
            filterModel.items[0] &&
            (filterModel.items[0].operatorValue === "isEmpty" ||
                filterModel.items[0].operatorValue === "isNotEmpty" ||
                filterModel.items[0].value)
            ? filterModel.items[0]
            : undefined;
    }

    function getSortModelOrUndefined(sortModel: GridSortModel): string | undefined {
        return sortModel ? JSON.stringify(sortModel[0]) : undefined;
    }

    function updateExperimentsPerPage(
        pageSize: number,
        pageNumber: number,
        filter: GridFilterItem | undefined,
        sort: string | undefined,
        typeFilter: GridFilterItem | undefined,
        statusFilter: GridFilterItem | undefined
    ) {
        if (typeFilter === undefined || statusFilter === undefined) {
            return;
        }
        const from = pageSize * pageNumber;
        setStopHandleToParent(true);

        let filters;
        if (!filter && !typeFilter) filters = undefined;
        else if (!filter && typeFilter) filters = JSON.stringify([typeFilter, statusFilter]);
        else if (filter && !typeFilter) filters = JSON.stringify([filter, statusFilter]);
        else if (filter && typeFilter) filters = JSON.stringify([filter, typeFilter, statusFilter]);

        experimentsApi
            .getExperimentCount(filters)
            .then((resp: any) => {
                setRowsNumber(parseInt(resp));
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });

        let idlist;
        if (showOnlySelected) {
            idlist = props.selectedExperimentIds;
        }
        experimentsApi
            .listExperiments(from, pageSize, filters, sort, idlist)
            .then((resp: ListExperimentResponse) => {
                const tableExperiments = resp.experiments.map((obj) => {
                    if (obj.analysisStatus === undefined) {
                        return {...obj, analysisStatus: "Not run"};
                    }
                    return obj;
                });
                setExperiments(tableExperiments);
                setSelectedRows(props.selectedExperimentIds.map((id) => id as GridRowId));
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            })
            .finally(() => {
                setStopHandleToParent(false);
            });
    }

    const handleSelectionChange = (selection: GridRowId[]) => {
        setSelectedRows(selection);
        if (!stopHandleToParent)
            props.handleSelectExperimentId(
                experiments.map((ex) => ex.id),
                selection.map((id) => String(id))
            );
    };

    useEffect(() => {
        updateExperimentsPerPage(
            pageSize,
            pageNumber,
            getFilterItemOrUndefined(filterModel),
            getSortModelOrUndefined(sortModel),
            typeFilterModel,
            statusFilterModel
        );
    }, [showOnlySelected]);

    // calculation of the number of rows based on the information whether we display only selected items
    const rowsCount = (showOnlySelected ? selectedRows.length : rowsNumber) || 0;

    return (
        <Box
            sx={{
                height: "auto",
                width: "100%",
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <DataGrid
                sx={{
                    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
                        display: "none",
                    },
                    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                        color: theme.palette.text.primary,
                    },
                    "& .MuiDataGrid-cell:focus,& .MuiDataGrid-cell:active, & .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader:focus,& .MuiDataGrid-columnHeader:active, & .MuiDataGrid-columnHeader:focus-within":
                        {
                            outline: "none",
                        },
                    "& .MuiDataGrid-cell:nth-of-type(6)": {
                        textAlign: "right",
                    },
                    border: "none",
                    color: theme.palette.text.primary,
                    "& >.MuiDataGrid-main": {
                        "&>.MuiDataGrid-columnHeaders": {
                            borderColor: theme.palette.background.default,
                        },
                        "& div div div div >.MuiDataGrid-cell": {
                            borderColor: theme.palette.background.default,
                        },
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderColor: theme.palette.background.default,
                    },
                    "& .MuiDataGrid-row": {cursor: "pointer"},
                }}
                onSortModelChange={(newSortModel) => {
                    setSortModel(newSortModel);
                    updateExperimentsPerPage(
                        pageSize,
                        pageNumber,
                        getFilterItemOrUndefined(filterModel),
                        getSortModelOrUndefined(newSortModel),
                        typeFilterModel,
                        statusFilterModel
                    );
                }}
                onFilterModelChange={(newFilterModel) => {
                    if (newFilterModel.items.length > 0) {
                        //assume there is only one filter
                        let updatedFilterModel = undefined;
                        if (
                            newFilterModel.items[0].columnField === "analysisStatus" &&
                            newFilterModel.items[0].value === "Not run"
                        ) {
                            const notRunFilter = {
                                columnField: "analysisStatus",
                                id: newFilterModel.items[0].id,
                                operatorValue: "isEmpty",
                            };
                            updatedFilterModel = {
                                items: [notRunFilter],
                            };
                        }
                        setFilterModel(newFilterModel);
                        updateExperimentsPerPage(
                            pageSize,
                            pageNumber,
                            getFilterItemOrUndefined(updatedFilterModel ? updatedFilterModel : newFilterModel),
                            getSortModelOrUndefined(sortModel),
                            typeFilterModel,
                            statusFilterModel
                        );
                    }
                }}
                paginationMode="server"
                checkboxSelection={props.multiple}
                onSelectionModelChange={handleSelectionChange}
                selectionModel={selectedRows}
                sortModel={sortModel}
                filterModel={filterModel}
                autoHeight={true}
                rows={experiments}
                columns={columns}
                pageSize={pageSize}
                page={pageNumber}
                rowCount={rowsCount}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageSizeChange={handlePageSize}
                onPageChange={handlePageChange}
                components={{
                    Footer: CustomPagination,
                }}
                onRowClick={(r) => {
                    if (props.multiple) {
                        return;
                    }
                    props.handleSelectExperimentId([], [r.id.toString()]);
                }}
                componentsProps={{
                    footer: {
                        showSelected: showOnlySelected,
                        handleShowSelected: (b: boolean) => setShowOnlySelected(b),
                        rowsPerPageOptions: rowsPerPageOptions,
                        rowsNumber: rowsCount,
                    },
                }}
            />
        </Box>
    );
}
