import {
    Button,
    Grid,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {StandardDateTimeDisplay} from "../../helpers/HelperComponents";

interface IError {
    errorTime: Date;
    errorTitle: string;
    errorContext: string;
    id: string;
    errorDescription: string;
}

interface Props {
    className?: string;
    errorList: IError[];
}

function ErrorsTable(props: Props): JSX.Element {
    const navigate = useNavigate();
    const theme = useTheme();
    const handleRef = (row: IError): void => {
        navigate(`/error/${row.errorContext}/${row.id}`);
    };

    return (
        <div className={props.className}>
            <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Date and Time</TableCell>
                        <TableCell align="left">Context</TableCell>
                        <TableCell align="left">Title</TableCell>
                        <TableCell align="left">Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.errorList.map((row) => (
                        <TableRow
                            onClick={() => handleRef(row)}
                            key={row.id}
                            sx={{
                                cursor: "pointer",
                                "&:last-child td, &:last-child th": {border: 0},
                                ":hover": {backgroundColor: theme.palette.action.hover},
                            }}
                        >
                            <TableCell component="th" scope="row" sx={{paddingTop: "10px", paddingBottom: "10px"}}>
                                <Typography noWrap={true}>
                                    <StandardDateTimeDisplay value={row.errorTime} />
                                </Typography>
                            </TableCell>
                            <TableCell align="left">{row.errorContext}</TableCell>
                            <TableCell
                                align="left"
                                style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}
                            >
                                {row.errorTitle}
                            </TableCell>

                            <TableCell align="left">
                                <Typography noWrap={false}>{row.errorDescription}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default styled(ErrorsTable)``;
