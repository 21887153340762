/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MeasurementBatchUpdateRequest
 */
export interface MeasurementBatchUpdateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MeasurementBatchUpdateRequest
     */
    ids: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MeasurementBatchUpdateRequest
     */
    labeledName: string;
    /**
     * 
     * @type {number}
     * @memberof MeasurementBatchUpdateRequest
     */
    labeledConcentration?: number;
    /**
     * 
     * @type {string}
     * @memberof MeasurementBatchUpdateRequest
     */
    unlabeledName?: string;
    /**
     * 
     * @type {number}
     * @memberof MeasurementBatchUpdateRequest
     */
    unlabeledConcentration?: number;
    /**
     * 
     * @type {string}
     * @memberof MeasurementBatchUpdateRequest
     */
    complexName?: string;
    /**
     * 
     * @type {number}
     * @memberof MeasurementBatchUpdateRequest
     */
    complexMixtureDilution?: number;
}

/**
 * Check if a given object implements the MeasurementBatchUpdateRequest interface.
 */
export function instanceOfMeasurementBatchUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ids" in value;
    isInstance = isInstance && "labeledName" in value;

    return isInstance;
}

export function MeasurementBatchUpdateRequestFromJSON(json: any): MeasurementBatchUpdateRequest {
    return MeasurementBatchUpdateRequestFromJSONTyped(json, false);
}

export function MeasurementBatchUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementBatchUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': json['ids'],
        'labeledName': json['labeled_name'],
        'labeledConcentration': !exists(json, 'labeled_concentration') ? undefined : json['labeled_concentration'],
        'unlabeledName': !exists(json, 'unlabeled_name') ? undefined : json['unlabeled_name'],
        'unlabeledConcentration': !exists(json, 'unlabeled_concentration') ? undefined : json['unlabeled_concentration'],
        'complexName': !exists(json, 'complex_name') ? undefined : json['complex_name'],
        'complexMixtureDilution': !exists(json, 'complex_mixture_dilution') ? undefined : json['complex_mixture_dilution'],
    };
}

export function MeasurementBatchUpdateRequestToJSON(value?: MeasurementBatchUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
        'labeled_name': value.labeledName,
        'labeled_concentration': value.labeledConcentration,
        'unlabeled_name': value.unlabeledName,
        'unlabeled_concentration': value.unlabeledConcentration,
        'complex_name': value.complexName,
        'complex_mixture_dilution': value.complexMixtureDilution,
    };
}

