import {IconButton, styled, Tooltip} from "@mui/material";
import {PageButtonBar, PageContainer, PageContentBlock, PageHeader} from "../../helpers/HelperComponents";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "./DataTable";
import {StudiesApi, StudySchema, ResponseError, StudySummarySchema} from "../../api";
import {apiConfig} from "../../ApiConfig";
import {useEffect, useState} from "react";
import {handleNetworkError} from "../../helpers/error";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../ReduxStore";
import CreationModal from "../Study/CreationModal";

const studiesApi = new StudiesApi(apiConfig);

export default styled((props: any): JSX.Element => {
    const [studies, setStudies] = useState<StudySchema[]>([]);
    const [rowsNumber, setRowsNumber] = useState<number>();
    const navigate = useNavigate();
    const state = useAppSelector((state) => state.studiesTable);

    useEffect(() => {
        if (props.eventMessage !== undefined) {
            let message = JSON.parse(props.eventMessage);
            if (message.action == "statuschange") {
                updateStudiesPerPage(
                    state.pageSize,
                    state.page,
                    state.filterModel ? JSON.stringify(state.filterModel.items[0]) : undefined,
                    state.sortModel ? JSON.stringify(state.sortModel[0]) : undefined
                );
            }
        }
    }, [props.eventMessage]);

    useEffect(() => {
        let notRunFilter = undefined;
        if (state && state.filterModel && state.filterModel.items.length > 0) {
            if (
                state.filterModel.items[0].columnField === "analysisStatus" &&
                state.filterModel.items[0].value === "Not run"
            ) {
                notRunFilter = {
                    columnField: "analysisStatus",
                    id: state.filterModel.items[0].id,
                    operatorValue: "isEmpty",
                };
            }
        }
        updateStudiesPerPage(
            state.pageSize,
            state.page,
            state.filterModel ? JSON.stringify(notRunFilter ? notRunFilter : state.filterModel.items[0]) : undefined,
            state.sortModel ? JSON.stringify(state.sortModel[0]) : undefined
        );
    }, []);

    function updateStudiesPerPage(
        pageSize: number,
        pageNumber: number,
        filter: string | undefined,
        sort: string | undefined
    ) {
        const from = pageSize * pageNumber;
        if (rowsNumber === undefined) {
            studiesApi
                .getStudyCount(filter)
                .then((resp) => {
                    setRowsNumber(resp.size);
                })
                .catch((response: ResponseError) => {
                    handleNetworkError(response).then((target) => {
                        if (target) {
                            navigate(target);
                        }
                    });
                });
        }
        studiesApi
            .listStudies(from, pageSize, filter, sort)
            .then((resp: StudySummarySchema[]) => {
                const tableStudies = resp.map((obj) => {
                    if (obj.analysisStatus === undefined) {
                        return {...obj, analysisStatus: "Not run"};
                    }
                    return obj;
                });
                setStudies(tableStudies);
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    }

    return (
        <div className={props.className}>
            <PageHeader text={"Studies"} />
            <PageContainer>
                <PageContentBlock>
                    <PageButtonBar>
                        <CreationModal>
                            <Tooltip title="New study">
                                <IconButton
                                    sx={{
                                        marginLeft: "10px",
                                        backgroundColor: "primary.main",
                                        color: "primary.contrastText",
                                        "&:hover": {backgroundColor: "primary.dark"},
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </CreationModal>
                    </PageButtonBar>
                    <DataTable studies={studies} updateStudiesPerPage={updateStudiesPerPage} rowsNumber={rowsNumber} />
                </PageContentBlock>
            </PageContainer>
        </div>
    );
})((theme) => {
    return {};
});
