interface JsonTreeProps {
    data: any;
}

function JsonTree({data}: JsonTreeProps) {
    if (Array.isArray(data)) {
        return (
            <ul style={{textAlign: "left"}}>
                {data.map((item, index) => (
                    <li key={index}>
                        <JsonTree data={item} />
                    </li>
                ))}
            </ul>
        );
    } else if (typeof data?.getMonth === "function" && data !== null) {
        return (
            <span>
                {data.toLocaleString("en-GB")}.{data.getMilliseconds().toString().padStart(3, "0")}
            </span>
        );
    } else if (typeof data === "object" && data !== null) {
        return (
            <ul style={{textAlign: "left", margin: "0px"}}>
                {Object.entries(data).map(([key, value]) => (
                    <li key={key}>
                        <strong>{key}:</strong> <JsonTree data={value} />
                    </li>
                ))}
            </ul>
        );
    } else {
        return <span>{String(data)}</span>;
    }
}

export default JsonTree;
