/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StudyCreateResponseSchema
 */
export interface StudyCreateResponseSchema {
    /**
     * 
     * @type {string}
     * @memberof StudyCreateResponseSchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StudyCreateResponseSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StudyCreateResponseSchema
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof StudyCreateResponseSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudyCreateResponseSchema
     */
    updated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof StudyCreateResponseSchema
     */
    isDeleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudyCreateResponseSchema
     */
    type: string;
}

/**
 * Check if a given object implements the StudyCreateResponseSchema interface.
 */
export function instanceOfStudyCreateResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "isDeleted" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function StudyCreateResponseSchemaFromJSON(json: any): StudyCreateResponseSchema {
    return StudyCreateResponseSchemaFromJSONTyped(json, false);
}

export function StudyCreateResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyCreateResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'created': (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'isDeleted': json['is_deleted'],
        'type': json['type'],
    };
}

export function StudyCreateResponseSchemaToJSON(value?: StudyCreateResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'created': (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'is_deleted': value.isDeleted,
        'type': value.type,
    };
}

