import {MaapTableOutputRow, NllsTableOutputRow} from "../types";
import {MeasurementSchema, MaapDataStats, NllsResult, Statistics, OutputParameters} from "../api";
import moment from "moment/moment";
import {GridFilterModel} from "@mui/x-data-grid";
import reactStringReplace from "react-string-replace";
import * as React from "react";

const math = require("mathjs");

export const colorMap: {[key: string]: string} = {
    red: "rgb(190, 58, 52)",
    green: "rgb(14, 158, 57)",
};

export function formatStandardDate(date: Date): string {
    return moment(date).format("YYYY-MM-DD HH:mm");
}

export function capitalizeFirstLetterOrNotRunWhenNull(str: string): string {
    return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "Not run";
}

export function addTextStyles(text: string): any {
    return reactStringReplace(
        reactStringReplace(text, /_(.*?)_/gm, (match) => <sub key={match}>{match}</sub>),
        /~(.*?)~/gm,
        (match) => <i key={match}>{match}</i>
    );
}

export function extractAnalysisOutputResultsMAAP(
    result: MaapDataStats,
    returnPrimaryResultRows: boolean = true,
    returnEmptyLines: boolean = false
): MaapTableOutputRow[] {
    // This next constant describes the parameters and their display order on the page.
    // Kd -  disocciation constant, A_0 - analyte binding site concentration, Kd_log10 - log 10 of
    // disocciation constant, A_0_log10 - target concentration/M, f_ab - diffused fraction of the
    // complex, f_b - diffused fraction of the unbound species, eps - standard deviation
    const all_parameters: (keyof Statistics | undefined)[] = [
        "kd",
        "a0",
        "rhFree",
        "rhComplex",
        "nReceptors",
        "nA",
        undefined,
        "kdLog10",
        "nALog10",
        "a0Log10",
        "fAb",
        "fB",
        "eps",
    ];

    const parameters = all_parameters.reduce((acc, cur) => {
        if (cur === undefined) {
            returnPrimaryResultRows = !returnPrimaryResultRows;
            return acc;
        }
        if (
            returnPrimaryResultRows &&
            (result.mode[cur] ||
                result.mean[cur] ||
                result.median[cur] ||
                result.sd[cur] ||
                result.hdi25[cur] ||
                result.hdi975[cur])
        ) {
            acc.push(cur);
        }

        return acc;
    }, [] as (keyof Statistics)[]);

    // We need to display different names because the new FE parameter names are a bit messy
    const parameter_names: {[key in keyof Statistics]: string} = {
        kd: "Dissociation constant, ~K~_D_",
        a0: "Target concentration",
        rhFree: "R_h_ Free labeled species",
        rhComplex: "R_h_ Complex",
        nA: "Labeled stoichiometry",
        nALog10: "log(stoichiometry)",
        nReceptors: "Receptor copy number",
        kdLog10: "−log_10_ (~K~_D_/M)",
        a0Log10: "−log_10_ (target concentration/M)",
        fAb: "Complex diffused fraction (f_ab_)",
        fB: "Unbound diffused fraction (f_b_)",
        eps: "Standard deviation of error (ε)",
    };

    const units: {[key in keyof Statistics]: string} = {
        kd: "nM",
        a0: "nM",
        rhFree: "nm",
        rhComplex: "nm",
        nA: "",
        nReceptors: "per cell",
        kdLog10: "",
        a0Log10: "",
        fAb: "",
        fB: "",
        eps: "",
    };

    const tableRows: any = [];

    parameters.map((param): void => {
        const row_data = {
            id: param,
            parameter: parameter_names[param],
            unit: units[param],
            power: calcPower([
                result.mode[param] || 0,
                result.mean[param] || 0,
                result.median[param] || 0,
                result.sd[param] || 0,
                result.hdi25[param] || 0,
                result.hdi975[param] || 0,
            ]),
            mode: result.mode[param],
            mean: result.mean[param],
            median: result.median[param],
            sd: result.sd[param],
            hdi25: result["hdi25"][param],
            hdi975: result["hdi975"][param],
        };
        tableRows.push(row_data);
    });

    return tableRows;
}

export function extractAnalysisOutputResultsNLLS(result: NllsResult, removeEmpty = true): NllsTableOutputRow[] {
    const parameters: (keyof OutputParameters)[] = ["kd", "a", "rhFree", "rhComplex", "nA", "nReceptors"];

    // We need to display different names because the new FE parameter names are a bit messy
    const parameter_names: {[key in keyof OutputParameters]: string} = {
        kd: "Dissociation constant, ~K~_D_",
        a: "Target concentration",
        rhFree: "R_h_ Free labeled species",
        rhComplex: "R_h_ Complex",
        nA: "Labeled stoichiometry",
        nReceptors: "Receptor copy number",
    };

    const units: {[key in keyof OutputParameters]: string} = {
        kd: "nM",
        a: "nM",
        rhFree: "nm",
        rhComplex: "nm",
        nA: "",
        nReceptors: "per cell",
    };

    let tableRows: NllsTableOutputRow[] = [] as NllsTableOutputRow[];

    parameters.map((param): void => {
        const row_data: NllsTableOutputRow = {
            id: param,
            parameter: parameter_names[param] || "",
            unit: units[param] || "",
            value: result.globalFit[param] || "",
        };
        tableRows.push(row_data);
    });

    if (removeEmpty) {
        tableRows = tableRows.reduce((acc, cur) => {
            if (cur.value) {
                acc.push(cur);
            }
            return acc;
        }, [] as NllsTableOutputRow[]);
    }

    return tableRows;
}

export const isFunction = function (obj: any) {
    return !!(obj && obj.constructor && obj.call && obj.apply);
};

export const calcPower = function (data: number[]): number {
    const minValue = Math.min(...data);
    let power = 0;
    if (minValue < 10 ** 3) {
        power = 0;
    } else if (10 ** 3 <= minValue && minValue < 10 ** 6) {
        power = 3;
    } else if (10 ** 6 <= minValue && minValue < 10 ** 9) {
        power = 6;
    } else if (10 ** 9 <= minValue && minValue < 10 ** 12) {
        power = 9;
    } else if (10 ** 12 <= minValue && minValue < 10 ** 15) {
        power = 12;
    } else if (10 ** 15 <= minValue && minValue < 10 ** 18) {
        power = 15;
    } else {
        power = 18;
    }

    return power;
};

export const countDecimals = function (value: number): number {
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
};

export const roundBigNumbers = function (value: number, digits: number): number {
    return Math.round(value / Math.pow(10, digits)) * Math.pow(10, digits);
};

export const round = function (value: number, decimalPlace: number): string {
    return decimalPlace > 0 ? value.toFixed(decimalPlace) : `${roundBigNumbers(value, -decimalPlace)}`;
};

export const formatValueToScientific = function (value: number, power: number): string {
    // Check if the number is zero, as scientific notation is not applicable
    if (value === 0) {
        return "0";
    }

    let negative = false;
    if (value < 0) {
        value = Math.abs(value);
        negative = true;
    }
    const exponent = Math.floor(Math.log10(value));
    const decimalPlace = countDecimals(value);
    const x3: number = value / Math.pow(10, power);
    const x3Exponent = Math.floor(Math.log10(x3));
    const dpToFix = decimalPlace - (x3Exponent - exponent);
    const x3FixedDp = math.format(Number((negative ? "-" : "") + round(x3, dpToFix)), {notation: "fixed"});
    return power == 0 ? `${x3FixedDp}` : `${x3FixedDp}e${power}`;
};
