import {useState, useEffect} from "react";

import pegasus_frontend_licenses from "./licenses.txt";
import pegasus_backend_licenses from './pegasus_backend_licenses.txt';
import pegasus_analytics_licenses from './pegasus_analytics_licenses.txt';

const get_text_file = async (filepath: string) => {

  const response = await fetch(filepath);

  // check for errors
  if (!response.ok) {
    throw response;
  }
  return response.text();
};

function Licenses() {
    const sx: {[key: string]: React.CSSProperties} = {
      text: {
        whiteSpace: "pre-wrap",
        textAlign: "justify",
        },

    };

  const [frontendText, setFrontendText] = useState(""); // init with an empty string
  const [backendText, setBackendText] = useState(""); // init with an empty string
  const [analyticsText, setAnalyticsText] = useState(""); // init with an empty string
  useEffect(() => {
    get_text_file(pegasus_frontend_licenses).then(setFrontendText).catch(console.error);
    get_text_file(pegasus_backend_licenses).then(setBackendText).catch(console.error);
    get_text_file(pegasus_analytics_licenses).then(setAnalyticsText).catch(console.error);
  }, [pegasus_frontend_licenses]);
  return (
    
    <div style={sx.text}>
      {frontendText}
    {backendText}
    {analyticsText}
    </div>
    );
}

export default Licenses;
