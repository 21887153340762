import {Props} from "./types";
import React, {FC} from "react";
import {styled} from "@mui/material";

const SvgFluidicLogoWhite: FC<Props> = ({className, ...props}) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.996 34" {...props}>
            <g id="_-Symbols" transform="translate(46)">
                <g id="Navigation-_-colour" transform="translate(-46)">
                    <g id="logo">
                        <g id="Group-42">
                            <g id="full-colour">
                                <g id="marque_shape">
                                    <path
                                        id="Shape"
                                        d="M14.118 0a1.3 1.3 0 0 0-.866.41C7.809 5.807 0 12.967 0 20.267a14 14 0 0 0 28 0c0-7.3-6.639-13.243-13.035-19.9A1.133 1.133 0 0 0 14.118 0z"
                                        className="cls-1"
                                    />
                                </g>
                                <g id="type" transform="translate(32.321 7.203)">
                                    <path
                                        id="Shape-2"
                                        d="M7.559 6.944a.649.649 0 0 0-.648-.644H1.479V2.4h6.142a.655.655 0 0 0 0-1.31H.772a.711.711 0 0 0-.7.711v9.891a.7.7 0 1 0 1.406 0v-4.1h5.436a.649.649 0 0 0 .645-.648z"
                                        className="cls-1"
                                        transform="translate(-.076 -.535)"
                                    />
                                    <path
                                        id="Shape-3"
                                        d="M18.106 12.328a.67.67 0 0 0 .68-.7V1.732a.687.687 0 0 0-1.375 0v9.891a.689.689 0 0 0 .7.7z"
                                        className="cls-1"
                                        transform="translate(-7.548 -.515)"
                                    />
                                    <path
                                        id="Shape-4"
                                        d="M29.479 6.038a.67.67 0 0 0-.692.7v4.142a2.324 2.324 0 0 1-2.374 2.483c-1.4 0-2.173-.894-2.173-2.393V6.733a.7.7 0 0 0-.7-.7.67.67 0 0 0-.68.7v4.541a3.08 3.08 0 0 0 3.212 3.355 3.138 3.138 0 0 0 2.709-1.418v.621a.68.68 0 1 0 1.359 0v-7.1a.68.68 0 0 0-.667-.7z"
                                        className="cls-1"
                                        transform="translate(-9.898 -2.667)"
                                    />
                                    <path
                                        id="Shape-5"
                                        d="M39.085 6.038a.7.7 0 0 0-.68.7v7.107a.689.689 0 0 0 .7.7.67.67 0 0 0 .68-.7V6.733a.686.686 0 0 0-.7-.7z"
                                        className="cls-1"
                                        transform="translate(-16.596 -2.668)"
                                    />
                                    <path
                                        id="Shape-6"
                                        d="M50.262 10.422v.863a.688.688 0 1 0 1.375 0V.81a.686.686 0 0 0-.7-.7.673.673 0 0 0-.68.7v4.159a3.69 3.69 0 0 0-3.057-1.58 4.079 4.079 0 0 0-3.988 4.345v.031a4.076 4.076 0 0 0 3.988 4.32 3.676 3.676 0 0 0 3.062-1.663zM44.625 7.74v-.031a2.817 2.817 0 0 1 2.793-3.073A2.948 2.948 0 0 1 50.3 7.709v.031a3 3 0 0 1-2.883 3.088 2.887 2.887 0 0 1-2.792-3.088z"
                                        className="cls-1"
                                        transform="translate(-18.67 -.114)"
                                    />
                                    <path
                                        id="Shape-7"
                                        d="M61.78 6.038a.7.7 0 0 0-.689.7v7.107a.686.686 0 0 0 .7.7.67.67 0 0 0 .68-.7V6.733a.686.686 0 0 0-.686-.7z"
                                        className="cls-1"
                                        transform="translate(-26.374 -2.668)"
                                    />
                                    <path
                                        id="Shape-8"
                                        d="M70 14.591a4.208 4.208 0 0 0 3.141-1.338.621.621 0 0 0 .18-.434.649.649 0 0 0-.621-.619.552.552 0 0 0-.4.18 3.159 3.159 0 0 1-2.225.975 2.989 2.989 0 0 1-2.93-3.1v-.031a2.938 2.938 0 0 1 2.847-3.094 3.1 3.1 0 0 1 2.173.962.708.708 0 0 0 .447.161.664.664 0 0 0 .664-.664.652.652 0 0 0-.214-.484 4.345 4.345 0 0 0-7.334 3.135v.031A4.3 4.3 0 0 0 70 14.591z"
                                        className="cls-1"
                                        transform="translate(-28.372 -2.614)"
                                    />
                                    <path
                                        id="Shape-9"
                                        d="M8.922 25.2a.649.649 0 0 0-.621-.438h-.027a.658.658 0 0 0-.621.431L4.86 31.257a.6.6 0 0 0-.065.273.509.509 0 0 0 .506.506.54.54 0 0 0 .512-.357l.673-1.5h3.554l.67 1.48a.571.571 0 0 0 .524.376.528.528 0 0 0 .528-.528.671.671 0 0 0-.062-.261zm.678 3.965H6.93l1.344-2.976z"
                                        className="cls-1"
                                        transform="translate(-2.11 -10.736)"
                                    />
                                    <path
                                        id="Shape-10"
                                        d="M21.243 27.85a2.033 2.033 0 0 0-1.573.673v-.071a.532.532 0 1 0-1.065 0v4.379a.531.531 0 0 0 .537.537.521.521 0 0 0 .528-.537v-2.56a1.335 1.335 0 0 1 1.359-1.428c.788 0 1.241.5 1.241 1.369v2.619a.532.532 0 0 0 1.065 0v-2.793a2.006 2.006 0 0 0-2.092-2.188z"
                                        className="cls-1"
                                        transform="translate(-8.062 -12.068)"
                                    />
                                    <path
                                        id="Shape-11"
                                        d="M30.613 27.913a4.119 4.119 0 0 0-1.723.351.5.5 0 0 0-.31.456.49.49 0 0 0 .487.478.542.542 0 0 0 .2-.043 3 3 0 0 1 1.241-.27c.621 0 1.338.208 1.35 1.2a5.519 5.519 0 0 0-1.4-.168c-1.428 0-2.315.686-2.315 1.788v.019a1.852 1.852 0 0 0 2.042 1.729 2.378 2.378 0 0 0 1.673-.621v.059a.5.5 0 0 0 .518.506.509.509 0 0 0 .518-.528V30.1a2.154 2.154 0 0 0-.565-1.586 2.371 2.371 0 0 0-1.719-.6zm-.255 4.634c-.537 0-1.114-.267-1.114-.872s.481-.891 1.322-.891a5.076 5.076 0 0 1 1.331.177v.369a1.377 1.377 0 0 1-1.539 1.213z"
                                        className="cls-1"
                                        transform="translate(-12.174 -12.094)"
                                    />
                                    <path
                                        id="Shape-12"
                                        d="M39.244 24.294a.534.534 0 0 0-.528.537V31.3a.531.531 0 0 0 .537.537.521.521 0 0 0 .528-.537v-6.469a.537.537 0 0 0-.537-.537z"
                                        className="cls-1"
                                        transform="translate(-16.73 -10.536)"
                                    />
                                    <path
                                        id="Shape-13"
                                        d="M46.808 27.818a.528.528 0 0 0-.506.382l-1.422 3.625-1.614-3.625a.546.546 0 0 0-.528-.382.524.524 0 0 0-.537.528.679.679 0 0 0 .065.27l2.079 4.41v.016c-.261.59-.5.807-.875.807a1.406 1.406 0 0 1-.5-.081.706.706 0 0 0-.233-.047.478.478 0 0 0-.478.478.5.5 0 0 0 .326.447 2.536 2.536 0 0 0 .881.155c.847 0 1.378-.441 1.834-1.524l1.98-4.724a.765.765 0 0 0 .047-.233.528.528 0 0 0-.518-.5z"
                                        className="cls-1"
                                        transform="translate(-18.233 -12.055)"
                                    />
                                    <path
                                        id="Shape-14"
                                        d="M55.7 27.7a.494.494 0 0 0 .5-.487.5.5 0 0 0-.5-.487h-1.154v-1.065a.543.543 0 0 0-.537-.537.525.525 0 0 0-.528.537v1.058h-.254a.487.487 0 0 0-.487.487.5.5 0 0 0 .487.487h.254v2.874a1.441 1.441 0 0 0 1.583 1.583 2.052 2.052 0 0 0 .8-.146.469.469 0 0 0 .31-.438.459.459 0 0 0-.6-.456 1.243 1.243 0 0 1-.338.047c-.481 0-.7-.211-.7-.686V27.7z"
                                        className="cls-1"
                                        transform="translate(-22.775 -10.894)"
                                    />
                                    <path
                                        id="Shape-15"
                                        d="M61.628 27.949a.534.534 0 0 0-.537.546v4.379a.531.531 0 0 0 .537.537.521.521 0 0 0 .528-.537V28.5a.531.531 0 0 0-.528-.546z"
                                        className="cls-1"
                                        transform="translate(-26.374 -12.111)"
                                    />
                                    <path
                                        id="Shape-16"
                                        d="M69.529 31.728a.447.447 0 0 0-.31.14 1.862 1.862 0 0 1-1.3.574 1.744 1.744 0 0 1-1.719-1.816 1.713 1.713 0 0 1 1.654-1.8 1.8 1.8 0 0 1 1.276.559.543.543 0 0 0 .345.124.518.518 0 0 0 .525-.519.5.5 0 0 0-.168-.379 2.793 2.793 0 0 0-4.708 2.014v.019a2.762 2.762 0 0 0 2.747 2.775 2.7 2.7 0 0 0 2.014-.857.5.5 0 0 0 .143-.344.509.509 0 0 0-.5-.49z"
                                        className="cls-1"
                                        transform="translate(-28.111 -12.069)"
                                    />
                                    <path
                                        id="Shape-17"
                                        d="M77.176 30.145c-.6-.183-1.173-.357-1.173-.736s.332-.577.822-.577a2.582 2.582 0 0 1 1.241.376.533.533 0 0 0 .236.056.484.484 0 0 0 .5-.49.525.525 0 0 0-.267-.45 3.684 3.684 0 0 0-1.685-.45 1.668 1.668 0 0 0-1.862 1.62c0 1.071 1.03 1.362 1.785 1.577.649.183 1.148.351 1.148.76s-.372.667-.906.667a2.681 2.681 0 0 1-1.505-.5.453.453 0 0 0-.282-.093.484.484 0 0 0-.5.472.5.5 0 0 0 .2.4 3.638 3.638 0 0 0 2.045.655 1.749 1.749 0 0 0 1.952-1.688c.001-1.072-1.041-1.385-1.749-1.599z"
                                        className="cls-1"
                                        transform="translate(-32.251 -12.078)"
                                    />
                                    <circle
                                        id="Oval"
                                        cx=".695"
                                        cy=".695"
                                        r=".695"
                                        className="cls-1"
                                        transform="translate(21.794 .556)"
                                    />
                                    <circle
                                        id="Oval-2"
                                        cx=".695"
                                        cy=".695"
                                        r=".695"
                                        className="cls-1"
                                        transform="translate(34.708 .556)"
                                    />
                                    <circle
                                        id="Oval-3"
                                        cx=".569"
                                        cy=".569"
                                        r=".569"
                                        className="cls-1"
                                        transform="translate(34.688 13.68)"
                                    />
                                </g>
                                <g id="squiggle" transform="translate(5.226 12.954)">
                                    <path
                                        id="outlined_squiggle"
                                        fill="#0072ce"
                                        d="M11.563 14.246H6.231a.583.583 0 0 1 0-1.164h5.332a1 1 0 1 0 0-2H2.631a2.173 2.173 0 1 1 0-4.345h12.963a1 1 0 0 0 0-2H6.47A2.173 2.173 0 0 1 6.662.4h5.329a.582.582 0 0 1 0 1.164H6.662a1 1 0 0 0-.112 2h9.044a2.173 2.173 0 1 1 0 4.345H2.631a1 1 0 1 0 0 2h8.932a2.173 2.173 0 0 1 0 4.345z"
                                        transform="translate(-.458 -.404)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default styled(SvgFluidicLogoWhite)``;
