import {closeSnackbar, enqueueSnackbar, OptionsObject, SnackbarKey} from "notistack";
import SnackbarCloseButton from "../components/SnackbarCloseButton";
import {getConfig} from "../RuntimeConfig";
import {history} from "../App";
import {FetchError, ResponseError} from "../api";

export const snackbarError: OptionsObject = {
    variant: "error",
    persist: true,
    action: (key: SnackbarKey) => {
        return <SnackbarCloseButton snackbarKey={key} variant="error" />;
    },
};
export const snackbarSuccess: OptionsObject = {variant: "success"};
export const snackbarInfo: OptionsObject = {variant: "info"};
export const snackbarWarning: OptionsObject = {variant: "warning"};
export const snackbarWithClose = (
    persist: boolean,
    otherOptions: OptionsObject = {} as OptionsObject
): OptionsObject => {
    return {
        ...otherOptions,
        persist: persist,
        action: (key) => (
            <>
                {otherOptions.action && typeof otherOptions.action === "function" && otherOptions.action(key)}
                <SnackbarCloseButton snackbarKey={key} />
            </>
        ),
    };
};
export const snackbarWithLink = (
    title: string,
    link: string,
    otherOptions: OptionsObject = {} as OptionsObject
): OptionsObject => {
    return {
        ...otherOptions,
        action: (key) => (
            <div>
                <>
                    <span
                        style={{cursor: "pointer", textDecoration: "underline"}}
                        onClick={(e) => {
                            closeSnackbar(key);
                            if (link.startsWith("/")) {
                                history.push(link);
                            } else {
                                window.location.href = link;
                            }
                        }}
                    >
                        {title}
                    </span>
                    {otherOptions.action && typeof otherOptions.action === "function" && otherOptions.action(key)}
                </>
            </div>
        ),
    };
};

export async function handleNetworkError(response: any): Promise<string | undefined> {
    var body: string | undefined = undefined;

    if (response === undefined) {
        return;
    }

    if (response === null) {
        console.log("Network error accessing backend");

        enqueueSnackbar("A network error occurred. Please try again later", snackbarError);

        return undefined;
    }

    if (!response.response || !response.response.body) {
        // This handles messages that we get from redux and I haven't figured out how to prevent them
        if (response.message.startsWith("Invariant failed")) {
            console.error("Redux error: " + response.message);

            return undefined;
        }

        if (response instanceof FetchError) {
            console.log("Received a FetchError which may mask a HTTP error 500");
        }
        if (response instanceof ResponseError) {
            console.log("Error response has no descriptive body");

            enqueueSnackbar("Network communication error", snackbarError);
        } else {
            console.log("Received unexpected exception " + response);
        }

        return;
    } else {
        try {
            body = new TextDecoder().decode((await response.response.body.getReader().read()).value);
        } catch (ex) {
            console.log("Error getting or reading response body", ex);
        }
    }

    if (response.response.status == 403) {
        window.localStorage.setItem("url_pathname", window.location.pathname);
        window.location.href = getConfig("authUrl");
    } else if (response.response.status == 400) {
        notifyUser(body ?? "A bad request was made to the backend");
    } else if (response.response.status == 404) {
        //enqueueSnackbar("A requested document was not found", snackbarInfo);
    } else if (response.response.status == 422) {
        //notifyUser("Data sent to the server could not be processed");
    } else if (response.response.status == 500) {
        notifyUser("The server has encountered an error and could not process the request");
    } else if (response.response.status >= 400) {
        console.log("HTTP error in request to backend");
        console.log(response);
    }

    return undefined;
}

function notifyUser(body: string): void {
    try {
        var jsonResponse = JSON.parse(body);

        if (jsonResponse.hasOwnProperty("detail")) {
            enqueueSnackbar(jsonResponse.detail, snackbarError);
        } else {
            enqueueSnackbar("The server has reported an error, but we have no further details");
            console.error("JSON error response from server did not contain a 'detail' field");
        }
    } catch (e) {
        enqueueSnackbar(body, snackbarError);
    }
}
