/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MeasurementSchema } from './MeasurementSchema';
import {
    MeasurementSchemaFromJSON,
    MeasurementSchemaFromJSONTyped,
    MeasurementSchemaToJSON,
} from './MeasurementSchema';

/**
 * 
 * @export
 * @interface FileUploadReport
 */
export interface FileUploadReport {
    /**
     * 
     * @type {number}
     * @memberof FileUploadReport
     */
    statusCode: number;
    /**
     * 
     * @type {Array<MeasurementSchema>}
     * @memberof FileUploadReport
     */
    measurements: Array<MeasurementSchema>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileUploadReport
     */
    invalidMeasurements: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FileUploadReport
     */
    size: number;
}

/**
 * Check if a given object implements the FileUploadReport interface.
 */
export function instanceOfFileUploadReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "statusCode" in value;
    isInstance = isInstance && "measurements" in value;
    isInstance = isInstance && "invalidMeasurements" in value;
    isInstance = isInstance && "size" in value;

    return isInstance;
}

export function FileUploadReportFromJSON(json: any): FileUploadReport {
    return FileUploadReportFromJSONTyped(json, false);
}

export function FileUploadReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileUploadReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'measurements': ((json['measurements'] as Array<any>).map(MeasurementSchemaFromJSON)),
        'invalidMeasurements': json['invalid_measurements'],
        'size': json['size'],
    };
}

export function FileUploadReportToJSON(value?: FileUploadReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status_code': value.statusCode,
        'measurements': ((value.measurements as Array<any>).map(MeasurementSchemaToJSON)),
        'invalid_measurements': value.invalidMeasurements,
        'size': value.size,
    };
}

