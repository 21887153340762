/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResourceType } from './ResourceType';
import {
    ResourceTypeFromJSON,
    ResourceTypeFromJSONTyped,
    ResourceTypeToJSON,
} from './ResourceType';

/**
 * 
 * @export
 * @interface ResourceDefinition
 */
export interface ResourceDefinition {
    /**
     * 
     * @type {string}
     * @memberof ResourceDefinition
     */
    name: string;
    /**
     * 
     * @type {ResourceType}
     * @memberof ResourceDefinition
     */
    type: ResourceType;
    /**
     * 
     * @type {string}
     * @memberof ResourceDefinition
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof ResourceDefinition
     */
    priorityScore: number;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceDefinition
     */
    included: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceDefinition
     */
    zipped: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourceDefinition
     */
    scale: number;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceDefinition
     */
    isOptional?: boolean;
}

/**
 * Check if a given object implements the ResourceDefinition interface.
 */
export function instanceOfResourceDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "priorityScore" in value;
    isInstance = isInstance && "included" in value;
    isInstance = isInstance && "zipped" in value;
    isInstance = isInstance && "scale" in value;

    return isInstance;
}

export function ResourceDefinitionFromJSON(json: any): ResourceDefinition {
    return ResourceDefinitionFromJSONTyped(json, false);
}

export function ResourceDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': ResourceTypeFromJSON(json['type']),
        'title': json['title'],
        'priorityScore': json['priorityScore'],
        'included': json['included'],
        'zipped': json['zipped'],
        'scale': json['scale'],
        'isOptional': !exists(json, 'is_optional') ? undefined : json['is_optional'],
    };
}

export function ResourceDefinitionToJSON(value?: ResourceDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': ResourceTypeToJSON(value.type),
        'title': value.title,
        'priorityScore': value.priorityScore,
        'included': value.included,
        'zipped': value.zipped,
        'scale': value.scale,
        'is_optional': value.isOptional,
    };
}

