import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import {
    MeasurementHeader,
    MeasurementsApi,
    MeasurementSchema,
    MeasurementUpdateRequest,
    ResponseError,
} from "../../api";

import EditMeasurementTextField from "./EditMeasurementTextField";

import {useSnackbar} from "notistack";

import {useNavigate} from "react-router-dom";

import {apiConfig} from "../../ApiConfig";

import EditIcon from "@mui/icons-material/Edit";
import {handleNetworkError} from "../../helpers/error";
import {styled, useTheme} from "@mui/material";

const measurementsApi = new MeasurementsApi(apiConfig);

type MeasurementSchemaParam = [keyof MeasurementSchema];

interface SimplifiedMeasurementSchema {
    label: string;
    paramName: MeasurementSchemaParam;
    validate: (value: string | number) => boolean;
    validationText: string;
    multiplier: number;
}

var editableValues: SimplifiedMeasurementSchema[] = [
    {
        label: "Labeled species name",
        paramName: "labeledName" as unknown as MeasurementSchemaParam,
        validate: (val) => !!val,
        validationText: "Labaled name must not be empty",
        multiplier: 0,
    },
    {
        label: "Labeled species concentration (nM)",
        paramName: "labeledConcentration" as unknown as MeasurementSchemaParam,
        validate: (val) =>
            typeof val === "undefined" ||
            (val as string).length === 0 ||
            (/^\d*\.?\d+$/.test(val as string) && val >= 0),
        validationText: "Labeled concentration must be a non-negative nM value.",
        multiplier: 1000,
    },
    {
        label: "Unlabeled species name",
        paramName: "unlabeledName" as unknown as MeasurementSchemaParam,
        validate: (val) => true,
        validationText: "",
        multiplier: 0,
    },
    {
        label: "Unlabeled species concentration (nM)",
        paramName: "unlabeledConcentration" as unknown as [keyof MeasurementSchema],
        validate: (val) =>
            typeof val === "undefined" ||
            (val as string).length === 0 ||
            (/^\d*\.?\d+$/.test(val as string) && val >= 0),
        validationText: "Unlabeled concentration must be a non-negative nM value.",
        multiplier: 1000,
    },
    {
        label: "Complex mixture name",
        paramName: "complexName" as unknown as MeasurementSchemaParam,
        validate: (val) => true,
        validationText: "",
        multiplier: 0,
    },
    {
        label: "Complex mixture dilution (%)",
        paramName: "complexMixtureDilution" as unknown as [keyof MeasurementSchema],
        validate: (val) =>
            typeof val === "undefined" ||
            (val as string).length === 0 ||
            (/^\d*\.?\d+$/.test(val as string) && val >= 0 && val <= 100),
        validationText: "Complex mixture dilution percentage must be a number between 0 and 100.",
        multiplier: 0,
    },
];

interface editMeasurementModalProps {
    className?: string;
    selectedMeasurementId: string;
    header: MeasurementHeader;
    setLastModifiedHeaders?: React.Dispatch<React.SetStateAction<MeasurementHeader[]>>;
}

// export default function EditMeasurementModal({
//     selectedMeasurementId,
//     header,
//     setLastModifiedHeaders,
// }: editMeasurementModalProps) {
function EditMeasurementModal({
    selectedMeasurementId,
    header,
    setLastModifiedHeaders,
    className,
}: editMeasurementModalProps) {
    const [open, setOpen] = React.useState<boolean>(false);
    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
    const [allValuesAreValid, setAllValuesAreValid] = React.useState<boolean>(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [measurementData, setMeasurementData] = React.useState<MeasurementSchema>({} as MeasurementSchema);

    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();

    React.useEffect(() => {
        measurementsApi
            .getMeasurementById(selectedMeasurementId)
            .then((resp: MeasurementSchema) => {
                setMeasurementData(resp);
                setIsLoaded(true);
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    }, [open, selectedMeasurementId]);

    React.useEffect(() => {
        const allValuesValid = editableValues.every((ev) => {
            const value = measurementData[ev.paramName as unknown as keyof typeof measurementData] as string | number;
            const isValid = ev.validate(value);
            return isValid;
        });

        // adding extra validation - ensuring that labeledConcentrationValue and at least one of unlabeledConcentration or complexMixtureDiluttion is filled, the backend would reject otherwise
        const labeledConcentrationValue = measurementData[
            "labeledConcentration" as unknown as keyof typeof measurementData
        ] as string;
        const unlabeledConcentrationValue = measurementData[
            "unlabeledConcentration" as unknown as keyof typeof measurementData
        ] as string;
        const complexMixtureDiluttionValue = measurementData[
            "complexMixtureDilution" as unknown as keyof typeof measurementData
        ] as string;
        const necessaryValuesFilled: boolean =
            typeof labeledConcentrationValue !== "undefined" &&
            labeledConcentrationValue.toString().length > 0 &&
            ((typeof unlabeledConcentrationValue !== "undefined" &&
                unlabeledConcentrationValue.toString().length > 0) ||
                (typeof complexMixtureDiluttionValue !== "undefined" &&
                    complexMixtureDiluttionValue.toString().length > 0));

        setAllValuesAreValid(allValuesValid && necessaryValuesFilled);
    }, [measurementData]);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        changeTarget: MeasurementSchemaParam
    ) => {
        var val = event.target.value;
        let ev: any = editableValues.filter((e) => e.paramName == changeTarget);
        if (ev !== undefined && (ev as SimplifiedMeasurementSchema[]).length == 1) {
            let fieldConfig: SimplifiedMeasurementSchema = ev[0];
            if (fieldConfig.multiplier != 0 && val) {
                let f = parseFloat(val);
                f /= fieldConfig.multiplier;
                val = f.toString();
            }
        }
        setMeasurementData((oldMeasurementData) => ({
            ...oldMeasurementData,
            [changeTarget as unknown as string]: val,
        }));
    };

    const navigate = useNavigate();

    const handleEditMeasurement = (): void => {
        const requestBody: MeasurementUpdateRequest = {
            id: selectedMeasurementId,
            labeledName: measurementData.labeledName,
            labeledConcentration: parseFloat(measurementData.labeledConcentration as unknown as string),
            unlabeledName: measurementData.unlabeledName,
            unlabeledConcentration: parseFloat(measurementData.unlabeledConcentration as unknown as string),
            complexName: measurementData.complexName,
            complexMixtureDilution: parseFloat(measurementData.complexMixtureDilution as unknown as string),
        };

        setOpen(false);
        measurementsApi
            .updateMeasurementById(selectedMeasurementId, requestBody)
            .then((response) => {
                enqueueSnackbar(`Measurement for ${response.circuitId} updated successfully.`, {variant: "success"});
                // we will need to send the current header and the new header with modified values (if one exists)
                const headersToSend = [header];
                if (
                    header?.labeledName !== measurementData.labeledName ||
                    (header?.unlabeledName !== measurementData.unlabeledName && measurementData.unlabeledName)
                ) {
                    const newHeader = {
                        ...header,
                        labeledName: measurementData.labeledName,
                        unlabeledName: measurementData.unlabeledName,
                    };
                    headersToSend.push(newHeader as MeasurementHeader);
                }

                setLastModifiedHeaders && header && setLastModifiedHeaders(headersToSend as MeasurementHeader[]);
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    };

    return (
        <>
            <ListItemButton onClick={handleOpen} sx={{position: "relative", top: "7px"}}>
                <EditIcon />
            </ListItemButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={className}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit measurement (in circuit {measurementData.circuitId})
                    </Typography>
                    <Box sx={{minWidth: 120, paddingTop: "2rem", paddingBottom: "0.5rem"}}>
                        {editableValues.map((ev) => {
                            const value = measurementData[ev.paramName as unknown as keyof typeof measurementData] as
                                | string
                                | number;

                            const isValid = ev.validate(value);
                            const isModified =
                                measurementData.originalValues !== undefined &&
                                ((measurementData.originalValues[
                                    ev.paramName as unknown as keyof typeof measurementData.originalValues
                                ] !== undefined &&
                                    measurementData.originalValues[
                                        ev.paramName as unknown as keyof typeof measurementData.originalValues
                                    ] != measurementData[ev.paramName as unknown as keyof typeof measurementData]) ||
                                    (measurementData.originalValues[
                                        ev.paramName as unknown as keyof typeof measurementData.originalValues
                                    ] === undefined &&
                                        measurementData[ev.paramName as unknown as keyof typeof measurementData] !==
                                            undefined));

                            return (
                                <EditMeasurementTextField
                                    showReset={isModified}
                                    sx={{
                                        "& .MuiOutlinedInput-input": {
                                            color: isModified ? theme.palette.warning.dark : undefined,
                                        },
                                    }}
                                    key={`${ev.label}_field`}
                                    label={ev.label}
                                    value={
                                        ev.multiplier != 0 && value
                                            ? (parseFloat(value.toString()) * ev.multiplier).toString()
                                            : value
                                    }
                                    id={ev.label}
                                    paramName={ev.paramName}
                                    handleInputChange={handleInputChange}
                                    isValidated={isValid}
                                    validationText={isValid ? "" : ev.validationText}
                                    resetValue={() => {
                                        if (measurementData.originalValues !== undefined) {
                                            setMeasurementData((oldMeasurementData) => ({
                                                ...oldMeasurementData,
                                                [ev.paramName as unknown as string | number]:
                                                    oldMeasurementData.originalValues?.[
                                                        ev.paramName as unknown as keyof typeof measurementData.originalValues
                                                    ],
                                            }));
                                        }
                                    }}
                                />
                            );
                        })}
                    </Box>
                    <p style={{display: allValuesAreValid ? "none" : "block"}}>
                        For the measurement to be valid, labeled species concentration and unlabeled species
                        concentration and/or complex mixture dilution needs to be provided.
                    </p>
                    <Typography id="modal-modal-description" sx={{mt: 2, textAlign: "center", margin: "auto"}}>
                        <Button
                            disabled={!isLoaded || !allValuesAreValid}
                            onClick={handleEditMeasurement}
                            variant="contained"
                            tabIndex={-1}
                            size="medium"
                            sx={{marginTop: "1rem", marginBottom: "1rem"}}
                        >
                            Edit measurement data
                        </Button>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}

export default styled(EditMeasurementModal)(
    (theme) => `
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 24px;
    transform: translate(-50%, -50%);
    width: 650px;
    background-color: ${theme.theme.palette.background.paper};
    border: 2px solid #000;
    box-shadow: #606060 8px 8px;
    border-radius: 6px;
`
);
