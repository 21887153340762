/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Extra } from './Extra';
import {
    ExtraFromJSON,
    ExtraFromJSONTyped,
    ExtraToJSON,
} from './Extra';

/**
 * 
 * @export
 * @interface UpdateStudyRequest
 */
export interface UpdateStudyRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateStudyRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudyRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStudyRequest
     */
    description?: string;
    /**
     * 
     * @type {Extra}
     * @memberof UpdateStudyRequest
     */
    extra?: Extra;
}

/**
 * Check if a given object implements the UpdateStudyRequest interface.
 */
export function instanceOfUpdateStudyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function UpdateStudyRequestFromJSON(json: any): UpdateStudyRequest {
    return UpdateStudyRequestFromJSONTyped(json, false);
}

export function UpdateStudyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateStudyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'extra': !exists(json, 'extra') ? undefined : ExtraFromJSON(json['extra']),
    };
}

export function UpdateStudyRequestToJSON(value?: UpdateStudyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'extra': ExtraToJSON(value.extra),
    };
}

