import React, {useContext, useEffect, useState} from "react";

import {Stack, styled, Tooltip, Typography} from "@mui/material";

import {useParams} from "react-router-dom";

import {useSnackbar} from "notistack";

import {useNavigate} from "react-router-dom";
import {
    AnalysisApi,
    ExperimentSummary,
    MeasurementSchema,
    ResponseError,
    SingleExperimentResponse,
    ZippedAnalysisRequest,
    ZippedAnalysisResponse,
} from "../../api";
import {apiConfig} from "../../ApiConfig";
import {ExperimentsApi} from "../../api";
import {handleNetworkError, snackbarError, snackbarSuccess} from "../../helpers/error";

import CreationModal from "./CreationModal";
import DeletionModal from "./DeletionModal";

import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {useTheme} from "@mui/material/styles";
import MeasurementEntry from "../../components/MeasurementEntry";
import {getConfig} from "../../RuntimeConfig";
import {ExperimentContext} from "../../App";
import {
    PageButtonBar,
    PageContainer,
    PageContentBlock,
    PageHeader,
    StandardDateTimeDisplay,
} from "../../helpers/HelperComponents";
import AnalysisStatus from "../../components/AnalysisStatus";
import AnalysisInputDataValidation from "../../components/AnalysisInputDataValidation";

const experimentsApi = new ExperimentsApi(apiConfig);
const analysisApi = new AnalysisApi(apiConfig);

export default styled(function (props: any) {
    const [experimentData, setExperimentData] = React.useState<ExperimentSummary>({} as ExperimentSummary);
    const [experimentMeasurements, setExperimentMeasurements] = React.useState<MeasurementSchema[]>(
        [] as MeasurementSchema[]
    );
    const [isAnalysisInputDataValid, setIsAnalysisInputDataValid] = React.useState<boolean | null>(null);
    const [analysisStatus, setAnalysisStatus] = React.useState<string | null>(null);
    const [reportStatus, setReportStatus] = React.useState<ZippedAnalysisResponse | null>(null);

    const {id} = useParams();
    const navigate = useNavigate();
    const {eventMessage} = props;

    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    const experimentTypeMap = useContext(ExperimentContext);

    function updatePage() {
        getExperimentData(id as string);
    }

    useEffect(() => {
        getExperimentData(id as string);
    }, []);

    useEffect(() => {
        getExperimentData(id as string);
    }, [id, eventMessage]);

    function getExperimentData(experimentId: string): void {
        experimentsApi
            .getExperimentById(experimentId)
            .then((data: SingleExperimentResponse) => {
                setExperimentData(data);

                if (data.validAnalysis === undefined) {
                    setAnalysisStatus("Not run");
                }

                getAnalysisStatus(data.validAnalysis as string);

                if (data.measurements !== undefined) {
                    setExperimentMeasurements(data.measurements);
                }
            })
            .catch((response: ResponseError) => {
                if (response.response.status === 404) {
                    navigate("/404", {replace: true});
                } else if (response.response.status === 422) {
                    navigate("/422/" + response.response.statusText, {replace: true});
                } else {
                    handleNetworkError(response).then((target) => {
                        if (target) {
                            navigate(target);
                        }
                    });
                }
            });
    }

    const deleteExperiment = (experiment_id: string) => {
        experimentsApi
            .deleteExperiment(experiment_id)
            .then((_response) => {
                enqueueSnackbar(`Successfully deleted.`, snackbarSuccess);
                navigate("/experiments", {replace: true});
            })
            .catch((response: ResponseError) => {
                if (response.response.status === 204) {
                    enqueueSnackbar(`Successfully deleted.`, snackbarSuccess);
                    navigate("/experiments", {replace: true});
                }
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    };

    function getAnalysisStatus(analysisId: string): void {
        if (analysisId !== undefined) {
            analysisApi
                .getAnalysisSummary(analysisId)
                .then((data) => {
                    setAnalysisStatus(data.status || null);
                })
                .catch((response: ResponseError) => {
                    if (response && response.response && response.response.status === 204) {
                        setAnalysisStatus("Not run");
                        return;
                    }
                    handleNetworkError(response).then((target) => {
                        if (target) {
                            navigate(target);
                        }
                    });
                });
            analysisApi
                .getReportStatus(analysisId)
                .then((resp) => {
                    setReportStatus(resp);
                })
                .catch((response: ResponseError) => {
                    setReportStatus(null);
                    handleNetworkError(response).then((target) => {
                        if (target) {
                            navigate(target);
                        }
                    });
                });
        }
    }

    const runAnalysis = (experimentId: string): void => {
        analysisApi
            .createAnalysis(experimentId)
            .then((response) => {
                enqueueSnackbar(`Analysis started.`, snackbarSuccess);
                updatePage();
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    };

    const viewAnalysis = (analysisId: string): void => {
        navigate(`/analysis/${analysisId}`);
    };

    const generateReport = (analysisId: string): void => {
        analysisApi
            .requestReport(analysisId, {generate: true} as ZippedAnalysisRequest)
            .then((resp) => {
                setReportStatus(resp);
            })
            .catch((resp: ResponseError) => {
                enqueueSnackbar("Could not start report generation", snackbarError);
            });
    };

    return (
        <div className={props.className}>
            <PageHeader>
                <Stack spacing={0.5}>
                    <Typography variant="h4">{experimentData.name}</Typography>
                    <Typography variant="body2" color="text.secondary" data-testid="experiment-analysis-text">
                        {
                            experimentTypeMap.nameMap[
                                experimentData.type as unknown as keyof typeof experimentTypeMap.nameMap
                            ]
                        }{" "}
                        analysis
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {experimentData.description}
                    </Typography>
                </Stack>
            </PageHeader>

            <PageContainer>
                <PageContentBlock>
                    <PageButtonBar
                        backLink={
                            <Link to={"/experiments"}>
                                <Tooltip title={"Back to experiments"}>
                                    <IconButton
                                        sx={{
                                            marginLeft: "10px",
                                            backgroundColor: "primary.main",
                                            color: "primary.contrastText",
                                            "&:hover": {backgroundColor: "primary.dark"},
                                        }}
                                    >
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        }
                    >
                        <div style={{textAlign: "right", padding: "1rem"}}>
                            <div
                                style={{
                                    display: "inline-block",
                                    position: "relative",
                                    top: "-10px",
                                    marginRight: "30px",
                                }}
                            >
                                <AnalysisStatus
                                    created={experimentData.created}
                                    analysisStatus={experimentData.analysisStatus}
                                    analysisId={experimentData.validAnalysis}
                                />
                                <br />
                                Report status:&nbsp;
                                <span>
                                    {id === undefined ? (
                                        <i style={{color: theme.palette.text.disabled}}>Waiting</i>
                                    ) : reportStatus?.processing ? (
                                        "Processing"
                                    ) : reportStatus?.link ? (
                                        <>
                                            Ready{" "}
                                            <a
                                                style={{textDecoration: "underline"}}
                                                href={getConfig("backendUrl") + reportStatus.link}
                                            >
                                                {" "}
                                                Download
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            Not generated{" "}
                                            {experimentData.validAnalysis &&
                                            analysisStatus?.toLowerCase() === "completed" ? (
                                                <a
                                                    onClick={() =>
                                                        generateReport(experimentData.validAnalysis as string)
                                                    }
                                                    style={{cursor: "pointer", textDecoration: "underline"}}
                                                >
                                                    Generate
                                                </a>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    )}
                                </span>
                            </div>
                        </div>
                        <Tooltip title={"View analysis"}>
                            <div>
                                <IconButton
                                    sx={{
                                        backgroundColor: "primary.main",
                                        color: "primary.contrastText",
                                        "&:hover": {backgroundColor: "primary.dark"},
                                        "&:disabled": {backgroundColor: "action.disabledBackground"},
                                    }}
                                    disabled={analysisStatus?.toLowerCase() !== "completed"}
                                    onClick={() => viewAnalysis(experimentData.validAnalysis as string)}
                                    data-testid="view-analysis-button"
                                >
                                    <AssessmentIcon />
                                </IconButton>
                            </div>
                        </Tooltip>
                        <Tooltip title={"Run analysis"}>
                            <div>
                                <IconButton
                                    sx={{
                                        backgroundColor: "primary.main",
                                        color: "primary.contrastText",
                                        "&:hover": {backgroundColor: "primary.dark"},
                                        "&:disabled": {backgroundColor: "action.disabledBackground"},
                                    }}
                                    disabled={
                                        (analysisStatus !== "Not run" && analysisStatus !== "FAILED") ||
                                        isAnalysisInputDataValid !== true
                                    }
                                    onClick={() => runAnalysis(id as string)}
                                    data-testid="run-analysis-button"
                                >
                                    <PlayArrowIcon />
                                </IconButton>
                            </div>
                        </Tooltip>
                        <CreationModal
                            experimentData={experimentData}
                            experimentMeasurements={experimentMeasurements}
                            experimentUpdatedCallback={updatePage}
                        >
                            <Tooltip title={"Edit experiment"}>
                                <IconButton
                                    sx={{
                                        marginLeft: "10px",
                                        backgroundColor: "primary.main",
                                        color: "primary.contrastText",
                                        "&:hover": {backgroundColor: "primary.dark"},
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </CreationModal>
                        <DeletionModal experimentId={id as string} deleteExperiment={deleteExperiment}>
                            <Tooltip title={"Delete experiment"}>
                                <IconButton
                                    style={{backgroundColor: theme.palette.error.main}}
                                    sx={{
                                        marginLeft: "10px",
                                        color: "primary.contrastText",
                                        "&:hover": {backgroundColor: "primary.dark"},
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </DeletionModal>
                    </PageButtonBar>

                    {experimentData?.id && (
                        <AnalysisInputDataValidation
                            experimentData={experimentData}
                            experimentMeasurements={experimentMeasurements}
                            isAnalysisInputDataValid={isAnalysisInputDataValid}
                            setIsAnalysisInputDataValid={(v) => {
                                setIsAnalysisInputDataValid(v);
                            }}
                        />
                    )}

                    <Typography
                        variant="h5"
                        style={{
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            backgroundColor: theme.palette.background.paper,
                            width: "100%",
                            textAlign: "center",
                        }}
                    >
                        Measurements
                    </Typography>
                    <div style={{background: theme.palette.background.default}}>
                        {experimentMeasurements.map((m) => {
                            return (
                                <div key={m.id} style={{display: "flex"}}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-end",
                                            backgroundColor: theme.palette.background.paper,
                                            marginTop: "5px",
                                            paddingLeft: "20px",
                                        }}
                                    >
                                        <div style={{fontSize: "8pt", color: theme.palette.text.secondary}}>
                                            Sample date
                                        </div>
                                        <div>
                                            <StandardDateTimeDisplay value={m.runTimestamp} />
                                        </div>
                                    </div>
                                    <MeasurementEntry sx={{flex: "12"}} measurement={m} useNames={true} />
                                </div>
                            );
                        })}
                    </div>
                </PageContentBlock>
            </PageContainer>
        </div>
    );
})((theme) => {
    return {};
});
