import {Grid, IconButton, styled, Tooltip, Typography, useTheme} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {useEffect, useState} from "react";
import {ErrorApi, ErrorInformation} from "../../api";
import {handleNetworkError} from "../../helpers/error";
import {apiConfig} from "../../ApiConfig";
import JsonTree from "./JsonTree";

interface Props {
    className?: string;
}

interface JsonTreeProps {
    data: any;
}
const errorApi = new ErrorApi(apiConfig);

function ErrorDetails(props: Props): JSX.Element {
    const theme = useTheme();
    const {id} = useParams();
    const navigate = useNavigate();
    const [errorList, setErrorList] = useState<ErrorInformation>();

    useEffect(() => {
        if (id === undefined) return;
        errorApi
            .getErrorById(id)
            .then((resp) => {
                setErrorList(resp);
            })
            .catch((response) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    }, [id, navigate]);

    return (
        <>
            <div style={{width: "100%", paddingTop: "2rem"}}></div>
            <Grid
                container
                sx={{
                    padding: "10px",
                    backgroundColor: "background.paper",
                    width: "1200px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "2rem",
                }}
            >
                <Grid item xs={12}>
                    <Typography align={"center"} noWrap={true} variant={"h4"}>
                        Error Details
                    </Typography>
                </Grid>
            </Grid>

            <div
                style={{
                    width: "1200px",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                <Grid
                    item
                    xs
                    sx={{
                        textAlign: "left",
                        backgroundColor: "background.paper",
                        marginBottom: "2rem",
                        paddingBottom: "2rem",
                    }}
                >
                    <Link to={"/errors"}>
                        <Tooltip title={"Back to errors"}>
                            <IconButton
                                sx={{
                                    marginLeft: "10px",
                                    marginTop: "10px",
                                    backgroundColor: "primary.main",
                                    color: "primary.contrastText",
                                    "&:hover": {backgroundColor: "primary.dark"},
                                }}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        </Tooltip>
                    </Link>
                    <JsonTree data={{id, errorList}} />
                </Grid>
            </div>
        </>
    );
}

export default styled(ErrorDetails)``;
