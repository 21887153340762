/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Statistics
 */
export interface Statistics {
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    kdLog10: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    kd: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    a0Log10?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    a0?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    fB: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    fAb: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    eps: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    rhFree?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    rhComplex?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    nReceptors?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    nA?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    nALog10?: number;
}

/**
 * Check if a given object implements the Statistics interface.
 */
export function instanceOfStatistics(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "kdLog10" in value;
    isInstance = isInstance && "kd" in value;
    isInstance = isInstance && "fB" in value;
    isInstance = isInstance && "fAb" in value;
    isInstance = isInstance && "eps" in value;

    return isInstance;
}

export function StatisticsFromJSON(json: any): Statistics {
    return StatisticsFromJSONTyped(json, false);
}

export function StatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Statistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kdLog10': json['Kd_log10'],
        'kd': json['Kd'],
        'a0Log10': !exists(json, 'A_0_log10') ? undefined : json['A_0_log10'],
        'a0': !exists(json, 'A_0') ? undefined : json['A_0'],
        'fB': json['f_b'],
        'fAb': json['f_ab'],
        'eps': json['eps'],
        'rhFree': !exists(json, 'rh_free') ? undefined : json['rh_free'],
        'rhComplex': !exists(json, 'rh_complex') ? undefined : json['rh_complex'],
        'nReceptors': !exists(json, 'n_receptors') ? undefined : json['n_receptors'],
        'nA': !exists(json, 'n_A') ? undefined : json['n_A'],
        'nALog10': !exists(json, 'n_A_log10') ? undefined : json['n_A_log10'],
    };
}

export function StatisticsToJSON(value?: Statistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Kd_log10': value.kdLog10,
        'Kd': value.kd,
        'A_0_log10': value.a0Log10,
        'A_0': value.a0,
        'f_b': value.fB,
        'f_ab': value.fAb,
        'eps': value.eps,
        'rh_free': value.rhFree,
        'rh_complex': value.rhComplex,
        'n_receptors': value.nReceptors,
        'n_A': value.nA,
        'n_A_log10': value.nALog10,
    };
}

