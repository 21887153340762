/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnalysisSchema
 */
export interface AnalysisSchema {
    /**
     * 
     * @type {string}
     * @memberof AnalysisSchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSchema
     */
    jobId?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSchema
     */
    status: string;
    /**
     * 
     * @type {Date}
     * @memberof AnalysisSchema
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof AnalysisSchema
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSchema
     */
    experimentId: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSchema
     */
    userId: string;
}

/**
 * Check if a given object implements the AnalysisSchema interface.
 */
export function instanceOfAnalysisSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "experimentId" in value;
    isInstance = isInstance && "userId" in value;

    return isInstance;
}

export function AnalysisSchemaFromJSON(json: any): AnalysisSchema {
    return AnalysisSchemaFromJSONTyped(json, false);
}

export function AnalysisSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalysisSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'jobId': !exists(json, 'job_id') ? undefined : json['job_id'],
        'status': json['status'],
        'created': (new Date(json['created'])),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'experimentId': json['experiment_id'],
        'userId': json['user_id'],
    };
}

export function AnalysisSchemaToJSON(value?: AnalysisSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'job_id': value.jobId,
        'status': value.status,
        'created': (value.created.toISOString()),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'experiment_id': value.experimentId,
        'user_id': value.userId,
    };
}

