import * as React from "react";
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";

import {MaapTableOutputRow, NllsTableOutputRow} from "../../types";
import type {NllsResult} from "../../api";

import {addTextStyles, extractAnalysisOutputResultsNLLS, formatValueToScientific} from "../../helpers";
import reactStringReplace from "react-string-replace";
import {useTheme} from "@mui/material/styles";
const math = require("mathjs");

const sciNotationFields = ["kd", "a", "nReceptors"];

const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 90, hide: true },
    {
        field: "parameter",
        headerName: "Parameter name",
        type: "string",
        width: 235,
        renderCell: (param: any) => <span key={param.value}>{addTextStyles(param.value)}</span>,
    },
    {
        field: "unit",
        headerName: "Unit",
        type: "string",
        width: 100,
    },
    {
        field: "value",
        headerName: "Value",
        type: "string",
        width: 200,
        renderCell: (data: GridRenderCellParams) => {
            if (sciNotationFields.includes(data.row.id)) {
                return <>{math.format(data.value, {notation: "auto", lowerExp: -3, upperExp: 3}).replace("e+", "e")}</>;
            }
        },
    },
];

export default function AnalysisDataTableNLLS({results}: {results: NllsResult}) {
    let rows: NllsTableOutputRow[] = "globalFit" in results ? extractAnalysisOutputResultsNLLS(results) : [];
    const theme = useTheme();

    return (
        <DataGrid
            autoHeight={true}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.id}
            pageSize={100}
            rowsPerPageOptions={[100]}
            hideFooter={true}
            disableSelectionOnClick
            sx={{
                backgroundColor: theme.palette.background.paper,
                border: "none",
                color: theme.palette.text.primary,
                "& >.MuiDataGrid-main": {
                    "&>.MuiDataGrid-columnHeaders": {
                        borderColor: theme.palette.background.default,
                    },
                    "& div div div div >.MuiDataGrid-cell": {
                        borderColor: theme.palette.background.default,
                    },
                },
                "& .MuiDataGrid-footerContainer": {
                    borderColor: theme.palette.background.default,
                },
            }}
        />
    );
}
