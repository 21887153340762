import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material";
import SelectItemsPerPage from "./SelectItemsPerPage";
import {Theme, useTheme} from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {useEffect} from "react";

interface ListPaginationProps {
    children?: React.ReactNode;
    className?: string;
    currentPage: number;
    itemsPerPage?: number | undefined;
    numberOfPages: number;
    showSelected?: boolean;
    rowsPerPageOptions: Array<number>;
    rowsCount?: number;
    handleChangePaginationPage: (newPage: number) => void;
    handleChangeItemsPerPage: (newItemsNumber: number) => void;
    handleShowSelected?: (e: boolean) => void;
}

function ListPagination(props: ListPaginationProps): JSX.Element {
    const theme = useTheme();

    const handleChange = (event: React.ChangeEvent<unknown> | undefined, value: number) => {
        props.handleChangePaginationPage(value);
    };

    const renderShowActions = React.useMemo(() => {
        const setShowSelectedFactory = (value: boolean) => (e: any) => {
            props.handleShowSelected && props.handleShowSelected(value);
        };

        if (props.showSelected === undefined) {
            return <>&nbsp;</>;
        }
        if (props.showSelected === true) {
            return (
                <span onClick={setShowSelectedFactory(false)}>
                    Show all <ExpandMoreIcon sx={{position: "relative", top: "5px"}} />
                </span>
            );
        }
        if (props.showSelected === false) {
            return (
                <span onClick={setShowSelectedFactory(true)}>
                    Show selected <ExpandLessIcon sx={{position: "relative", top: "5px"}} />
                </span>
            );
        }
    }, [props.showSelected]);

    useEffect(() => {
        if (props.currentPage && props.currentPage > 0) {
            handleChange(undefined, props.currentPage);
        }
    }, []);

    return (
        <div className={props.className}>
            <div style={{minWidth: "160px", paddingLeft: "10px", cursor: "pointer"}}>{renderShowActions}</div>
            <div style={{flex: "500"}}>
                {props.itemsPerPage !== undefined ? (
                    <Pagination
                        sx={{" > *": {justifyContent: "center"}}}
                        defaultPage={props.currentPage}
                        page={props.currentPage}
                        onChange={handleChange}
                        count={props.numberOfPages}
                        siblingCount={2}
                        boundaryCount={2}
                    />
                ) : (
                    <div>&nbsp;</div>
                )}
            </div>
            <div style={{minWidth: "160px"}}>
                <SelectItemsPerPage
                    itemsPerPage={props.itemsPerPage}
                    itemsPerPageOptions={props.rowsPerPageOptions}
                    handleChangeItemsPerPage={props.handleChangeItemsPerPage}
                />
            </div>
        </div>
    );
}

export default styled(ListPagination)((theme) => {
    return {
        marginTop: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.theme.palette.background.paper,
        minHeight: "55px",
        height: "auto",
        marginBottom: theme.theme.spacing.toString(),
        "& >div": {
            flex: "1",
        },
        "& .common": {
            verticalAlign: "middle",
        },
        "& .divider": {
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: theme.theme.palette.primary.main,
        },
    };
});
