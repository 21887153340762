import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";

import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";

interface StudiesTableState {
    sortModel: GridSortModel;
    filterModel: GridFilterModel;
    pageSize: number;
    page: number;
}

const initialState = {sortModel: [{field: "created", sort: "desc"}], pageSize: 10, page: 0} as StudiesTableState;

const StudiesTableStateSlice = createSlice({
    name: "StudiesTableState",
    initialState,
    reducers: {
        modifySortModel(state, action: PayloadAction<GridSortModel>) {
            state.sortModel = action.payload;
        },
        modifyFilterModel(state, action: PayloadAction<GridFilterModel>) {
            state.filterModel = action.payload;
        },
        modifyPageRows(state, action: PayloadAction<number>) {
            state.pageSize = action.payload;
        },
        modifyPage(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.sortModel = [];
        });
    },
});

export const {modifySortModel, modifyFilterModel, modifyPageRows, modifyPage} = StudiesTableStateSlice.actions;
export default StudiesTableStateSlice.reducer;
