import React from "react";
import {styled} from "@mui/material";
import {useTheme} from "@mui/styles";
import Box from "@mui/material/Box";
import TopBarRight from "../../components/Svgs/TopBarRight";

interface ShowColorsProps {
    className?: string;
}

function ShowColors(props: ShowColorsProps) {
    const theme: any = useTheme();

    return (
        <Box sx={{margin: "20px", "& >div": {paddingLeft: "20px"}}}>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                }}
            >
                palette.primary
                <div style={{display: "flex", color: theme.palette.primary.contrastText}}>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.primary.main}}>main</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.primary.light}}>light</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.primary.dark}}>dark</div>
                    <div
                        style={{
                            color: theme.palette.primary.main,
                            minWidth: "100px",
                            backgroundColor: theme.palette.primary.contrastText,
                        }}
                    >
                        contrast
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                }}
            >
                palette.secondary
                <div style={{display: "flex", color: theme.palette.secondary.contrastText}}>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.secondary.main}}>main</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.secondary.light}}>light</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.secondary.dark}}>dark</div>
                    <div
                        style={{
                            color: theme.palette.secondary.main,
                            minWidth: "100px",
                            backgroundColor: theme.palette.secondary.contrastText,
                        }}
                    >
                        contrast
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                }}
            >
                palette.success
                <div style={{display: "flex", color: theme.palette.success.contrastText}}>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.success.main}}>main</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.success.light}}>light</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.success.dark}}>dark</div>
                    <div
                        style={{
                            color: theme.palette.success.main,
                            minWidth: "100px",
                            backgroundColor: theme.palette.success.contrastText,
                        }}
                    >
                        contrast
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                }}
            >
                palette.info
                <div style={{display: "flex", color: theme.palette.info.contrastText}}>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.info.main}}>main</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.info.light}}>light</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.info.dark}}>dark</div>
                    <div
                        style={{
                            color: theme.palette.info.main,
                            minWidth: "100px",
                            backgroundColor: theme.palette.info.contrastText,
                        }}
                    >
                        contrast
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                }}
            >
                palette.warning
                <div style={{display: "flex", color: theme.palette.warning.contrastText}}>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.warning.main}}>main</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.warning.light}}>light</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.warning.dark}}>dark</div>
                    <div
                        style={{
                            color: theme.palette.warning.main,
                            minWidth: "100px",
                            backgroundColor: theme.palette.warning.contrastText,
                        }}
                    >
                        contrast
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                }}
            >
                palette.error
                <div style={{display: "flex", color: theme.palette.error.contrastText}}>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.error.main}}>main</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.error.light}}>light</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.error.dark}}>dark</div>
                    <div
                        style={{
                            color: theme.palette.error.main,
                            minWidth: "100px",
                            backgroundColor: theme.palette.error.contrastText,
                        }}
                    >
                        contrast
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                }}
            >
                palette.background
                <div style={{display: "flex", color: theme.palette.text.main}}>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.background.default}}>default</div>
                    <div style={{minWidth: "100px", backgroundColor: theme.palette.background.paper}}>paper</div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                }}
            >
                palette.text
                <div style={{display: "flex", backgroundColor: theme.palette.background.paper}}>
                    <div style={{minWidth: "100px", color: theme.palette.text.primary}}>primary</div>
                    <div style={{minWidth: "100px", color: theme.palette.text.secondary}}>secondary</div>
                    <div style={{minWidth: "100px", color: theme.palette.text.disabled}}>disabled</div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                }}
            >
                palette.action
                <div style={{minWidth: "100px", backgroundColor: theme.palette.action.disabledBackground}}>
                    disabledBackground
                </div>
                <div style={{minWidth: "100px", backgroundColor: theme.palette.action.disabled}}>disabled</div>
                <div style={{minWidth: "100px", backgroundColor: theme.palette.action.active}}>active</div>
                <div style={{minWidth: "100px", backgroundColor: theme.palette.action.hover}}>hover</div>
                <div style={{minWidth: "100px", backgroundColor: theme.palette.action.selected}}>selected</div>
                <div style={{minWidth: "100px", backgroundColor: theme.palette.action.focus}}>focus</div>
            </div>
        </Box>
    );
}

export default styled(ShowColors)({});
