/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WhoAmIResponse
 */
export interface WhoAmIResponse {
    /**
     * 
     * @type {string}
     * @memberof WhoAmIResponse
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof WhoAmIResponse
     */
    fullname: string;
    /**
     * 
     * @type {string}
     * @memberof WhoAmIResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof WhoAmIResponse
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof WhoAmIResponse
     */
    tenantId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WhoAmIResponse
     */
    roles?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof WhoAmIResponse
     */
    isRequirePasswordChange: boolean;
}

/**
 * Check if a given object implements the WhoAmIResponse interface.
 */
export function instanceOfWhoAmIResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "fullname" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "tenantId" in value;
    isInstance = isInstance && "isRequirePasswordChange" in value;

    return isInstance;
}

export function WhoAmIResponseFromJSON(json: any): WhoAmIResponse {
    return WhoAmIResponseFromJSONTyped(json, false);
}

export function WhoAmIResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WhoAmIResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'fullname': json['fullname'],
        'email': json['email'],
        'userId': json['user_id'],
        'tenantId': json['tenant_id'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'isRequirePasswordChange': json['is_require_password_change'],
    };
}

export function WhoAmIResponseToJSON(value?: WhoAmIResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'fullname': value.fullname,
        'email': value.email,
        'user_id': value.userId,
        'tenant_id': value.tenantId,
        'roles': value.roles,
        'is_require_password_change': value.isRequirePasswordChange,
    };
}

