import {
    gridPageCountSelector,
    gridPageSelector,
    gridPageSizeSelector,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid";

import ListPagination from "../MeasurementFilter/ListPagination";

interface CustomPaginationProps {
    rowsCount?: number;
    rowsPerPageOptions: number[];
    showSelected?: boolean;
    handleShowSelected?: (e: boolean) => void;
}

export default function CustomPagination(props: CustomPaginationProps) {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const itemsPerPage = useGridSelector(apiRef, gridPageSizeSelector);

    const defaultOption = props.rowsPerPageOptions[0] || 10;

    return (
        <ListPagination
            sx={{paddingRight: "20px", paddingLeft: "20px"}}
            currentPage={page + 1}
            numberOfPages={pageCount}
            itemsPerPage={itemsPerPage}
            showSelected={props.showSelected}
            handleShowSelected={props.handleShowSelected}
            handleChangePaginationPage={(p) => apiRef.current.setPage(p - 1)}
            handleChangeItemsPerPage={(n) => apiRef.current.setPageSize(n || defaultOption)}
            rowsPerPageOptions={props.rowsPerPageOptions}
            rowsCount={props.rowsCount}
        />
    );
}
