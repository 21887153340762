import {styled, Typography} from "@mui/material";
import GroupsTable from "../../pages/Study/GroupsTable";
import {StudySummarySchema} from "../../api";

interface StudyDetailsSaffconProps {
    studyData: StudySummarySchema;
}

export default styled((props: StudyDetailsSaffconProps) => {
    return (
        <>
            <Typography
                variant="h5"
                style={{
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                Groups
            </Typography>
            <div>
                <GroupsTable study={props.studyData} />
            </div>
        </>
    );
})((theme) => {
    return {};
});
