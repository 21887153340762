import "./stylesheets/App.scss";
import TopNav from "./components/TopNav";
import Footer from "./components/Footer";
import {BrowserRouter as Router, Routes, Route, useParams} from "react-router-dom";
import ExperimentsTable from "./pages/ExperimentsTable";
import Experiment from "./pages/Experiment";
import Analysis from "./pages/Analysis";
import LandingPage from "./pages/LandingPage";
import Licenses from "./pages/Licenses";
import MeasurementsPage from "./pages/MeasurementsPage";


import {Login, LoginFailed} from "./pages/Login";

import React, {createContext, useEffect, useState} from "react";

import {LicenseInfo} from "@mui/x-license-pro";
import EventListener from "./components/EventListener";
import {createBrowserHistory} from "history";
import {unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import ShowColors from "./pages/ShowColors";
import ErrorList from "./pages/ErrorList";
import ErrorDetails from "./pages/ErrorList/ErrorDetails";
import ErrorImport from "./pages/ErrorList/ErrorImport";
import {GlobalState} from "./GlobalState";
import {AnalysisApi, ExperimentsApi, TypeMapResponse, ServicesApi} from "./api";
import {apiConfig} from "./ApiConfig";
import UploadPage from "./pages/UploadPage";
import Playground from "./pages/Playground";
import {ExperimentType, ResourceOutput} from "./api";
import VersionPage from "./pages/VersionPage";
import Studies from "./pages/StudiesTable";
import Study from "./pages/Study";
import Group from "./pages/Study/Group";
import Plot from "./pages/Study/Plot";
import ErrorAnalysis from "./pages/ErrorList/ErrorAnalysis";


LicenseInfo.setLicenseKey(
    "d7ef89186f89a82c5e31990ffe1c7149Tz01MDU3MixFPTE2OTQzMzMzMDkzODAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export const history = createBrowserHistory();
export const ExperimentContext = createContext({} as TypeMapResponse);
export const AnalysisContext = createContext({} as {[key: string]: ResourceOutput});

const servicesApi = new ServicesApi(apiConfig);

export default function App() {
    const [typeMap, setTypeMap] = useState<TypeMapResponse>({} as TypeMapResponse);
    const [resourceDefinitions, setResourceDefinitions] = useState<{[key: string]: ResourceOutput}>({});

    useEffect(() => {
        servicesApi.getTypeMap().then((resp) => {
            setTypeMap(resp);
        });
        servicesApi.getResourceDefinitions().then((resp) => {
            setResourceDefinitions(resp);
        });
    }, []);

    if (Object.keys(typeMap || {}).length == 0) {
        return <></>;
    }

    const sx: {[key: string]: React.CSSProperties} = {
        app: {
            width: "100%",
            minHeight: "100vh",
            overflowX: "hidden",
        },
        navbar: {
            width: "100%",
            // height: "99px",
            overflow: "hidden",
        },
        wrapper: {
            width: "100%",
            minHeight: "calc(100vh - 125px)",
        },
    };

    return (
        <HistoryRouter history={history}>
            <main role="main" style={sx.app} className="App">
                <div className="Navbar" style={sx.navbar}>
                    <TopNav />
                </div>
                <div style={sx.wrapper} className="App-wrapper">
                    {" "}
                    <ExperimentContext.Provider value={typeMap}>
                        <AnalysisContext.Provider value={resourceDefinitions}>
                            <Routes>
                                <Route key={"default"} path="/" element={<LandingPage />} />
                                <Route
                                    key={"experiments"}
                                    path="/experiments/"
                                    element={
                                        <EventListener topic={"analysis"} prop={"eventMessage"}>
                                            <ExperimentsTable />
                                        </EventListener>
                                    }
                                />
                                <Route
                                    key={"experiment"}
                                    path="/experiment/:id"
                                    element={
                                        <EventListener topic={["analysis", "report"]} prop={"eventMessage"}>
                                            <Experiment />
                                        </EventListener>
                                    }
                                />
                                <Route key={"measurements"} path="/measurements/" element={<MeasurementsPage />} />
                                <Route key={"analysis"} path="/analysis/:id" element={<Analysis />} />
                                <Route key={"login"} path="/login" element={<Login />} />
                                <Route key={"loginfail"} path="/loginfail" element={<LoginFailed />} />
                                <Route key={"errors"} path="/errors" element={<ErrorList />} />
                                <Route key={"errorAnalysis"} path="/error/analysis/:id" element={<ErrorAnalysis />} />
                                <Route key={"licenses"} path="/licenses" element={<Licenses />} />
                                <Route key={"errorImport"} path="/error/import/:id" element={<ErrorImport />} />
                                {/*The below mapping is for DEBUG ONLY! We do not present a such basic view to the user, ever.*/}
                                {/*It makes us look bad!*/}
                                <Route key={"errorDetails"} path="/error/details/:id" element={<ErrorDetails />} />
                                <Route key={"upload"} path="/upload" element={<UploadPage />} />
                                <Route
                                    key={"studies"}
                                    path="/studies"
                                    element={
                                        <EventListener topic={"study-analysis"} prop={"eventMessage"}>
                                            <Studies />
                                        </EventListener>
                                    }
                                />
                                <Route
                                    key={"study"}
                                    path="/study/:id"
                                    element={
                                        <EventListener topic={"study-analysis"} prop={"eventMessage"}>
                                            <Study />
                                        </EventListener>
                                    }
                                />
                                <Route key={"group"} path="/study/:studyId/group/:id" element={<Group />} />
                                <Route key={"result"} path="/study/:studyId/result" element={<Plot />} />
                                <Route key={"playground"} path="/playground" element={<Playground />} />
                                <Route key={"version"} path="/version" element={<VersionPage />} />
                                <Route key={"colors"} path="/colors" element={<ShowColors />} />
                                <Route key={"asterisk"} path="*" element={<NotFound />} />
                                <Route key={"404"} path="/404" element={<NotFound />} />
                                <Route key={"422"} path="/422/:msg" element={<UnprocessableContent />} />
                            </Routes>
                        </AnalysisContext.Provider>
                    </ExperimentContext.Provider>
                </div>
                {<Footer />}
            </main>
        </HistoryRouter>
    );


    function NotFound() {
        return (
            <div>
                <h1>404 Error - Page Not Found</h1>
                <p>The requested page does not exist.</p>
            </div>
        );
    }

    function UnprocessableContent() {
        const {msg} = useParams();
        return (
            <div>
                <h1>422 Error - {msg}</h1>
            </div>
        );
    }
}
