import {
    Grid,
    InputAdornment,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    styled,
    TextField,
    Tooltip,
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import List from "@mui/material/List";
import OutlineBorder from "../../components/OutlineBorder";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import SearchIcon from "@mui/icons-material/Search";
import ExperimentSelectionDialog from "../../components/ExperimentSelectionDialog";
import {ExperimentsApi, MaffconDensityInfo, SingleExperimentResponse} from "../../api";
import {apiConfig} from "../../ApiConfig";
import {randomColor} from "../../helpers/PlotColors";

const experimentsApi = new ExperimentsApi(apiConfig);

interface MultiDensityInputProps {
    className?: string;
    setSelectedExperiment: (selected: string) => void;
    selectedExperiment: string | undefined;
    maffconDensityInfo: MaffconDensityInfo[];
    setMaffconDensityInfo: (selected: MaffconDensityInfo[]) => void;
}

export default styled((props: MultiDensityInputProps) => {
    const inputRef = useRef<HTMLInputElement>();
    const [maffconDensityInfo, setMaffconDensityInfo] = useState<MaffconDensityInfo[]>([]);
    const [selectedExperiment, setSelectedExperiment] = useState<SingleExperimentResponse | undefined>(undefined);
    const [cellDensity, setCellDensity] = useState<string>("");

    useEffect(() => {
        setMaffconDensityInfo(props.maffconDensityInfo);
    }, [props.maffconDensityInfo]);

    useEffect(() => {
        if (props.selectedExperiment !== undefined) {
            experimentsApi.getExperimentById(props.selectedExperiment).then((ex) => {
                setSelectedExperiment(ex);
            });
        }
    }, [props.selectedExperiment]);

    return (
        <>
            <OutlineBorder
                title={"Selected experiment"}
                sx={{
                    height: "40px",
                    marginBottom: "10px",
                }}
            >
                <Grid container flexDirection={"row"} flexWrap={"nowrap"} alignItems={"center"}>
                    <Grid item flexGrow={1}>
                        {selectedExperiment && selectedExperiment.name}
                    </Grid>
                    <Grid item>
                        <ExperimentSelectionDialog
                            multiple={false}
                            setSelectedExperiments={(experiment) => {
                                if (experiment[0] !== undefined) {
                                    experimentsApi.getExperimentById(experiment[0]).then((ex) => {
                                        setSelectedExperiment(ex);
                                    });
                                    props.setSelectedExperiment(experiment[0]);
                                }
                            }}
                        >
                            <Tooltip title={"Select experiment"}>
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                        </ExperimentSelectionDialog>
                    </Grid>
                </Grid>
            </OutlineBorder>
            <Grid container flexDirection={"row"} gap={"10px"} flexWrap={"nowrap"}>
                <Grid item xs={6}>
                    <TextField
                        sx={{marginTop: "10px"}}
                        id={"cell-density"}
                        value={cellDensity}
                        fullWidth
                        onChange={(input: React.ChangeEvent<HTMLInputElement>) => {
                            const n = parseInt(input.target.value || "");
                            if (input.target.value === "" || (isFinite(n) && n.toString() === input.target.value))
                                setCellDensity(input.target.value);
                        }}
                        label="Cell density (cells/L)"
                        size="small"
                        inputRef={inputRef}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={"end"}>
                                    <IconButton
                                        onClick={(e) => {
                                            if (inputRef.current !== undefined) {
                                                const n = parseInt((inputRef.current && inputRef.current.value) || "");
                                                if (isFinite(n) && n.toString() === inputRef.current.value) {
                                                    if (!maffconDensityInfo.find((e) => e.cellDensity == n)) {
                                                        const color = randomColor();
                                                        setMaffconDensityInfo([
                                                            ...maffconDensityInfo,
                                                            {cellDensity: n, color: color},
                                                        ]);
                                                        props.setMaffconDensityInfo([
                                                            ...maffconDensityInfo,
                                                            {cellDensity: n, color: color},
                                                        ]);
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        <DoubleArrowIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <OutlineBorder title={"Selected densities"}>
                        <List
                            sx={{
                                height: "16rem",
                                overflowY: "auto",
                                "& > * > *": {
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                },
                                "& >li button": {
                                    display: "none",
                                },
                                "& >li:hover button": {
                                    display: "block",
                                },
                            }}
                        >
                            {[...maffconDensityInfo]
                                .sort((a, b) => a.cellDensity - b.cellDensity)
                                .map((d) => {
                                    return (
                                        <ListItem value={d.cellDensity.toString()} key={d.cellDensity.toString()}>
                                            <ListItemText primary={d.cellDensity.toString()} />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    onClick={() => {
                                                        const indexToRemove = maffconDensityInfo.findIndex(
                                                            (e) => e.cellDensity == d.cellDensity
                                                        );
                                                        if (indexToRemove != -1) {
                                                            const densities = [...maffconDensityInfo];
                                                            densities.splice(indexToRemove, 1);
                                                            setMaffconDensityInfo(densities);
                                                            props.setMaffconDensityInfo(densities);
                                                        }
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </OutlineBorder>
                </Grid>
            </Grid>
        </>
    );
})((theme) => {
    return {};
});
