import React, {useRef} from "react";
import {Box, styled, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import clsx from "clsx";

export type FileUploadProps = {
    imageButton?: boolean;
    accept: string;
    hoverLabel?: string;
    dropLabel?: string;
    width?: string;
    height?: string;
    backgroundColor?: string;
    image?: {
        url: string;
        imageStyle?: {
            width?: string;
            height?: string;
        };
    };
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onDrop: (event: React.DragEvent<HTMLElement>) => void;
    className?: string;
};

const FileUpload: React.FC<FileUploadProps> = ({
    accept,
    imageButton = false,
    hoverLabel = "Click or drag to upload file(s)",
    dropLabel = "Drop file(s) here",
    width = "100%",
    height = "80px",
    backgroundColor = undefined,
    image: {
        url = "",
        imageStyle = {
            height: "inherit",
        },
    } = {},
    onChange,
    onDrop,
    className,
}) => {
    const [imageUrl, setImageUrl] = React.useState(url);
    const [labelText, setLabelText] = React.useState<string>(hoverLabel);
    const [isDragOver, setIsDragOver] = React.useState<boolean>(false);
    const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false);

    const stopDefaults = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
    };
    const dragEvents = {
        onMouseEnter: () => {
            setIsMouseOver(true);
        },
        onMouseLeave: () => {
            setIsMouseOver(false);
        },
        onDragEnter: (e: React.DragEvent) => {
            stopDefaults(e);
            setIsDragOver(true);
            setLabelText(dropLabel);
        },
        onDragLeave: (e: React.DragEvent) => {
            stopDefaults(e);
            setIsDragOver(false);
            setLabelText(hoverLabel);
        },
        onDragOver: stopDefaults,
        onDrop: (e: React.DragEvent<HTMLElement>) => {
            stopDefaults(e);
            setLabelText(hoverLabel);
            setIsDragOver(false);
            if (imageButton && e.dataTransfer.files[0]) {
                setImageUrl(URL.createObjectURL(e.dataTransfer.files[0]));
            }
            onDrop(e);
        },
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        if (imageButton && event.target.files[0]) {
            setImageUrl(URL.createObjectURL(event.target.files[0]));
        }
        onChange(event);
        event.target.value = ""; // this is reset in order to allow re-uploading the same file (or a file with the same file name)
    };

    return (
        <div className={className}>
            <input
                onChange={handleChange}
                accept={accept}
                className="hidden"
                id="file-upload"
                type="file"
                multiple={true}
            />

            <label htmlFor="file-upload" {...dragEvents} className={clsx("root", isDragOver && "onDragOver")}>
                <Box width={width} height={height} bgcolor={backgroundColor} className="noMouseEvent">
                    {imageButton && (
                        <Box position="absolute" height={height} width={width}>
                            <img alt="file upload" src={imageUrl} style={imageStyle} />
                        </Box>
                    )}

                    {(!imageButton || isDragOver || isMouseOver) && (
                        <>
                            <Box height={height} width={width} className="iconText">
                                <CloudUploadIcon fontSize="large" />
                                <Typography>{labelText}</Typography>
                            </Box>
                        </>
                    )}
                </Box>
            </label>
        </div>
    );
};

export default styled(FileUpload)((theme) => {
    return {
        ".root": {
            border: `1px solid ${theme.theme.palette.background.paper}`,
            cursor: "pointer",
            textAlign: "center",
            display: "flex",
            "&:hover p,&:hover svg,& img": {
                opacity: 1,
            },
            "& p, svg": {
                opacity: 0.4,
            },
            "&:hover img": {
                opacity: 0.3,
            },
        },
        ".noMouseEvent": {
            pointerEvents: "none",
        },
        ".iconText": {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
        },
        ".hidden": {
            display: "none",
        },
        ".onDragOver": {
            borderColor: `${theme.theme.palette.primary.main} !important`,
            "& img": {
                opacity: 0.3,
            },
            "& p, svg": {
                opacity: 1,
            },
        },
    };
});
