import {Grid, IconButton, styled, Tooltip, Typography, useTheme} from "@mui/material";
import {PageContentBlock} from "../../helpers/HelperComponents";
import MeasurementEntry from "../MeasurementEntry";
import React from "react";
import {MeasurementOriginalValues, QcData, SingleExperimentResponse} from "../../api";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {getConfig} from "../../RuntimeConfig";

interface QualityConcernsProps {
    className?: string;
    experiment: SingleExperimentResponse;
    qc: QcData;
    analysisId: string;
}

export default styled((props: QualityConcernsProps) => {
    const theme = useTheme();

    return (
        <>
            {props.qc.hasOwnProperty("qualityConcerns") && (
                <PageContentBlock sx={{paddingTop: "2rem"}}>
                    <Typography variant="h5" style={{textAlign: "center"}}>
                        Quality concerns
                    </Typography>
                    {props.qc.qualityConcerns.length > 0 ? (
                        <Typography variant="body2" color="text.secondary" style={{textAlign: "center"}}>
                            The following measurements were ignored
                        </Typography>
                    ) : (
                        <Typography variant="body2" color="text.secondary" style={{textAlign: "center"}}>
                            No measurements were ignored
                        </Typography>
                    )}
                    {props.qc.qualityConcerns.map((concern) => {
                        const inExperiment =
                            props.experiment.measurements?.find((m) => m.id == concern.measurement.id) !== undefined;
                        concern.measurement.originalValues = {
                            labeledName: concern.measurement.labeledName,
                            unlabeledName: concern.measurement.unlabeledName,
                            complexName: concern.measurement.complexName,
                            labeledConcentration: concern.measurement.labeledConcentration,
                            unlabeledConcentration: concern.measurement.unlabeledConcentration,
                            complexMixtureDilution: concern.measurement.complexMixtureDilution,
                        } as MeasurementOriginalValues;
                        return (
                            <Grid container sx={{paddingRight: "20px"}} key={concern.measurement.id}>
                                <Grid item xs={8}>
                                    <MeasurementEntry measurement={concern.measurement} strikeout={!inExperiment} />
                                </Grid>
                                <Grid item xs={4}>
                                    <div
                                        style={{
                                            fontSize: "8pt",
                                            marginTop: "5px",
                                            borderBottom: "1px solid " + theme.palette.text.secondary,
                                        }}
                                    >
                                        Reason
                                    </div>
                                    <div>{concern.reason}</div>
                                </Grid>
                            </Grid>
                        );
                    })}
                    {props.qc.qualityConcerns.length > 0 && (
                        <img
                            style={{paddingTop: "40px"}}
                            src={`${getConfig("backendUrl")}/analysis/${
                                props.analysisId
                            }/resource/rh_vs_replicates_showing_outliers`}
                            alt={"Plot with outliers"}
                        />
                    )}
                </PageContentBlock>
            )}
        </>
    );
})((theme) => {
    return {};
});
