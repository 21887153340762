/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaffconExtraArguments
 */
export interface MaffconExtraArguments {
    /**
     * 
     * @type {string}
     * @memberof MaffconExtraArguments
     */
    experimentType: MaffconExtraArgumentsExperimentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MaffconExtraArguments
     */
    numberOfCells: number;
    /**
     * 
     * @type {number}
     * @memberof MaffconExtraArguments
     */
    sampleVolume: number;
}


/**
 * @export
 */
export const MaffconExtraArgumentsExperimentTypeEnum = {
    MaffconBayesian: 'MAFFCON-BAYESIAN',
    MaffconNlls: 'MAFFCON-NLLS'
} as const;
export type MaffconExtraArgumentsExperimentTypeEnum = typeof MaffconExtraArgumentsExperimentTypeEnum[keyof typeof MaffconExtraArgumentsExperimentTypeEnum];


/**
 * Check if a given object implements the MaffconExtraArguments interface.
 */
export function instanceOfMaffconExtraArguments(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "experimentType" in value;
    isInstance = isInstance && "numberOfCells" in value;
    isInstance = isInstance && "sampleVolume" in value;

    return isInstance;
}

export function MaffconExtraArgumentsFromJSON(json: any): MaffconExtraArguments {
    return MaffconExtraArgumentsFromJSONTyped(json, false);
}

export function MaffconExtraArgumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaffconExtraArguments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'experimentType': json['experiment_type'],
        'numberOfCells': json['number_of_cells'],
        'sampleVolume': json['sample_volume'],
    };
}

export function MaffconExtraArgumentsToJSON(value?: MaffconExtraArguments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'experiment_type': value.experimentType,
        'number_of_cells': value.numberOfCells,
        'sample_volume': value.sampleVolume,
    };
}

