import {Stack, styled, Tooltip, Typography, useTheme} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import {ExperimentContext} from "../../App";
import {useContext, useEffect, useState} from "react";
import {
    CreateStudyAnalysisRequest,
    ExperimentsApi,
    ExperimentSchema,
    ExperimentType,
    ResponseError,
    StudiesApi,
    StudyAnalysisSchema,
    StudySummarySchema,
    StudyType,
} from "../../api";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AnalyticsIcon from "@mui/icons-material/Analytics";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {PageButtonBar, PageContainer, PageContentBlock, PageHeader} from "../../helpers/HelperComponents";
import {apiConfig} from "../../ApiConfig";
import {handleNetworkError, snackbarSuccess} from "../../helpers/error";
import CreationModal from "./CreationModal";
import DeletionModal from "./DeletionModal";
import {enqueueSnackbar} from "notistack";
import GroupCreationModal from "./Group/GroupCreationModal";
import GroupsTable from "./GroupsTable";
import AnalysisStatus from "../../components/AnalysisStatus";
import StudyDetailsAffinity from "../../components/StudyDetailsAffinity";
import StudyDetailsOccupancy from "../../components/StudyDetailsOccupancy";

const studiesApi = new StudiesApi(apiConfig);
const experimentsApi = new ExperimentsApi(apiConfig);

export default styled(function (props: any) {
    const [studyData, setStudyData] = useState<StudySummarySchema>({} as StudySummarySchema);
    const experimentTypeMap = useContext(ExperimentContext);
    const {id} = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const [studyUpdated, setStudyUpdated] = useState<boolean>(false);
    const [analysisStatus, setAnalysisStatus] = useState<string | null>(null);
    const [reportStatus, setReportStatus] = useState<null>(null);
    const isViewAnalysisDisabled = analysisStatus?.toLowerCase() !== "completed";
    const [maffconExperiments, setMaffconExperiments] = useState<ExperimentSchema[] | undefined>(undefined);

    useEffect(() => {
        if (props.eventMessage !== undefined) {
            let message = JSON.parse(props.eventMessage);
            if (message.study_id == id && message.action == "statuschange") {
                setAnalysisStatus(message.status);
            }
        }
    }, [props.eventMessage]);

    useEffect(() => {
        getStudyData(id as string);
    }, [id, studyUpdated]);

    function updatePage() {
        setStudyUpdated(!studyUpdated);
    }

    function getStudyData(studyId: string): void {
        studiesApi
            .getById(studyId)
            .then((data: StudySummarySchema) => {
                setStudyData(data);
                setAnalysisStatus(data.analysisStatus || "");

                if (data.type == ExperimentType.MaffconBayesian && maffconExperiments === undefined) {
                    let ids: string[] = [];

                    // @ts-ignore
                    if (data.extra?.studyType == StudyType.MaffconMultiDensity) {
                        ids.push(data.extra.experimentId);
                    }
                    // @ts-ignore
                    else if (data.extra?.studyType == StudyType.MaffconMultiAssay) {
                        ids = data.extra?.experiments?.map((e) => e.experimentId) || [];
                    }

                    if (ids.length) {
                        (async () => {
                            let experiments: ExperimentSchema[] = [];

                            for (let ex of ids) {
                                try {
                                    const experiment = await experimentsApi.getExperimentById(ex);
                                    experiments.push(experiment);
                                } catch (e) {} // Ignore
                            }

                            setMaffconExperiments(experiments);
                        })();
                    }
                }
            })
            .catch((response: ResponseError) => {
                if (response.response.status === 404) {
                    navigate("/404", {replace: true});
                } else if (response.response.status === 422) {
                    navigate("/422/" + response.response.statusText, {replace: true});
                } else {
                    handleNetworkError(response).then((target) => {
                        if (target) {
                            navigate(target);
                        }
                    });
                }
            });
    }

    const deleteStudy = (study_id: string) => {
        studiesApi
            .deleteStudySoft(study_id)
            .then((_response) => {
                enqueueSnackbar(`Successfully deleted.`, snackbarSuccess);
                navigate("/studies", {replace: true});
            })
            .catch((response: ResponseError) => {
                if (response.response.status === 204) {
                    enqueueSnackbar(`Successfully deleted.`, snackbarSuccess);
                    navigate("/studies", {replace: true});
                }
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    };

    function getAnalysisStatus(studyId: string): void {
        studiesApi
            .getStudyAnalysis(studyId)
            .then((data) => {
                const parsedData = JSON.parse(data);
                setAnalysisStatus(parsedData.status || null);
            })
            .catch((response: ResponseError) => {
                if (response && response.response && response.response.status === 204) {
                    setAnalysisStatus("Not run");
                    return;
                }
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
        // studiesApi TODO in the future
        // .getReportStatus(experimentId)
        // .then((resp) => {
        //     setReportStatus(resp);
        // })
        // .catch((response: ResponseError) => {
        //     handleNetworkError(response).then((target) => {
        //         if (target) {
        //             navigate(target);
        //         }
        //     });
        // });
    }

    const runAnalysis = (id: string) => {
        let request: CreateStudyAnalysisRequest = {studyId: studyData.id};
        studiesApi
            .createStudyAnalysis(studyData.id, request)
            .then((response: StudyAnalysisSchema) => {
                setAnalysisStatus(response.status || null);
                enqueueSnackbar(`Analysis started.`, snackbarSuccess);
                getAnalysisStatus(studyData.id);
            })
            .catch((response: ResponseError) => {
                handleNetworkError(response).then((target) => {
                    if (target) {
                        navigate(target);
                    }
                });
            });
    };

    function isAllExperimentsStatusComplete() {
        if (
            studyData.extra?.studyType === undefined || studyData.extra.studyType == StudyType.AffinityConcentration
        ) {
            if (studyData.groups && studyData.groups?.length > 0) {
                return studyData.groups?.every((group) => {
                    return group.experiments?.every(
                        (experiment) => experiment.analysisStatus?.toUpperCase() === "COMPLETED"
                    );
                });
            } else {
                return false;
            }
        } else if (studyData.type == ExperimentType.MaffconBayesian) {
            // @ts-ignore
            if (maffconExperiments) {
                return maffconExperiments.every((e) => e.validAnalysis !== undefined);
            }

            return false;
        }

        return false;
    }
    const viewAnalysisButton = (disabledButton: boolean) => (
        <Tooltip title="View analysis">
            <div>
                <IconButton
                    sx={{
                        backgroundColor: "primary.main",
                        color: "primary.contrastText",
                        "&:hover": {backgroundColor: "primary.dark"},
                        "&:disabled": {backgroundColor: "action.disabledBackground"},
                    }}
                    disabled={disabledButton}
                    data-testid="view-analysis-button"
                >
                    <AnalyticsIcon />
                </IconButton>
            </div>
        </Tooltip>
    );

    return (
        <div className={props.className}>
            <PageHeader>
                <Stack spacing={0.5}>
                    <Typography variant="h4">{studyData.name}</Typography>
                    <Typography variant="body2" color="text.secondary">
                        Study
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {studyData.description}
                    </Typography>
                </Stack>
            </PageHeader>

            <PageContainer>
                <PageContentBlock>
                    <PageButtonBar
                        backLink={
                            <Link to={"/studies"}>
                                <Tooltip title={"Back to studies"}>
                                    <IconButton>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        }
                    >
                        <div style={{textAlign: "right", padding: "1rem"}}>
                            <div
                                style={{
                                    display: "inline-block",
                                    position: "relative",
                                    top: "-10px",
                                    marginRight: "30px",
                                }}
                            >
                                <AnalysisStatus
                                    analysisStatus={analysisStatus || "Not run"}
                                    analysisId={studyData?.validAnalysis}
                                    created={studyData.created}
                                    disableFailedLink={true}
                                />
                            </div>
                        </div>

                        {!isViewAnalysisDisabled ? (
                            <Link to={`/study/${id}/result`}>{viewAnalysisButton(isViewAnalysisDisabled)}</Link>
                        ) : (
                            viewAnalysisButton(isViewAnalysisDisabled)
                        )}
                        <Tooltip title={"Run analysis"}>
                            <div>
                                <IconButton
                                    sx={{
                                        backgroundColor: "primary.main",
                                        color: "primary.contrastText",
                                        "&:hover": {backgroundColor: "primary.dark"},
                                        "&:disabled": {backgroundColor: "action.disabledBackground"},
                                        cursor: "pointer",
                                    }}
                                    disabled={
                                        !isAllExperimentsStatusComplete() ||
                                        (analysisStatus?.toLowerCase() !== "failed" && analysisStatus !== "")
                                    }
                                    onClick={() => runAnalysis(id as string)}
                                    data-testid="run-analysis-button"
                                >
                                    <PlayArrowIcon />
                                </IconButton>
                            </div>
                        </Tooltip>
                        {(studyData.extra?.studyType === undefined || studyData.extra.studyType == "AFFINITY-CONCENTRATION") && (
                            <GroupCreationModal
                                studyId={id as string}
                                study={studyData}
                                groupNames={studyData.groups?.map((item) => item.name)}
                                groupUpdatedCallback={updatePage}
                            >
                                <Tooltip title="New group">
                                    <IconButton>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </GroupCreationModal>
                        )}

                        <CreationModal studyData={studyData} studyUpdatedCallback={updatePage}>
                            <Tooltip title={"Edit study"}>
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </CreationModal>
                        <DeletionModal studyId={id as string} deleteStudy={deleteStudy}>
                            <Tooltip title={"Delete study"}>
                                <IconButton style={{backgroundColor: theme.palette.error.main}}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </DeletionModal>
                    </PageButtonBar>
                    {studyData.extra?.studyType === undefined || studyData.extra.studyType == "AFFINITY-CONCENTRATION" ? (
                        <StudyDetailsAffinity studyData={studyData} />
                    ) : (
                        <StudyDetailsOccupancy studyData={studyData} />
                    )}
                </PageContentBlock>
            </PageContainer>
        </div>
    );
})((theme) => {
    return {};
});
