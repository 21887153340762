/* tslint:disable */
/* eslint-disable */
/**
 * Fluidity Cloud
 * Fluidic Analytics Fluidic Cloud v2 OpenAPI schema
 *
 * The version of the OpenAPI document: 5.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateStudyAnalysisRequest
 */
export interface CreateStudyAnalysisRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateStudyAnalysisRequest
     */
    studyId: string;
}

/**
 * Check if a given object implements the CreateStudyAnalysisRequest interface.
 */
export function instanceOfCreateStudyAnalysisRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "studyId" in value;

    return isInstance;
}

export function CreateStudyAnalysisRequestFromJSON(json: any): CreateStudyAnalysisRequest {
    return CreateStudyAnalysisRequestFromJSONTyped(json, false);
}

export function CreateStudyAnalysisRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStudyAnalysisRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studyId': json['study_id'],
    };
}

export function CreateStudyAnalysisRequestToJSON(value?: CreateStudyAnalysisRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'study_id': value.studyId,
    };
}

